import { getAppSelected } from './../../reducers/index';
import { Store } from '@ngrx/store';
import {
  Component,
  Output,
  Input,
  AfterViewInit,
  EventEmitter,
  ElementRef
} from '@angular/core';
import { combineLatest, BehaviorSubject } from 'rxjs';
import * as Moment from 'moment';
import * as Flatpicker from 'flatpickr';
import * as fromRoot from '../../reducers';
import { map, take } from 'rxjs/operators';

type MomentRange = {
  beginning: Moment.Moment | null;
  ending: Moment.Moment | null;
};

/**
 * next時に値を変更できるSubject
 */
export class ModifierBehaviorSubject<T> extends BehaviorSubject<T> {
  /**
   * @param modifier - 変更する関数
   */
  constructor(_value: T, private modifier: (T) => T = v => v) {
    super(_value);
  }
  next(value: T) {
    super.next(this.modifier(value));
  }
}

@Component({
  templateUrl: './range-picker.component.html',
  selector: 'app-range-picker',
  styleUrls: ['./range-picker.component.scss']
})
export class RangePickerComponent implements AfterViewInit {
  @Input()
  public startDate: ModifierBehaviorSubject<Moment.Moment>;
  @Input()
  public endDate: ModifierBehaviorSubject<Moment.Moment>;
  @Output()
  public onClose = new EventEmitter();
  @Output()
  public onRangeChange = new EventEmitter<{
    startAt: Moment.Moment;
    endAt: Moment.Moment;
  }>();

  public isOpen = false;

  private rangeStart;
  private rangeEnd;

  public createdDate: Moment.Moment;

  public _el: HTMLElement;
  constructor(private el: ElementRef, private store$: Store<fromRoot.State>) {
    this._el = el.nativeElement;
  }

  ngAfterViewInit() {
    this.rangeStart = new Flatpicker(this._el.querySelector('.range.start'), {
      dateFormat: 'y/m/d',
      enableTime: false,
      maxDate: new Date(),
      onClose: selectedDates => {
        this.startDate.next(Moment(selectedDates[0]));
        this.onClose.emit();
      }
    });
    this.rangeEnd = new Flatpicker(this._el.querySelector('.range.end'), {
      dateFormat: 'y/m/d',
      enableTime: false,
      maxDate: Moment()
        .endOf('day')
        .toDate(),
      onClose: selectedDates => {
        this.endDate.next(Moment(selectedDates[0]));
        this.onClose.emit();
      }
    });
    this.startDate.subscribe(startAt => {
      this.rangeStart.setDate(startAt.toDate(), false);
      this.rangeEnd.set('minDate', startAt.toDate());
    });
    this.endDate.subscribe(endAt => {
      this.rangeEnd.setDate(endAt.toDate(), false);
      this.rangeStart.set('maxDate', endAt.toDate());
    });
    this.store$
      .select(getAppSelected)
      .pipe(
        take(1),
        map(app => app.created_date),
        map(str => Moment(str).startOf('day'))
      )
      .subscribe(m => {
        this.rangeStart.set('minDate', m.toDate());
        this.createdDate = m;
      });

    combineLatest(this.endDate, this.startDate).subscribe(selectedDates => {
      const [endAt, startAt] = selectedDates;
      this.onRangeChange.emit({ endAt, startAt });
    });
  }

  public generateRange(alias: string): MomentRange {
    const range: MomentRange = {
      beginning: null,
      ending: null
    };
    const now = Moment();
    switch (alias) {
      case 'past-week': {
        range.beginning = Moment(now)
          .subtract(1, 'week')
          .add(1, 'day');
        range.ending = Moment(now);
        break;
      }
      case 'last-month': {
        range.beginning = Moment(now)
          .subtract(1, 'month')
          .startOf('month'); // 前の月の初日
        range.ending = Moment(now)
          .subtract(1, 'month')
          .endOf('month'); // その月の1日の1日前
        break;
      }
      case 'past-month': {
        range.beginning = Moment(now)
          .subtract(1, 'month')
          .add(1, 'day');
        range.ending = Moment(now);
        break;
      }
      case 'this-month': {
        range.beginning = Moment(now).startOf('month');
        range.ending = Moment(now);
        break;
      }
    }
    return range;
  }

  public setRange(alias: string) {
    const range = this.generateRange(alias);
    this.rangeEnd.set('minDate', null);
    this.rangeStart.set('maxDate', null);
    this.startDate.next(range.beginning);
    this.endDate.next(range.ending);
  }
}
