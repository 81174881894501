import {
  Component,
  AfterViewInit,
  ElementRef,
  OnDestroy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import * as Flatpickr from 'flatpickr';

@Component({
  selector: 'app-segment-date-picker',
  template: `
    <input type="date" class="input" />
  `
})
export class DatePickerComponent implements AfterViewInit, OnDestroy {
  private _el: Element;
  private picker: Flatpickr;

  @Input()
  date: Date;
  @Output()
  dateChange: EventEmitter<Date> = new EventEmitter();

  constructor(private el: ElementRef) {
    this._el = el.nativeElement;
  }
  ngAfterViewInit() {
    const form = this._el.querySelector('.input');
    this.picker = new Flatpickr(form, {
      enableTime: true,
      defaultDate: this.date || new Date(),
      onChange: selectedDates => {
        if (!selectedDates[0]) {
          return;
        }
        this.dateChange.next(selectedDates[0]);
      }
    });
  }
  ngOnDestroy() {
    this.picker.destroy();
  }
}
