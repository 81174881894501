import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Notification } from '../../services/notification/notification.service';
import { UserService } from '../../services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { Go } from '../../actions/router';
import * as fromRoot from '../../reducers';
import { Subject } from 'rxjs';
import { ContactModel } from '../../models/contact.model';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
  public contact: ContactModel;
  public valid = false;
  public sending = false;
  constructor(
    private user: UserService,
    private translate: TranslateService,
    private notification: Notification,
    private store: Store<fromRoot.State>
  ) {}

  ngOnInit() {}

  public submit() {
    if (!this.valid || !this.contact) {
      return;
    }
    this.sending = true;
    this.user
      .contact(this.contact)
      .pipe(finalize(() => (this.sending = false)))
      .subscribe(
        () => {
          this.translate.get('CONTACT.SUCCESS').subscribe((m: string) => {
            this.notification.open(m, 'success');
          });
          this.store.dispatch(
            new Go({
              path: ['home']
            })
          );
        },
        response => {
          this.notification.open(response.error.error, 'warning');
        }
      );
  }
}
