/* tslint:disable */

export const ja = ` 第1条（規約の適用）

1. この利用規約（以下「本規約」といいます。）は、株式会社GNEX（以下「GNEX」といいます。）が提供するサービス「Push7」（以下「本サービス」といいます。）の利用者が遵守すべき事項及び利用者とGNEXとの関係を定めるものです。

2. 本サービスの利用者は、本規約の内容を十分理解したうえで、その内容を遵守することに同意して本サービスを利用するものとし、本サービスを利用した場合には、当該利用者は本規約を遵守することに同意したものとみなします。

第2条（定義）

本規約で使用する用語の定義については、本規約の他の条項で定めるほか、次の各号に定める定義に従うものとします。

(1) 本サービスの利用者のうち「サイト運営者」とは、本規約に同意の上、GNEX所定の方法により、自身の運用するウェブサイトに本サービスのプッシュ通知機能を適用することを希望して申込み、GNEXから承諾を受け、登録を完了した者をいいます。

(2)「アプリケーション登録ページ」とは、サイト運営者が本サービス登録後、GNEXがサイト運営者に提供するログインID及びパスワードの認証により、プッシュ通知を行う対象となるウェブサイトを設定することを目的としたページをいいます。

(3)「対象サイト」とは、サイト運営者がアプリケーション登録ページを利用して登録するプッシュ通知を行う対象となるウェブサイトをいいます。

(4) 本サービスの利用者のうち「ユーザー」とは、本サービスを利用して、対象サイトを閲覧する者をいいます。

(5)「知的財産権」とは、特許権、実用新案権、意匠権、著作権（著作権法第29条及び第28条に定める権利を含みます。）、商標権その他の知的財産権に関して法令により定められた権利又は法律上保護される利益にかかる権利を意味します。

第3条（利用登録）

1. 本サービスの利用を希望する者は、本規約の内容に同意した上で、所定の手続きを行い、本サービスの利用の申込みを行うものとします。サイト運営者は、申込み完了後、アプリケーション登録ページより対象サイトを登録するものとし、GNEXの提供するAPI接続手続書に従い、本サービスのシステムに接続を行うものとします。

2. 本サービスの申込みにあたっては、真実、正確かつ最新の情報をGNEXに提供するものとします。

3. GNEXは、第1項に基づき登録を申請した者が、以下の各号のいずれかの事由に該当する場合は、当該登録を拒否することができるものとします。

(1) 本規約に過去に違反し、又は違反するおそれがあるとGNEXが判断した場合

(2) GNEXに提供された登録情報の全部又は一部につき虚偽、誤記、記載漏れがあった場合

(3) 未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人､保佐人又は補助人の同意等を得ていなかった場合

(4) 所属する企業又は業界団体の内部規則等に違反し、当該企業、所轄監督庁又は業界団体から何らかの処分を受けたことがある場合

(5) 反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味する。以下同様とします。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っているとGNEXが判断した場合

(6) その他、GNEXが会員登録を適当でないと判断した場合

第4条（登録情報の変更）

1. サイト運営者は、登録情報に変更があった場合には、速やかにアプリケーション登録ページ又はGNEX所定の手続きによりGNEXに届け出るものとします。

2. サイト運営者が前項に基づく届出を怠ったことにより、不利益を被ったとしても、GNEXはその責任を負いません。また、サイト運営者が前項に基づく届出を怠ったことによりGNEXがサイト運営者に発送した通知が到達せず、または遅着した場合、当該通知は通常到達すべき時にサイト運営者に到達したものとみなします。

第5条（Cookie及びIPアドレス情報）

1. サイト運営者は、次の各号に掲げる目的を達成するため、GNEXが対象サイトのCookieによりユーザーから取得したアクセスログ等（ドメイン名、IPアドレス、アクセス日時、閲覧したページのアドレス情報、行動履歴等。以下同じ。）を利用することに、承諾するものとします。

(1) 本サービスの提供のため

(2) ユーザーの動向の分析や本サービスの技術強化、新たな企画・開発、改良をするため

2. サイト運営者は、ユーザーに対してアクセスログ等を取得すること及び前項の目的のために使用する可能性があることを対象サイトのプライバシーポリシーに記載するなどしてユーザーに対して告知するものとします。

第6条（利用料金）

GNEXは、サイト運営者に対し、原則無償で本サービスを提供するものとし、有償プランを希望する利用者に対しては、Push7申込書に定める利用料金を支払うことを前提に、本サービスを提供するものとします。なお、料金体系の如何を問わずサイト運営者が選択した本サービスのプランのプッシュ数又はAPIリクエスト数のいずれかがプランに定める上限に達した場合には、本サービスは停止されるものとします。

第7条（ユーザー対応）

1. サイト運営者は、自らの責任において対象サイトのユーザーをサポートするものとします。

2. GNEXは、ユーザーその他の第三者から、対象サイトに起因して本サービスに関する問い合わせを受けた場合には、必要に応じて、サイト運営者にサポート対応の一部または全部についての協力を要請することができるものとし、この場合、サイト運営者は、GNEXの対応に必要な協力を行うものとします。

3. 前項の定めにかかわらず、GNEXは、問合わせ等を行ったユーザーその他第三者に対して、GNEXの判断により、サイト運営者の連絡先等を通知し、サイト運営者への連絡を促すことができるものとします。この場合、サイト運営者は、直接当該ユーザー等への対応を行い、自己の責任と費用負担によりこれを解決するものとします。

第8条（本サービスの停止・中断）

1. GNEXは、以下の場合、予告なく本サービスを一時的に停止することができます。

(1) 天災事変、停電その他のGNEXの責に帰せざる事由により、本サービスに障害等が発生したとき

(2) 電気通信設備等の障害、停止、その他の事由によって本サービスに関するネットワーク運営に影響が生じたとき

(3) GNEXが設置する電気通信設備の障害等が生じたとき

2. GNEXは、ネットワーク運営に影響を与える施設の電気通信設備の保守または工事が行われる場合、可能な限り事前にサイト運営者へ通知することにより本サービスを一時的に停止することができます。

3. GNEXは、セキュリティやパフォーマンスの向上または監視に伴うメンテナンス作業のため、可能な限り事前に通知することにより本サービスを一時的に停止することができます。

4. GNEXは、サイト運営者が第3条第3項各号に該当した場合又は本規約に違反すると判断した場合には、事前の通知を要することなく、本サービスの利用を停止する措置を講じることができるものとします。

5. GNEXは、理由の如何を問わず、前各項に定める利用停止によりサイト運営者又はユーザーに生じた損害から一切免責されるものとします。

第9条（権利帰属）

1. GNEXは、本サービスを第三者に宣伝・周知させる目的で、本サービスの機能が対象サイトにおいて利用されている様子をGNEXの広告物やホームページ、ブログ、資料等の媒体において利用することができるものとします。

2. 本サービスに関する知的財産権は、全てGNEXに帰属するものとし、サイト運営者及びユーザーに対する登録の許可は、サイト運営者及びユーザーに対して、本サービスの利用目的を超えて、本サービスに含まれる知的財産権の利用を許諾するものではありません。

3. 本サービスに関する集計データ及びサイト運営者及びユーザーに関する集計データの利用等にかかる一切の権利は、GNEXに帰属するものとします。

第10条（秘密保持）

1. サイト運営者は、GNEXの事前の書面による承諾なくして、本サービスを通じてGNEXから口頭、書面又は管理サイトを問わず開示されたGNEXの技術上、営業上又は業務上の一切の情報（アイディア、ノウハウ、発明、図面、写真、仕様、データなどを含み、以下「秘密情報」といいます。）を、本サービスの利用以外の目的に使用してはならず、また第三者に開示、漏洩してはならないものとします。

2. 前項の定めに関わらず、次の各号に定める事項は、秘密情報から除外します。

(1) GNEXから開示を受ける前に、サイト運営者が正当に保有していたことを証明できる情報

(2) GNEXから開示を受ける前に、公知となっていた情報

(3) GNEXから開示を受けた後に、サイト運営者の責に帰すべからざる事由により公知となった情報

(4) サイト運営者が、正当な権限を有する第三者から秘密保持義務を負うことなく正当に入手した情報

(5) サイト運営者が、開示された情報によらず独自に開発した情報

第11条（禁止事項）

GNEXは、本サービスの利用において、サイト運営者が次に定める行為を行うことを禁止します。

(1) 本規約に違反する行為

(2) GNEX又は第三者の知的財産権、名誉権、プライバシー権、肖像権その他法令上又は契約上の権利を侵害する行為

(3) 第三者になりすます行為または意図的に虚偽の情報を流布させる行為

(4) GNEXの承諾なく一個人又は一法人が複数の対象サイトに関して本サービスの提供を受ける行為

(5) 本サービスを利用して、ユーザーにウィルスに感染するサイト、猥褻・アダルト関連サイト、ギャンブル・賭博に関連するサイト（公営競技、公営くじまたは合法的な遊戯を除きます。）又は金銭詐取目的のサイト等へのアクセスを促すURLの通知をし、若しくはアイコン等に猥褻、児童ポルノ又は虐待等の画像を表示させる設定を行う行為

(6) 本サービスを個人、法人、商業、非商業を問わず再販または再配布、リバースエンジニアリング等をおこなう行為（但し、GNEXが書面又は電子的方法によって承諾をした場合にはこの限りではありません。）

(7) 本サービスの不具合を意図的に利用したり、本サービスを不正に操作したり、本サービスのサーバーやネットワークシステムに支障を与える行為、その他GNEXによる本サービスの運営又は他のユーザーによる本サービスの利用に支障を与える行為

(8) 犯罪的行為を助長する表現、その他反社会的な内容を含み他人に不快感を与える表現をする行為

(9) 公序良俗を害するおそれのある行為

(10)反社会的勢力に対する利益供与その他の協力行為

(11)その他、GNEXが不適切と判断する行為

第12条（解約）

サイト運営者はいつでも本サービスの利用を終了することができます。但し、本サービスの有償プランの利用者は、解約を希望する月が開始する日の15日前までに、GNEXの定める方法により事前にその旨を通知するものとします。

第13条（解除等）

GNEXは、サイト運営者が次の各号のいずれかに該当する場合、何らの通知・催告をすることなく、本サービスの全部または一部を解除する等、GNEXが適切と判断する措置をとることができるものとします。

(1) 第3条第3項各号に該当した場合

(2) 本規約に違反した場合

(3) 登録情報、その他GNEXへの届出内容に虚偽の記載があるとGNEXが判断した場合

(4) 対象サイトに不適切な内容が掲載されているとGNEXが判断した場合

(5) 所在が不明となった場合または連絡が不可能となった場合

(6) 正当な理由なく本規約に基づく義務を履行する見込みがないと認められる場合

(7) 破産手続開始、民事再生手続開始、会社更生手続開始、特別清算申立、民事調停申立、あるいはこれらのための保全手続の申立がなされあるいは受けた場合

(8) 差押、仮差押、仮処分、強制執行または滞納処分等を受けた場合

(9) 自己振出の手形または小切手が不渡りとなった場合

(10)監督官庁から営業の取消、停止等の処分を受けた場合

(11)GNEXに重大な損害を与えた場合またはそのおそれがある場合

(12)その他、信用状況が悪化しまたはそのおそれがあると認められる相当の事由がある場合

第14条（免責）

1. GNEX（およびGNEXと協働する第三者。以下本条において同じ。）は、本サービスの本サービス内のコンテンツ、特定の機能、その信頼性、利用可能性について、何ら保証するものではなく、一切の責任を負わないものとします。

2. GNEXは、本サービスにおいて、サイト運営者及びユーザーを含む利用者並びに第三者間で生じたトラブル（違法または公序良俗に反する行為の提案、名誉毀損、侮辱、プライバシー侵害、脅迫、誹謗中傷、いやがらせ等を含みますが、これらに限られません。）及び損害に関して、一切の責任を負わないものとします。

3. GNEXは、対象サイトの内容が、ユーザー又は第三者の権利を侵害し、又は、権利の侵害に起因して紛争が生じた場合、その侵害及び紛争に対して何らの責任も負わないものとします。

4. GNEXは、本サービス提供のためのコンピューターシステム及びサーバーの障害等により生じた損害について、賠償する義務を一切負わないものとします。

5. GNEXは、サイト運営者又はユーザーが使用するコンピューター、回線、ソフトウェア等の環境等に基づき生じた損害について、賠償する義務を一切負わないものとします。

6. GNEXは、本サービス及び対象サイトならびにサイト運営者及びユーザーを含む第三者のウェブサイトからのダウンロードやコンピュータウイルス感染等により発生した、コンピューター、回線、ソフトウェア等の損害について、賠償する義務を一切負わないものとします。

7. GNEXは、本サービスを通じて行われたサイト運営者及びユーザーを含む第三者間の取引について、一切の責任を負わないものとし、全ての取引は当該第三者間の責任において行われるものとします。

8. GNEXは、本サービスに関し、遅滞、変更、停止、中止、廃止、及び本サービスを通じて提供される情報等の消失、その他本サービスに関連して発生した損害について、一切の責任を負わないものとします。

第15条（権利義務の譲渡禁止）

サイト運営者は、GNEXの書面による事前の承諾なく、本規約上の地位又は本規約に基づく権利若しくは義務を、第三者に譲渡若しくは担保提供し、又は引受けさせる等の処分を行ってはならないものとします。

第16条（損害賠償）

1. サイト運営者は、本規約の各条項のいずれかに違反し、または履行を怠り、自己の責に帰すべき事由によりGNEXに損害を与えた場合には、本サービスの解除の有無にかかわらず、GNEXが被った損害を賠償しなければならないものとします。

2. サイト運営者が管理する対象サイトに起因して、ユーザーその他第三者からの問合わせ、苦情、紛争等が発生し、これによりGNEXが損害を被った場合は、サイト運営者はその損害及び合理的な範囲内での費用（弁護士費用を含みます。）を賠償するものとします。

第17条（本規約の改訂）

本規約は、GNEXの判断により事前の予告なく変更・追加・削除されることがあります。サイト運営者は、本規約変更後に本サービスを利用した場合には、変更された本規約の内容に同意したものとみなします。

第18条（協議）

サイト運営者及びGNEXは、本規約に定めのない事項または本規約の各条項の解釈に疑義が生じた場合には、誠実に協議を行い、速やかに解決を図るものとします。

第19条（準拠法・管轄裁判所）

本規約は、日本法に準じて解釈されるものとし、本規約及び本サービスに起因又は関連して発生する一切の紛争については、東京地方裁判所もしくは東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
`;
