
    <div class="field">
      <article class="message is-info">
        <div class="message-header">
          オートメーションルールによるパラメータの削除
        </div>
        <div class="message-body">
          <p>
            オートメーションルールが実行された際、「実行対象」であり「スキップ条件」を満たさない購読者からパラメータを削除することができます。
          </p>
          <p>
            パラメータは、改行区切りで入力してください。パラメータに利用できるのは、半角英数字・ハイフン・アンダーバーです。
          </p>
          <p>
            ワイルドカードとして、<code>%</code>を利用できます。<code>%</code>は、0文字以上の任意の文字列とマッチし、マッチしたパラメータ全てが削除されます。
          </p>
        </div>
      </article>
      <div class="control">
        <textarea
          class="textarea"
          placeholder="parameter1
parameter2"
          [(ngModel)]="strParameters"
        ></textarea>
      </div>
    </div>
  