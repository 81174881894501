<div class="main-nav">{{ 'ANALYTICS.LABEL' | translate }}(β)</div>

<div class="tabs is-medium p7-tabs">
  <ul class="tabs-gray">
    <li [routerLink]="['overview']" [routerLinkActive]="['is-active']">
      <a>{{ 'ANALYTICS.OVERVIEW.LABEL' | translate }}</a>
    </li>
    <li [routerLink]="['performance']" [routerLinkActive]="['is-active']">
      <a>{{ 'ANALYTICS.PERFORMANCE.LABEL' | translate }}</a>
    </li>
    <!--
    <li class="is-disabled" [routerLink]="['list']" [routerLinkActive]="['is-active']"><a>{{'ANALYTICS.USERS.LABEL'|translate}}</a></li>
    -->
  </ul>
</div>

<router-outlet></router-outlet>
