<div class="modal is-wide is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{ 'ABTEST.NEW' | translate }}
      </div>
      <div class="content">
        <div class="main-title">
          <label class="label">{{ 'ABTEST.AB_TITLE' | translate }}</label>
          <p class="control">
            <input
              class="input is-medium"
              [(ngModel)]="test.name"
              type="text"
            />
          </p>
        </div>
        <hr />
        <section *ngFor="let noti of test?.pushs; let i = index">
          <hr *ngIf="i !== 0" />
          <div class="is-flex between">
            <h2>{{ 'ABTEST.TEST_CASE' | translate }}{{ i + 1 }}</h2>
            <a
              class="delete is-large"
              (click)="remove(i)"
              [class.is-invisible]="!removable"
            ></a>
          </div>
          <app-notification-editor
            horizonal="true"
            [(notification)]="test.pushs[i]"
          ></app-notification-editor>
        </section>
        <ng-container *ngIf="addable">
          <hr />
          <div class="flex has-text-centered">
            <button class="button is-primary" (click)="add()">
              {{ 'ABTEST.ADD_CASE' | translate }}
            </button>
          </div>
        </ng-container>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button class="button" (click)="close()">
            {{ 'ABTEST.CANCEL' | translate }}
          </button>
          <button
            class="button is-success"
            (click)="submit()"
            [class.is-loading]="submitting"
          >
            {{ 'ABTEST.CREATE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="modal-close" (click)="close()"></button>
</div>
