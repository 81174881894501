<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-header has-text-weight-bold is-size-4">
        <div class="is-flex is-align-items-center">
          <a class="icon mr-2" (click)="back()"
            ><span class="fa fa-arrow-circle-left"></span
          ></a>
          <span>オートメーション配信履歴</span>
        </div>
      </div>
      <div class="container-body is-paddingless">
        <ng-container
          *ngIf="actions$ | async; then Loaded; else Loading"
        ></ng-container>
        <ng-template #Loaded>
          <ng-container
            *ngIf="(actions$ | async)?.length > 0; then List; else NoAction"
          ></ng-container>
          <ng-template #List>
            <table class="table is-striped is-fullwidth p7-table">
              <thead>
                <tr>
                  <th>
                    実行時刻
                  </th>
                  <th>
                    実行ステータス
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let action of actions$ | async">
                  <td>
                    {{ action.created_at | date: 'yyyy年MM月dd日 HH時mm分' }}
                  </td>
                  <td>
                    <ng-container [ngSwitch]="action.status">
                      <ng-container *ngSwitchCase="'skipped'">
                        スキップ済み
                      </ng-container>
                      <ng-container *ngSwitchCase="'done'">
                        実行完了
                      </ng-container>
                    </ng-container>
                  </td>
                  <td class="has-text-right">
                    <button
                      class="button is-success is-outlined"
                      (click)="openActionDetail(action)"
                      [disabled]="action.status !== 'done'"
                    >
                      <span>詳細を表示</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-template>
          <ng-template #NoAction>
            <div class="p-4">
              <article class="message is-info">
                <div class="message-body">
                  オートメーションルールによるアクションの履歴がありません
                </div>
              </article>
            </div>
          </ng-template>
        </ng-template>
        <ng-template #Loading>
          <app-loading></app-loading>
        </ng-template>
      </div>
      <div class="container-footer" *ngIf="(actions$ | async)?.length > 0">
        <div class="pagination is-left">
          <button
            (click)="page$.next(page$.getValue() - 1)"
            class="pagination-previous has-background-primary"
            [disabled]="page$.getValue() == 1"
          >
            <i class="fa fa-caret-left" aria-hidden="true"></i>
          </button>
          <div class=" pagination-list">
            <span class="button is-primary"
              >{{ page$.getValue() }}/{{ max }}</span
            >
          </div>
          <button
            (click)="page$.next(page$.getValue() + 1)"
            class="pagination-next has-background-primary"
            [disabled]="page$.getValue() == max"
          >
            <i class="fa fa-caret-right" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
