
    <div class="message" *ngIf="value">
      <div
        class="message-header"
        [appAstDraggable]="value"
        (onMoved)="removeFromParentNode.emit()"
      >
        <p>ブラウザ</p>
        <a
          *ngIf="!hideDelete"
          class="delete"
          (click)="removeFromParentNode.emit()"
        ></a>
      </div>
      <div class="message-body">
        <div class="select">
          <select
            [(ngModel)]="value.browser"
            (change)="value.browser = $event.target.value"
          >
            <option value="Chromium">Chrome</option>
            <option value="Firefox">Firefox</option>
            <option value="iOS">iOS</option>
          </select>
        </div>
      </div>
    </div>
  