import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth/auth.service';
import { map } from 'rxjs/operators';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(route: ActivatedRouteSnapshot) {
    // ログインしていない場合は/loginにリダイレクト
    return this.authService.isLoggedIn$.pipe(
      map(x => x || this.router.createUrlTree(['login']))
    );
  }
}
