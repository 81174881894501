
    <form [formGroup]="form">
      <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content">
          <div class="box">
            <div class="content-header is-yellow">
              オートメーションルールの{{ !!ruleId ? '編集' : '作成' }}
            </div>
            <div class="content">
              <app-automation-rule-form
                formControlName="rule"
              ></app-automation-rule-form>
            </div>

            <div class="content-footer">
              <div class="is-flex is-justify-content-space-between">
                <div>
                  <button
                    *ngIf="!!ruleId"
                    class="button is-danger is-outlined p7-button"
                    [class.is-loading]="loading"
                    (click)="remove()"
                  >
                    削除
                  </button>
                </div>
                <div>
                  <button
                    class="button is-danger is-outlined p7-button mr-1"
                    [disabled]="loading"
                    (click)="close()"
                  >
                    キャンセル
                  </button>
                  <button
                    class="button is-success p7-button"
                    [disabled]="!form.valid"
                    [class.is-loading]="loading"
                    (click)="submit()"
                  >
                    {{ !!ruleId ? '更新' : '作成' }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="modal-close" (click)="close()"></button>
      </div>
    </form>
  