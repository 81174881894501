<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{ 'PUSH.RESERVED.DELETE_CONFIRM' | translate }}
      </div>
      <div class="content">
        <div>
          <p>{{ 'PUSH.RESERVED.DELETE_MESSAGE' | translate }}</p>
        </div>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button class="button is-info p7-button" (click)="close()">
            {{ 'PUSH.COMMON.CANCEL' | translate }}
          </button>
          <button class="button is-danger p7-button" (click)="delete()">
            {{ 'PUSH.COMMON.DELETE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="modal-close" (click)="close()"></button>
</div>
