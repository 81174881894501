import { Component } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Modal } from '../../services/modal/modal.service';
import { NewAppModalComponent } from './new-app-modal/new-app-modal.component';

import * as fromRoot from '../../reducers';
import { Project } from '../../services/api';
import { AuthService } from '../../services/auth/auth.service';
import { NewProjectModalComponent } from './new-project-modal/new-project-modal.component';
import { EditProjectModalComponent } from './edit-project-modal/edit-project-modal.component';
import { Router } from '@angular/router';
import { ProfileResponse } from '../../services/backend';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  public profile$: Observable<ProfileResponse>;
  public projects$: Observable<Project[]>;
  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private modal: Modal,
    private auth: AuthService
  ) {
    this.projects$ = this.auth.projects$;
    this.profile$ = this.auth.profile$;
  }
  goApp(appno: string) {
    this.router.navigate(['/app', appno]);
  }
  addApp(project: Project) {
    this.modal
      .open(NewAppModalComponent, {
        projectId: project.uuid,
        projectName: project.name
      })
      .then(() => this.auth.loadProjects())
      .catch(() => {
        /* ignore */
      });
  }
  addProject() {
    this.modal
      .open(NewProjectModalComponent)
      .then(() => this.auth.loadProjects())
      .catch(() => {
        /* ignore */
      });
  }
  editProject(project: Project) {
    this.modal
      .open(EditProjectModalComponent, project)
      .then(() => this.auth.loadProjects())
      .catch(() => {
        /* ignore */
      });
  }
}
