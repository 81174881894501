<div class="columns is-centered">
  <div class="column is-8-widescreen">
    <div class="container main-container">
      <ng-container
        *ngIf="this.list$ | async; then Loaded; else Loading"
      ></ng-container>
      <ng-template #Loaded>
        <ng-container
          *ngIf="(this.list$ | async)?.length > 0; then List; else NoPush"
        ></ng-container>
        <ng-template #List>
          <div>
            <div>
              <table
                class="table is-striped is-fullwidth p7-table"
                [class.csv]="(app$ | async).permissions.dumpcsv"
              >
                <thead>
                  <tr>
                    <th class="cell-img"></th>
                    <th class="cell-title">
                      {{ 'PUSH.COMMON.TITLE' | translate }}
                    </th>
                    <th class="cell-sent">
                      {{ 'PUSH.COMMON.SENT' | translate }}
                    </th>
                    <th class="cell-clicked">
                      {{ 'PUSH.COMMON.CLICK' | translate }}
                    </th>
                    <th class="cell-action">
                      {{ 'PUSH.STANDARD.ACTION' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let noti of this.list$ | async">
                    <td class="noti-icon cell-image">
                      <figure class="image is-24x24">
                        <img src="{{ noti.icon }}" alt="icon" />
                      </figure>
                    </td>
                    <td class="noti-title cell-title">{{ noti.title }}</td>
                    <td class="cell-sent">
                      {{
                        noti.success_send_count !== null &&
                        noti.success_send_count !== undefined
                          ? noti.success_send_count
                          : noti.subscribers
                      }}
                    </td>
                    <td class="cell-clicked">{{ noti.clicked_count }}</td>
                    <td class="cell-action">
                      <div class="field is-grouped">
                        <div class="control">
                          <button
                            (click)="detail(noti)"
                            class="button is-success is-outlined p7-button"
                          >
                            <span class="icon is-marginless">
                              <i
                                class="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span class="myapp-name">{{
                              'PUSH.STANDARD.DETAIL' | translate
                            }}</span>
                          </button>
                        </div>
                        <ng-container
                          *ngIf="(app$ | async).permissions.dumpcsv"
                        >
                          <div class="control">
                            <button
                              (click)="csvDownload(noti)"
                              [disabled]="!checkCsv(noti)"
                              class="button is-info is-outlined p7-button"
                            >
                              <span class="icon is-marginless">
                                <i
                                  class="fa fa-download"
                                  aria-hidden="true"
                                ></i>
                              </span>
                              <span class="myapp-name">CSV</span>
                            </button>
                          </div>
                        </ng-container>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="container-footer">
            <div class="pagination is-left">
              <a
                (click)="prev()"
                class="pagination-previous has-background-primary"
                [class.is-disabled]="page == 1"
              >
                <i class="fa fa-caret-left" aria-hidden="true"></i>
              </a>
              <div class=" pagination-list">
                <span class="button is-primary">{{ page }}/{{ max }}</span>
              </div>
              <a
                (click)="next()"
                class="pagination-next has-background-primary"
                [class.is-disabled]="page == max"
              >
                <i class="fa fa-caret-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </ng-template>
        <ng-template #NoPush>
          <div class="container-body">
            <div class="columns is-vcentered is-centered is-mobile">
              <div class="column is-narrow">
                <figure class="image is-185x185">
                  <img
                    class="box is-paddingless"
                    src="/assets/img/hint/new-push.png"
                    alt=""
                  />
                </figure>
              </div>
              <div class="column is-half">
                <p class="is-size-4">
                  プッシュ通知がありません
                </p>
                <a
                  class="button"
                  (click)="compose()"
                  *appDisplayFor="'operator'"
                >
                  {{ 'PUSH.CREATE' | translate }}
                </a>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-template>
      <ng-template #Loading>
        <app-loading></app-loading>
      </ng-template>
    </div>
  </div>
</div>
