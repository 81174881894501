
    <article (click)="click()" *ngIf="show" class="message">
      <div class="message-header">
        <p>{{ 'TAGS.MESSAGE.LABEL' | translate }}</p>
        <button class="delete" (click)="hide()"></button>
      </div>
      <div class="message-body">
        <p>{{ 'TAGS.MESSAGE.MESSAGE' | translate }}</p>
      </div>
    </article>
  