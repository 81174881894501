<div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{ 'COMMON.CATEGORY' | translate }}
      </div>
      <div class="content">
        <p>{{ 'OTHERS.SET_CATEGORY' | translate }}</p>
        <div class="control">
          <div class="select">
            <select name="category" (change)="select($event)">
              <option selected="selected" disabled="true">{{
                'OTHERS.CATEGORY_SELECT' | translate
              }}</option>
              <option *ngFor="let c of categories | async" [value]="c.no">{{
                c.name
              }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button
            class="button is-success p7-button"
            [disabled]="!categoryno"
            (click)="ok()"
          >
            {{ 'COMMON.OK' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
