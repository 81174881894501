
    <div class="message" [class.is-danger]="error">
      <div
        class="message-header"
        [appAstDraggable]="value"
        (onMoved)="removeFromParentNode.emit()"
      >
        <p>指定パラメータのみ</p>
        <a
          *ngIf="!hideDelete"
          class="delete"
          (click)="removeFromParentNode.emit()"
        ></a>
      </div>
      <div class="message-body">
        <p *ngIf="error" class="warning">{{ error }}</p>
        <form (submit)="add()">
          <div class="field has-addons">
            <div class="control">
              <input
                [(ngModel)]="param"
                name="param"
                class="input"
                type="text"
                placeholder="{{
                  'NOTIFICATION_EDITOR.QUERY_EDITOR.PLACEHOLDER' | translate
                }}"
              />
            </div>
            <div class="control">
              <button type="submit" [disabled]="!param" class="button">
                {{ 'NOTIFICATION_EDITOR.QUERY_EDITOR.ADD' | translate }}
              </button>
            </div>
          </div>
        </form>
        <div>
          <p class="control paramsList" *ngIf="value.parameters.length > 0">
            <span
              class="tag is-dark is-medium"
              *ngFor="let p of value.parameters; index as i"
              >{{ p.parameter
              }}<button (click)="remove(i)" class="delete is-small"></button
            ></span>
          </p>
        </div>
      </div>
    </div>
  