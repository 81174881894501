<ng-container [formGroup]="form">
  <div class="field">
    <label class="label">タイトル</label>
    <div class="control">
      <input class="input" type="text" formControlName="title" />
    </div>
  </div>
  <div class="field">
    <label class="label">URL</label>
    <div class="control">
      <input class="input" type="url" formControlName="url" />
    </div>
  </div>
  <div class="field">
    <label class="label">内容</label>
    <div class="control">
      <textarea class="textarea" formControlName="body"></textarea>
    </div>
  </div>
  <div class="field">
    <label class="label">TTL(秒)</label>
    <p class="help">
      {{ 'NOTIFICATION_EDITOR.CUSTOM_TTL.DESCRIPTION' | translate }}
    </p>
    <app-ttl-editor formControlName="ttl"></app-ttl-editor>
  </div>
  <div class="field columns">
    <div class="column is-half">
      <label class="label">アイコン</label>
      <app-img-crop-v2
        [src]="form.value.icon"
        (srcChange)="form.get('icon').setValue($event)"
      ></app-img-crop-v2>
    </div>
    <div class="column is-half">
      <label class="label">画像</label>
      <app-img-crop-v2
        [src]="form.value.image"
        (srcChange)="form.get('image').setValue($event)"
        [canReset]="true"
      ></app-img-crop-v2>
    </div>
  </div>
  <div class="field">
    <label class="label">表示</label>
    <div class="control">
      <label class="checkbox">
        <input
          type="checkbox"
          [(ngModel)]="disappearWithoutInteraction"
          [ngModelOptions]="{ standalone: true }"
        />
        クリックするまで表示
      </label>
    </div>
  </div>
  <div class="field">
    <label class="label">サウンド</label>
    <div class="control">
      <label class="checkbox">
        <input type="checkbox" formControlName="enable_ios_sound" />
        iOS端末で音を鳴らす
      </label>
    </div>
  </div>
</ng-container>
