<ng-container [formGroup]="form">
  <div class="field">
    <label class="label">送信方法</label>
    <div class="control">
      <div class="select">
        <select formControlName="type">
          <option value="fast">一括配信</option>
          <option value="load_balance">負荷分散配信</option>
        </select>
      </div>
    </div>
  </div>
  <ng-container *ngIf="form.value.type === 'load_balance'">
    <label class="label">一度に配信する通数</label>
    <div class="field has-addons">
      <div class="control">
        <input
          class="input"
          type="number"
          formControlName="subscribers_per_group"
        />
      </div>
      <p class="control">
        <a class="button is-static">
          通
        </a>
      </p>
    </div>
    <label class="label">配信間隔（分）</label>
    <div class="field has-addons">
      <div class="control">
        <input
          class="input"
          type="number"
          formControlName="send_interval_minutes"
        />
      </div>
      <p class="control">
        <a class="button is-static">
          分
        </a>
      </p>
    </div>
  </ng-container>
</ng-container>
