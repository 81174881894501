import './polyfills.ts';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

const GMOPaymentScriptTag = document.createElement('script');
GMOPaymentScriptTag.type = 'text/javascript';
GMOPaymentScriptTag.async = false;
GMOPaymentScriptTag.src = environment.production
  ? 'https://static.mul-pay.jp/ext/js/token.js'
  : 'https://stg.static.mul-pay.jp/ext/js/token.js';
document.head.appendChild(GMOPaymentScriptTag);

platformBrowserDynamic().bootstrapModule(AppModule);
