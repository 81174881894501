<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-header has-text-weight-bold is-size-4">
        {{ 'AUTO.RSS.TITLE' | translate }}
        <app-help-link
          link="https://push7.jp/docs/guide/automation/"
        ></app-help-link>
      </div>
      <div class="container-body p7-form">
        <div class="content" [innerHTML]="'AUTO.RSS.MESSAGE' | translate"></div>
        <label class="label">{{ 'AUTO.RSS.LINK' | translate }}</label>
        <p class="control">
          <input
            class="input"
            [disabled]="isSet"
            [(ngModel)]="link"
            type="text"
            placeholder="https://example.com/rss.xml"
            [appEnableFor]="'administrator'"
          />
        </p>
        <p class="rss-error" *ngIf="error">
          {{ error.reason }}
        </p>
      </div>
      <div class="container-footer has-text-right" *ngIf="!isSet">
        <button
          class="button is-primary"
          (click)="save()"
          [appEnableFor]="'administrator'"
        >
          {{ 'AUTO.RSS.SAVE' | translate }}
        </button>
      </div>
      <div class="container-footer has-text-right" *ngIf="isSet">
        <button
          class="button is-primary"
          (click)="reset()"
          [appEnableFor]="'administrator'"
        >
          {{ 'AUTO.RSS.RESET' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
