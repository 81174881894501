import { PushComponent } from './../push.component';
import { NormalPushDetailComponent } from './normal-push-detail/normal-push-detail';
import { Modal } from './../../../../services/modal/modal.service';
import { AppService } from './../../../../services/app/app.service';
import { NotificationModel } from './../../../../models/notification.model';
import { AppModel } from './../../../../models/app.model';
import { Observable } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as app from '../../../../actions/app';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-normal-push-history',
  templateUrl: './normal-push-history.component.html',
  styleUrls: ['./normal-push-history.component.scss']
})
export class NormalPushHistoryComponent implements OnInit {
  public page = 1;
  public max: number;
  private appno: string;
  private appSubscription: Subscription;
  public list$: Observable<NotificationModel[] | boolean>;
  public app$: Observable<AppModel>;
  public notification: Observable<any>;
  constructor(
    private store: Store<fromRoot.State>,
    private appService: AppService,
    private modalService: Modal,
    private pushComponent: PushComponent
  ) {
    this.appSubscription = this.store
      .select(fromRoot.getAppSelectedId)
      .subscribe(appno => (this.appno = appno));
    this.list$ = this.store.select(fromRoot.getTheNotifications);
    this.notification = this.store.select(fromRoot.getAppSelectedNotifications);
    this.app$ = this.store.select(fromRoot.getAppSelected);
    this.notification.subscribe(notiState => {
      if (!notiState) {
        return;
      }
      this.page = notiState.current;
      this.max = Math.ceil(notiState.count / 20);
    });
  }
  checkCsv(noti: NotificationModel) {
    // 通知がダンプできるか
    if (noti.clicked_count <= 0) {
      return false;
    }
    const criteria = new Date('2016-10-14');
    const sent = new Date(String(noti.created_date));
    if (criteria >= sent) {
      return false;
    }
    return true;
  }
  csvDownload(noti: NotificationModel) {
    window.open(this.appService.get_dumpcsv(noti));
  }
  update(render) {
    if (render < 1 || this.max < render) {
      return;
    } else {
      this.store.dispatch(
        new app.LoadNotificationsAction({
          page: render
        })
      );
    }
  }
  ngOnInit() {
    this.update(this.page);
  }
  detail(noti: NotificationModel) {
    this.modalService.open(NormalPushDetailComponent, noti).then(
      () => {},
      () => {}
    );
  }
  next() {
    this.update(this.page + 1);
  }
  prev() {
    this.update(this.page - 1);
  }
  compose() {
    this.pushComponent.compose();
  }
}
