
    <div class="is-flex">
      <app-segment-date-specifier-selector
        [dateSpecifier]="range.from"
        (dateSpecifierChange)="fromChangeHandler($event)"
      ></app-segment-date-specifier-selector>
      <p class="middle">~</p>
      <app-segment-date-specifier-selector
        [dateSpecifier]="range.to"
        (dateSpecifierChange)="toChangeHandler($event)"
      ></app-segment-date-specifier-selector>
    </div>
  