import { SessionService } from './../../../services/session/session.service';
import { ASTNode } from './../../../models/segments.model';
import {
  Directive,
  Input,
  ElementRef,
  OnInit,
  Output,
  EventEmitter
} from '@angular/core';

@Directive({
  selector: '[appAstDraggable]'
})
export class AstDroggableDirective implements OnInit {
  @Input()
  appAstDraggable: ASTNode;
  @Output()
  onMoved: EventEmitter<void> = new EventEmitter<void>();
  public _el: Element;
  constructor(private el: ElementRef, private session: SessionService) {}
  ngOnInit() {
    this._el = this.el.nativeElement;
    this._el.setAttribute('draggable', 'true');
    this._el.addEventListener('dragstart', (e: DragEvent | any) => {
      this.session.set('ast-editor-passing', this.appAstDraggable); // 渡す値
      this.session.set('beingDrag', this._el.parentElement); // 自分自身へのドロップを防ぐ。
      e.dataTransfer.setData('ast-node', 'ast-editor-passing');
      e.dataTransfer.setDragImage(this._el.parentElement, 100, 100);
    });
    this._el.addEventListener('dragend', (e: DragEvent) => {
      if (e.dataTransfer.dropEffect === 'none') {
        return;
      }
      this.onMoved.emit();
    });
  }
}
