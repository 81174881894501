import { UserService } from './../../services/user/user.service';
import {
  Component,
  OnDestroy,
  Renderer,
  HostListener,
  ElementRef
} from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { Go } from '../../actions/router';

import * as fromRoot from '../../reducers';

import { AppModel } from '../../models/app.model';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { ProfileResponse } from '../../services/backend';
import { map, withLatestFrom, tap } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  profile$: Observable<ProfileResponse>;
  nonSelected$: Observable<AppModel[]>;
  selected$: Observable<AppModel>;
  showApps = false;
  showUserDropdown = false;
  private listener: EventListener;
  private el: Element;
  constructor(
    private store: Store<fromRoot.State>,
    private renderer: Renderer,
    private _el: ElementRef,
    private user: UserService,
    private authService: AuthService,
    private router: Router
  ) {
    this.profile$ = this.authService.profile$;
    this.selected$ = this.store.select(fromRoot.getAppSelected);
    this.nonSelected$ = combineLatest(
      this.authService.apps$,
      this.selected$
    ).pipe(
      map(([apps, selected]) => apps.filter(a => a.appno !== selected.appno))
    );
    this.el = _el.nativeElement;
  }
  openApp(appno) {
    this.showApps = false;
    this.store.dispatch(
      new Go({
        path: ['app', appno]
      })
    );
  }
  home() {
    this.store.dispatch(new Go({ path: ['home'] }));
  }
  logout() {
    this.authService.logout().subscribe(() => this.router.navigate(['login']));
  }
  @HostListener('document:click', ['$event'])
  onClickFunc(e: MouseEvent) {
    if (!this.showApps && !this.showUserDropdown) {
      return;
    }
    const trueIndex = Array.from(
      this.el.querySelectorAll('.nav-right.nav-menu .application-menu-wrapper'),
      (wrapper: Element) => {
        return wrapper.contains(e.target as Element);
      }
    ).findIndex(b => {
      return b;
    });
    if (trueIndex > -1) {
      return;
    }
    this.showApps = false;
    this.showUserDropdown = false;
  }
}
