<div class="device-mac">
  <div class="pc">
    <div class="pc-head">
      <div class="tab-wrapper">
        <div class="tab">今日</div>
        <div class="tab current">通知</div>
      </div>
    </div>
    <div class="pc-body">
      <div class="pc-label">
        <div class="pc-title">今日</div>
        <div class="pc-close">
          <img src="/assets/img/preview/close-button.png" />
        </div>
      </div>
      <div class="notification-wrapper">
        <div class="notification">
          <div class="notification-head">
            <div class="notification-icon">
              <img src="/assets/img/preview/chrome.png" />
            </div>
            <div class="notification-label">GOOGLE&nbsp;CHROME</div>
            <div class="notification-time">now</div>
          </div>
          <div class="notification-body">
            <div class="notification-content">
              <div class="notification-title">
                {{
                  notification.title ||
                    ('NOTIFICATION_EDITOR.VALIDATIONS.TITLE.NOLENGTH'
                      | translate)
                }}
              </div>
              <div class="notification-url">{{ url_without_http }}</div>
              <div class="notification-message">
                {{
                  notification.body ||
                    ('NOTIFICATION_EDITOR.VALIDATIONS.BODY' | translate)
                }}
              </div>
            </div>
            <div class="notification-img">
              <img [src]="notification.icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
