<section class="inner-menu">
  <aside class="menu">
    <ul class="menu-list">
      <li>
        <a class="myapp" [routerLink]="['.']">
          <span class="icon">
            <img src="{{ (app$ | async)?.icon }}" alt="" />
          </span>
          {{ (app$ | async)?.name }}
        </a>
      </li>
    </ul>
    <p class="menu-label">
      {{ 'SIDEBAR.INTRODUCE' | translate }}
    </p>
    <ul class="menu-list">
      <li>
        <a [routerLink]="['settings']" [routerLinkActive]="['is-active']">
          <i class="fa fa-cog" aria-hidden="true"></i>
          {{ 'SIDEBAR.SETTINGS' | translate }}
        </a>
      </li>
      <li>
        <a [routerLink]="['auto']" [routerLinkActive]="['is-active']">
          <i class="fa fa-bell" aria-hidden="true"></i>
          {{ 'SIDEBAR.AUTO' | translate }}
        </a>
      </li>
      <li>
        <a [routerLink]="['tags']" [routerLinkActive]="['is-active']">
          <i class="fa fa-tags" aria-hidden="true"></i>
          {{ 'SIDEBAR.TAGS' | translate }}
        </a>
      </li>
      <li *appDisplayFor="'administrator'">
        <a [routerLink]="['domain']" [routerLinkActive]="['is-active']">
          <i class="fa fa-link" aria-hidden="true"></i>
          {{ 'SIDEBAR.DOMAIN' | translate }}
        </a>
      </li>
      <li
        *ngIf="
          (profile$ | async)?.is_corporation ||
          (app$ | async)?.permissions.parameter
        "
      >
        <a [routerLink]="['sftp']" [routerLinkActive]="['is-active']">
          <i class="fa fa-server" aria-hidden="true"></i>
          {{ 'SIDEBAR.SFTP_INTEGRATION' | translate }}
        </a>
      </li>
      <li *appDisplayFor="'administrator'">
        <a [routerLink]="['api']" [routerLinkActive]="['is-active']">
          <i class="fa fa-terminal" aria-hidden="true"></i>
          {{ 'SIDEBAR.API' | translate }}
        </a>
      </li>
    </ul>
    <p class="menu-label">
      {{ 'SIDEBAR.TRANSMIT' | translate }}
    </p>
    <ul class="menu-list">
      <li>
        <a [routerLink]="['push']" [routerLinkActive]="['is-active']">
          <i class="fa fa-paper-plane" aria-hidden="true"></i>
          {{ 'SIDEBAR.STANDARD_NOTIFICATIONS' | translate }}
        </a>
      </li>
      <li>
        <a [routerLink]="['load-balance']" [routerLinkActive]="['is-active']">
          <i class="fa fa-balance-scale" aria-hidden="true"></i>
          {{ 'SIDEBAR.LOAD_BALANCE_PUSH' | translate }}
        </a>
      </li>
      <li>
        <a [routerLink]="['abtest']" [routerLinkActive]="['is-active']">
          <i class="fa fa-users" aria-hidden="true"></i>
          {{ 'SIDEBAR.ABTEST' | translate }}
        </a>
      </li>
      <li *ngIf="(app$ | async).plan.current.is_enterprise">
        <a [routerLink]="['automation']" [routerLinkActive]="['is-active']">
          <i class="fa fa-magic" aria-hidden="true"></i>
          オートメーション配信
        </a>
      </li>
      <li *ngIf="(app$ | async)?.permissions.can_use_segment">
        <a [routerLink]="['queries']" [routerLinkActive]="['is-active']">
          <i class="fa fa-user-plus" aria-hidden="true"></i>
          {{ 'SIDEBAR.SEGMENTED_NOTIFICATIONS' | translate }}
        </a>
      </li>
    </ul>
    <p class="menu-label">
      {{ 'SIDEBAR.ANALYZE' | translate }}
    </p>
    <ul class="menu-list">
      <li>
        <a [routerLink]="['analytics']" [routerLinkActive]="['is-active']">
          <i class="fa fa-bar-chart" aria-hidden="true"></i>
          {{ 'SIDEBAR.ANALYTICS' | translate }}(β)
        </a>
      </li>
      <li *ngIf="(app$ | async)?.permissions.parameter">
        <a [routerLink]="['parameters']" [routerLinkActive]="['is-active']">
          <i class="fa fa-list-ul" aria-hidden="true"></i>
          {{ 'SIDEBAR.PARAMETERS' | translate }}
        </a>
      </li>
    </ul>
    <ng-container *appDisplayFor="'administrator'">
      <p class="menu-label">
        {{ 'SIDEBAR.PLANS' | translate }}
      </p>
      <ul class="menu-list">
        <li>
          <a [routerLink]="['plan']" [routerLinkActive]="['is-active']">
            <i class="fa fa-credit-card" aria-hidden="true"></i>
            {{ 'SIDEBAR.CHOOSE_PLANS' | translate }}
          </a>
        </li>
        <li *ngIf="showPaymentMethodLink | async">
          <a
            [routerLink]="['payment-method']"
            [routerLinkActive]="['is-active']"
          >
            <i class="fa fa-credit-card" aria-hidden="true"></i>
            支払い情報
          </a>
        </li>
      </ul>
    </ng-container>
  </aside>
</section>
