/**
 * Push7 Project Feature for push7-apiserver
 * Push7 Project Featurs API specfication
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: enginners@gnex.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A request model of updateProjectMember.
 */
export interface UpdateProjectMemberRequest { 
    /**
     * 更新したいユーザーに新しく付与する権限。リクエスタがGNEXユーザーではない場合は、スパイ権限は付与できない。
     */
    role: UpdateProjectMemberRequest.RoleEnum;
}
export namespace UpdateProjectMemberRequest {
    export type RoleEnum = 'administrator' | 'operator' | 'viewer' | 'spy_viewer' | 'spy_administrator';
    export const RoleEnum = {
        Administrator: 'administrator' as RoleEnum,
        Operator: 'operator' as RoleEnum,
        Viewer: 'viewer' as RoleEnum,
        SpyViewer: 'spy_viewer' as RoleEnum,
        SpyAdministrator: 'spy_administrator' as RoleEnum
    };
}


