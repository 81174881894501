import { Injectable, Inject, Injector } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRoot from '../../reducers';
import { environment as env } from '../../../environments/environment';
import { ContactModel } from '../../models/contact.model';
import { switchMap, take } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class UserService {
  private email: string;
  private authService: AuthService;
  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private injector: Injector
  ) {
    setTimeout(() => {
      this.authService = this.injector.get(AuthService);
      this.authService.profile$.subscribe(profile => {
        this.email = profile.email;
      });
    }, 0);
  }
  /**
   * ログイン
   * @param {string} email - メールアドレス
   * @param {string} password - パスワード
   */
  login(email, password): Observable<any> {
    return this.http
      .post<any>(`${env.HOST}/login`, { email, password })
      .pipe(
        switchMap(() =>
          this.http.post<any>(`${env.API}/user_auth_token/create`, {
            email,
            password
          })
        )
      );
  }
  /**
   * パスワード変更
   * @param {string} old_password - 古いパスワード
   * @param {string} new_password - 新しいパスワード
   */
  password(old_password: string, new_password: string) {
    return this.http.post<any>(`${env.HOST}/password`, {
      old_password,
      new_password
    });
  }
  /**
   * お問い合わせ
   * @param {string} content - お問い合わせ内容
   */
  contact(contact: ContactModel) {
    // appnoを指定された場合はそちらを使う
    if (contact.appno) {
      return this.http.post<any>(`${env.HOST}/${contact.appno}/contact`, {
        category_id: contact.categoryId,
        subject: contact.subject,
        body: contact.body
      });
    }
    return this.http.post<any>(`${env.HOST}/contact`, {
      email: this.email,
      category_id: contact.categoryId,
      subject: contact.subject,
      body: contact.body
    });
  }
  /**
   * ログアウト
   */
  logout() {
    return this.http.get<any>(`${env.HOST}/logout`);
  }
  /**
   * ユーザーが法人か個人かを後から設定
   */
  set_corporate(is_corporation: boolean) {
    return this.http.post<any>(`${env.HOST}/user_type`, {
      is_corporation
    });
  }
  /**
   * ユーザーの削除
   */
  delete_account(email: string, password: string) {
    return this.http.post<any>(`${env.API}/users/delete`, {
      email,
      password
    });
  }
  /**
   * アンケート
   */
  send_survey(payload: any) {
    return this.http.post<any>(`${env.BIZAPI}/deluser/v1/survey`, payload);
  }
  telephone_appointment(payload: {
    company: string;
    tel: string;
    candidates: string[];
    name: string;
    email: string;
    contact_body: string;
  }) {
    return this.http.post<{ result: boolean }>(`${env.TELEAPO_API}`, payload);
  }
}
