<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box flex">
      <div class="content-leftside">
        <app-notification-progress-steps
          [step]="step"
          (stepChange)="nStep = $event"
        ></app-notification-progress-steps>
      </div>
      <div class="content">
        <app-notification-editor-v2
          [(notification)]="notification"
          [step]="nStep"
          (stepChange)="step = nStep = $event"
          (onComplete)="modalResolve($event)"
          (onClose)="close()"
        ></app-notification-editor-v2>
      </div>
      <div class="content-rightside">
        <app-notification-preview
          [notification]="notification"
        ></app-notification-preview>
      </div>
    </div>
  </div>
  <button class="modal-close" (click)="close()"></button>
</div>
