import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Chart } from 'chart.js';
import * as Moment from 'moment';
import { map } from 'rxjs/operators';
import { AppService } from './../../../../../services/app/app.service';

export interface DataSource {
  labels: string[];
  datasets: {
    [name: string]: number[];
  };
}
export interface ChartData {
  labels: string[];
  datasets: {
    label?: string;
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
    pointBorderWidth: number;
    lineTension: number;
    fill: boolean;
    data: number[];
  }[];
}

@Component({
  selector: 'app-ovewview-chart',
  template: `
    <canvas width="780px" height="190px"></canvas>
  `
})
export class OverviewChartComponent implements AfterViewInit, OnChanges {
  private _el: HTMLElement;
  private chart: any;

  @Input()
  chartType:
    | 'ACTIVE_SUBSCRIBERS'
    | 'CUMULATIVE_SUBSCRIBERS'
    | 'NEW_SUBSCRIBERS';
  @Input()
  scope: 'hour' | 'day' | 'week' | 'month';
  @Input()
  startDate: Moment.Moment;
  @Input()
  endDate: Moment.Moment;

  constructor(private el: ElementRef, private app: AppService) {}
  ngAfterViewInit() {
    this._el = this.el.nativeElement;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (!this.scope || !this.chartType || !this.startDate || !this.endDate) {
      return;
    }
    const n = Math.ceil(this.endDate.diff(this.startDate, this.scope, true));
    return this.app
      .analytics_subscribers_change(
        this.startDate.format('YYYY-MM-DDTHH'),
        this.scope,
        n
      )
      .pipe(
        map(rawArr => {
          const format = this.scope === 'hour' ? 'MM-DD HH:mm' : 'Y-MM-DD';
          return {
            labels: rawArr.map(item => Moment(item.datetime).format(format)),
            datasets: {
              count: rawArr.map(item =>
                (() => {
                  switch (this.chartType) {
                    case 'ACTIVE_SUBSCRIBERS':
                      return item.active_subscribers;
                    case 'CUMULATIVE_SUBSCRIBERS':
                      return item.total_subscribers;
                    case 'NEW_SUBSCRIBERS':
                      return item.delta;
                  }
                })()
              )
            }
          };
        })
      )
      .subscribe(source => {
        if (this._el && !this.chart) {
          // generate if it is the first time
          this.generateChart();
        }
        const data = this.generateData(source);
        if (!this.chart) {
          return;
        }
        this.chart.data.datasets = data.datasets;
        this.chart.data.labels = data.labels;
        this.chart.update();
      });
  }
  generateChart() {
    const canvas = this._el.querySelector('canvas');
    const ctx = canvas.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'line',
      options: {
        responsive: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        },
        legend: {
          display: false
        }
      }
    } as any);
  }
  // Helpers

  private generateData(source: DataSource): ChartData {
    // ngx-translateは非同期になってだるいのでここだけ力技
    let label: string;
    switch (navigator.language.substr(0, 2)) {
      case 'ja': {
        label = '購読者数';
        break;
      }
      default: {
        label = 'Subscribers';
        break;
      }
    }
    return {
      labels: source.labels,
      datasets: [
        {
          label,
          backgroundColor: '#EEAC01',
          borderColor: '#EEAC01',
          borderWidth: 1,
          pointBorderWidth: 1,
          lineTension: 0,
          fill: false,
          data: source.datasets.count
        }
      ]
    };
  }
}
