<div class="main-nav" [innerHTML]="'SETTINGS.LABEL' | translate"></div>
<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-header has-text-weight-bold is-size-4">
        {{ 'SETTINGS.LABEL' | translate }}
      </div>
      <div class="container-body">
        <div class="field">
          <label class="label">{{ 'SETTINGS.NAME' | translate }}</label>
          <p class="control">
            <input
              class="input"
              type="text"
              placeholder="Site Name"
              [(ngModel)]="name"
              [appEnableFor]="'administrator'"
            />
          </p>
        </div>
        <div class="field">
          <label class="label">{{ 'SETTINGS.URL' | translate }}</label>
          <p class="control">
            <input
              class="input"
              type="text"
              placeholder="https://hogehoge.com"
              [(ngModel)]="url"
              [appEnableFor]="'administrator'"
            />
          </p>
        </div>
        <div class="field">
          <label class="label">{{ 'SETTINGS.ICON' | translate }}</label>
          <app-img-crop
            [(src)]="icon"
            (srcChange)="save()"
            aspectRatio="1"
            *appDisplayFor="'administrator'; fallback: NoPermissionImagePreview"
          >
          </app-img-crop>
          <ng-template #NoPermissionImagePreview>
            <img width="120" [src]="icon" />
          </ng-template>
        </div>
        <div class="field">
          <label class="label">{{ 'HOME.NEWAPP.CATEGORY' | translate }}</label>
          <div class="control">
            <div class="select">
              <select
                [(ngModel)]="categoryno"
                name="category"
                [appEnableFor]="'administrator'"
              >
                <option *ngFor="let c of categories | async" [ngValue]="c.no">{{
                  c.name
                }}</option>
              </select>
            </div>
          </div>
        </div>
        <hr />
        <label class="label">アプリケーションのプロジェクト移行</label>
        <p>
          アプリケーションを、別のプロジェクトに移行することができます。アプリケーションを移行するには、現在のプロジェクトと移行先のプロジェクト両方の管理者権限が必要です。
        </p>
        <ng-container *appDisplayFor="'administrator'">
          <ng-container
            *ngIf="
              (projectsToTransfer$ | async).length > 0;
              else NoProjectsToTransfer
            "
          >
            <label class="label mt-3">移行先プロジェクト</label>
            <div class="field has-addons mt-3">
              <div class="control ">
                <div class="select">
                  <select
                    [(ngModel)]="projectTransferDestination"
                    name="projectId"
                  >
                    <option disabled="disabled" selected
                      >移行先プロジェクトを選択</option
                    >
                    <option
                      *ngFor="let p of projectsToTransfer$ | async"
                      [value]="p.uuid"
                      >{{ p.name }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="control">
                <button
                  (click)="transfer()"
                  class="button is-danger input-btn"
                  [disabled]="!projectTransferDestination"
                >
                  プロジェクトの移行
                </button>
              </div>
            </div>
          </ng-container>
          <ng-template #NoProjectsToTransfer>
            <p class="notification is-info is-light mt-3">
              アプリケーションを移行できるプロジェクトがありません
            </p>
          </ng-template>
        </ng-container>
        <hr />
        <label class="label">{{ 'SETTINGS.DELETE' | translate }}</label>
        <button
          (click)="delete()"
          class="button is-danger input-btn"
          [appEnableFor]="'administrator'"
        >
          {{ 'SETTINGS.DELETE' | translate }}
        </button>
      </div>
      <div class="container-footer has-text-right">
        <button
          (click)="save()"
          class="button is-primary"
          [class.is-loading]="processing"
          [appEnableFor]="'administrator'"
        >
          {{ 'SETTINGS.SAVE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
