<div class="modal is-wide is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content">
        <div>
          <img id="image" #editor />
        </div>
      </div>
      <div class="content-footer">
        <div class="is-flex is-justify-content-space-between">
          <button
            class="button is-dark is-outlined p7-button"
            [disabled]="processing"
            (click)="close()"
          >
            {{ 'IMGCROP.CANCEL' | translate }}
          </button>
          <button
            class="button is-success p7-button"
            (click)="upload()"
            [class.is-loading]="processing"
          >
            {{ 'IMGCROP.APPLY' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
