<div class="control">
  <label class="radio">
    <input
      type="radio"
      [name]="randomId"
      [ngModel]="kind"
      (change)="update('range')"
      value="range"
    />
    期間指定
  </label>
  <label class="radio">
    <input
      type="radio"
      [name]="randomId"
      [ngModel]="kind"
      (change)="update('never')"
      value="never"
    />
    該当なし
  </label>
</div>
<ng-container [ngSwitch]="dateQuery.className">
  <div *ngSwitchCase="'ASTDateTimeNever'">
    <p>該当するイベントを一度も行っていないユーザーを選択します。</p>
  </div>
  <div *ngSwitchCase="'ASTDateTimeRange'">
    <app-segment-date-range-selector
      [range]="dateQuery"
      (rangeChange)="dateQueryChange.next($event)"
    ></app-segment-date-range-selector>
  </div>
</ng-container>
