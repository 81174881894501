<section class="hero pre is-fullheight">
  <div class="hero-body">
    <div class="container has-text-centered">
      <img class="logo" src="/assets/img/logo_black.svg" />
      <div class="inner card">
        <div class="card-content">
          <div class="notification has-text-left is-success" *ngIf="success">
            {{ 'FORGET.SUCCESS' | translate }}
          </div>
          <div class="notification has-text-left is-danger" *ngIf="error">
            {{ error }}
          </div>
          <form (submit)="submit()">
            <p class="control has-icons-left">
              <input
                class="input"
                type="email"
                required
                [(ngModel)]="email"
                name="email"
                placeholder="{{ 'FORGET.EMAIL' | translate }}"
              />
              <span class="icon is-small is-left">
                <i class="fa fa-envelope"></i>
              </span>
            </p>
            <div class="field is-grouped is-grouped-centered">
              <p class="control">
                <button class="button is-primary" type="submit">
                  {{ 'FORGET.BUTTON' | translate }}
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div class="links adjust-margin">
        <p class="adjust-margin">
          <a [routerLink]="['/login']">{{ 'LOGIN.BUTTON' | translate }}</a>
        </p>
      </div>
    </div>
  </div>
</section>
