<div class="copy-input">
  <label class="label">{{ copyLabel }}</label>
  <div class="field has-addons">
    <p class="control is-expanded">
      <input
        readonly
        class="input copy"
        [class.is-success]="succeeded"
        [class.is-danger]="failed"
        spellcheck="false"
        type="text"
        [ngModel]="copyText"
      />
    </p>
    <p class="control">
      <a
        class="button is-shadowless"
        [class.is-success]="succeeded"
        [class.is-danger]="failed"
      >
        <span class="icon is-small">
          <i class="fa fa-files-o" aria-hidden="true"></i>
        </span>
        <span>{{ 'COPY.COPY' | translate }}</span>
      </a>
    </p>
  </div>
  <span class="help is-success" *ngIf="succeeded">{{
    'COPY.SUCCEEDED' | translate
  }}</span>
  <span class="help is-danger" *ngIf="failed">{{
    'COPY.FAILED' | translate
  }}</span>
</div>
