<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        要素の追加
      </div>
      <div class="content-body">
        <div class="columns is-mobile is-gapless">
          <div class="column is-menu is-4">
            <aside class="menu">
              <ng-container *ngIf="options.operator">
                <p class="menu-label">
                  多項オペレータ
                </p>
                <ul class="menu-list">
                  <li>
                    <a [class.is-active]="name == 'ADD'" (click)="select('ADD')"
                      >+</a
                    >
                  </li>
                  <li>
                    <a [class.is-active]="name == 'SUB'" (click)="select('SUB')"
                      >-</a
                    >
                  </li>
                  <li>
                    <a [class.is-active]="name == 'AND'" (click)="select('AND')"
                      >AND</a
                    >
                  </li>
                  <li>
                    <a [class.is-active]="name == 'OR'" (click)="select('OR')"
                      >OR</a
                    >
                  </li>
                  <li>
                    <a [class.is-active]="name == 'XOR'" (click)="select('XOR')"
                      >XOR</a
                    >
                  </li>
                  <li>
                    <a [class.is-active]="name == 'NOR'" (click)="select('NOR')"
                      >NOR</a
                    >
                  </li>
                  <li>
                    <a
                      [class.is-active]="name == 'NAND'"
                      (click)="select('NAND')"
                      >NAND</a
                    >
                  </li>
                  <li>
                    <a
                      [class.is-active]="name == 'XNOR'"
                      (click)="select('XNOR')"
                      >XNOR</a
                    >
                  </li>
                </ul>
              </ng-container>
              <ng-container *ngIf="options.qualifier">
                <p class="menu-label">
                  単項オペレータ
                </p>
                <ul class="menu-list">
                  <li>
                    <a [class.is-active]="name == 'NOT'" (click)="select('NOT')"
                      >NOT</a
                    >
                  </li>
                </ul>
              </ng-container>
              <ng-container *ngIf="options.condition">
                <p class="menu-label">
                  条件
                </p>
                <ul class="menu-list">
                  <li>
                    <a
                      [class.is-active]="name == 'PARAMETER'"
                      (click)="select('PARAMETER')"
                      >パラメータ</a
                    >
                  </li>
                  <li>
                    <a
                      [class.is-active]="name == 'PARAMETERS'"
                      (click)="select('PARAMETERS')"
                      >指定パラメータのみ</a
                    >
                  </li>
                  <li>
                    <a
                      [class.is-active]="name == 'LASTOPENED'"
                      (click)="select('LASTOPENED')"
                      >最終開封時間</a
                    >
                  </li>
                  <li>
                    <a
                      [class.is-active]="name == 'LASTVISIT'"
                      (click)="select('LASTVISIT')"
                      >最終訪問時間</a
                    >
                  </li>

                  <li>
                    <a
                      [class.is-active]="name == 'LASTVISIT_URL'"
                      (click)="select('LASTVISIT_URL')"
                      >URLごと最終訪問時間</a
                    >
                  </li>
                  <li>
                    <a
                      [class.is-active]="name == 'SUBSCRIBEDAY'"
                      (click)="select('SUBSCRIBEDAY')"
                      >購読日</a
                    >
                  </li>
                  <li>
                    <a
                      [class.is-active]="name == 'BROWSER'"
                      (click)="select('BROWSER')"
                      >ブラウザ</a
                    >
                  </li>
                  <li>
                    <a
                      [class.is-active]="name == 'OPENED'"
                      (click)="select('OPENED')"
                      >開封済み通知</a
                    >
                  </li>
                  <li>
                    <a
                      [class.is-active]="name == 'SENT'"
                      (click)="select('SENT')"
                      >送信済み通知</a
                    >
                  </li>
                </ul>
              </ng-container>
            </aside>
          </div>
          <div class="column is-body">
            <div class="content">
              <ng-container [ngSwitch]="name">
                <ng-container *ngSwitchCase="'ADD'">
                  <h2>ADD</h2>
                  <p>集合の和を選択します。</p>
                  <img src="/assets/img/venn/or.png" />
                </ng-container>
                <ng-container *ngSwitchCase="'SUB'">
                  <h2>SUB</h2>
                  <p>集合の差を選択します。</p>
                  <p>
                    演算は左上から右に向かって行われます。必要に応じて要素の並び替えを行ってください。
                  </p>
                  <img src="/assets/img/venn/sub.png" />
                </ng-container>
                <ng-container *ngSwitchCase="'AND'">
                  <h2>AND</h2>
                  <p>集合の論理積を選択します。</p>
                  <img src="/assets/img/venn/and.png" />
                </ng-container>
                <ng-container *ngSwitchCase="'OR'">
                  <h2>OR</h2>
                  <p>集合の論理和を選択します。</p>
                  <img src="/assets/img/venn/or.png" />
                </ng-container>
                <ng-container *ngSwitchCase="'XOR'">
                  <h2>XOR</h2>
                  <p>集合の排他的論理和を選択します。</p>
                  <img src="/assets/img/venn/xor.png" />
                </ng-container>
                <ng-container *ngSwitchCase="'NOR'">
                  <h2>NOR</h2>
                  <p>集合の否定論理和を選択します。</p>
                  <img src="/assets/img/venn/nor.png" />
                </ng-container>
                <ng-container *ngSwitchCase="'NAND'">
                  <h2>NAND</h2>
                  <p>集合の否定論理積を選択します。</p>
                  <img src="/assets/img/venn/nand.png" />
                </ng-container>
                <ng-container *ngSwitchCase="'XNOR'">
                  <h2>XNOR</h2>
                  <p>集合の排他的論理和の否定を選択します。</p>
                  <img src="/assets/img/venn/xnor.png" />
                </ng-container>
                <ng-container *ngSwitchCase="'NOT'">
                  <h2>NOT</h2>
                  <p>集合の否定を選択します。</p>
                  <img src="/assets/img/venn/not.png" />
                </ng-container>
                <ng-container *ngSwitchCase="'PARAMETER'">
                  <h2>パラメータ</h2>
                  <p>特定のパラメータを保持するユーザーを選択します。</p>
                </ng-container>
                <ng-container *ngSwitchCase="'PARAMETERS'">
                  <h2>指定パラメータのみ</h2>
                  <p>特定のパラメータのみを持つユーザーを選択します。</p>
                  <p>指定した以外のパラメータも持つユーザーは除外されます。</p>
                </ng-container>
                <ng-container *ngSwitchCase="'LASTOPENED'">
                  <h2>最終開封時間</h2>
                  <p>
                    最後にプッシュ通知を開封した時間でユーザーを選択します。
                  </p>
                  <p>
                    特定の日付, 現在からの相対的な時間,
                    キーワードを組み合わせて期間を設定できます。
                  </p>
                  <p>
                    「該当なし」を選択すると、1件もプッシュ通知を開封していないユーザーを選択できます。
                  </p>
                </ng-container>
                <ng-container *ngSwitchCase="'SUBSCRIBEDAY'">
                  <h2>購読日</h2>
                  <p>
                    購読した時間で時間でユーザーを選択します。
                  </p>
                  <p>
                    特定の日付, 現在からの相対的な時間,
                    キーワードを組み合わせて期間を設定できます。
                  </p>
                </ng-container>
                <ng-container *ngSwitchCase="'LASTVISIT'">
                  <h2>最終訪問時間</h2>
                  <p>
                    最後にWebサイトを訪問した時間でユーザーを選択します。
                  </p>
                  <p>
                    特定の日付, 現在からの相対的な時間,
                    キーワードを組み合わせて期間を設定できます。
                  </p>
                </ng-container>
                <ng-container *ngSwitchCase="'LASTVISIT_URL'">
                  <h2>URLごと最終訪問時間</h2>
                  <p>
                    Webサイトの特定のページを最後に訪問した時間でユーザーを選択します。
                  </p>
                  <p>
                    URLはパスの完全一致及び前方一致を指定できます。
                  </p>
                  <p>
                    特定の日付, 現在からの相対的な時間,
                    キーワードを組み合わせて期間を設定できます。
                  </p>
                </ng-container>
                <ng-container *ngSwitchCase="'OS'">
                  <h2>OS</h2>
                  <p>特定のOSで購読しているユーザーを選択します</p>
                </ng-container>
                <ng-container *ngSwitchCase="'BROWSER'">
                  <h2>ブラウザ</h2>
                  <p>特定のブラウザで購読しているユーザーを選択します</p>
                </ng-container>
                <ng-container *ngSwitchCase="'OPENED'">
                  <h2>開封済み通知</h2>
                  <p>
                    特定のIDの通知を開封したことのあるユーザーを選択します。
                  </p>
                </ng-container>
                <ng-container *ngSwitchCase="'SENT'">
                  <h2>送信済み通知</h2>
                  <p>
                    特定のIDの通知が送信されたユーザーを選択します。
                  </p>
                </ng-container>
                <div class="preview" *ngIf="displayPreview()">
                  <app-ast-node
                    [hideDelete]="true"
                    [(value)]="node"
                  ></app-ast-node>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button
            class="button is-danger p7-button"
            (click)="close()"
            style="margin-right: 0.6rem;"
          >
            Close
          </button>
          <button
            class="button is-success p7-button"
            [disabled]="!node.validate().isValid"
            (click)="ok()"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
