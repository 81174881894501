import { Component, OnInit } from '@angular/core';
import { ProvisionalService } from '../../../services/provisional/provisional.service';

@Component({
  selector: 'app-provisional',
  templateUrl: './provisional.component.html',
  styleUrls: ['./provisional.component.scss'],
  providers: [ProvisionalService]
})
export class ProvisionalComponent implements OnInit {
  public email: string;
  public success: boolean;
  public error: string;
  constructor(private provisionalService: ProvisionalService) {}

  ngOnInit() {}
  submit() {
    this.error = null;
    this.success = false;
    this.provisionalService.provisional(this.email).subscribe(
      res => {
        this.success = true;
      },
      response => {
        this.error = response.error.error;
      }
    );
  }
}
