<app-navbar></app-navbar>
<div class="p7-container">
  <div class="p7-sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="p7-main">
    <router-outlet></router-outlet>
  </div>
  <div class="p7-footer">
    <a (click)="help()">{{ 'OTHERS.NEED_HELP' | translate }}</a>
  </div>
</div>
