<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        負荷分散配信詳細
      </div>
      <div class="content">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64">
              <img src="{{noti.icon}}" />
            </p>
          </figure>
          <div class="media-content">
            <div class="field">
              <p class="label">配信ステータス</p>
              <ng-container [ngSwitch]="noti.status">
                <p *ngSwitchCase="'completed'">
                  完了
                </p>
                <div *ngSwitchCase="'running'">
                  <p>
                    送信中({{noti.pushed_groups / noti.total_groups * 100 |
                    number:'0.0-3'}}%)
                  </p>
                  <progress
                    class="progress is-info"
                    [value]="noti.pushed_groups"
                    [max]="noti.total_groups"
                  ></progress>
                </div>
                <p *ngSwitchCase="'scheduled'">
                  予約済み
                </p>
                <p *ngSwitchCase="'preparing'">
                  準備中
                </p>
                <p *ngSwitchCase="'canceled'">
                  キャンセル済み
                </p>
                <p *ngSwitchCase="'failed'">
                  失敗
                </p>
              </ng-container>
            </div>
            <div class="field">
              <p class="label">送信開始日時</p>
              <p>{{noti.start_at | dateParse | date:'y/M/d, H:m'}}</p>
            </div>
            <div class="field">
              <p class="label">{{'PUSH.COMMON.TITLE'|translate}}</p>
              <p>{{noti.title}}</p>
            </div>
            <div class="field">
              <p class="label">{{'PUSH.COMMON.URL'|translate}}</p>
              <p><a href="{{noti.url}}" target="_blank">{{noti.url}}</a></p>
            </div>
            <div class="field">
              <p class="label">{{'PUSH.COMMON.BODY'|translate}}</p>
              <p>{{noti.body}}</p>
            </div>
            <div class="field" *ngIf="noti.image">
              <p class="label">{{'PUSH.COMMON.IMAGE' | translate }}</p>
              <p><img [src]="noti.image" /></p>
            </div>
            <div class="control columns">
              <div class="column is-12">
                <p class="label">
                  {{'PUSH.COMMON.ENABLE_IOS_SOUND' | translate}}
                </p>
                <p class="label">
                  {{(noti.enable_ios_sound ? 'COMMON.YES' : 'COMMON.NO') |
                  translate}}
                </p>
              </div>
            </div>
            <div
              class="field columns"
              *ngIf="noti.status === 'completed' || noti.status === 'running'"
            >
              <div class="column is-4">
                <p class="label">{{'PUSH.COMMON.SENT'|translate}}</p>
                <p>{{noti.total_success_send_count}}</p>
              </div>
              <div class="column is-8">
                <p class="label">{{'PUSH.COMMON.CLICK'|translate}}</p>
                <p>{{noti.total_clicked_count}}</p>
              </div>
            </div>
          </div>
        </article>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <ng-container
            *ngIf="noti.status === 'scheduled' || noti.status === 'running'"
          >
            <ng-container *ngIf="!(isConfirmingCancel | async)">
              <button
                class="button is-danger is-outlined p7-button"
                (click)="isConfirmingCancel.next(true)"
                [appEnableFor]="'operator'"
              >
                配信をキャンセル
              </button>
            </ng-container>
            <ng-container *ngIf="isConfirmingCancel | async">
              <button
                class="button is-danger p7-button"
                [class.is-loading]="isSending"
                (click)="cancel()"
              >
                キャンセルを確定
              </button>
            </ng-container>
          </ng-container>
          <button class="button is-primary" (click)="close()">
            {{'PUSH.COMMON.CLOSE'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="modal-close" (click)="close()"></button>
</div>
