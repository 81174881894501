<div class="modal is-wide is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{ test.name }}
      </div>
      <div class="content">
        <article class="message is-info" *ngIf="test.canceled_at">
          <div class="message-body">
            このプッシュ通知は{{
              test.canceled_at | dateParse | date: 'yyyy年MM月dd日'
            }}にキャンセルされました。
          </div>
        </article>
        <article class="media" *ngFor="let case of test.pushs; let i = index">
          <div class="media-left">
            <figure class="image is-64x64">
              <img [src]="case.icon" alt="Image" />
            </figure>
          </div>
          <div class="media-content">
            <div class="content">
              <ng-container *appDisplayFor="'operator'">
                <a
                  class="edit-reservasion"
                  *ngIf="case.transmission_time"
                  (click)="edit(case, i)"
                >
                  <span class="icon"><i class="fa fa-edit"></i></span>
                </a>
              </ng-container>
              <div class="columns">
                <div class="column is-3">
                  <label class="label">{{
                    'NOTIFICATION_EDITOR.TITLE' | translate
                  }}</label>
                  <p>{{ case.title }}</p>
                  <label class="label">{{
                    'NOTIFICATION_EDITOR.URL' | translate
                  }}</label>
                  <p>
                    <a target="_blank" href="{{ case.url }}">{{ case.url }}</a>
                  </p>
                </div>
                <div class="column is-3">
                  <label class="label">{{
                    'NOTIFICATION_EDITOR.BODY' | translate
                  }}</label>
                  <p>{{ case.body }}</p>
                  <ng-container *ngIf="case.image">
                    <label class="label">{{
                      'PUSH.COMMON.IMAGE' | translate
                    }}</label>
                    <p><img [src]="case.image" /></p>
                  </ng-container>
                </div>
                <div class="column is-3">
                  <!-- 送信済み -->
                  <ng-container *ngIf="!case.transmission_time">
                    <label class="label">{{
                      'ABTEST.CREATED_AT' | translate
                    }}</label>
                    <p>
                      {{ case.created_date | dateParse | date: 'y/M/d, H:m' }}
                    </p>
                  </ng-container>
                  <!-- 予約済み -->
                  <ng-container *ngIf="!!case.transmission_time">
                    <label class="label">{{
                      'ABTEST.RESERVED' | translate
                    }}</label>
                    <p>
                      {{
                        case.transmission_time | dateParse | date: 'y/M/d, H:m'
                      }}
                    </p>
                  </ng-container>

                  <label class="label">
                    {{ 'PUSH.COMMON.SHOW_UNTIL_CLICKED' | translate }}
                  </label>
                  <p>
                    {{
                      (case.disappear_instantly ? 'COMMON.NO' : 'COMMON.YES')
                        | translate
                    }}
                  </p>
                  <label class="label">
                    {{ 'PUSH.COMMON.ENABLE_IOS_SOUND' | translate }}
                  </label>
                  <p>
                    {{
                      (case.enable_ios_sound ? 'COMMON.YES' : 'COMMON.NO')
                        | translate
                    }}
                  </p>
                </div>
                <div class="column is-3">
                  <label class="label">{{ 'ABTEST.SENT' | translate }}</label>
                  <p>{{ case.success_send_count || case.subscribers || 0 }}</p>
                  <label class="label">{{ 'ABTEST.CLICK' | translate }}</label>
                  <p>{{ case.clicked_count || 0 }}</p>
                </div>
              </div>
            </div>
          </div>
        </article>
        <div class="cancel-push has-text-centered" *ngIf="cancellable">
          <button
            class="button p7-button is-danger"
            (click)="cancel()"
            [appEnableFor]="'operator'"
          >
            キャンセル
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="modal-close" (click)="close()"></button>
</div>
