/**
 * Push7 Project Feature for push7-apiserver
 * Push7 Project Featurs API specfication
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: enginners@gnex.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AutomationRuleSegmentation { 
    /**
     * セグメント手段です。 all or csv_preset or query or segment_preset です 
     */
    type: AutomationRuleSegmentation.TypeEnum;
    /**
     * (type=csv_preset) DMP連携を用いて送信対象を絞り込みます。 
     */
    csv_preset_id?: string;
    /**
     * (type=query)送信対象を絞り込むクエリを指定してください。この条件指定された購読グループに対してのみPush送信を行います。(パラメータベース配信) パラメータ管理も見て下さい。 セグメントクエリの指定はできません。
     */
    query?: object;
    /**
     * (type=segment_preset)segment_preset_idです preset_queryのidを指定してください。
     */
    segment_preset_id?: string;
}
export namespace AutomationRuleSegmentation {
    export type TypeEnum = 'csv_preset' | 'query' | 'segment_preset' | 'all';
    export const TypeEnum = {
        CsvPreset: 'csv_preset' as TypeEnum,
        Query: 'query' as TypeEnum,
        SegmentPreset: 'segment_preset' as TypeEnum,
        All: 'all' as TypeEnum
    };
}


