<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{'PUSH.STANDARD.HIDE.LABEL'|translate}}
      </div>
      <div class="content">
        <p [innerHTML]="'PUSH.STANDARD.HIDE.MESSAGE'|translate"></p>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button class="button is-info p7-button" (click)="close()">
            {{'COMMON.CANCEL'|translate}}
          </button>
          <button class="button is-danger p7-button" (click)="approve()">
            {{'PUSH.STANDARD.HIDE.APPROVE'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="modal-close" (click)="close()"></button>
</div>
