<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{ 'DOMAIN.CONFIRM.TITLE' | translate }}
      </div>
      <div
        class="content"
        [innerHTML]="'DOMAIN.CONFIRM.CONTENT' | translate"
      ></div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button class="button" (click)="close()">
            {{ 'DOMAIN.CONFIRM.CANCEL' | translate }}
          </button>
          <button class="button is-danger" (click)="okay()">
            {{ 'DOMAIN.CONFIRM.DELETE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="modal-close" (click)="close()"></button>
</div>
