import {
  AstSelectorOptionType,
  AstSelectorComponent
} from './ast-selector/ast-selector.component';
import { ASTNode } from './../../models/segments.model';
import { Modal } from './../../services/modal/modal.service';
import {
  Directive,
  ElementRef,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Directive({
  selector: `[appAstAddElem]`
})
export class AstAddElemDirective implements OnInit {
  private _el: Element;
  @Input()
  withoutValue = false;
  @Output()
  addElem: EventEmitter<ASTNode> = new EventEmitter<ASTNode>();
  constructor(private el: ElementRef, private modal: Modal) {}
  ngOnInit() {
    this._el = this.el.nativeElement;
    this._el.addEventListener('click', () => {
      this.modal
        .open(AstSelectorComponent, {
          withoutValue: this.withoutValue
        } as AstSelectorOptionType)
        .then(
          (newElem: ASTNode) => this.addElem.emit(newElem),
          () => {}
        );
    });
  }
}
