import { ModalContext } from './../../services/modal/modal.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Notification } from '../../services/notification/notification.service';
import { AppService } from '../../services/app/app.service';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import Cropper from 'cropperjs/dist/cropper.esm.js';

@Component({
  selector: 'app-img-crop-modal',
  templateUrl: './img-crop-modal.component.html',
  styleUrls: ['./img-crop-modal.component.scss']
})
export class ImgCropModalComponent implements OnInit {
  @ViewChild('editor', { static: false })
  private editorRef: ElementRef;
  private cropper;
  private filename: string;
  private filetype: string;
  public processing = false;
  constructor(
    private modalCtx: ModalContext,
    private notification: Notification,
    private appService: AppService
  ) {
    const { file, aspectRatio } = modalCtx.payload;
    console.log(file);
    if (
      file &&
      file.type &&
      ['image/jpeg', 'image/png', 'image/gif'].indexOf(file.type) < 0
    ) {
      close();
    }
    this.filename = file.name;
    this.filetype = file.type;
    const reader = new FileReader();
    reader.onload = (() => el => {
      this.editorRef.nativeElement.src = el.target.result;
      this.cropper = new Cropper(this.editorRef.nativeElement, {
        aspectRatio: aspectRatio || null,
        modal: true,
        autoCropArea: 1,
        dragMode: 'move',
        zoomOnWheel: false,
        minContainerWidth: 150
      });
    })();
    reader.readAsDataURL(file);
  }
  upload() {
    let type;
    switch (this.filetype) {
      case 'image/jpeg': {
        type = 'image/jpeg';
        break;
      }
      case 'image/png': {
        type = 'image/png';
        break;
      }
      default: {
        type = 'image/png';
        break;
      }
    }
    this.processing = true;
    const data = this.cropper
      .getCroppedCanvas()
      .toDataURL(type)
      .split(',')[1];
    this.appService
      .upload(this.filename, data)
      .pipe<any>(finalize(() => (this.processing = false)))
      .subscribe(
        s => {
          this.modalCtx.resolve(s.url);
        },
        (e: HttpErrorResponse) => {
          this.notification.open(`${e.status} ${e.statusText}`, 'danger');
          close();
        }
      );
  }
  close() {
    if (!this.processing) this.modalCtx.reject();
  }
  ngOnInit() {}
}
