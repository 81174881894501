import { EditPushModalComponent } from './../../push/edit-push-modal/edit-push-modal.component';
import { ABTestModel } from './../../../../models/notification.model';
import {
  ModalContext,
  Modal
} from './../../../../services/modal/modal.service';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../../services/app/app.service';
import { AbtestDetailCancelConfirmComponent } from './abtest-detail-cancel-confirm/abtest-detail-cancel-confirm.component';

@Component({
  selector: 'app-abtest-detail',
  templateUrl: './abtest-detail.component.html',
  styleUrls: ['./abtest-detail.component.scss']
})
export class AbtestDetailComponent implements OnInit {
  test: ABTestModel;
  constructor(
    private modalCtx: ModalContext,
    private modal: Modal,
    private api: AppService
  ) {}

  ngOnInit() {
    this.test = this.modalCtx.payload.test;
  }
  close() {
    this.modalCtx.reject();
  }
  edit(notification, index) {
    this.modal
      .open(EditPushModalComponent, {
        notification
      })
      .then((success: any) => {
        this.test.pushs[index] = success.data;
      })
      .catch(err => {
        console.log(err);
      });
  }
  get cancellable(): boolean {
    if (this.test.canceled_at) {
      // すでにキャンセル済み
      return false;
    }
    for (let push of this.test.pushs) {
      // 予約pushにのみtransmission_timeが存在する -> cancelできる
      if (!!push.transmission_time) {
        return true;
      }
    }
    return false;
  }
  cancel() {
    this.modal
      .open(AbtestDetailCancelConfirmComponent)
      .then(() => {
        this.api
          .cancel_abtest(this.test.id)
          .subscribe(() => this.modalCtx.resolve());
      })
      .catch(() => {});
  }
}
