import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-notification-progress-steps',
  templateUrl: './notification-progress-steps.component.html',
  styleUrls: ['./notification-progress-steps.component.scss']
})
export class NotificationProgressStepsComponent implements OnInit {
  @Input()
  step = 0;
  @Output()
  stepChange = new EventEmitter<number>();

  public stepItems = [
    { icon: 'fa-bell', name: 'CONTENT' },
    { icon: 'fa-calendar', name: 'TIME' },
    { icon: 'fa-users', name: 'AUDIENCE' },
    { icon: 'fa-search', name: 'PREVIEW' }
  ];

  constructor() {}

  ngOnInit() {}

  stepClick(s: number) {
    this.stepChange.emit(s);
  }
}
