
    <div class="message" *ngIf="value">
      <div
        class="message-header"
        [appAstDraggable]="value"
        (onMoved)="removeFromParentNode.emit()"
      >
        <p>開封済み通知</p>
        <a
          *ngIf="!hideDelete"
          class="delete"
          (click)="removeFromParentNode.emit()"
        ></a>
      </div>
      <div class="message-body">
        <p style="margin-bottom: 0.6rem;">
          pushid: {{ value.pushid || '未選択' }}
        </p>
        <button class="button" (click)="openSelector()">選択</button>
      </div>
    </div>
  