import { Pipe, PipeTransform } from '@angular/core';
import * as Moment from 'moment';

@Pipe({
  name: 'dateParse'
})
export class DateParsePipe implements PipeTransform {
  transform(value: any | Date): Date {
    if (!value) {
      return null;
    }
    if (value instanceof Date) {
      // Dateはそのまま返す
      return value;
    }
    try {
      return Moment(value).toDate();
    } catch (e) {
      return null;
    }
  }
}
