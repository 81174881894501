<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{ 'PUSH.CREATE' | translate }}
      </div>
      <div class="content">
        <app-notification-editor
          [(notification)]="notification"
          [query]="true"
        ></app-notification-editor>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button class="button" (click)="close()">
            {{ 'PUSH.COMMON.CANCEL' | translate }}
          </button>
          <button
            class="button is-success"
            (click)="transmit()"
            [class.is-loading]="sending"
          >
            {{ 'PUSH.COMMON.SUBMIT' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="modal-close" (click)="close()"></button>
</div>
