import { Component } from '@angular/core';
import { Modal } from '../../../services/modal/modal.service';
import { NewQueryModalComponent } from './query-list/new-query-modal/new-query-modal.component';
import { Query } from '../../../models/segments.model';
import { SegmentService } from '../../../services/segment/segment.service';

@Component({
  templateUrl: './query.component.html'
})
export class QueryComponent {
  constructor(private modal: Modal, private segment: SegmentService) {}
  public create() {
    this.modal
      .open<Query>(NewQueryModalComponent)
      .then(() => {
        this.segment.update();
      })
      .catch(() => {});
  }
}
