import { TranslateService } from '@ngx-translate/core';
import { AppService } from './../../../../services/app/app.service';
import { Store } from '@ngrx/store';
import { Notification } from './../../../../services/notification/notification.service';
import {
  EnterprisePlanOptions,
  PlanModel
} from './../../../../models/plan.model';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from './../../../../services/session/session.service';
import { Component } from '@angular/core';
import * as fromRoot from '../../../../reducers';
import * as app from '../../../../actions/app';

import { ja } from './agreement';
import { take } from 'rxjs/operators';
import { CardModel } from '../../../../models/card.model';

@Component({
  selector: 'app-plan-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class PlanConfirmComponent {
  public plan: PlanModel;
  public card: CardModel;
  public agree: boolean;
  private currentPlan: PlanModel;
  private appno: string;
  public agreement: string;
  public processing = false;
  constructor(
    private session: SessionService,
    private router: Router,
    private route: ActivatedRoute,
    private notification: Notification,
    private store: Store<fromRoot.State>,
    private api: AppService,
    private translate: TranslateService
  ) {
    // 現在のプランの取得
    store
      .select(fromRoot.getAppSelected)
      .pipe(take(1))
      .subscribe(selectedApp => {
        this.currentPlan = selectedApp.plan.current;
        this.appno = selectedApp.appno;
      });
    this.agreement = ja;
    // データの引き継ぎ
    this.plan = session.get('plan');
    if (!this.plan || this.plan.is_enterprise) {
      // どんな処理をするにしてもプラン情報は必要
      this.router.navigate(['../list'], { relativeTo: this.route });
      return;
    }

    // 個人プラン
    if (this.currentPlan.no < this.plan.no) {
      // 現在のプランよりも対象のプランの方が番号が大きい -> アップグレード
      if (this.currentPlan.no === 1) {
        // かつ現在無料プラン -> カード情報が必要
        this.card = session.get('card');
        if (!this.card) {
          // カード情報が必要だが定義されていない
          this.router.navigate(['../list'], { relativeTo: this.route });
          return;
        }
      }
    }
  }
  submit() {
    if (!this.agree) {
      this.translate.get('PLAN.CONFIRM.MUST_AGREE').subscribe((m: string) => {
        this.notification.open(m, 'warning');
      });
      return false;
    }
    this.processing = true;

    // 現在のプランと更新後のプランを比較
    if (this.currentPlan.no < this.plan.no) {
      // 更新後の方が値が大きい → アップグレード
      let ob;
      if (this.card) {
        // カードが定義されている -> カード情報が必要なアップグレード
        ob = this.api.role_upgrade_individual(
          this.plan.no,
          this.card.card_no,
          this.card.expire,
          this.card.holder
        );
      } else {
        // カードが未定義 -> カード情報の必要ないアップグレード
        ob = this.api.role_upgrade_individual(this.plan.no);
      }
      ob.subscribe(
        success => this.success(success),
        err => this.error(err)
      );
    } else if (this.currentPlan.no > this.plan.no) {
      this.api.role_downgrade(this.plan.no).subscribe(
        success => this.success(success),
        err => this.error(err)
      );
    }
  }
  success(s) {
    this.processing = false;
    this.notification.open(s.success, 'success');
    this.store.dispatch(new app.LoadAppAction(this.appno));
    this.router.navigate(['../list'], { relativeTo: this.route });
  }
  error(e) {
    this.processing = false;
    console.error(e);
    const error = e.error;
    const errorMSG =
      error +
      Object.keys(error.reason).reduce(
        (reason, key) => `${reason}\n${key}: ${error.reason[key]}`,
        ''
      );
    this.notification.open(errorMSG, 'danger');
  }
}
