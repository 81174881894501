import { ModalContext } from './../../../services/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Notification } from './../../../services/notification/notification.service';
import { UserService } from './../../../services/user/user.service';
import { Component } from '@angular/core';
import * as fromRoot from './../../../reducers';
import { ContactModel } from '../../../models/contact.model';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html'
})
export class ContactModalComponent {
  public contact: ContactModel;
  public valid = false;
  public appno$: Observable<string>;
  public sending = false;
  constructor(
    private user: UserService,
    private notification: Notification,
    private store: Store<fromRoot.State>,
    private translate: TranslateService,
    private modalCtx: ModalContext
  ) {
    this.appno$ = this.store.select(fromRoot.getAppSelectedId);
  }
  close() {
    this.modalCtx.reject();
  }
  submit() {
    if (!this.valid || !this.contact) {
      return;
    }
    this.sending = true;
    this.user
      .contact(this.contact)
      .pipe(finalize(() => (this.sending = false)))
      .subscribe(
        () => {
          this.translate.get('CONTACT.SUCCESS').subscribe((m: string) => {
            this.notification.open(m, 'success');
          });
          this.modalCtx.resolve();
        },
        response => {
          this.notification.open(response.error.error, 'warning');
        }
      );
  }
}
