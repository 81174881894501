<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        アクションの詳細
      </div>
      <div class="content">
        <article class="message is-info">
          <div class="message-body">
            {{
              action.created_at | dateParse | date: 'yyyy年MM月dd日 HH時mm分'
            }}に実行されたアクションの詳細です。{{
              action.target_subscribers
            }}名の購読者に対して、以下のアクションを実行しました。
          </div>
        </article>
        <ng-container *ngIf="action.actions.push_notification">
          <p class="is-4 title">通知の送信</p>
          <article class="message">
            <div class="message-body">
              {{
                action.actions.push_notification.type === 'push'
                  ? 'オートメーションルールから、プッシュ通知を送信しました'
                  : 'オートメーションルールから、負荷分散配信を用いて通知を送信しました'
              }}
            </div>
          </article>
          <ng-container *ngIf="normalPush; let noti">
            <article class="media">
              <figure class="media-left">
                <p class="image is-64x64">
                  <img src="{{ noti.icon }}" />
                </p>
              </figure>
              <div class="media-content">
                <div class="block">
                  <div class="control">
                    <p class="label">{{ 'PUSH.COMMON.CREATED' | translate }}</p>
                    <p>
                      {{ noti.created_date | dateParse | date: 'y/M/d, H:m' }}
                    </p>
                  </div>
                </div>
                <div class="block">
                  <div class="control">
                    <p class="label">{{ 'PUSH.COMMON.TITLE' | translate }}</p>
                    <p>{{ noti.title }}</p>
                  </div>
                </div>
                <div class="block">
                  <div class="control">
                    <p class="label">{{ 'PUSH.COMMON.URL' | translate }}</p>
                    <p>
                      <a href="{{ noti.url }}" target="_blank">{{
                        noti.url
                      }}</a>
                    </p>
                  </div>
                </div>
                <div class="block">
                  <div class="control">
                    <p class="label">{{ 'PUSH.COMMON.BODY' | translate }}</p>
                    <p>{{ noti.body }}</p>
                  </div>
                </div>
                <div class="block">
                  <div class="control" *ngIf="noti.image">
                    <p class="label">{{ 'PUSH.COMMON.IMAGE' | translate }}</p>
                    <p><img [src]="noti.image" /></p>
                  </div>
                </div>
                <div class="block">
                  <div class="control columns">
                    <div class="column is-6">
                      <p class="label">
                        {{ 'PUSH.COMMON.SHOW_UNTIL_CLICKED' | translate }}
                      </p>
                      <p>
                        {{
                          (noti.disappear_instantly
                            ? 'COMMON.NO'
                            : 'COMMON.YES'
                          ) | translate
                        }}
                      </p>
                    </div>
                    <div class="column is-6">
                      <p class="label">
                        {{ 'PUSH.COMMON.ENABLE_IOS_SOUND' | translate }}
                      </p>
                      <p>
                        {{
                          (noti.enable_ios_sound ? 'COMMON.YES' : 'COMMON.NO')
                            | translate
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="control columns">
                  <div class="column is-4">
                    <p class="label">{{ 'PUSH.COMMON.SENT' | translate }}</p>
                    <p>{{ noti.success_send_count || noti.subscribers }}</p>
                  </div>
                  <div class="column is-8">
                    <p class="label">{{ 'PUSH.COMMON.CLICK' | translate }}</p>
                    <p>{{ noti.clicked_count }}</p>
                  </div>
                </div>
              </div>
            </article>
          </ng-container>
          <ng-container *ngIf="loadBalancePush; let noti">
            <article class="media">
              <figure class="media-left">
                <p class="image is-64x64">
                  <img src="{{ noti.icon }}" />
                </p>
              </figure>
              <div class="media-content">
                <div class="field block">
                  <p class="label">配信ステータス</p>
                  <ng-container [ngSwitch]="noti.status">
                    <p *ngSwitchCase="'completed'">
                      完了
                    </p>
                    <div *ngSwitchCase="'running'">
                      <p>
                        送信中({{
                          (noti.pushed_groups / noti.total_groups) * 100
                            | number: '0.0-3'
                        }}%)
                      </p>
                      <progress
                        class="progress is-info"
                        [value]="noti.pushed_groups"
                        [max]="noti.total_groups"
                      ></progress>
                    </div>
                    <p *ngSwitchCase="'scheduled'">
                      予約済み
                    </p>
                    <p *ngSwitchCase="'preparing'">
                      準備中
                    </p>
                    <p *ngSwitchCase="'canceled'">
                      キャンセル済み
                    </p>
                    <p *ngSwitchCase="'failed'">
                      失敗
                    </p>
                  </ng-container>
                </div>
                <div class="field">
                  <p class="label">送信開始日時</p>
                  <p>{{ noti.start_at | dateParse | date: 'y/M/d, H:m' }}</p>
                </div>
                <div class="field">
                  <p class="label">{{ 'PUSH.COMMON.TITLE' | translate }}</p>
                  <p>{{ noti.title }}</p>
                </div>
                <div class="field">
                  <p class="label">{{ 'PUSH.COMMON.URL' | translate }}</p>
                  <p>
                    <a href="{{ noti.url }}" target="_blank">{{ noti.url }}</a>
                  </p>
                </div>
                <div class="field">
                  <p class="label">{{ 'PUSH.COMMON.BODY' | translate }}</p>
                  <p>{{ noti.body }}</p>
                </div>
                <div class="field" *ngIf="noti.image">
                  <p class="label">{{ 'PUSH.COMMON.IMAGE' | translate }}</p>
                  <p><img [src]="noti.image" /></p>
                </div>
                <div class="control columns">
                  <div class="column is-12">
                    <p class="label">
                      {{ 'PUSH.COMMON.ENABLE_IOS_SOUND' | translate }}
                    </p>
                    <p class="label">
                      {{
                        (noti.enable_ios_sound ? 'COMMON.YES' : 'COMMON.NO')
                          | translate
                      }}
                    </p>
                  </div>
                </div>
                <div
                  class="field columns"
                  *ngIf="
                    noti.status === 'completed' || noti.status === 'running'
                  "
                >
                  <div class="column is-4">
                    <p class="label">{{ 'PUSH.COMMON.SENT' | translate }}</p>
                    <p>{{ noti.total_success_send_count }}</p>
                  </div>
                  <div class="column is-8">
                    <p class="label">{{ 'PUSH.COMMON.CLICK' | translate }}</p>
                    <p>{{ noti.total_clicked_count }}</p>
                  </div>
                </div>
              </div>
            </article>
          </ng-container>
          <hr />
        </ng-container>
        <ng-container *ngIf="action.actions.add_parameters">
          <h3 class="is-title is-4">パラメータの追加</h3>
          <article class="message">
            <div class="message-body">
              オートメーションルールから、対象購読者に以下{{
                action.actions.add_parameters.length
              }}件のパラメータを追加しました。
            </div>
          </article>
          <div class="block">
            <span
              class="tag is-primary is-medium mr-2"
              *ngFor="let param of action.actions.add_parameters"
              >{{ param }}</span
            >
          </div>
          <hr />
        </ng-container>
        <ng-container *ngIf="action.actions.delete_parameters">
          <h3 class="is-title is-4">パラメータの削除</h3>
          <article class="message">
            <div class="message-body">
              オートメーションルールから、対象購読者に以下{{
                action.actions.delete_parameters.length
              }}件のパラメータを削除しました。
            </div>
          </article>
          <div class="block">
            <span
              class="tag is-primary is-medium mr-2"
              *ngFor="let param of action.actions.delete_parameters"
              >{{ param }}</span
            >
          </div>
        </ng-container>
      </div>
      <div class="content-footer">
        <div class="has-text-right">
          <button class="button is-dark p7-button" (click)="close()">
            {{ 'COMMON.CLOSE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="modal-close" (click)="close()"></button>
</div>
