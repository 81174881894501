import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ConnectableObservable, Observable, Subject } from 'rxjs';
import {
  finalize,
  map,
  publishReplay,
  switchMap,
  takeUntil,
  tap
} from 'rxjs/operators';
import { PaymentMethodFormComponent } from '../../../components/payment-method-form/payment-method-form.component';
import { PaymentMethodModel } from '../../../models/plan.model';
import { getAppSelected, State } from '../../../reducers';
import { AppService } from '../../../services/app/app.service';
import { AuthService } from '../../../services/auth/auth.service';
import { Notification } from '../../../services/notification/notification.service';

@Component({
  templateUrl: './payment-method.component.html'
})
export class PaymentMethodComponent implements OnInit, OnDestroy {
  onDestroy$: Subject<void> = new Subject();
  paymentMethod$: Observable<PaymentMethodModel>;
  form: FormGroup;
  loading = false;
  isEnterpriseProject$: Observable<boolean>;
  loadPaymentMethod$: Subject<void> = new Subject();

  constructor(
    private fb: FormBuilder,
    private app: AppService,
    private notificationService: Notification,
    private store: Store<State>,
    private authService: AuthService
  ) {
    this.paymentMethod$ = this.loadPaymentMethod$.pipe(
      takeUntil(this.onDestroy$),
      switchMap(() => this.app.get_payment_method()),
      publishReplay(1)
    );
    (this.paymentMethod$ as ConnectableObservable<any>).connect();
  }

  ngOnInit() {
    this.form = this.fb.group({
      paymentMethod: this.fb.control(
        PaymentMethodFormComponent.defaultFormValue()
      )
    });
    this.isEnterpriseProject$ = this.store.select(getAppSelected).pipe(
      switchMap(app => this.authService.getProjectFromAppno(app.appno)),
      map(project => project.is_corporation)
    );

    this.loadPaymentMethod$.next();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  displayPaymentMethod(method: PaymentMethodModel['payment_method']) {
    switch (method) {
      case 'card':
        return 'カード支払い';
      case 'transfer':
        return '銀行振り込み';
      default:
        return 'お支払い方法が指定されていません';
    }
  }

  submit() {
    this.loading = true;
    this.app
      .update_payment_method(this.form.value.paymentMethod)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        () => {
          this.notificationService.open('支払い方法を更新しました', 'success');
          this.form.reset();
          this.loadPaymentMethod$.next();
        },
        () => {
          this.notificationService.open(
            '支払い方法を更新に失敗しました',
            'danger'
          );
        }
      );
  }
}
