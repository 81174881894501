import { Component, OnInit, Input } from '@angular/core';
import { NotificationModel } from '../../../models/notification.model';

@Component({
  selector: 'app-preview-device-android',
  templateUrl: './device-android.component.html',
  styleUrls: ['./device-android.component.scss']
})
export class PreviewDeviceAndroidComponent implements OnInit {
  @Input()
  notification: NotificationModel;
  @Input()
  appUrl: string;

  constructor() {}

  ngOnInit() {}

  public get clock_time() {
    return this.notification.transmission_time || new Date();
  }

  public get url_without_http() {
    return this.appUrl.replace(/^https?:\/\//, '');
  }
}
