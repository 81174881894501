
    <div
      class="app-ast-inner-node message"
      appAstDroppable
      (onDrop)="dropHandler($event)"
      *ngIf="value"
    >
      <div
        class="message-header"
        [appAstDraggable]="value"
        (onMoved)="removeFromParentNode.emit()"
      >
        <p>Operator:{{ value.operator }}</p>
        <div>
          <button
            *ngIf="!hideDelete"
            class="delete"
            aria-label="delete"
            style="transform:rotate(45deg);"
            appAstAddElem
            [withoutValue]="true"
            (addElem)="pushElem($event)"
          ></button>
          <button
            class="delete"
            aria-label="delete"
            (click)="removeFromParentNode.emit()"
          ></button>
        </div>
      </div>
      <div class="message-body">
        <div class="columns is-multiline {{ uniqueClassName }}">
          <div
            class="column is-narrow ast-elem-wrap"
            *ngFor="let elem of value.children; let i = index"
          >
            <app-ast-node
              [value]="elem"
              (removeFromParentNode)="handleDelete(i)"
            ></app-ast-node>
          </div>
        </div>
      </div>
    </div>
  