import { Observable, of } from 'rxjs';

import { default as ja } from '../../translations/ja/translations.json';
import { default as en } from '../../translations/en/translations.json';

import { TranslateLoader } from '@ngx-translate/core';

export class CustomLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case 'ja': {
        return of(ja);
      }
      default: {
        return of(en);
      }
    }
  }
}
