import { Component } from '@angular/core';
import { ModalContext } from '../../../../../services/modal/modal.service';
import { FormControl, Validators } from '@angular/forms';
import { AppService } from '../../../../../services/app/app.service';

@Component({
  templateUrl: './new-query-modal.component.html'
})
export class NewQueryModalComponent {
  public processing = false;
  public form = new FormControl('', [Validators.required]);
  constructor(private modalCtx: ModalContext, private api: AppService) {}
  close() {
    this.modalCtx.reject();
  }
  submit() {
    if (this.form.invalid) {
      return;
    }
    this.processing = true;
    this.api.create_query('A AND B', this.form.value).subscribe(
      response => {
        this.modalCtx.resolve(response);
      },
      () => {
        this.processing = false;
      }
    );
  }
}
