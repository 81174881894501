import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getAppSelectedNotifications } from './../../../../../reducers/index';
import { Component, ElementRef, AfterContentInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../reducers';
import * as app from '../../../../../actions/app';
import { AppModel } from '../../../../../models/app.model';
import { NotificationModel } from '../../../../../models/notification.model';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { Chart } from 'chart.js';

import { map, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-performance-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class AnalyticsPerformanceDetailComponent implements AfterContentInit {
  private _el: HTMLElement;
  private appno: string;
  public notification: Observable<any>;
  private appSubscription: Subscription;
  public list$: Observable<NotificationModel[]>;

  public chartLegends: string;
  public context: any;
  public chart: any;

  public push: any;
  public page = 1;
  public max: number;
  constructor(
    el: ElementRef,
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {
    this._el = el.nativeElement;
  }

  ngAfterContentInit() {
    this.route.paramMap
      .pipe(map(params => params.get('id')))
      .pipe(
        withLatestFrom(this.store.select(getAppSelectedNotifications)),
        map(([pushid, selectedApp]) => {
          if (!selectedApp || !selectedApp.data || !selectedApp.data[pushid]) {
            throw new Error(pushid);
          }
          return selectedApp.data[pushid];
        }),
        withLatestFrom(
          this.translate.get('PUSH.COMMON.CLICK'),
          this.translate.get('COMMON.UNCLICKED')
        )
      )
      .subscribe(
        ([push, CLICKED, UNCLICKED]) => {
          this.push = Object.assign({}, push, {
            unclicked:
              (push.success_send_count || push.subscribers) - push.clicked_count
          });
          const sent = push.success_send_count || push.subscribers;
          const clicked = push.clicked_count;

          // グラフの描画
          this.context = this._el.querySelector('canvas').getContext('2d');
          this.chart = new Chart(this.context, {
            type: 'pie',
            data: {
              labels: [CLICKED, UNCLICKED],
              datasets: [
                {
                  label: '',
                  backgroundColor: ['#EEAC01', '#CCCCCC'],
                  borderWidth: 0,
                  data: [this.push.clicked_count, this.push.unclicked]
                }
              ]
            },
            options: {
              responsive: false,
              legend: {
                display: true,
                position: 'right',
                onClick: () => {}
              }
            }
          });
          this.chartLegends = this.chart.generateLegend();
        },
        pushid => {
          this.router.navigate(['../../index'], { relativeTo: this.route });
        }
      );
  }
}
