import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getAppSelected, State } from '../../reducers';
import { AppModel } from '../../models/app.model';
import { AutomationRule } from '../../services/api';
import { DeliveryStrategyFormComponent } from '../delivery-strategy-form/delivery-strategy-form.component';
import { NotificationBodyFormComponent } from '../notification-body-form/notification-body-form.component';
import { SegmentationFormComponent } from '../segmentation-form/segmentation-form.component';

type EditableAutomationRule = Pick<
  AutomationRule,
  'name' | 'status' | 'skip_days' | 'trigger' | 'segmentation' | 'actions'
>;

@Component({
  selector: 'app-automation-rule-form',
  templateUrl: './automation-rule-form.component.html',
  styleUrls: ['./automation-rule-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AutomationRuleFormComponent)
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AutomationRuleFormComponent),
      multi: true
    }
  ]
})
export class AutomationRuleFormComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  private onDestroy$: Subject<void> = new Subject<void>();
  private app: AppModel;

  // 編集対象のrule
  public form: FormGroup;

  // 状態として
  public isDisabled = false;

  // コールバック群
  private onChange: any = () => {};
  private onTouched: any = () => {};

  // 外部とのやり取り
  get value(): EditableAutomationRule {
    return this.form.value;
  }
  @Input('value')
  set value(rule: EditableAutomationRule) {
    if (!rule) {
      return;
    }
    this.form.setValue(rule);
    // 外部から値がセットされた場合、内部ステートである _skipDaysType を更新する
    this._skipDaysType =
      typeof rule.skip_days === 'number' ? 'number' : 'forever';
    this.onChange(rule);
    this.onTouched();
  }

  constructor(private fb: FormBuilder, private store: Store<State>) {}

  validate(_: AbstractControl): ValidationErrors {
    return this.form.valid ? null : { rule: { valid: false } };
  }

  // スキップ条件を日数で指定するか無限とするかを内部ステートとしてここで持つ
  private _skipDaysType: 'number' | 'forever';
  get skipDaysType(): 'number' | 'forever' {
    return this._skipDaysType;
  }
  set skipDaysType(type: 'number' | 'forever') {
    // 更新時には、skip_daysにデフォルト値を設定する
    this._skipDaysType = type;
    switch (type) {
      case 'number':
        this.form.get('skip_days').setValue(0);
        break;
      case 'forever':
        this.form.get('skip_days').setValue('forever');
        break;
    }
  }

  // 各アクションのオン・オフを内部ステートとして管理
  get performPushNotification(): boolean {
    return (
      !!this.form.value.actions.push_notification &&
      !!this.form.value.actions.push_notification.notification
    );
  }
  set performPushNotification(b: boolean) {
    if (b) {
      this.form
        .get(['actions', 'push_notification', 'notification'])
        .setValue(NotificationBodyFormComponent.defaultFormValue(this.app));
      this.form
        .get(['actions', 'push_notification', 'delivery_strategy'])
        .setValue(DeliveryStrategyFormComponent.defaultFormValue());
    } else {
      this.form
        .get(['actions', 'push_notification', 'notification'])
        .setValue(null);
      this.form
        .get(['actions', 'push_notification', 'delivery_strategy'])
        .setValue(null);
    }
  }
  get performAddParameters(): boolean {
    return !!this.form.value.actions.add_parameters;
  }
  set performAddParameters(b: boolean) {
    if (b) {
      this.form.get(['actions', 'add_parameters']).setValue([]);
    } else {
      this.form.get(['actions', 'add_parameters']).setValue(null);
    }
  }
  get performDeleteParameters(): boolean {
    return !!this.form.value.actions.delete_parameters;
  }
  set performDeleteParameters(b: boolean) {
    if (b) {
      this.form.get(['actions', 'delete_parameters']).setValue([]);
    } else {
      this.form.get(['actions', 'delete_parameters']).setValue(null);
    }
  }

  static defaultFormValue(app: AppModel) {
    const emptyRule: AutomationRule = {
      name: '',
      status: 'active',
      skip_days: 'forever',
      trigger: {
        schedule: '0 0 1/1 * *'
      },
      actions: {
        push_notification: {
          notification: null,
          delivery_strategy: null
        },
        delete_parameters: null,
        add_parameters: null
      },
      segmentation: SegmentationFormComponent.defaultFormValue()
    };
    return emptyRule;
  }

  ngOnInit() {
    this.store
      .select(getAppSelected)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(app => (this.app = app));
    this.form = this.fb.group(
      {
        name: this.fb.control('', Validators.required),
        status: this.fb.control('active', Validators.required),
        skip_days: this.fb.control(0, Validators.required),
        trigger: this.fb.group({
          schedule: this.fb.control('0 0 1/1 * *', Validators.required)
        }),
        actions: this.fb.group({
          push_notification: this.fb.group({
            notification: this.fb.control(null),
            delivery_strategy: this.fb.control(null)
          }),
          delete_parameters: [],
          add_parameters: []
        }),
        segmentation: []
      },
      {
        validators: [
          (control: AbstractControl) => {
            const actions = control.value.actions;
            // アクションは1つ以上必要
            if (
              !actions.push_notification.notification &&
              !actions.add_parameters &&
              !actions.delete_parameters
            ) {
              return { require_one_or_more_actions: true };
            }
            // push_notificationを指定している場合
            if (!!actions.push_notification.notification) {
              const bodyValidationResult = NotificationBodyFormComponent.validate(
                control.get(['actions', 'push_notification', 'notification'])
              );
              if (bodyValidationResult) {
                return bodyValidationResult;
              }
              const DSValidationResult = DeliveryStrategyFormComponent.validate(
                control.get([
                  'actions',
                  'push_notification',
                  'delivery_strategy'
                ])
              );
              if (DSValidationResult) {
                return DSValidationResult;
              }
            }
            // add_parametersを指定する場合 -> 1つ以上のパラメータを指定する
            if (!!actions.add_parameters) {
              if (actions.add_parameters.length <= 0) {
                return { empty_add_parameters: true };
              }
            }
            // delete_parametersを指定する場合 -> 1つ以上のパラメータを指定する
            if (!!actions.delete_parameters) {
              if (actions.delete_parameters.length <= 0) {
                return { empty_delete_parameters: true };
              }
            }
            return null;
          }
        ]
      }
    );
    this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  writeValue(obj: EditableAutomationRule): void {
    if (obj) {
      this.value = obj;
    }
    if (obj === null) {
      this.form.reset();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
