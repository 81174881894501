import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-help-link',
  template: `
    <a class="icon" (click)="open()"
      ><span class="fa fa-question-circle"></span
    ></a>
  `,
  styles: [
    `
      .icon {
        position: absolute;
        right: 28.5px;
        top: 28.5px;
      }
    `
  ]
})
export class HelpLinkComponent {
  @Input()
  public link;
  public open() {
    window.open(this.link);
  }
}
