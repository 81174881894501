import { Subject } from 'rxjs';
import { Category } from './../../models/category.model';
import { BehaviorSubject } from 'rxjs';
import { getAppSelected } from './../../reducers/index';
import { Store } from '@ngrx/store';
import { AppService } from './../../services/app/app.service';
import { CategoryService } from './../../services/category/category.service';
import { ModalContext } from './../../services/modal/modal.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import * as fromRoot from '../../reducers';
import * as App from '../../actions/app';

import { take, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-set-category-modal',
  templateUrl: './set-category-modal.component.html'
})
export class SetCategoryComponent implements OnDestroy {
  public categoryno: number;
  public categories: BehaviorSubject<Category[]>;
  private isDestroied: Subject<void> = new Subject<void>();
  constructor(
    private modalCtx: ModalContext,
    private categoryService: CategoryService,
    private api: AppService,
    private store$: Store<fromRoot.State>
  ) {
    this.categories = categoryService.categories;
  }
  select(ev) {
    this.categoryno = ev.target.value;
  }
  close() {
    this.modalCtx.reject();
  }
  ok() {
    this.store$
      .select(getAppSelected)
      .pipe(
        take(1),
        switchMap(app =>
          this.api.update(
            Object.assign({}, app, { categoryno: this.categoryno })
          )
        )
      )
      .subscribe(() => {
        this.modalCtx.resolve();
        this.store$
          .select(getAppSelected)
          .pipe(take(1))
          .subscribe(app =>
            this.store$.dispatch(new App.LoadAppAction(app.appno))
          );
      });
  }
  ngOnDestroy() {
    this.isDestroied.next();
    this.isDestroied.complete();
  }
}
