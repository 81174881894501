<app-navbar></app-navbar>
<div class="wrapper">
  <section class="hero welcome">
    <div class="hero-body home-header">
      <div class="container">
        <h1 class="title">
          {{ 'HOME.WELCOME' | translate }}
        </h1>
        <h2 class="subtitle">
          {{ 'HOME.MESSAGE' | translate }}
        </h2>
      </div>
    </div>
  </section>
  <div class="hero">
    <section class="hero-body">
      <div
        class="container"
        *ngIf="projects$ | async; let projects; else: Loading"
      >
        <div class="projects" *ngIf="projects.length > 0; else NoProjects">
          <div
            class="project card mb-4 px-5 py-5"
            *ngFor="let project of projects"
          >
            <div class="project-header">
              <h2 class="title is-3 mb-5">{{ project.name }}</h2>
              <div class="project-config-button">
                <button
                  class="button is-dark mr-3"
                  (click)="addApp(project)"
                  *ngIf="project.role === 'administrator'"
                >
                  <span class="icon">
                    <i class="fa fa-plus"></i>
                  </span>
                  <span>新規アプリケーション</span>
                </button>
                <button class="button is-dark" (click)="editProject(project)">
                  <span class="icon">
                    <i class="fa fa-cog"></i>
                  </span>
                </button>
              </div>
            </div>
            <div
              class="columns apps"
              *ngIf="project.apps.length > 0; else NoApps"
            >
              <div
                class="column is-half is-one-quarter-fullhd"
                *ngFor="let app of project.apps"
              >
                <div class="card app px-3 py-3" (click)="goApp(app.appno)">
                  <div class="card-content is-flex">
                    <img
                      src="{{ app.icon }}"
                      class="image is-64x64"
                      alt="Image"
                    />
                    <div class="app-body">
                      <div class="app-name">{{ app.name }}</div>
                      <div class="app-url">{{ app.url }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #NoApps>
              <div class="notification is-info is-light">
                アプリケーションがありません
              </div>
            </ng-template>
          </div>
        </div>
        <ng-template #NoProjects>
          <div class="notification is-info is-light">
            プロジェクトが見つかりませんでした。下記ボタンから新しいプロジェクトを作成するか、プロジェクト管理者に招待を依頼してください。
          </div>
        </ng-template>
        <div class="button-new-project">
          <button class="button is-dark" (click)="addProject()">
            <span class="icon">
              <i class="fa fa-plus"></i>
            </span>
            <span>プロジェクトを作成</span>
          </button>
        </div>
      </div>
      <ng-template #Loading>
        <app-loading></app-loading>
      </ng-template>
    </section>
  </div>
</div>
