<ng-container class="form" [formGroup]="parent.control">
  <div class="field has-addons mx-2">
    <ng-container *ngIf="!use24HourTime">
      <div class="control">
        <div class="select">
          <select formControlName="hourType">
            <option *ngFor="let hourType of hourTypes" [value]="hourType">{{
              hourType
            }}</option>
          </select>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!hideHours">
      <div class="control">
        <div class="select">
          <select formControlName="hours">
            <option *ngFor="let hour of hours" [value]="hour">{{
              hour
            }}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <a class="button is-static">
          時
        </a>
      </div>
    </ng-container>
    <ng-container *ngIf="!hideMinutes">
      <div class="control">
        <div class="select">
          <select formControlName="minutes">
            <option *ngFor="let minute of minutes" [value]="minute">{{
              minute
            }}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <a class="button is-static">
          分
        </a>
      </div>
    </ng-container>

    <ng-container *ngIf="!hideSeconds">
      <div class="control">
        <div class="select">
          <select formControlName="seconds">
            <option *ngFor="let second of seconds" [value]="second">{{
              second
            }}</option>
          </select>
        </div>
      </div>
      <div class="control">
        <a class="button is-static">
          秒
        </a>
      </div>
    </ng-container>
  </div>
</ng-container>
