<div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        Enterpriseトライアル申し込み
      </div>
      <ng-container *ngIf="step == 0">
        <div class="content">
          <p>
            Enterpriseトライアルプランでは、法人向けプランであるEnterpriseプランを1ヶ月無料でお試しいただけます。
          </p>
          <p>ご試用にあたって、以下の注意事項をご一読ください。</p>
          <ul>
            <li>
              トライアル期間は1ヶ月です。ご契約される場合は
              <a href="https://push7.jp/contact" target="_blank"
                >お問い合わせページ</a
              >よりご連絡ください。
            </li>
            <li>
              トライアルプランの商用環境でのご利用は禁止とさせていただいています。既にPush7をご利用になられている場合は、
              <a href="https://push7.jp/contact" target="_blank"
                >お問い合わせページ</a
              >よりご連絡ください。
            </li>
          </ul>
        </div>
        <div class="content-footer">
          <div class="flex has-text-right">
            <button class="button is-error" (click)="cancel()">
              キャンセル
            </button>
            <button class="button is-success" (click)="next()">
              上記を理解して次へ
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="step == 1">
        <div class="content">
          <p>会社情報を入力してください。</p>
          <form [formGroup]="infoForm">
            <div class="field">
              <label class="label">会社名</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  formControlName="name"
                  name="name"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">部署名(任意)</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  formControlName="department_name"
                  name="department_name"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">ご担当者様名</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  formControlName="personnel_name"
                  name="personnel_name"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">住所</label>
              <div class="control">
                <textarea
                  class="textarea"
                  formControlName="address"
                  name="address"
                ></textarea>
              </div>
            </div>
            <div class="field">
              <label class="label">電話番号(任意)</label>
              <div class="control">
                <input
                  class="input"
                  type="tel"
                  formControlName="phone_number"
                  name="phone_number"
                />
              </div>
            </div>
          </form>
        </div>
        <div class="content-footer">
          <div class="flex has-text-right">
            <button class="button is-error" (click)="back()">戻る</button>
            <button
              class="button is-success"
              [class.is-loading]="sending"
              [disabled]="!infoForm.valid"
              (click)="submitInfo()"
            >
              Enterpriseトライアルを開始
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="step == 2">
        <div class="content">
          <p>Enterpriseトライアルを開始しました。</p>
          <p>
            別途、お電話でのご案内を希望される方は、下記よりご希望の日時をお知らせください。
          </p>
          <form [formGroup]="telForm">
            <div class="field">
              <label class="label">電話番号</label>
              <div class="control">
                <input
                  class="input"
                  type="tel"
                  formControlName="tel"
                  name="tel"
                />
              </div>
            </div>
            <ng-container *ngFor="let i of [0, 1, 2]">
              <label class="label">候補日時{{ i + 1 }}</label>
              <div class="field is-flex">
                <div class="control">
                  <input
                    class="input date_input"
                    type="datetime"
                    name="date1"
                  />
                </div>
                <p class="control">
                  <span class="select">
                    <select (change)="dates[i].hour($event.target.value)">
                      <option *ngFor="let h of hours" [value]="h"
                        >{{ h }}時</option
                      >
                    </select>
                  </span>
                </p>
                <p class="control">
                  <span class="select">
                    <select (change)="dates[i].minute($event.target.value)">
                      <option *ngFor="let m of mins" [value]="m"
                        >{{ m }}分</option
                      >
                    </select>
                  </span>
                </p>
              </div>
            </ng-container>
          </form>
        </div>
        <div class="content-footer">
          <div class="flex has-text-right">
            <button class="button is-error" (click)="cancel()">
              電話での案内を希望しない
            </button>
            <button
              class="button is-success"
              [class.is-loading]="sending"
              [disabled]="!telForm.valid"
              (click)="submitTel()"
            >
              送信
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="step == 3">
        <div class="content">
          <p>
            ご希望のご連絡日時の送信ありがとうございました。<br />
            担当者よりご連絡日時について別途ご連絡いたします。
          </p>
        </div>
        <div class="content-footer">
          <div class="flex has-text-right">
            <button class="button is-success" (click)="done()">完了</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
