<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-header has-text-weight-bold is-size-4">
        <span>セグメント一覧</span>
        <a class="icon" (click)="newSegment()" *appDisplayFor="'operator'"
          ><span class="fa fa-plus-circle"></span
        ></a>
      </div>
      <div class="container-body is-paddingless">
        <ng-container *ngIf="queries$ | async; let queries; else: Loading">
          <ng-container *ngIf="queries.length > 0; else NoQueries">
            <table class="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>{{ 'SEGMENTATION.NAME' | translate }}</th>
                  <!--<th>{{ 'SEGMENTATION.QUERY' | translate }}</th>-->
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let query of queries">
                  <td>{{ query.name }}</td>
                  <!--<td>{{ query.query }}</td>-->
                  <td>
                    <a
                      class="button is-info is-outlined"
                      [routerLink]="['../detail/' + query.id]"
                    >
                      編集
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <ng-template #NoQueries>
            <div class="box">
              <div class="content">
                <article class="message is-info">
                  <div class="message-header">
                    <p>{{ 'SEGMENTATION.NOQUERY' | translate }}</p>
                  </div>
                  <div class="message-body">
                    <p>{{ 'SEGMENTATION.NOQUERY_MESSAGE' | translate }}</p>
                  </div>
                </article>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #Loading>
          <app-loading></app-loading>
        </ng-template>
      </div>
    </div>
  </div>
</div>
