import { Category } from './../../../models/category.model';
import { Component } from '@angular/core';

import { Observable, Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { finalize, take, switchMap, withLatestFrom, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as app from '../../../actions/app';
import { AppModel } from '../../../models/app.model';
import { AppService } from '../../../services/app/app.service';
import { Notification } from '../../../services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { CategoryService } from './../../../services/category/category.service';
import { Go } from './../../../actions/router';

import { Modal } from '../../../services/modal/modal.service';
import { AppDeleteModalComponent } from './app-delete-modal/app-delete-modal.component';
import { Project, DefaultService as ApiService } from '../../../services/api';
import { AuthService } from '../../../services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  app$: Observable<AppModel>;
  private currentProject$: Observable<Project>;
  projectsToTransfer$: Observable<Project[]>;
  appSubscription: Subscription;
  name: string;
  url: string;
  icon: string;
  categoryno: number;
  categories: BehaviorSubject<Category[]>;
  /**
   * プロジェクト移行の際の移行先プロジェクト
   */
  projectTransferDestination: string;
  appno: string;
  plan: number;
  processing = false;
  constructor(
    private store: Store<fromRoot.State>,
    private appService: AppService,
    private translate: TranslateService,
    private notification: Notification,
    private modal: Modal,
    private categoryService: CategoryService,
    private authService: AuthService,
    private api: ApiService,
    private router: Router
  ) {
    this.app$ = this.store.select(fromRoot.getAppSelected);
    this.currentProject$ = this.app$.pipe(
      map(a => a.appno),
      switchMap(appno => this.authService.getProjectFromAppno(appno))
    );
    this.projectsToTransfer$ = combineLatest(
      this.authService.projects$,
      this.currentProject$
    ).pipe(
      map(([projects, currentProject]) =>
        projects
          .filter(p => p.uuid !== currentProject.uuid)
          .filter(
            p => p.role === 'administrator' || p.role === 'spy_administrator'
          )
      )
    );
    this.appSubscription = this.app$.pipe(take(1)).subscribe(selectedApp => {
      this.appno = selectedApp.appno;
      this.name = selectedApp.name;
      this.url = selectedApp.url;
      this.icon = selectedApp.icon;
      this.categoryno = selectedApp.categoryno;
      this.plan = selectedApp.plan.current.no;
    });

    this.categories = categoryService.categories;
  }
  save() {
    this.processing = true;
    this.appService
      .update({
        name: this.name,
        url: this.url,
        icon: this.icon,
        categoryno: this.categoryno
      })
      .pipe(finalize(() => (this.processing = false)))
      .subscribe(
        success => {
          this.authService.loadProjects();
          this.store.dispatch(new app.LoadAppAction(this.appno));
          this.translate
            .get('SETTINGS.MESSAGES.SUCCESS')
            .subscribe((m: string) => {
              this.notification.open(m, 'success');
            });
        },
        response => {
          this.translate
            .get('SETTINGS.MESSAGES.ERROR')
            .subscribe((m: string) => {
              this.notification.open(`${m}:${response.error.error}`, 'danger');
            });
        }
      );
  }
  transfer() {
    if (!this.projectTransferDestination) {
      return;
    }
    this.app$
      .pipe(
        switchMap(({ appno }) =>
          this.api.transferApp(appno, {
            project_uuid: this.projectTransferDestination
          })
        )
      )
      .subscribe(() => {
        this.authService.loadProjects();
        this.notification.open(
          'アプリケーションの移行が完了しました',
          'success'
        );
        this.router.navigate(['home']);
      });
  }
  delete() {
    if (this.plan !== 1) {
      this.translate
        .get('SETTINGS.DELETE_PROHIBITED')
        .subscribe((m: string) => {
          this.notification.open(m, 'warning');
        });
      return;
    }
    this.modal
      .open(AppDeleteModalComponent)
      .then(success => {
        // 削除された場合はresolveされる
        this.store.dispatch(new Go({ path: ['/home'] }));
      })
      .catch(() => {});
  }
}
