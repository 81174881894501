import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  DateSpecifier,
  ASTRelativeDateTime,
  ASTKeywordDateTime,
  ASTDateTime
} from '../../../../models/segments.model';

@Component({
  selector: 'app-segment-date-specifier-selector',
  templateUrl: 'date-specifier-selector-component.html'
})
export class DateSpecifierSelectorComponent {
  @Input()
  dateSpecifier: DateSpecifier;
  @Output()
  dateSpecifierChange: EventEmitter<DateSpecifier> = new EventEmitter<
    DateSpecifier
  >();

  update({ target: { value } }) {
    switch (value) {
      case 'ASTRelativeDateTime':
        this.dateSpecifierChange.next(new ASTRelativeDateTime(3, 'MINUTE'));
        break;
      case 'ASTKeywordDateTime':
        this.dateSpecifierChange.next(new ASTKeywordDateTime('NOW'));
        break;
      case 'ASTDateTime':
        this.dateSpecifierChange.next(new ASTDateTime());
        break;
    }
  }
}
