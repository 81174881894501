import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Notification } from './../../../../services/notification/notification.service';
import { AppService } from './../../../../services/app/app.service';
import { NotificationModel } from './../../../../models/notification.model';
import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
  public notification: NotificationModel;
  public show: boolean;
  public processing = false;
  constructor(
    private appService: AppService,
    private notificationService: Notification,
    private translate: TranslateService
  ) {
    appService
      .welcome_get()
      .pipe(take(1))
      .subscribe(n => {
        this.notification = n;
        this.show = !!Object.keys(n).length;
      });
  }
  save() {
    if (this.show) {
      // 表示する
      if (
        !this.notification.title ||
        !this.notification.body ||
        !this.notification.url
      ) {
        this.translate.get('AUTO.WELCOME.FILL').subscribe(m => {
          this.notificationService.open(m, 'warning');
        });
        return;
      }
      this.processing = true;
      this.appService
        .welcome_set(this.notification)
        .pipe(
          take(1),
          finalize(() => (this.processing = false))
        )
        .subscribe(
          success => {
            this.translate.get('AUTO.WELCOME.UPDATED').subscribe(m => {
              this.notificationService.open(m, 'success');
            });
          },
          (response: HttpErrorResponse) => {
            const e = response.error;
            if (e.error) {
              this.notificationService.open(
                `${response.status} ${e.error}`,
                'danger'
              );
            } else {
              this.translate
                .get('AUTO.WELCOME.FAILED')
                .subscribe((m: string) => {
                  this.notificationService.open(m, 'danger');
                });
            }
          }
        );
    } else {
      this.appService
        .welcome_set({})
        .pipe(
          take(1),
          finalize(() => (this.processing = false))
        )
        .subscribe(
          success => {
            this.translate.get('AUTO.WELCOME.DISABLED').subscribe(m => {
              this.notificationService.open(m, 'success');
            });
          },
          (error: Response) => {
            this.translate.get('AUTO.WELCOME.FAILED').subscribe(m => {
              this.notificationService.open(m, 'danger');
            });
          }
        );
    }
  }
}
