import {
  Directive,
  ElementRef,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnDestroy,
  OnInit,
  EmbeddedViewRef
} from '@angular/core';
import { Observable, Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { Project } from '../../services/api';
import { AuthService } from '../../services/auth/auth.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { switchMap, map, takeUntil } from 'rxjs/operators';
import { hasSufficientPermission, PermissionRole } from '../../helpers/roleCmp';

@Directive({
  selector: '[appDisplayFor]'
})
export class DisplayForDirective implements OnInit, OnDestroy {
  private contentViewRef: EmbeddedViewRef<any> | null = null;
  private fallbackTemplateRef: TemplateRef<any> | null = null;
  private fallbackViewRef: EmbeddedViewRef<any> | null = null;
  private role$: Observable<Project.RoleEnum>;
  private unsubscribe$: Subject<void> = new Subject();
  private requires$: BehaviorSubject<PermissionRole | null> = new BehaviorSubject(
    null
  );
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
    private store: Store<fromRoot.State>
  ) {
    this.role$ = this.store.select(fromRoot.getAppSelected).pipe(
      takeUntil(this.unsubscribe$),
      switchMap(app => this.authService.getProjectFromAppno(app.appno)),
      map(project => project.role)
    );
  }
  ngOnInit(): void {
    combineLatest(this.role$, this.requires$)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(([role, requires]) => hasSufficientPermission(role, requires))
      )
      .subscribe(hasPermission => {
        if (hasPermission) {
          if (!this.contentViewRef) {
            this.contentViewRef = this.viewContainer.createEmbeddedView(
              this.templateRef
            );
          }
        } else {
          if (!this.fallbackViewRef) {
            this.viewContainer.clear();
            if (this.fallbackTemplateRef) {
              this.fallbackViewRef = this.viewContainer.createEmbeddedView(
                this.fallbackTemplateRef
              );
            }
          }
        }
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  @Input('appDisplayFor') set requires(role: PermissionRole) {
    this.requires$.next(role);
  }
  @Input()
  set appDisplayForFallback(templateRef: TemplateRef<any> | null) {
    this.fallbackTemplateRef = templateRef;
    this.fallbackViewRef = null;
  }
}
