import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { Query } from '../../models/segments.model';
import { AppService } from '../../services/app/app.service';

/**
 * 現在のアプリケーションのセグメントプリセットを一覧表示し、1つを選択します
 */
@Component({
  selector: 'app-advanced-query-form',
  template: `
    <div class="field">
      <label class="label">ユーザーセグメント</label>
      <ng-container *ngIf="queries$ | async; let queries; else: Loading">
        <table
          class="table is-striped p7-table"
          *ngIf="queries.length > 0; else NoQueries"
        >
          <thead>
            <tr>
              <td>
                セグメント名
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let q of queries">
              <td>{{ q.name }}</td>
              <td>
                <button
                  class="button is-info p7-button is-outlines"
                  (click)="select(q.id)"
                  [disabled]="q.id === queryId"
                >
                  {{ q.id === queryId ? '選択中' : '選択' }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <ng-template #NoQueries>
          <div class="box">
            <div class="content">
              <article class="message is-info">
                <div class="message-header">
                  <p>
                    セグメントがありません
                  </p>
                </div>
                <div class="message-body">
                  <p>
                    サイドバー→セグメント作成と進み、セグメントクエリを作成してください
                  </p>
                </div>
              </article>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #Loading>
        <app-loading></app-loading>
      </ng-template>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AdvancedQueryFormComponent)
    }
  ]
})
export class AdvancedQueryFormComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  private onDestroy$: Subject<void> = new Subject<void>();

  public queries$: Observable<Query[]>;
  private _query: string;
  public set queryId(q: string) {
    this._query = q;
    this.onChange(q);
  }
  public get queryId(): string {
    return this._query;
  }

  // コールバック群
  private onChange: any = () => {};
  private onTouched: any = () => {};

  get value(): string {
    return this.queryId;
  }
  @Input('value')
  set value(q: string) {
    this.queryId = q;
    this.onChange(q);
    this.onTouched();
  }

  constructor(private appService: AppService) {}
  ngOnInit() {
    this.queries$ = this.appService.get_queries();
  }

  select(q) {
    this.queryId = q;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
