<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{ 'SETTINGS.DELETE' | translate }}
      </div>
      <div class="content">
        <div [innerHTML]="'SETTINGS.CONFIRM_HTML' | translate"></div>
        <p class="control">
          {{ 'SETTINGS.DELETE_ID' | translate }}：<span class="highlight">{{
            id
          }}</span>
        </p>
        <p class="control">
          <input
            class="input"
            [(ngModel)]="valuecheck"
            type="text"
            placeholder="{{ 'SETTINGS.INPUT_APP_NAME' | translate }}"
          />
        </p>
      </div>
      <div class="content-footer">
        <div class="has-text-right">
          <button class="button p7-button" (click)="close()">
            {{ 'SETTINGS.CONFIRM_CANCEL' | translate }}
          </button>
          <button
            class="button is-danger p7-button"
            [disabled]="id !== valuecheck"
            (click)="delete()"
          >
            {{ 'SETTINGS.CONFIRM_DELETE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
