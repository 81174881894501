<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-header has-text-weight-bold is-size-4">
        {{ 'TAGS.BOX.LABEL' | translate }}
      </div>
      <form (submit)="save($event)">
        <div class="container-body">
          <app-sdk-message></app-sdk-message>
          <p>{{ 'TAGS.BOX.DESCRIPTION' | translate }}</p>
          <div class="preview">
            <div id="p7box" class="active">
              <div class="p7box-inner">
                <div class="p7box-top">
                  <div class="p7box-img">
                    <img [src]="box.icon" />
                  </div>
                  <div class="p7box-text">
                    <p class="p7box-title">
                      {{
                        box.title ||
                          ('TAGS.BOX.TITLE_SAMPLE' | translate: sampleParams)
                      }}
                    </p>
                    <p class="p7box-message">
                      {{
                        box.body ||
                          ('TAGS.BOX.MESSAGE_SAMPLE' | translate: sampleParams)
                      }}
                    </p>
                  </div>
                </div>
                <div class="p7box-bottom">
                  <div class="p7box-credit">
                    <span>Powered by Push7</span>
                  </div>
                  <div class="p7box-buttons">
                    <a class="p7box-btn p7box-deny">
                      <span>{{
                        box.deny_text || ('TAGS.BOX.DENY_SAMPLE' | translate)
                      }}</span>
                    </a>
                    <a class="p7box-btn p7box-allow" target="_blank">
                      <span>{{
                        box.allow_text || ('TAGS.BOX.ALLOW_SAMPLE' | translate)
                      }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form">
            <div class="field">
              <label class="label adjust-margin">{{
                'TAGS.BOX.TITLE' | translate
              }}</label>
              <p class="control">
                <input
                  class="input"
                  name="title"
                  type="text"
                  placeholder="{{
                    'TAGS.BOX.TITLE_SAMPLE' | translate: sampleParams
                  }}"
                  [(ngModel)]="box.title"
                  [appEnableFor]="'administrator'"
                />
              </p>
            </div>
            <div class="field">
              <label class="label">{{ 'TAGS.BOX.MESSAGE' | translate }}</label>
              <p class="control">
                <input
                  class="input"
                  name="message"
                  type="text"
                  placeholder="{{
                    'TAGS.BOX.MESSAGE_SAMPLE' | translate: sampleParams
                  }}"
                  [(ngModel)]="box.body"
                  [appEnableFor]="'administrator'"
                />
              </p>
            </div>
            <div class="field">
              <p class="control">
                <label class="label">{{ 'TAGS.BOX.ICON' | translate }}</label>
                <ng-container
                  *appDisplayFor="'administrator'; fallback: ImagePreview"
                >
                  <app-img-crop [(src)]="box.icon"></app-img-crop>
                </ng-container>
                <ng-template #ImagePreview>
                  <img width="100" [src]="box.icon" />
                </ng-template>
              </p>
            </div>
            <div class="field">
              <label class="label">{{
                'TAGS.BOX.ALLOW_BUTTON' | translate
              }}</label>
              <p class="control">
                <input
                  class="input"
                  name="allow"
                  type="text"
                  placeholder="{{ 'TAGS.BOX.ALLOW_SAMPLE' | translate }}"
                  [(ngModel)]="box.allow_text"
                  [appEnableFor]="'administrator'"
                />
              </p>
            </div>
            <div class="field">
              <label class="label">{{
                'TAGS.BOX.DENY_BUTTON' | translate
              }}</label>
              <p class="control">
                <input
                  class="input"
                  name="deny"
                  type="text"
                  placeholder="{{ 'TAGS.BOX.DENY_SAMPLE' | translate }}"
                  [(ngModel)]="box.deny_text"
                  [appEnableFor]="'administrator'"
                />
              </p>
            </div>
            <div class="field">
              <p class="control">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    name="pc"
                    [(ngModel)]="box.display_desktop"
                    [appEnableFor]="'administrator'"
                  />
                  {{ 'TAGS.BOX.PC' | translate }}
                </label>
              </p>
            </div>
            <div class="field">
              <p class="control">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    name="mobile"
                    [(ngModel)]="box.display_mobile"
                    [appEnableFor]="'administrator'"
                  />
                  {{ 'TAGS.BOX.MOBILE' | translate }}
                </label>
              </p>
            </div>
          </div>
        </div>
        <div class="container-footer has-text-right">
          <button
            type="submit"
            class="button is-primary"
            [appEnableFor]="'administrator'"
          >
            {{ 'SETTINGS.SAVE' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
