<div class="columns is-centered">
  <div class="column is-8-widescreen">
    <div class="container main-container">
      <ng-container
        *ngIf="this.list$ | async; then Loaded; else Loading"
      ></ng-container>
      <ng-template #Loaded>
        <ng-container
          *ngIf="(this.list$ | async)?.length > 0; then List; else NoPush"
        ></ng-container>
        <ng-template #List>
          <table class="table is-striped is-fullwidth">
            <thead>
              <tr>
                <th class="cell-img"></th>
                <th class="cell-status">
                  {{ 'PUSH.COMMON.STATUS' | translate }}
                </th>
                <th class="cell-date">{{ 'PUSH.COMMON.DATE' | translate }}</th>
                <th class="cell-title">
                  {{ 'PUSH.COMMON.TITLE' | translate }}
                </th>
                <th class="cell-action">
                  {{ 'PUSH.COMMON.ACTION' | translate }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="reserved_row"
                *ngFor="let noti of this.list$ | async"
                [class.is-error]="noti.error"
              >
                <td class="noti-icon">
                  <figure class="image is-24x24">
                    <img src="{{ noti.icon }}" alt="icon" />
                  </figure>
                </td>
                <td>
                  {{
                    noti.error
                      ? ('PUSH.COMMON.FAILED' | translate)
                      : ('PUSH.COMMON.PENDING' | translate)
                  }}
                </td>
                <td>
                  {{ noti.transmission_time | dateParse | date: 'y/M/d, H:m' }}
                </td>
                <td>{{ noti.title }}</td>
                <td>
                  <div class="field is-grouped">
                    <div class="control">
                      <button
                        (click)="edit(noti)"
                        class="button is-info is-outlined p7-button"
                        [disabled]="noti.error"
                      >
                        <span class="icon is-marginless">
                          <i
                            class="fa fa-pencil-square-o"
                            aria-hidden="true"
                          ></i>
                        </span>
                        <span class="myapp-name">{{
                          'PUSH.COMMON.EDIT' | translate
                        }}</span>
                      </button>
                    </div>
                    <div class="control" *appDisplayFor="'operator'">
                      <button
                        (click)="delete(noti)"
                        class="button is-danger is-outlined p7-button"
                      >
                        <span class="icon is-marginless">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </span>
                        <span class="myapp-name">{{
                          'PUSH.COMMON.DELETE' | translate
                        }}</span>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-template>
        <ng-template #NoPush>
          <div class="container-body">
            <div class="columns is-vcentered is-centered is-mobile">
              <div class="column is-narrow">
                <figure class="image is-185x185">
                  <img
                    class="box is-paddingless"
                    src="/assets/img/hint/new-push.png"
                    alt=""
                  />
                </figure>
              </div>
              <div class="column is-half">
                <p class="is-size-4">
                  予約済みプッシュ通知がありません
                </p>
                <a
                  class="button"
                  (click)="compose()"
                  *appDisplayFor="'operator'"
                >
                  {{ 'PUSH.CREATE' | translate }}
                </a>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-template>
      <ng-template #Loading>
        <app-loading></app-loading>
      </ng-template>
    </div>
  </div>
</div>
