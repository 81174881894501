import { Component, OnInit } from '@angular/core';
import { ModalContext } from '../../../../services/modal/modal.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as app from '../../../../actions/app';
import { Observable } from 'rxjs';
import { NotificationModel } from '../../../../models/notification.model';

@Component({
  templateUrl: './pushid-selector.component.html',
  styleUrls: ['./pushid-selector.component.scss']
})
export class PushIdSelectorComponent implements OnInit {
  public list$: Observable<NotificationModel[] | boolean>;
  public notification$: Observable<any>;
  public max: number;
  public page = 1;
  public selected?: NotificationModel;
  constructor(
    private store: Store<fromRoot.State>,
    private modalCtx: ModalContext
  ) {
    this.list$ = this.store.select(fromRoot.getTheNotifications);
    this.notification$ = this.store.select(
      fromRoot.getAppSelectedNotifications
    );
    this.notification$.subscribe(notificationState => {
      if (!notificationState) {
        return;
      }
      this.page = notificationState.current;
      this.max = Math.ceil(notificationState.count / 20);
    });
  }
  public ngOnInit() {
    this.update(1);
  }
  public update(pageNum: number) {
    if (pageNum < 1 || this.max < pageNum) {
      return;
    }
    this.store.dispatch(
      new app.LoadNotificationsAction({
        page: pageNum
      })
    );
  }

  public prev() {
    this.update(--this.page);
  }
  public next() {
    this.update(++this.page);
  }

  public select(id: string) {
    this.modalCtx.resolve(id);
  }

  public close() {
    this.modalCtx.reject();
  }
  public confirm() {
    this.modalCtx.resolve(this.selected.id);
  }
}
