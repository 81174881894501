<ng-container [formGroup]="form">
  <ng-container *ngIf="showPaymentOptions">
    <div class="field">
      <label class="label">支払い方法</label>
      <div class="control">
        <label class="radio">
          <input
            type="radio"
            formControlName="payment_method"
            value="transfer"
            name="payment_method"
          />
          銀行振り込み
        </label>
        <label class="radio">
          <input
            type="radio"
            name="payment_method"
            formControlName="payment_method"
            value="card"
          />
          クレジットカード
        </label>
      </div>
    </div>
  </ng-container>
  <ng-container
    formGroupName="card"
    *ngIf="form.value.payment_method === 'card'"
  >
    <div class="field">
      <label class="label">カード番号（Visa, Master）</label>
      <div class="control">
        <input
          class="input"
          formControlName="card_no"
          type="text"
          placeholder="カード番号"
          autocomplete="cc-number"
          inputmode="numeric"
          x-autocompletetype="cc-number"
          pattern="[0-9]*"
          type="text"
          formControlName="card_no"
        />
      </div>
    </div>
    <div class="field">
      <label class="label">カード名義</label>
      <div class="control">
        <input
          class="input"
          formControlName="holder"
          x-autocompletetype="cc-full-name"
          type="text"
          placeholder="カード名義"
        />
      </div>
    </div>
    <label class="label">有効期限</label>
    <div class="field is-grouped is-align-items-flex-end">
      <div class="control">
        <span class="select">
          <select
            class="year required"
            required
            name="year"
            x-autocompletetype="cc-exp-year"
            formControlName="expire_year"
          >
            <option *ngFor="let y of yearsList" value="{{ y }}">{{ y }}</option>
          </select>
        </span>
      </div>
      <div class="control">
        <span class="selectlabel">年</span>
      </div>
      <div class="control">
        <span class="select">
          <select
            class="month required"
            required
            name="month"
            x-autocompletetype="cc-exp-month"
            formControlName="expire_month"
          >
            <option *ngFor="let m of monthsList" value="{{ m }}">{{
              m
            }}</option>
          </select>
        </span>
      </div>

      <div class="control">
        <span class="selectlabel">月</span>
      </div>
    </div>
  </ng-container>
</ng-container>
