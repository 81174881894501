import * as Segments from './../../../../models/segments.model';
import { AppService } from './../../../../services/app/app.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { map, tap, switchMap } from 'rxjs/operators';
import segmentJsonParser from '../../../../helpers/segmentJsonParser';
import { Notification } from '../../../../services/notification/notification.service';
import { Modal } from '../../../../services/modal/modal.service';
import { QueryDeleteWarningComponent } from '../delete-warning/query-delete-warning.component';
import { QuerySaveBeforeLeaveComponent } from './query-save-before-leave/query-save-before-leave.component';

@Component({
  templateUrl: './query-detail.component.html'
})
export class QueryDetailComponent implements OnInit {
  public ASTRoot: Segments.ASTRoot;
  public name: string;
  private id: string;
  private original_query: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: AppService,
    private notification: Notification,
    private modal: Modal
  ) {}
  ngOnInit() {
    const id$ = this.route.params.pipe(map(x => x['id']));
    id$.subscribe(id => (this.id = id));
    const data$ = id$.pipe(switchMap(id => this.api.get_query(id)));
    data$.pipe(map(x => x.name)).subscribe(name => (this.name = name));
    data$
      .pipe(
        map(x => x.query),
        tap(str => (this.original_query = str)),
        switchMap(str => this.api.generate_ast(str)),
        map(segmentJsonParser)
      )
      .subscribe(root => {
        this.ASTRoot = root;
      });
  }
  back() {
    if (this.original_query === this.ASTRoot.genQuery()) {
      // no changes
      this.navigateToList();
      return;
    }
    this.modal
      .open(QuerySaveBeforeLeaveComponent)
      .then(save => {
        if (save) {
          this.api
            .update_query(this.id, this.ASTRoot.genQuery(), this.name)
            .subscribe(
              () => {
                this.notification.open('セグメントを保存しました', 'success');
                this.navigateToList();
              },
              error => this.notification.open(error.error.error, 'danger')
            );
        } else {
          this.navigateToList();
        }
      })
      .catch(() => {});
  }
  private navigateToList() {
    this.router.navigate(['../../list'], {
      relativeTo: this.route
    });
  }
  remove() {
    this.modal
      .open(QueryDeleteWarningComponent)
      .then(() => {
        this.api
          .delete_query(this.id)
          .subscribe(() =>
            this.router.navigate(['../../list'], { relativeTo: this.route })
          );
      })
      .catch(() => {});
  }
  save() {
    this.original_query = this.ASTRoot.genQuery();
    this.api
      .update_query(this.id, this.ASTRoot.genQuery(), this.name)
      .subscribe(
        () => this.notification.open('セグメントを保存しました', 'success'),
        error => this.notification.open(error.error.error, 'danger')
      );
  }
}
