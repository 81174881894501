<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-header has-text-weight-bold is-size-4">
        <span>オートメーションルール一覧</span>
        <a class="icon" (click)="createRule()" *appDisplayFor="'operator'"
          ><span class="fa fa-plus-circle"></span
        ></a>
      </div>
      <div class="container-body is-paddingless">
        <ng-container *ngIf="rules$ | async; let rules; else: Loading">
          <ng-container *ngIf="rules.length > 0; else NoQueries">
            <table class="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>ルール名</th>
                  <th>ステータス</th>
                  <th style="width: 250px;"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let rule of rules">
                  <td>{{ rule.name }}</td>
                  <td>{{ rule.status === 'active' ? '有効' : '一時停止' }}</td>
                  <td class="has-text-right">
                    <button
                      class="button is-info is-outlined mr-1"
                      (click)="editRule(rule)"
                    >
                      編集
                    </button>
                    <a
                      class="button is-info is-outlined"
                      [routerLink]="['../history/' + rule.id]"
                    >
                      履歴
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <ng-template #NoQueries>
            <div class="box">
              <div class="content">
                <article class="message is-info">
                  <div class="message-header">
                    <p>オートメーションルールがありません</p>
                  </div>
                  <div class="message-body">
                    <p>
                      運用担当者及び管理者アカウントをお持ちの方は、右上のボタンより新規オートメーションルールを作成いただけます。
                    </p>
                  </div>
                </article>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #Loading>
          <app-loading></app-loading>
        </ng-template>
      </div>
    </div>
  </div>
</div>
