import { Notification } from './../../../services/notification/notification.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';

import { ProvisionalService } from '../../../services/provisional/provisional.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
  providers: [ProvisionalService]
})
export class ResetComponent implements OnInit {
  private token: string;
  public email: string;
  public password: string;
  public confirm: string;
  public error: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private privisionalService: ProvisionalService,
    private notification: Notification
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(res => (this.token = res['token']));
  }
  submit() {
    this.error = null;
    if (this.password !== this.confirm) {
      this.translate
        .get('SIGNUP.ERRORS.PASSWORD_MISMATCH')
        .subscribe((res: string) => {
          this.error = res;
        });
      return;
    }
    this.privisionalService
      .reset(this.email, this.token, this.password)
      .subscribe(
        res => {
          this.translate.get('SIGNUP.PASSWORD_RESET').subscribe((m: string) => {
            this.notification.open(m, 'success');
          });
          this.router.navigate(['/login']);
        },
        (response: HttpErrorResponse) => {
          this.error = response.error.error;
        }
      );
  }
}
