import { createSelector } from 'reselect';

import * as Moment from 'moment';

import { cloneDeep } from 'lodash';

import * as ana from '../actions/analytics';

import { StateService } from './../services/state/state.service';

let getAppno = null;

class StateProvider {
  constructor(public ss: StateService) {
    getAppno = ss.getAppno;
  }
}

export type ScopeTypes = 'hour' | 'day' | 'week' | 'month';
export type TabTypes = 'total' | 'increase';

export interface AppState {
  /*
   * Overview
   */
  info?: {
    sent_push_count: number;
    clicked_count: number;
  };
  // 購読者推移
  transitions: {
    // 'hour' | 'day' | 'week' | 'month'
    [scope: string]: {
      [start: string]: {
        date: string;
        count: number;
      };
    };
  };
  increases: {
    [scope: string]: {
      [start: string]: {
        datetime: string;
        count: string;
      };
    };
  };
  // UI
  overview: {
    scope: ScopeTypes;
    tab: TabTypes;
    isLoading: boolean;
    beginning: Moment.Moment;
    ending: Moment.Moment;
  };
  /*
   * ユーザー
   */
  subscribers: {
    first_opt_in_time: string;
    id: string;
    last_clicked_pushid: string;
    parameters: string[];
  }[];
  parameters: {
    parameter: string;
    subscribers_count: number;
  }[];
}
export interface State {
  [appno: string]: AppState;
}

const initialState: State = {};
const appInitialState: AppState = {
  overview: {
    scope: 'hour',
    tab: 'total',
    isLoading: true,
    beginning: Moment().subtract(1, 'days'),
    ending: Moment()
  },
  increases: {
    hour: {},
    day: {},
    week: {},
    month: {}
  },
  transitions: {
    hour: {},
    day: {},
    week: {},
    month: {}
  },
  parameters: [],
  subscribers: []
};

export function reducer(state: State = initialState, action: ana.Actions) {
  switch (action.type) {
    case ana.ActionTypes.INIT_STATE:
      return Object.assign({}, state, { [action.appno]: appInitialState });
    case ana.ActionTypes.INFO_SUCCESS: {
      const n = cloneDeep(state);
      n[action.payload.appno].info = action.payload.info;
      return n;
    }
    case ana.ActionTypes.OVERVIEW_TAB: {
      const n = cloneDeep(state);
      n[action.appno].overview.tab = action.payload;
      return n;
    }
    case ana.ActionTypes.OVERVIEW_SCOPE: {
      const n = cloneDeep(state);
      n[action.appno].overview.isLoading = true;
      n[action.appno].overview.scope = action.payload;
      return n;
    }
    case ana.ActionTypes.OVERVIEW_RANGE: {
      const n = cloneDeep(state);
      n[action.appno].overview.isLoading = true;
      if (action.range.beginning) {
        n[action.appno].overview.beginning = action.range.beginning;
      }
      if (action.range.ending) {
        n[action.appno].overview.ending = action.range.ending;
      }
      return n;
    }
    case ana.ActionTypes.OVERVIEW_TRANSITION_ADD: {
      const n = cloneDeep(state);
      const kv = {};
      action.counts.forEach(count => {
        kv[count.datetime] = count;
      });
      Object.assign(
        n[action.appno].transitions[state[action.appno].overview.scope],
        kv
      );
      return n;
    }
    case ana.ActionTypes.OVERVIEW_INCREASE_ADD: {
      const n = cloneDeep(state);
      const kv = {};
      action.counts.forEach(count => {
        kv[count.datetime] = count;
      });
      Object.assign(
        n[action.appno].increases[state[action.appno].overview.scope],
        kv
      );
      return n;
    }
    case ana.ActionTypes.OVERVIEW_LOADED: {
      const n = cloneDeep(state);
      n[action.appno].overview.isLoading = false;
      return n;
    }
    case ana.ActionTypes.SUBSCRIBERS_SUCCESS: {
      console.log(action);
      const n = cloneDeep(state);
      n[action.appno].subscribers = action.subscribers;
      return n;
    }
    case ana.ActionTypes.PARAMS_SUCCESS: {
      const n = cloneDeep(state);
      n[action.appno].parameters = action.params;
      return n;
    }
    default: {
      return state;
    }
  }
}
