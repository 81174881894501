<div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        Enterpriseプラン申し込み
      </div>
      <ng-container *ngIf="step == 0">
        <div class="content">
          <p>
            Enterpriseプランのお申し込みを行います。
          </p>
          <p>申し込みにあたって、以下の注意事項をご一読ください。</p>
          <ul>
            <li>
              課金は、本申込みがPush7事務局によって受諾された後プランを選択することで開始します。
              申込みのみでは課金は発生しませんのでご安心ください。
            </li>
          </ul>
        </div>
        <div class="content-footer">
          <div class="flex has-text-right">
            <button class="button is-error p7-button" (click)="cancel()">
              キャンセル
            </button>
            <button class="button is-success p7-button" (click)="next()">
              上記を理解して次へ
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="step == 1">
        <div class="content">
          <p>会社情報を入力してください。</p>
          <form [formGroup]="infoForm">
            <div class="field">
              <label class="label">会社名</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  formControlName="name"
                  name="name"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">部署名(任意)</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  formControlName="department_name"
                  name="department_name"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">ご担当者様名</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  formControlName="personnel_name"
                  name="personnel_name"
                />
              </div>
            </div>
            <div class="field">
              <label class="label">住所</label>
              <div class="control">
                <textarea
                  class="textarea"
                  formControlName="address"
                  name="address"
                ></textarea>
              </div>
            </div>
            <div class="field">
              <label class="label">電話番号</label>
              <div class="control">
                <input
                  class="input"
                  type="tel"
                  formControlName="phone_number"
                  name="phone_number"
                />
              </div>
            </div>
            <div class="field">
              <input type="checkbox" formControlName="is_not_anti_social_org" />
              <span class="native-download-link">
                <a
                  href="https://push7.jp/legal#__layout:~:text=%E5%8F%8D%E7%A4%BE%E4%BC%9A%E7%9A%84%E5%8B%A2%E5%8A%9B%E3%81%A7%E3%81%AF%E3%81%AA%E3%81%84%E3%81%93%E3%81%A8%E3%81%AE%E8%A1%A8%E6%98%8E%E3%83%BB%E7%A2%BA%E7%B4%84%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E5%90%8C%E6%84%8F"
                  target="_blank"
                  >「反社会的勢力ではないことの表明・確約に関する同意」</a
                >について、確認し同意しました。
              </span>
            </div>
          </form>
        </div>
        <div class="content-footer">
          <div class="flex has-text-right">
            <button class="button is-error p7-button" (click)="back()">
              戻る
            </button>
            <button
              class="button is-success p7-button"
              [class.is-loading]="sending"
              [disabled]="!infoForm.valid"
              (click)="submitInfo()"
            >
              Enterpriseプランに申し込み
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="step == 2">
        <div class="content">
          <p>Enterpriseプランへのお申込みありがとうございました。</p>
          <p>
            別途、導入方法や活用方法などの導入支援をお電話でご案内します。<br />
            電話案内を希望される方は、下記よりご希望の日時をお知らせください。
          </p>
          <form [formGroup]="telForm">
            <div class="field">
              <label class="label">電話番号</label>
              <div class="control">
                <input
                  class="input"
                  type="tel"
                  formControlName="tel"
                  name="tel"
                />
              </div>
            </div>
            <ng-container *ngFor="let i of [0, 1, 2]">
              <label class="label">候補日時{{ i + 1 }}</label>
              <div class="field is-flex">
                <div class="control">
                  <input
                    class="input date_input"
                    type="datetime"
                    name="date1"
                  />
                </div>
                <p class="control">
                  <span class="select">
                    <select (change)="dates[i].hour($event.target.value)">
                      <option *ngFor="let h of hours" [value]="h"
                        >{{ h }}時</option
                      >
                    </select>
                  </span>
                </p>
                <p class="control">
                  <span class="select">
                    <select (change)="dates[i].minute($event.target.value)">
                      <option *ngFor="let m of mins" [value]="m"
                        >{{ m }}分</option
                      >
                    </select>
                  </span>
                </p>
              </div>
            </ng-container>
          </form>
        </div>
        <div class="content-footer">
          <div class="flex has-text-right">
            <button class="button is-error" (click)="cancel()">
              電話での案内を希望しない
            </button>
            <button
              class="button is-success"
              [class.is-loading]="sending"
              [disabled]="!telForm.valid"
              (click)="submitTel()"
            >
              送信
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="step == 3">
        <div class="content">
          <p>
            ご希望のご連絡日時を受け付けました。
          </p>
          <p>担当者よりご連絡日時について別途ご連絡いたします。</p>
        </div>
        <div class="content-footer">
          <div class="flex has-text-right">
            <button class="button is-success p7-button" (click)="done()">
              完了
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
