
    <div class="field">
      <article class="message is-info">
        <div class="message-header">
          オートメーションルールによるパラメータの追加
        </div>
        <div class="message-body">
          <p>
            オートメーションルールが実行された際、「実行対象」であり「スキップ条件」を満たさない購読者にパラメータを追加することができます。
          </p>
          <p>
            パラメータは、改行区切りで入力してください。パラメータに利用できるのは、半角英数字・ハイフン・アンダーバーです。
          </p>
          <p>
            以下の文字列を入力すると、実行時間に応じたパラメータを付与します。
          </p>
          <ul>
            <li>
              <code>{{ '{' }}{{ '{' }}year{{ '}' }}{{ '}' }}</code
              >...現在の西暦(例: 2021)
            </li>
            <li>
              <code>{{ '{' }}{{ '{' }}month{{ '}' }}{{ '}' }}</code
              >...現在の月(例: 04)
            </li>
            <li>
              <code>{{ '{' }}{{ '{' }}day{{ '}' }}{{ '}' }}</code
              >...現在の日(例: 01)
            </li>
            <li>
              <code>{{ '{' }}{{ '{' }}hour{{ '}' }}{{ '}' }}</code
              >...現在の日(例: 01)
            </li>
          </ul>
        </div>
      </article>
      <div class="control">
        <textarea
          class="textarea"
          placeholder="parameter1
parameter2"
          [(ngModel)]="strParameters"
        ></textarea>
      </div>
    </div>
  