import { Component } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { AppModel } from '../../../../models/app.model';

@Component({
  selector: 'app-wp',
  templateUrl: './wp.component.html',
  styleUrls: ['./wp.component.scss']
})
export class WpComponent {
  app$: Observable<AppModel>;
  constructor(private store: Store<fromRoot.State>) {
    this.app$ = this.store.select(fromRoot.getAppSelected);
  }
}
