import { AppModel } from './../../../../models/app.model';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Notification } from './../../../../services/notification/notification.service';
import { AppService } from './../../../../services/app/app.service';
import { BoxModel } from './../../../../models/box.model';
import * as fromRoot from './../../../../reducers';
import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss']
})
export class BoxComponent implements OnInit {
  private app$: Observable<AppModel>;
  public sampleParams: {
    name: string;
  };
  public box: BoxModel = {
    title: '',
    body: '',
    icon: '',
    deny_text: '',
    allow_text: '',
    display_delay: 0,
    display_powered: true,
    display_mobile: false,
    display_desktop: false
  };
  constructor(
    private api: AppService,
    private notification: Notification,
    private store: Store<fromRoot.State>,
    private translate: TranslateService
  ) {
    this.app$ = store.select(fromRoot.getAppSelected);
    api.box_get().subscribe(box => {
      if (Object.keys(box).length !== 0) {
        this.box = box;
      } else {
        this.app$.pipe(take(1)).subscribe(app => {
          this.box.icon = app.icon;
          this.box.display_mobile = true;
          this.box.display_desktop = true;
        });
      }
    });
    this.app$.pipe(take(1)).subscribe(app => {
      this.sampleParams = {
        name: app.name
      };
    });
  }
  save($event) {
    $event.preventDefault();
    const jobs: Promise<string>[] = [
      new Promise(resolve =>
        this.translate
          .get('TAGS.BOX.TITLE_SAMPLE', this.sampleParams)
          .subscribe(m => resolve(m))
      ),
      new Promise(resolve =>
        this.translate
          .get('TAGS.BOX.MESSAGE_SAMPLE', this.sampleParams)
          .subscribe(m => resolve(m))
      ),
      new Promise(resolve =>
        this.translate
          .get('TAGS.BOX.ALLOW_SAMPLE', this.sampleParams)
          .subscribe(m => resolve(m))
      ),
      new Promise(resolve =>
        this.translate
          .get('TAGS.BOX.DENY_SAMPLE', this.sampleParams)
          .subscribe(m => resolve(m))
      )
    ];
    return Promise.all(jobs).then(([_title, _body, _allow, _deny]) => {
      if (!this.box.title) {
        this.box.title = _title;
      }
      if (!this.box.body) {
        this.box.body = _body;
      }
      if (!this.box.allow_text) {
        this.box.allow_text = _allow;
      }
      if (!this.box.deny_text) {
        this.box.deny_text = _deny;
      }
      this.api.box_save(this.box).subscribe(
        success => {
          this.translate.get('TAGS.BOX.UPDATED').subscribe(m => {
            this.notification.open(m, 'success');
          });
        },
        err => {
          this.notification.open(err.error, 'danger');
        }
      );
    });
  }
  ngOnInit() {}
}
