<form [(formGroup)]="form">
  <div class="field">
    <label class="label">対象アプリケーション</label>
    <div class="control">
      <div class="select">
        <select formControlName="appno">
          <option selected disabled>選択してください</option>
          <option *ngFor="let app of apps$ | async" [value]="app.appno">{{
            app.name
          }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="field">
    <div class="control">
      <label class="checkbox">
        <input type="checkbox" formControlName="isAppNonSpecific" />
        特定のアプリケーションに限定しないお問い合わせ（アカウントに関するお問い合わせなど）
      </label>
    </div>
  </div>
  <!-- 以下, appnoが選択される or チェックボックスがtrueになると表示 -->
  <ng-container
    *ngIf="form.get('appno').value || form.get('isAppNonSpecific').value"
  >
    <ng-container *ngIf="isAppLoaded; else LoadingPLan">
      <div class="field">
        <label class="label">お問い合わせカテゴリ</label>
        <div class="control">
          <div class="select">
            <select formControlName="categoryId">
              <option selected disabled>選択してください</option>
              <option
                *ngFor="let category of categories"
                [value]="category.id"
                >{{ category.name }}</option
              >
            </select>
          </div>
        </div>
      </div>
      <!-- 以下, カテゴリ選択時のみ表示 -->
      <ng-container *ngIf="form.get('categoryId').value">
        <ng-container
          *ngIf="isSelectedCategoryAvailable; else CategoryNotAvailable"
        >
          <div class="field" *ngIf="requireSubject">
            <label class="label">件名</label>
            <div class="control">
              <input class="input" type="text" formControlName="subject" />
            </div>
          </div>
          <div class="field">
            <label class="label">お問い合わせ内容</label>
            <div class="control">
              <textarea class="textarea" formControlName="body"></textarea>
            </div>
          </div>
        </ng-container>
        <ng-template #CategoryNotAvailable>
          <div class="message">
            <p class="message-body">
              「{{
                selectedCategory && selectedCategory.name
              }}」に関するお問い合わせにはEnterpriseプランの契約が必要です。Enterpriseプランのアプリケーションを選択するか、カテゴリを変更してください。
            </p>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-template #LoadingPLan>
      <app-loading></app-loading>
    </ng-template>
  </ng-container>
</form>
