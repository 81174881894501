import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { NotificationModel } from './../../../../models/notification.model';
import { Notification } from './../../../../services/notification/notification.service';
import { AppService } from './../../../../services/app/app.service';
import { ModalContext } from './../../../../services/modal/modal.service';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-edit-push-modal',
  templateUrl: './edit-push-modal.component.html',
  styleUrls: ['./edit-push-modal.component.scss']
})
export class EditPushModalComponent implements OnInit {
  public updating = false;
  public notification: NotificationModel = {
    title: '',
    body: '',
    icon: '',
    url: ''
  };
  constructor(
    private modalCtx: ModalContext,
    private appService: AppService,
    private notiService: Notification,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    // since modalCtx keeps its value, it is needed to clone to local value
    this.notification = cloneDeep(this.modalCtx.payload.notification);
  }
  close() {
    this.modalCtx.reject();
  }
  update() {
    this.updating = true;
    this.appService
      .update_reservation(this.notification)
      .pipe(finalize(() => (this.updating = false)))
      .subscribe(
        success => {
          this.translate
            .get('PUSH.RESERVED.EDITOR_SUCCESS')
            .subscribe((m: string) => {
              this.notiService.open(m, 'success');
            });
          this.modalCtx.resolve({
            data: this.notification,
            pushid: success.pushid
          });
        },
        response => {
          this.notiService.open(response.error.error, 'danger');
        }
      );
  }
}
