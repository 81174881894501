<ul class="progress-steps">
  <ng-container *ngFor="let item of stepItems; let i = index">
    <li class="progress-item" (click)="stepClick(i)">
      <div class="step-icon" [class.not-done]="i > step">
        <i
          [class]="step > i ? 'fa fa-check' : 'fa ' + item.icon"
          aria-hidden="true"
        ></i>
      </div>
      {{ 'NOTIFICATION_EDITOR.STEPS.' + item.name | translate }}
    </li>
  </ng-container>
</ul>
