import { Category } from './../../models/category.model';
import { AppService } from './../app/app.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CategoryService {
  public categories: BehaviorSubject<Category[]> = new BehaviorSubject<
    Category[]
  >([]);
  constructor(private api: AppService) {
    api.get_categories().subscribe((categories: Category[]) => {
      this.categories.next(categories);
    });
  }
}
