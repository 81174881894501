<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{ 'PUSH.RESERVED.EDITOR' | translate }}
      </div>
      <div class="content">
        <app-notification-editor
          [(notification)]="notification"
          [query]="true"
          role="operator"
        ></app-notification-editor>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button class="button is-danger p7-button mr-3" (click)="close()">
            {{ 'PUSH.COMMON.CANCEL' | translate }}
          </button>
          <button
            class="button is-success p7-button"
            (click)="update()"
            [class.is-loading]="updating"
            [appEnableFor]="'operator'"
          >
            {{ 'PUSH.COMMON.UPDATE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="modal-close" (click)="close()"></button>
</div>
