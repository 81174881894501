import { Component } from '@angular/core';
import { ModalContext } from '../../../services/modal/modal.service';
import {
  Project,
  DefaultService as ApiService,
  Member,
  UpdateProjectMemberRequest
} from '../../../services/api';
import { Notification } from '../../../services/notification/notification.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfirmationService } from '../../../services/confirmation/confirmation.service';
import { switchMap, take, map, filter } from 'rxjs/operators';
import { AuthService } from '../../../services/auth/auth.service';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './edit-project-modal.component.html',
  styleUrls: ['./edit-project-modal.component.scss']
})
export class EditProjectModalComponent {
  public name: string;
  public members: Member[] | null = null;
  public inviteeForm = new FormGroup({
    email: new FormControl('', Validators.email),
    role: new FormControl('viewer')
  });
  public isGNEX$: Observable<boolean>;
  private project: Project;
  private reloadProjects = false;
  constructor(
    private modalCtx: ModalContext,
    private notification: Notification,
    private confirmationService: ConfirmationService,
    private api: ApiService,
    private authService: AuthService
  ) {
    this.project = modalCtx.payload;
    this.name = this.project.name;
    this.loadMembers();
    this.isGNEX$ = this.authService.profile$.pipe(
      map(
        profile =>
          profile.email.endsWith('@globalnet-ex.com') ||
          profile.email.endsWith('@gnex.jp')
      )
    );
  }
  public close() {
    if (this.reloadProjects) {
      this.modalCtx.resolve();
    } else {
      this.modalCtx.reject();
    }
  }
  public saveName() {
    this.api.updateProject(this.project.uuid, { name: this.name }).subscribe(
      () => {
        this.project.name = this.name;
        this.reloadProjects = true;
        this.notification.open('プロジェクトを更新しました', 'success');
      },
      () => this.notification.open('エラーが発生しました', 'danger')
    );
  }
  public invite() {
    const values = this.inviteeForm.value;
    this.api
      .inviteProjectMember(this.project.uuid, {
        email: values.email,
        role: values.role
      })
      .subscribe(
        () => {
          this.loadMembers();
          this.inviteeForm.controls['email'].setValue('');
        },
        () => this.notification.open('エラーが発生しました', 'danger')
      );
  }
  public get canEditProject(): boolean {
    return (
      this.project.role === 'administrator' ||
      this.project.role === 'spy_administrator'
    );
  }
  /**
   * 特定のメンバーを編集できるか返す
   */
  public canEditMember(member: Member): boolean {
    // 管理者およびスパイ管理者以外はメンバーを編集できない
    if (
      this.project.role !== 'administrator' &&
      this.project.role !== 'spy_administrator'
    ) {
      return false;
    }
    // 当該メンバーがactiveではない場合、必ず削除できる
    if (member.status !== 'active') {
      return true;
    }
    // activeな当該メンバーが管理者以外の場合、必ず削除できる
    if (member.role !== 'administrator') {
      return true;
    }
    // activeな管理者の場合、canRemoveAdmin
    return this.canRemoveAdmin;
  }
  public get canDeleteProject(): boolean {
    return this.project.role === 'administrator' && !this.project.apps.length;
  }
  public get canRemoveAdmin(): boolean {
    // 2人以上のactiveな管理者がいる場合は管理者を消すことができる
    if (!this.members) {
      return false;
    }
    return (
      this.members
        .filter(m => m.status === 'active')
        .filter(m => m.role === 'administrator').length >= 2
    );
  }
  public get canLeaveProject(): boolean {
    // 管理者が1人以上いることが保証されているので、管理者でない場合は常に抜けれる
    if (this.project.role !== 'administrator') {
      return true;
    }
    // 管理者の場合、canRemoveAdmin
    return this.canRemoveAdmin;
  }
  public updateRole(member: Member, role: UpdateProjectMemberRequest.RoleEnum) {
    this.api
      .updateProjectMember(this.project.uuid, member.uuid, { role })
      .pipe(switchMap(() => this.authService.profile$))
      .subscribe(
        profile => {
          if (profile.email === member.email) {
            // 自分自身のroleを更新した場合
            this.project.role = role;
            this.reloadProjects = true;
          }
          this.loadMembers();
          this.notification.open('権限を更新しました', 'success');
        },
        () => this.notification.open('権限の更新に失敗しました', 'danger')
      );
  }
  public leaveProject() {
    this.authService.profile$
      .pipe(
        take(1),
        map(profile => this.members.find(m => m.email === profile.email)),
        filter(me => !!me)
      )
      .subscribe(me => this.removeMember(me));
  }
  public removeMember(member: Member) {
    this.confirmationService
      .open({
        title: 'プロジェクトメンバーの削除',
        body: `この操作によって、${member.email} はプロジェクト「${this.project.name}」にアクセスできなくなります。実行してよろしいですか？`,
        confirmLabel: 'メンバーを削除',
        cancelLabel: 'キャンセル'
      })
      .then(() => {
        this.api
          .deleteProjectMember(this.project.uuid, member.uuid)
          .pipe(switchMap(() => this.authService.profile$))
          .subscribe(
            profile => {
              if (profile.email === member.email) {
                // 自分自身を削除した場合
                this.reloadProjects = true;
                this.close();
                this.notification.open(
                  'プロジェクトから離脱しました',
                  'success'
                );
              } else {
                this.loadMembers();
                this.notification.open('メンバーを削除しました', 'success');
              }
            },
            () => this.notification.open('エラーが発生しました', 'danger')
          );
      })
      .catch(() => {
        /*ignore*/
      });
  }
  public deleteProject() {
    this.confirmationService
      .open({
        title: 'プロジェクトの削除',
        body:
          '削除したプロジェクトは復元できません。本当に削除してよろしいですか？',
        confirmLabel: 'プロジェクトを削除'
      })
      .then(() => {
        this.api.deleteProject(this.project.uuid).subscribe(
          () => {
            this.notification.open('プロジェクトを削除しました', 'success');
            this.modalCtx.resolve();
          },
          error => {
            this.notification.open(
              'プロジェクトを削除できませんでした',
              'warning'
            );
          }
        );
      })
      .catch(() => {
        /* */
      });
  }
  private loadMembers() {
    this.members = null;
    this.api
      .listProjectMembers(this.project.uuid)
      .subscribe(response => (this.members = response.members));
  }
}
