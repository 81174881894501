<form [formGroup]="cardForm">
  <div class="field">
    <label class="label">{{ 'PLAN.PAYMENT.CARDNO' | translate }}</label>
    <p class="control">
      <input
        class="input required"
        required
        name="card_no"
        autocomplete="cc-number"
        inputmode="numeric"
        x-autocompletetype="cc-number"
        pattern="[0-9]*"
        type="text"
        formControlName="card_no"
      />
    </p>
  </div>
  <div class="field">
    <label class="label">{{ 'PLAN.PAYMENT.HOLDER' | translate }}</label>
    <p class="control">
      <input
        class="input required"
        required
        formControlName="holder"
        x-autocompletetype="cc-full-name"
        type="text"
      />
    </p>
  </div>
  <label class="label">{{ 'PLAN.PAYMENT.EXPIRE' | translate }}</label>
  <div class="field is-grouped">
    <div class="control">
      <span class="select">
        <select
          class="year required"
          required
          name="year"
          x-autocompletetype="cc-exp-year"
          formControlName="year"
        >
          <option *ngFor="let y of yearsList" value="{{ y }}">{{ y }}</option>
        </select>
      </span>
    </div>
    <div class="control">
      <span class="selectlabel">/</span>
    </div>
    <div class="control">
      <span class="select">
        <select
          class="month required"
          required
          name="month"
          x-autocompletetype="cc-exp-month"
          formControlName="month"
        >
          <option *ngFor="let m of monthsList" value="{{ m }}">{{ m }}</option>
        </select>
      </span>
    </div>
  </div>
</form>
