<div class="device-windows">
  <div class="windows">
    <div class="windows-head">
      <div class="windows-title">
        <img src="/assets/img/preview/chrome.png" />
        <span>Google&nbsp;Chrome</span>
      </div>
    </div>
    <div class="windows-body">
      <div class="notification-wrapper">
        <a class="notification" target="_blank" [href]="notification.url">
          <div class="notification-body">
            <div class="notification-img">
              <img [src]="notification.icon" />
            </div>
            <div class="notification-content">
              <div class="notification-title">
                {{
                  notification.title ||
                    ('NOTIFICATION_EDITOR.VALIDATIONS.TITLE.NOLENGTH'
                      | translate)
                }}
              </div>
              <div class="notification-message">
                {{
                  notification.body ||
                    ('NOTIFICATION_EDITOR.VALIDATIONS.BODY' | translate)
                }}
              </div>
              <div class="notification-url">{{ url_without_http }}</div>
            </div>
            <div class="notification-arrow">
              <img src="/assets/img/preview/windows-arrow.svg" />
            </div>
          </div>
        </a>
      </div>
      <div class="utility-wrapper">
        <div class="utility-head">
          <span>Expand</span>
          <span>Clear all notifications</span>
        </div>
        <div class="utility-body">
          <img src="/assets/img/preview/windows-utilities.jpg" />
        </div>
      </div>
    </div>
  </div>
</div>
