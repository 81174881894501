<div class="main-nav">
  API
</div>
<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-header has-text-weight-bold is-size-4">
        API
        <app-help-link
          link="https://push7.jp/docs/development/api/"
        ></app-help-link>
      </div>
      <div class="container-body p7-form">
        <div class="content" [innerHTML]="'API.MESSAGE' | translate"></div>
        <app-copy-input
          copyText="{{ (app$ | async)?.appno }}"
          copyLabel="App Number"
        ></app-copy-input>
        <app-copy-input
          copyText="{{ (app$ | async)?.apikey }}"
          copyLabel="API Key"
        ></app-copy-input>
      </div>
    </div>
  </div>
</div>
