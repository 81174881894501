import { Component, OnInit } from '@angular/core';
import {
  LoadBalanceNotificationModel,
  LoadBalanceStatus,
  NotificationModel
} from '../../../models/notification.model';
import { AppService } from '../../../services/app/app.service';
import { Modal } from '../../../services/modal/modal.service';
import { NewPushModalComponent } from '../push/new-push-modal/new-push-modal.component';
import { NewLoadBalancePushModalComponent } from './new-load-balance-push-modal/new-load-balance-push-modal';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { take, map, switchMap, tap } from 'rxjs/operators';
import { AppModel } from '../../../models/app.model';
import { Observable } from 'rxjs';
import { LoadBalancePushDetailComponent } from './load-balance-push-detail/load-balance-push-detail';
import { PushModalV2Component } from '../push/push-modal-v2/push-modal-v2.component';
import { ActivatedRoute, Router } from '@angular/router';

const ITEM_PER_PAGE = 10;

@Component({
  templateUrl: 'load-balance.component.html',
  styles: [
    `
      th:nth-child(1) {
        width: 10%;
      }
      th:nth-child(3) {
        width: 180px;
      }
      th:nth-child(4) {
        width: 180px;
      }
    `
  ]
})
export class LoadBalanceComponent implements OnInit {
  public pushs: LoadBalanceNotificationModel[] | null;
  private app$: Observable<AppModel>;
  public page = 1;
  public max: number;
  constructor(
    private appService: AppService,
    private modal: Modal,
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.app$ = this.store.select(fromRoot.getAppSelected);
  }
  update() {
    if (this.page < 1) {
      return;
    }
    this.pushs = null;
    this.appService
      .get_load_balance_push((this.page - 1) * ITEM_PER_PAGE, ITEM_PER_PAGE)
      .subscribe(response => {
        this.pushs = response.pushs;
        this.max = Math.ceil(response.count / ITEM_PER_PAGE);
      });
  }
  translateStatus(status: LoadBalanceStatus): string {
    switch (status) {
      case 'canceled':
        return 'キャンセル済み';
      case 'completed':
        return '配信完了';
      case 'failed':
        return '失敗';
      case 'running':
        return '配信中';
      case 'scheduled':
        return '予約済み';
      case 'preparing':
        return '準備中';
    }
  }
  ngOnInit() {
    this.update();
  }
  create() {
    this.modal
      .open(PushModalV2Component, {
        notification: { delivery_strategy: { type: 'load_balance' } }
      })
      .then((sent: { data: NotificationModel; pushid: string }) => {
        if (
          sent.data.delivery_strategy &&
          sent.data.delivery_strategy.type === 'load_balance'
        ) {
          // 負荷分散配信
          this.page = 1;
          this.update();
        } else if (sent.data.transmission_time) {
          // 予約投稿
          this.router.navigate(['../push/reserved'], {
            relativeTo: this.route
          });
        } else {
          // 通常投稿
          this.router.navigate(['../push/normal'], {
            relativeTo: this.route
          });
        }
      })
      .catch(() => {});
  }
  detail(push: LoadBalanceNotificationModel) {
    this.modal
      .open(LoadBalancePushDetailComponent, push)
      .then(() => {
        this.update();
      })
      .catch(() => {});
  }
  next() {
    if (this.max != null && this.page + 1 <= this.max) {
      this.page++;
      this.update();
    }
  }
  prev() {
    if (this.page - 1 > 0) {
      this.page--;
      this.update();
    }
  }
}
