import { Component, OnDestroy, OnInit, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  Validator,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormBuilder,
  AbstractControl,
  ValidationErrors,
  Validators,
  ValidatorFn
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { take, takeUntil, filter, switchMap } from 'rxjs/operators';
import { getAppSelected, State } from '../../reducers';
import { AppModel } from '../../models/app.model';
import { AutomationRuleActionsPushNotificationDeliveryStrategy } from '../../services/api';

type DeliveryStrategy = AutomationRuleActionsPushNotificationDeliveryStrategy;

@Component({
  selector: 'app-delivery-strategy-form',
  templateUrl: './delivery-strategy-form.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DeliveryStrategyFormComponent)
    }
  ]
})
export class DeliveryStrategyFormComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  private onDestroy$: Subject<void> = new Subject<void>();

  public form: FormGroup;

  // コールバック群
  private onChange: any = () => {};
  private onTouched: any = () => {};

  get value(): DeliveryStrategy {
    return this.form.value;
  }
  @Input('value')
  set value(segmentation: DeliveryStrategy) {
    if (!segmentation) {
      return;
    }
    this.form.setValue(segmentation);
    this.onChange(segmentation);
    this.onTouched();
  }

  constructor(private fb: FormBuilder, private store: Store<State>) {}

  static defaultFormValue() {
    return {
      type: 'fast',
      subscribers_per_group: null,
      send_interval_minutes: null
    };
  }

  static validate: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors => {
    const value: DeliveryStrategy | null = control.value;
    if (!value) {
      return { empty_delivery_strategy: true };
    }
    if (!value.type) {
      return { delivery_strategy_unspecified: true };
    }
    if (
      value.type === 'load_balance' &&
      (!value.send_interval_minutes || !value.subscribers_per_group)
    ) {
      return { missing_load_balance_parameters: true };
    }
    return null;
  };

  ngOnInit() {
    this.form = this.fb.group(
      {
        type: this.fb.control('fast', [Validators.required]),
        subscribers_per_group: this.fb.control(0),
        send_interval_minutes: this.fb.control(0)
      },
      {
        validators: [
          control => {
            switch (control.get('type').value) {
              case 'fast':
                return null;
              case 'load_balance': {
                return control.get('subscribers_per_group').value != null &&
                  control.get('send_interval_minutes').value != null
                  ? null
                  : { missing_load_balance_params: true };
              }
            }
            return null;
          }
        ]
      }
    );
    this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });
    // typeがload_balanceに変更され、かつsubscribers_per_groupとsend_interval_minutesに値が設定されていない場合、appから初期値を代入する
    this.form
      .get('type')
      .valueChanges.pipe(
        filter(
          () =>
            !this.form.get('subscribers_per_group').value &&
            !this.form.get('send_interval_minutes').value
        ),
        takeUntil(this.onDestroy$),
        filter(x => x === 'load_balance'),
        switchMap(() => this.store.select(getAppSelected)),
        take(1)
      )
      .subscribe(app => {
        this.form
          .get('subscribers_per_group')
          .setValue(Math.ceil(app.subscribers / 6));
        this.form.get('send_interval_minutes').setValue(10);
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  writeValue(obj: DeliveryStrategy): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
