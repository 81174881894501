import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ASTDateTimeRange } from '../../../../models/segments.model';

@Component({
  selector: 'app-segment-date-range-selector',
  template: `
    <div class="is-flex">
      <app-segment-date-specifier-selector
        [dateSpecifier]="range.from"
        (dateSpecifierChange)="fromChangeHandler($event)"
      ></app-segment-date-specifier-selector>
      <p class="middle">~</p>
      <app-segment-date-specifier-selector
        [dateSpecifier]="range.to"
        (dateSpecifierChange)="toChangeHandler($event)"
      ></app-segment-date-specifier-selector>
    </div>
  `,
  styles: [
    `
      .middle {
        margin: 0.8rem;
      }
    `
  ]
})
export class DateRangeSelectorComponent {
  @Input()
  range: ASTDateTimeRange;
  @Output()
  rangeChange: EventEmitter<ASTDateTimeRange> = new EventEmitter();

  fromChangeHandler(newFrom) {
    const c = this.range;
    c.from = newFrom;
    this.rangeChange.next(c);
  }
  toChangeHandler(newTo) {
    const c = this.range;
    c.to = newTo;
    this.rangeChange.next(c);
  }
}
