import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  Validator,
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from '../../services/app/app.service';
import { AutomationRuleActionsPushNotification } from '../../services/api';
import { AppModel } from '../../models/app.model';

type NotificationBody = AutomationRuleActionsPushNotification['notification'];

@Component({
  selector: 'app-notification-body-form',
  templateUrl: './notification-body-form.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NotificationBodyFormComponent)
    }
  ]
})
export class NotificationBodyFormComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  private onDestroy$: Subject<void> = new Subject<void>();

  public form: FormGroup;

  // コールバック群
  private onChange: any = () => {};
  private onTouched: any = () => {};

  get value(): NotificationBody {
    return this.form.value;
  }
  @Input('value')
  set value(notification: NotificationBody) {
    if (!notification) {
      return;
    }
    this.form.setValue(notification);
    this.onChange(notification);
    this.onTouched();
  }

  constructor(private fb: FormBuilder, private appService: AppService) {}

  static validate: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors => {
    const value: NotificationBody = control.value;
    if (!value) {
      return { empty_notification_body: true };
    }
    // title, body, url, iconは必須
    if ([value.title, value.body, value.url, value.icon].some(str => !str)) {
      return { notification_body_missing_fields: true };
    }
    if (!(0 <= value.ttl && value.ttl <= 2419200)) {
      return { notification_body_invalid_ttl: true };
    }
    return null;
  };

  static defaultFormValue(app: AppModel) {
    return {
      title: '',
      body: '',
      url: '',
      icon: app.icon,
      image: null,
      ttl: 86400,
      disappear_instantly: true,
      enable_ios_sound: true
    };
  }

  // viewとしてはAPIと逆の値を表示したいので、getterとsetterで対応する
  public set disappearWithoutInteraction(value: boolean) {
    this.form.get('disappear_instantly').setValue(!value);
  }
  public get disappearWithoutInteraction(): boolean {
    return !this.form.get('disappear_instantly').value;
  }

  ngOnInit() {
    this.form = this.fb.group({
      title: this.fb.control('', Validators.required),
      body: this.fb.control('', Validators.required),
      url: this.fb.control('', [Validators.required]),
      icon: this.fb.control('', [Validators.required]),
      image: this.fb.control('', []),
      ttl: this.fb.control(86400, [
        Validators.required,
        Validators.min(0),
        Validators.max(2419200)
      ]),
      disappear_instantly: this.fb.control(true, []),
      enable_ios_sound: this.fb.control(true, [])
    });
    this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  writeValue(obj: NotificationBody): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public uploadImageAndSetURL(key: string, files: FileList) {
    const file = files.item(0);

    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.appService
        .upload(file.name, e.target.result.replace(/data:.*\/.*;base64,/, ''))
        .subscribe(({ url }) => {
          this.form.get(key).setValue(url);
        });
    };
    reader.readAsDataURL(file);
  }
}
