<div class="main-nav">
  {{ 'PARAMETERS.PARAMETERS_LIST' | translate }}
</div>
<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <ng-container *ngIf="list; then Loaded; else Loading"></ng-container>
      <ng-template #Loaded>
        <ng-container
          *ngIf="list?.length > 0; then List; else NoPush"
        ></ng-container>
        <ng-template #List>
          <div>
            <div>
              <table class="table is-striped is-fullwidth">
                <thead>
                  <tr>
                    <th class="cell-title">
                      {{ 'PARAMETERS.PARAMETERS_NAME' | translate }}
                    </th>
                    <th class="cell-sent">
                      {{ 'PARAMETERS.SUBSCRIBERS_COUNT' | translate }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let parameter of list">
                    <td>{{ parameter.parameter }}</td>
                    <td>{{ parameter.subscribers_count }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="container-footer">
            <div class="pagination">
              <a
                (click)="prev()"
                class="pagination-previous has-background-primary"
                [class.is-disabled]="page == 1"
              >
                <i class="fa fa-caret-left" aria-hidden="true"></i>
              </a>
              <div class="pagination-list">
                <span class="button view is-primary">{{ page }}/{{ max }}</span>
              </div>
              <a
                (click)="next()"
                class="pagination-next has-background-primary"
                [class.is-disabled]="page == max"
              >
                <i class="fa fa-caret-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </ng-template>
        <ng-template #NoPush>
          <div class="container-body">
            <p>{{ 'PARAMETERS.NO_PARAMETERS' | translate }}</p>
          </div>
        </ng-template>
      </ng-template>
      <ng-template #Loading>
        <app-loading></app-loading>
      </ng-template>
    </div>
  </div>
</div>
