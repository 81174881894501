import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { NotificationModel } from '../../../../models/notification.model';
import { Notification } from '../../../../services/notification/notification.service';
import { AppService } from '../../../../services/app/app.service';
import { ModalContext } from '../../../../services/modal/modal.service';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-push-modal-v2',
  templateUrl: './push-modal-v2.component.html',
  styleUrls: ['./push-modal-v2.component.scss']
})
export class PushModalV2Component implements OnInit {
  public updating = false;
  public notification: NotificationModel = {
    title: '',
    body: '',
    icon: '',
    url: '',
    disappear_instantly: true,
    enable_ios_sound: true,
    ttl: 43200
  };
  public step = 0;
  public nStep = 0;
  constructor(
    private modalCtx: ModalContext,
    private appService: AppService,
    private notiService: Notification,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    // since modalCtx keeps its value, it is needed to clone to local value
    if (this.modalCtx.payload && this.modalCtx.payload.notification) {
      this.notification = {
        ...this.notification,
        ...this.modalCtx.payload.notification
      };
    }
  }

  modalResolve(payload) {
    this.modalCtx.resolve(payload);
  }

  close() {
    this.modalCtx.reject();
  }
}
