<div class="copy-input">
  <label class="label">{{ copyLabel }}</label>
  <p class="control has-addons">
    <textarea
      readonly
      class="textarea copy"
      [class.is-success]="succeeded"
      [class.is-danger]="failed"
      spellcheck="false"
      [ngModel]="copyText"
    ></textarea>
    <button
      class="copy-button"
      type="button"
      name="button"
      [class.is-success]="succeeded"
      [class.is-danger]="failed"
    >
      <!-- {{'COPY.COPY' | translate}} -->
      <i class="fa fa-files-o" aria-hidden="true"></i>
    </button>
  </p>
  <span class="help is-success" *ngIf="succeeded">{{
    'COPY.SUCCEEDED' | translate
  }}</span>
  <span class="help is-danger" *ngIf="failed">{{
    'COPY.FAILED' | translate
  }}</span>
</div>
