<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-body">
        <h3 class="title is-4 has-text-centered">
          {{ 'PLAN.CONFIRM.TITLE' | translate }}
        </h3>
        <div class="agree">
          <p class="title is-5">{{ 'PLAN.CONFIRM.AGREEMENT' | translate }}</p>
          <p class="control">
            <textarea
              class="textarea"
              placeholder="agreement"
              [ngModel]="agreement"
              disabled
            ></textarea>
          </p>
          <p class="control">
            <label class="checkbox">
              <input
                name="agree"
                type="checkbox"
                id="agree_checkbox"
                [(ngModel)]="agree"
              />
              {{ 'PLAN.CONFIRM.AGREE' | translate }}
            </label>
          </p>
        </div>
        <div class="checkout" *ngIf="agree">
          <div class="box">
            <div class="media">
              <div class="media-content">
                <div class="has-text-centered about">
                  <img src="/assets/img/logo_black.svg" />
                  <p
                    [translate]="'PLAN.PAYMENT.PLAN'"
                    [translateParams]="{ name: plan?.name }"
                    class="plan"
                  ></p>
                </div>

                <p class="title is-4 price">¥{{ plan?.amount }}（税別）</p>
              </div>
            </div>
          </div>
          <button
            (click)="submit()"
            class="button is-fullwidth is-success"
            [class.is-loading]="processing"
          >
            {{ 'PLAN.CONFIRM.PURCHASE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
