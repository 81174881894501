import { Component, OnInit } from '@angular/core';
import { ProvisionalService } from '../../../services/provisional/provisional.service';

@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.scss'],
  providers: [ProvisionalService]
})
export class ForgetComponent implements OnInit {
  public email: string;
  public success: string;
  public error: string;
  constructor(private provisional: ProvisionalService) {}

  ngOnInit() {}
  submit() {
    if (!this.email) {
      return false;
    }
    this.provisional.forget(this.email).subscribe(
      d => {
        this.success = d;
      },
      e => {
        this.error = e.error.error;
      }
    );
  }
}
