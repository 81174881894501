import { Store } from '@ngrx/store';
import { SdkModalComponent } from './sdk-modal/sdk-modal.component';
import { Modal } from './../../../services/modal/modal.service';
import { Component } from '@angular/core';
import * as fromRoot from './../../../reducers';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-sdk-message',
  template: `
    <article (click)="click()" *ngIf="show" class="message">
      <div class="message-header">
        <p>{{ 'TAGS.MESSAGE.LABEL' | translate }}</p>
        <button class="delete" (click)="hide()"></button>
      </div>
      <div class="message-body">
        <p>{{ 'TAGS.MESSAGE.MESSAGE' | translate }}</p>
      </div>
    </article>
  `,
  styles: [
    `
      .message {
        cursor: pointer;
        margin-bottom: 2em;
        & > .message-header {
          background-color: #edeff1;
        }
        & > .message-body {
          border-color: #edeff1;
        }
      }
    `
  ]
})
export class SdkMessageComponent {
  public show: boolean;
  private appno: string;
  constructor(private modal: Modal, private store$: Store<fromRoot.State>) {
    this.store$
      .select(fromRoot.getAppSelected)
      .pipe(take(1))
      .subscribe(app => {
        this.appno = app.appno;
        const raw = localStorage.getItem('sdk-message-dismissed-' + this.appno);
        if (raw) {
          this.show = false;
        } else {
          this.show = true;
        }
      });
  }
  hide() {
    this.show = false;
    localStorage.setItem('sdk-message-dismissed-' + this.appno, 'yup');
  }
  click() {
    this.modal.open(SdkModalComponent);
  }
}
