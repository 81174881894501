import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { QueryModel } from '../../models/notification.model';

type SimpleQuery = QueryModel;

@Component({
  selector: 'app-simple-query-form',
  template: `
    <div class="field">
      <label class="label">パラメータ配信条件</label>
      <div class="control">
        <div class="select">
          <select [(ngModel)]="query.mode">
            <option value="AND">AND</option>
            <option value="OR">OR</option>
            <option value="NAND">NAND</option>
            <option value="NOR">NOR</option>
          </select>
        </div>
      </div>
    </div>
    <div class="field has-addons">
      <div class="control">
        <input class="input" type="text" [(ngModel)]="param" />
      </div>
      <div class="control">
        <a class="button" (click)="add(param)">
          パラメータ追加
        </a>
      </div>
    </div>
    <div class="field" *ngIf="query.params?.length > 0">
      <label class="label">パラメータ一覧</label>
      <p class="control" *ngIf="query.params?.length > 0">
        <span class="tag mr-1 is-light is-medium" *ngFor="let p of query.params"
          >{{ p }}<button (click)="remove(p)" class="delete is-small"></button
        ></span>
      </p>
    </div>
    <article class="message is-warning" *ngIf="query.params?.length <= 0">
      <div class="message-body">
        パラメータが設定されていません
      </div>
    </article>
    <div class="field">
      <div class="file">
        <label class="file-label">
          <input
            class="file-input"
            (change)="loadcsv($event)"
            type="file"
            name="csv"
          />
          <span class="file-cta">
            <span class="file-icon">
              <i class="fas fa-upload"></i>
            </span>
            <span class="file-label">
              パラメータをCSVからロード
            </span>
          </span>
        </label>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SimpleQueryFormComponent)
    }
  ]
})
export class SimpleQueryFormComponent
  implements OnInit, OnDestroy, ControlValueAccessor {
  private onDestroy$: Subject<void> = new Subject<void>();

  /**
   * SimpleQueryオブジェクトのバリデーションを提供する
   * 複数オブジェクトのUnionとして表現されるため、angular/formのバリデーションでは対応できないため
   * @param query クエリオブジェクト
   */
  static validateQuery(
    query: string | Record<string, string | Array<string>>
  ): boolean {
    return (
      !!query &&
      typeof query !== 'string' &&
      !!query.mode &&
      !!query.params &&
      Array.isArray(query.params) &&
      query.params.length > 0
    );
  }

  private _query: QueryModel = {
    mode: 'AND',
    params: []
  };
  set query(value: QueryModel) {
    this._query = value;
    this.onChange(value);
  }
  get query(): QueryModel {
    return this._query;
  }
  public param: string; // フォームの入力

  // コールバック群
  private onChange: any = () => {};
  private onTouched: any = () => {};

  get value(): SimpleQuery {
    return this.query;
  }
  @Input('value')
  set value(simpleQuery: SimpleQuery) {
    if (!simpleQuery) {
      simpleQuery = {
        mode: 'AND',
        params: []
      };
    }
    this.query = simpleQuery;
    this.onChange(simpleQuery);
    this.onTouched();
  }

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  writeValue(obj: SimpleQuery): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  add(param) {
    if (!param) {
      return;
    }
    if (!this.query || !this.query.params) {
      this.query = { mode: 'AND', params: [] };
    }
    const arr = param
      .split(',')
      .filter(p => p !== '')
      .filter(p => this.query.params.indexOf(p) < 0);
    this.query = { ...this.query, params: this.query.params.concat(arr) };
    this.param = '';
  }
  remove(param) {
    this.query = {
      ...this.query,
      params: this.query.params.filter(p => p !== param)
    };
  }
  loadcsv(e) {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = loaded => {
      const contents: any = loaded.target;
      const text = contents.result.replace(new RegExp('\n', 'g'), ','); // 改行をカンマに変換しておくとaddでいい感じになる
      this.add(text);
    };
    reader.readAsText(file);
  }
}
