<div>
  <div class="form">
    <div class="select">
      <select [ngModel]="dateSpecifier.className" (change)="update($event)">
        <option value="ASTDateTime">日付</option>
        <option value="ASTRelativeDateTime">相対時間</option>
        <option value="ASTKeywordDateTime">キーワード</option>
      </select>
    </div>
  </div>
  <ng-container [ngSwitch]="dateSpecifier.className">
    <ng-container *ngSwitchCase="'ASTDateTime'">
      <app-segment-date-picker
        [(date)]="dateSpecifier.date"
      ></app-segment-date-picker>
    </ng-container>
    <ng-container *ngSwitchCase="'ASTRelativeDateTime'">
      <app-segment-relative-datetime-picker
        [(relativeDate)]="dateSpecifier"
      ></app-segment-relative-datetime-picker>
    </ng-container>
    <ng-container *ngSwitchCase="'ASTKeywordDateTime'">
      <app-segment-keyword-datetime-picker
        [(keyword)]="dateSpecifier.value"
      ></app-segment-keyword-datetime-picker>
    </ng-container>
  </ng-container>
</div>
