<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <form class="box" (submit)="submit()">
      <div class="content-header is-yellow">
        {{ 'HOME.ADD_APP' | translate }}
      </div>
      <div class="content">
        <div class="notification is-info is-light">
          <p>
            プロジェクト「{{ projectName }}」にアプリケーションを追加します。
          </p>
        </div>
        <form>
          <div class="field">
            <label class="label">{{ 'HOME.NEWAPP.NAME' | translate }}</label>
            <p class="control">
              <input
                class="input"
                required
                name="name"
                type="text"
                [(ngModel)]="name"
              />
            </p>
          </div>
          <label class="label">{{ 'HOME.NEWAPP.URL' | translate }}</label>
          <div class="field">
            <div class="is-flex" style="margin-bottom: 1em;">
              <div class="select">
                <select [(ngModel)]="protocol" name="protocol" class="protocol">
                  <option disabled selected></option>
                  <option value="http://">http://</option>
                  <option value="https://">https://</option>
                </select>
              </div>
              <p class="control form" style="flex:1;">
                <input
                  class="input site-url"
                  required
                  name="url"
                  type="url"
                  [(ngModel)]="url"
                  (change)="checkProtocol($event)"
                />
              </p>
            </div>
          </div>
          <div class="field">
            <label class="label">{{ 'HOME.NEWAPP.ICON' | translate }}</label>
            <p class="control">
              <app-img-crop [(src)]="icon" aspectRatio="1"></app-img-crop>
            </p>
          </div>
          <div class="field">
            <label class="label">{{
              'HOME.NEWAPP.CATEGORY' | translate
            }}</label>
            <div class="control">
              <div class="select">
                <select name="category" (change)="select($event)">
                  <option selected="selected" disabled="true">{{
                    'OTHERS.CATEGORY_SELECT' | translate
                  }}</option>
                  <option *ngFor="let c of categories | async" [value]="c.no">{{
                    c.name
                  }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">{{ 'HOME.NEWAPP.DOMAIN' | translate }}</label>
            <div class="app-url">
              <div class="domain-suffix">
                <span>https://</span>
              </div>
              <div>
                <p class="control form">
                  <input
                    class="input domain-input"
                    [class.is-danger]="isAvailable === 2"
                    [class.is-success]="isAvailable === 1"
                    required
                    name="domain"
                    type="text"
                    [(ngModel)]="domain"
                  />
                </p>
              </div>
              <div class="domain-suffix">
                <span>.app.push7.jp</span>
              </div>
            </div>
            <span class="help is-success" *ngIf="isAvailable === 1">{{
              'HOME.NEWAPP.AVAILABLE' | translate
            }}</span>
            <span class="help is-danger" *ngIf="isAvailable === 2">{{
              error
            }}</span>
          </div>
        </form>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button class="button is-danger" (click)="close()">
            {{ 'HOME.NEWAPP.CANCEL' | translate }}
          </button>
          <button
            class="button is-success"
            [disabled]="!isReady()"
            type="submit"
            [class.is-loading]="processing"
          >
            {{ 'HOME.NEWAPP.SUBMIT' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <button class="modal-close" (click)="close()"></button>
</div>
