import { UserService } from './../../services/user/user.service';
import { ModalContext } from './../../services/modal/modal.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-set-corporate-modal',
  templateUrl: './set-corporate-modal.component.html'
})
export class SetCorporateComponent implements OnInit {
  public is_corporation: boolean = null;
  constructor(
    private modalCtx: ModalContext,
    private userService: UserService
  ) {}
  close() {
    this.modalCtx.reject();
  }
  ok() {
    this.userService
      .set_corporate(this.is_corporation)
      .subscribe(() => this.modalCtx.resolve());
  }
  ngOnInit() {}
}
