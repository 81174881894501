import { QueryModel } from './../../../models/notification.model';
import { ModalContext } from './../../../services/modal/modal.service';
import { Injectable, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-query-editor',
  templateUrl: './query-editor.component.html',
  styleUrls: ['./query-editor.component.scss']
})
export class QueryEditorComponent implements OnInit {
  public query: QueryModel;
  public modes = ['AND', 'OR', 'NAND', 'NOR'];
  public param: string; // フォームの入力
  constructor(private modalCtx: ModalContext) {}
  ngOnInit() {
    this.query = this.modalCtx.payload as QueryModel;
  }
  add(param) {
    if (!param) {
      return;
    }
    const arr = param
      .split(',')
      .filter(p => p !== '')
      .filter(p => this.query.params.indexOf(p) < 0);
    this.query.params = this.query.params.concat(arr);
    this.param = '';
  }
  remove(param) {
    this.query.params = this.query.params.filter(p => p !== param);
  }
  close() {
    this.modalCtx.reject();
  }
  ok() {
    this.modalCtx.resolve(this.query);
  }
  loadcsv(e) {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = loaded => {
      const contents: any = loaded.target;
      const text = contents.result.replace(new RegExp('\n', 'g'), ','); // 改行をカンマに変換しておくとaddでいい感じになる
      this.add(text);
    };
    reader.readAsText(file);
  }
}
