<ng-container [formGroup]="form">
  <div class="field">
    <div class="control">
      <div class="select">
        <select formControlName="type">
          <option value="all">全員</option>
          <option [disabled]="!(canUseParameters$ | async)" value="query"
            >パラメータ配信</option
          >
          <option [disabled]="!(canUseParameters$ | async)" value="csv_preset"
            >SFTP連携</option
          >
          <option
            [disabled]="!(canUseSegmentation$ | async)"
            value="segment_preset"
            >セグメント配信</option
          >
        </select>
      </div>
    </div>
  </div>
  <div [class.is-hidden]="form.value.type !== 'all'">
    <article class="message is-info">
      <div class="message-body">
        アプリケーションを購読している全員に送信します
      </div>
    </article>
  </div>
  <div [class.is-hidden]="form.value.type !== 'query'">
    <app-simple-query-form formControlName="query"></app-simple-query-form>
  </div>
  <div [class.is-hidden]="form.value.type !== 'csv_preset'">
    <app-sftp-query-form formControlName="csv_preset_id"></app-sftp-query-form>
  </div>
  <div [class.is-hidden]="form.value.type !== 'segment_preset'">
    <app-advanced-query-form
      formControlName="segment_preset_id"
    ></app-advanced-query-form>
  </div>
</ng-container>
