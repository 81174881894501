/**
 * Push7 Project Feature for push7-apiserver
 * Push7 Project Featurs API specfication
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: enginners@gnex.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * A request model of respondProjectInvitation.
 */
export interface RespondProjectInvitationRequest { 
    /**
     * 許諾 or 拒否
     */
    respond: RespondProjectInvitationRequest.RespondEnum;
}
export namespace RespondProjectInvitationRequest {
    export type RespondEnum = 'accept' | 'decline';
    export const RespondEnum = {
        Accept: 'accept' as RespondEnum,
        Decline: 'decline' as RespondEnum
    };
}


