<div class="range-picker">
  <button class="button is-light" (click)="isOpen = !isOpen">
    {{ 'ANALYTICS.OVERVIEW.RANGE' | translate }}
  </button>
  <div
    class="graph-scope-dropdown"
    [class.show]="isOpen"
    [class.hide]="!isOpen"
  >
    <ul>
      <li (click)="setRange('past-week')">
        {{ 'ANALYTICS.OVERVIEW.PAST_WEEK' | translate }}
      </li>
      <li (click)="setRange('last-month')">
        {{ 'ANALYTICS.OVERVIEW.LAST_MONTH' | translate }}
      </li>
      <li (click)="setRange('past-month')">
        {{ 'ANALYTICS.OVERVIEW.PAST_MONTH' | translate }}
      </li>
      <li (click)="setRange('this-month')">
        {{ 'ANALYTICS.OVERVIEW.THIS_MONTH' | translate }}
      </li>
    </ul>
    <div class="graph-scope-date">
      <input class="input range start" type="date" />
      <span class="tilde">~</span>
      <input class="input range end" type="date" />
    </div>
  </div>
</div>
