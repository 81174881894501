<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-header has-text-weight-bold is-size-4">
        {{ 'TAGS.BUTTON.LABEL' | translate }}
      </div>
      <div class="container-body">
        <app-sdk-message></app-sdk-message>
        <div class="step">
          <div class="count">
            <p>1</p>
          </div>
          <div class="content">
            <p>{{ 'TAGS.BUTTON.FIRST' | translate }}</p>
            <div class="btn-styles">
              <div class="preview">
                <div class="normal">
                  <div class="p7-b">
                    <a [href]="page" target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 113.46 214.32"
                      >
                        <defs>
                          <style>
                            .cls-1 {
                              fill: #fff;
                            }
                          </style>
                        </defs>
                        <polygon
                          class="cls-1"
                          points="64.61 0 0 121.34 66.18 121.34 40.97 214.32 113.46 86.67 44.12 86.67 64.61 0"
                        ></polygon>
                      </svg>
                      <span>{{ buttonText }}</span>
                    </a>
                  </div>
                </div>

                <div class="right">
                  <div class="p7-b">
                    <a [href]="page" target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 113.46 214.32"
                      >
                        <defs>
                          <style>
                            .cls-1 {
                              fill: #fff;
                            }
                          </style>
                        </defs>
                        <polygon
                          class="cls-1"
                          points="64.61 0 0 121.34 66.18 121.34 40.97 214.32 113.46 86.67 44.12 86.67 64.61 0"
                        ></polygon>
                      </svg>
                      <span>{{ buttonText }}</span>
                    </a>
                    <div class="p7-left">
                      <span>{{ subscribers }}</span>
                    </div>
                  </div>
                </div>

                <div class="top">
                  <div class="p7-b">
                    <div class="p7-top">
                      <span>{{ subscribers }}</span>
                    </div>
                    <a [href]="page" target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 113.46 214.32"
                      >
                        <defs>
                          <style>
                            .cls-1 {
                              fill: #fff;
                            }
                          </style>
                        </defs>
                        <polygon
                          class="cls-1"
                          points="64.61 0 0 121.34 66.18 121.34 40.97 214.32 113.46 86.67 44.12 86.67 64.61 0"
                        ></polygon>
                      </svg>
                      <span>{{ buttonText }}</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="btn-select adjust-margin">
                <div class="normal">
                  <input
                    type="radio"
                    (change)="change()"
                    value="n"
                    id="easyNormal"
                    class=""
                    name=""
                    [(ngModel)]="type"
                  /><label for="easyNormal">{{
                    'TAGS.BUTTON.BUTTON_ONLY' | translate
                  }}</label>
                </div>
                <div class="right">
                  <input
                    type="radio"
                    (change)="change()"
                    value="r"
                    id="easyRight"
                    class=""
                    name=""
                    [(ngModel)]="type"
                  /><label for="easyRight">{{
                    'TAGS.BUTTON.RIGHT' | translate
                  }}</label>
                </div>
                <div class="top">
                  <input
                    type="radio"
                    (change)="change()"
                    value="t"
                    id="easyTop"
                    class=""
                    name=""
                    [(ngModel)]="type"
                  /><label for="easyTop">{{
                    'TAGS.BUTTON.TOP' | translate
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="step adjust-margin">
          <div class="count">
            <p>2</p>
          </div>
          <div class="content">
            <p>{{ 'TAGS.BUTTON.TEXT_INPUT' | translate }}</p>
            <p class="control">
              <input
                class="input"
                [(ngModel)]="buttonText"
                (keyup)="change()"
              />
            </p>
          </div>
        </div>
        <div class="step adjust-margin">
          <div class="count">
            <p>3</p>
          </div>
          <div class="content">
            <p>{{ 'TAGS.BUTTON.COPY_PASTE' | translate }}</p>
            <app-copy-input [copyText]="tag"></app-copy-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
