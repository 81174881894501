import { ASTLastVisit, ASTLastVisitUrl } from '../../../models/segments.model';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ASTNodeComponentBase } from '../ast-node.interface';

@Component({
  selector: 'app-ast-lastvisit-url',
  template: `
    <div class="message" *ngIf="value">
      <div
        class="message-header"
        [appAstDraggable]="value"
        (onMoved)="removeFromParentNode.emit()"
      >
        <p>ページ毎の最終訪問時間</p>
        <a
          *ngIf="!hideDelete"
          class="delete"
          (click)="removeFromParentNode.emit()"
        ></a>
      </div>
      <div class="message-body">
        <app-segment-urlquery-editor
          [(urlQuery)]="value.urlDatetime.url"
        ></app-segment-urlquery-editor>
        <label class="label mt-3">最終訪問時間</label>
        <app-segment-datequery-editor
          [(dateQuery)]="value.urlDatetime.time"
        ></app-segment-datequery-editor>
      </div>
    </div>
  `
})
export class ASTLastVisitUrlComponent
  implements ASTNodeComponentBase<ASTLastVisitUrl> {
  @Input()
  hideDelete: boolean;
  @Input()
  value: ASTLastVisitUrl;
  @Output()
  removeFromParentNode: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onMoved: EventEmitter<void> = new EventEmitter<void>();
}
