import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DateTimeKeyword } from '../../../../models/segments.model';

@Component({
  selector: 'app-segment-keyword-datetime-picker',
  template: `
    <div class="form">
      <div class="select">
        <select
          [ngModel]="keyword"
          (change)="keywordChange.next($event.target.value)"
        >
          <option value="NOW">現在</option>
          <option value="TODAY">今日</option>
        </select>
      </div>
    </div>
  `
})
export class KeywordDateTimePickerComponent {
  @Input()
  keyword: DateTimeKeyword;
  @Output()
  keywordChange: EventEmitter<DateTimeKeyword> = new EventEmitter();
}
