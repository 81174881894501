<div class="device-android">
  <div class="android">
    <img class="android-device" src="/assets/img/preview/android.png" />
    <div class="android-body">
      <div class="device-head">No SIM Card</div>
      <div class="device-time">{{ clock_time | date: 'HH:mm' }}</div>
      <div class="notification-wrapper">
        <div class="notification">
          <div class="notification-head">
            <div class="notification-icon">
              <img src="/assets/img/preview/chrome-gray.png" />
            </div>
            <div class="notification-label">Chrome</div>
            <div class="notification-link">{{ url_without_http }}</div>
            <div class="notification-time">現在</div>
            <div
              class="notification-arrow"
              [ngClass]="{
                under: notification.image,
                up: !notification.image
              }"
            >
              <img src="/assets/img/preview/arrow-under.png" />
            </div>
          </div>
          <div class="notification-body">
            <div class="notification-content">
              <div class="notification-title">
                {{
                  notification.title ||
                    ('NOTIFICATION_EDITOR.VALIDATIONS.TITLE.NOLENGTH'
                      | translate)
                }}
              </div>
              <div class="notification-message">
                {{
                  notification.body ||
                    ('NOTIFICATION_EDITOR.VALIDATIONS.BODY' | translate)
                }}
              </div>
            </div>
            <div class="notification-appicon">
              <img [src]="notification.icon" />
            </div>
          </div>
          <div *ngIf="notification.image" class="notification-footer">
            <div class="notification-img">
              <img [src]="notification.image" />
            </div>
          </div>
        </div>
      </div>
      <div class="device-charge">77%・Charging</div>
    </div>
  </div>
</div>
