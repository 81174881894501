import * as Segments from './../../../models/segments.model';
import { ModalContext } from './../../../services/modal/modal.service';
import { Component, OnInit } from '@angular/core';

export interface AstSelectorOptionType {
  operator: boolean;
  qualifier: boolean;
  condition: boolean;
  init: string;
  withoutValue: boolean;
}
export class AstSelectorOption {
  public value: AstSelectorOptionType;
  valueOf() {
    return this.value;
  }
  constructor(input = {}) {
    // default
    const d: AstSelectorOptionType = {
      operator: true,
      qualifier: true,
      condition: true,
      init: 'AND',
      withoutValue: false
    };
    this.value = Object.assign({}, d, input);
  }
}

@Component({
  templateUrl: './ast-selector.component.html',
  styleUrls: ['./ast-selector.component.scss']
})
export class AstSelectorComponent {
  public name: string;
  public node: Segments.ASTNode;
  public options: AstSelectorOptionType;
  constructor(private modalCtx: ModalContext) {
    const x = new AstSelectorOption(this.modalCtx.payload);
    this.options = x.value;
    this.select(this.options.init);
  }
  public displayPreview(): boolean {
    if (['ASTInnerNode', 'ASTValue'].includes(this.node.className)) {
      return false;
    }
    return true;
  }
  public close() {
    this.modalCtx.reject();
  }
  public ok() {
    if (this.node) {
      this.modalCtx.resolve(this.node);
    } else {
      this.modalCtx.reject();
    }
  }
  public select(name) {
    this.name = name;
    switch (this.name) {
      case 'ADD':
        this.node = new Segments.ASTInnerNode('+');
        break;
      case 'SUB':
        this.node = new Segments.ASTInnerNode('-');
        break;
      case 'AND':
        this.node = new Segments.ASTInnerNode('AND');
        break;
      case 'OR':
        this.node = new Segments.ASTInnerNode('OR');
        break;
      case 'XOR':
        this.node = new Segments.ASTInnerNode('XOR');
        break;
      case 'NOR':
        this.node = new Segments.ASTInnerNode('NOR');
        break;
      case 'NAND':
        this.node = new Segments.ASTInnerNode('NAND');
        break;
      case 'XNOR':
        this.node = new Segments.ASTInnerNode('XNOR');
        break;
      case 'NOT':
        this.node = new Segments.ASTValue('NOT');
        break;
      case 'PARAMETER':
        this.node = new Segments.ASTParameter();
        break;
      case 'PARAMETERS':
        this.node = new Segments.ASTParameters();
        break;
      case 'LASTOPENED':
        this.node = new Segments.ASTLastOpened();
        break;
      case 'SUBSCRIBEDAY':
        this.node = new Segments.ASTSubscribeDay();
        break;
      case 'LASTVISIT':
        this.node = new Segments.ASTLastVisit();
        break;
      case 'LASTVISIT_URL':
        this.node = new Segments.ASTLastVisitUrl(
          new Segments.UrlDatetime(
            new Segments.ASTUrlJust(''),
            new Segments.ASTDateTimeNever()
          )
        );
        break;
      case 'BROWSER':
        this.node = new Segments.ASTBrowser();
        break;
      case 'OS':
        this.node = new Segments.ASTOS();
        break;
      case 'OPENED':
        this.node = new Segments.ASTOpened();
        break;
      case 'SENT':
        this.node = new Segments.ASTSent();
        break;
    }
  }
}
