import { TranslateService } from '@ngx-translate/core';
import { Notification } from './../../../../services/notification/notification.service';
import { AppService } from './../../../../services/app/app.service';
import { NotificationModel } from './../../../../models/notification.model';
import { ModalContext } from './../../../../services/modal/modal.service';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-new-push-modal',
  templateUrl: './new-push-modal.component.html',
  styleUrls: ['./new-push-modal.component.scss']
})
export class NewPushModalComponent implements OnInit {
  public sending = false;
  public notification: NotificationModel = {
    title: '',
    body: '',
    icon: '',
    url: '',
    disappear_instantly: true,
    enable_ios_sound: true
  };
  constructor(
    private modalCtx: ModalContext,
    private appService: AppService,
    private notiService: Notification,
    private translate: TranslateService
  ) {}

  ngOnInit() {}
  close() {
    this.modalCtx.reject();
  }
  transmit() {
    this.sending = true;
    this.appService
      .transmit(this.notification)
      .pipe<any>(finalize(() => (this.sending = false)))
      .subscribe(
        success => {
          this.translate.get('PUSH.COMPLETED').subscribe((m: string) => {
            this.notiService.open(m, 'success');
          });
          this.notification.id = success.pushid;
          this.modalCtx.resolve({
            data: this.notification,
            pushid: success.pushid
          });
        },
        response => {
          this.notiService.open(response.error.error, 'danger');
        }
      );
  }
}
