import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { CardModel, CardType } from '../../models/card.model';
import { Observable, combineLatest } from 'rxjs';

@Component({
  selector: 'app-card-editor',
  templateUrl: './card-editor.component.html'
})
export class CardEditorComponent {
  public yearsList: number[]; // 現在から20年後まで
  public monthsList: number[]; // 1 ~ 12
  public cardForm: FormGroup;

  public cardType$: Observable<CardType | null>;

  @Input()
  public value: CardModel;
  @Output()
  public valueChange: EventEmitter<CardModel> = new EventEmitter();
  @Output()
  public cardTypeChange: EventEmitter<CardType> = new EventEmitter();
  @Output()
  public validityChange: EventEmitter<boolean> = new EventEmitter();

  constructor(private fb: FormBuilder) {
    this.cardForm = fb.group({
      year: ['', Validators.required],
      month: ['', Validators.required],
      card_no: ['', Validators.required],
      holder: ['', Validators.required]
    });
    // カードの期限の範囲を生成
    this.yearsList = [];
    const year = Number(String(new Date().getFullYear()).slice(-2));
    for (let i = year; i < year + 20; i++) {
      this.yearsList.push(i);
    }
    this.monthsList = [];
    for (let i = 1; i <= 12; i++) {
      this.monthsList.push(i);
    }

    // カードの種類を判断
    this.cardType$ = this.cardForm.get('card_no').valueChanges.pipe(
      debounceTime(300),
      map(no => {
        const visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
        const master = new RegExp(
          '^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$'
        );
        if (visa.test(no.toString())) {
          return CardType.visa;
        } else if (master.test(no.toString())) {
          return CardType.master;
        } else {
          return null;
        }
      })
    );
    this.cardType$.subscribe(type => this.cardTypeChange.emit(type));

    // カード情報の更新を親コンポーネントに伝達
    this.cardForm.valueChanges
      .pipe(
        map(form => ({
          card_no: form.card_no,
          holder: form.holder,
          expire: `${form.year}${('0' + form.month).slice(-2)}`
        }))
      )
      .subscribe(card => this.valueChange.emit(card));

    // カード情報がvalidかどうか親コンポーネントに伝達
    combineLatest(this.cardForm.valueChanges, this.cardType$)
      .pipe(
        map(([_, cardType]) => this.cardForm.valid && !!cardType),
        distinctUntilChanged()
      )
      .subscribe(isValid => this.validityChange.emit(isValid));
  }
}
