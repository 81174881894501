
    <div class="field is-flex has-addons has-addons-left">
      <p class="control">
        <input
          class="input"
          type="number"
          min="1"
          [(ngModel)]="relativeDate.amount"
        />
      </p>
      <p class="control">
        <span class="select">
          <select [(ngModel)]="relativeDate.unit">
            <option value="YEAR">年前</option>
            <option value="MONTH">月前</option>
            <option value="DAY">日前</option>
            <option value="HOUR">時間前</option>
            <option value="MINUTE">分前</option>
          </select>
        </span>
      </p>
    </div>
  