<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-body">
        <h3 class="title is-4 has-text-centered">
          {{ 'PLAN.PAYMENT.TITLE' | translate }}
        </h3>
        <div class="columns">
          <div class="column is-half order">
            <div class="preview has-text-centered">
              <img src="/assets/img/logo_black.svg" />
              <p
                [translate]="'PLAN.PAYMENT.PLAN'"
                [translateParams]="{ name: plan?.name }"
                class="title"
              ></p>
              <p
                [translate]="'PLAN.LIST.LIMIT'"
                [translateParams]="{ sendlimit: plan?.sendlimit }"
                class="description"
              ></p>
            </div>

            <hr />
            <div class="price has-text-centered">
              <span class="unit">¥</span>
              <span class="value">{{ plan?.amount }}</span>
              <span class="range">/月（税別）</span>
            </div>
          </div>
          <div class="column is-half form">
            <div
              class="columns card-logo"
              [class.visa]="cardType == 'visa'"
              [class.master]="cardType == 'master'"
            >
              <div class="column is-half visa">
                <img src="/assets/img/cards/visa.svg" />
              </div>
              <div class="column is-half master">
                <img src="/assets/img/cards/master.svg" />
              </div>
            </div>
            <app-card-editor
              [(value)]="card"
              (validityChange)="isValid = $event"
              (cardTypeChange)="cardType = $event"
            ></app-card-editor>
            <button
              (click)="go()"
              [disabled]="!isValid"
              class="button is-fullwidth is-success adjust-margin"
            >
              {{ 'PLAN.PAYMENT.GO' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
