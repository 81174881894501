<div class="pb-2">
  <!-- Tabs -->
  <div class="tabs editor-tab">
    <ul role="tablist">
      <li
        [class.is-active]="activeTab === 'minutes'"
        *ngIf="!options.hideMinutesTab"
      >
        <a
          aria-controls="minutes"
          role="tab"
          data-toggle="tab"
          (click)="onTabFocus('minutes')"
        >
          毎分
        </a>
      </li>

      <li
        role="presentation"
        *ngIf="!options.hideHourlyTab"
        [class.is-active]="activeTab === 'hourly'"
      >
        <a
          aria-controls="hourly"
          role="tab"
          data-toggle="tab"
          (click)="onTabFocus('hourly')"
        >
          時間指定
        </a>
      </li>

      <li
        role="presentation"
        *ngIf="!options.hideDailyTab"
        [class.is-active]="activeTab === 'daily'"
      >
        <a
          aria-controls="daily"
          role="tab"
          data-toggle="tab"
          (click)="onTabFocus('daily')"
        >
          日付指定
        </a>
      </li>

      <li
        role="presentation"
        *ngIf="!options.hideWeeklyTab"
        [class.is-active]="activeTab === 'weekly'"
      >
        <a
          aria-controls="weekly"
          role="tab"
          data-toggle="tab"
          (click)="onTabFocus('weekly')"
        >
          曜日指定
        </a>
      </li>

      <li
        role="presentation"
        *ngIf="!options.hideMonthlyTab"
        [class.is-active]="activeTab === 'monthly'"
      >
        <a
          aria-controls="monthly"
          role="tab"
          data-toggle="tab"
          (click)="onTabFocus('monthly')"
        >
          毎月
        </a>
      </li>

      <li
        role="presentation"
        *ngIf="!options.hideYearlyTab"
        [class.is-active]="activeTab === 'yearly'"
      >
        <a
          aria-controls="yearly"
          role="tab"
          data-toggle="tab"
          (click)="onTabFocus('yearly')"
        >
          毎年
        </a>
      </li>

      <li
        role="presentation"
        *ngIf="!options.hideAdvancedTab"
        [class.is-active]="activeTab === 'advanced'"
      >
        <a
          aria-controls="advanced"
          role="tab"
          data-toggle="tab"
          (click)="onTabFocus('advanced')"
        >
          カスタム
        </a>
      </li>
    </ul>
  </div>

  <!-- Tab content -->
  <div class="cron-editor-container px-4 pt-0">
    <div class="row">
      <div class="col-xs-12">
        <div class="tab-content">
          <!-- Minutes-->
          <form
            class="tab-pane form"
            *ngIf="!options.hideMinutesTab && activeTab === 'minutes'"
          >
            <div class="field is-flex is-align-items-center is-flex-wrap-wrap">
              <app-cron-time-picker
                [formGroup]="minutesForm"
                [use24HourTime]="options.use24HourTime"
                [hideHours]="true"
                [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz"
              >
              </app-cron-time-picker>
              ごとに送信
            </div>
          </form>

          <!-- Hourly-->
          <div
            class="tab-pane form"
            *ngIf="!options.hideHourlyTab && activeTab === 'hourly'"
          >
            <p>①送信時を選択</p>
            <div class="field is-flex is-align-items-center is-flex-wrap-wrap">
              <div class="field hours">
                <div class="control columns" [formGroup]="hourlyForm">
                  <label
                    *ngFor="let hour of selectOptions.hours"
                    class="checkbox column is-2"
                  >
                    <input type="checkbox" [formControlName]="'h_' + hour" />
                    {{ hour }}
                  </label>
                  <div class="column is-2 is-offset-8">
                    <button
                      type="button"
                      class="button is-dark is-outlined"
                      (click)="setAllHours(true)"
                    >
                      全選択
                    </button>
                  </div>
                  <div class="column is-2">
                    <button
                      type="button"
                      class="button is-dark is-outlined"
                      (click)="setAllHours(false)"
                    >
                      全解除
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <p>②送信分を選択</p>
            <app-cron-time-picker
              [formGroup]="hourlyForm"
              [use24HourTime]="options.use24HourTime"
              [hideHours]="true"
              [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz"
            >
            </app-cron-time-picker>
          </div>

          <!-- Daily-->
          <div
            class="tab-pane form"
            *ngIf="!options.hideDailyTab && activeTab === 'daily'"
          >
            <p>①送信日を選択</p>
            <div class="field is-flex is-align-items-center is-flex-wrap-wrap">
              <div class="field dates">
                <div class="control columns" [formGroup]="dailyForm">
                  <label
                    *ngFor="let date of selectOptions.monthDays"
                    class="checkbox column is-12div7"
                  >
                    <input type="checkbox" [formControlName]="'d_' + date" />
                    {{ date }}
                  </label>
                  <div class="column is-2 is-offset-8">
                    <button
                      type="button"
                      class="button is-dark is-outlined"
                      (click)="setAllDates(true)"
                    >
                      全選択
                    </button>
                  </div>
                  <div class="column is-2">
                    <button
                      type="button"
                      class="button is-dark is-outlined"
                      (click)="setAllDates(false)"
                    >
                      全解除
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <p>②送信時刻を選択</p>
            <app-cron-time-picker
              [formGroup]="dailyForm"
              [use24HourTime]="options.use24HourTime"
              [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz"
            >
            </app-cron-time-picker>
          </div>

          <!-- Weekly-->
          <div
            class="tab-pane form"
            *ngIf="!options.hideWeeklyTab && activeTab === 'weekly'"
          >
            <p>①送信曜日を選択</p>
            <div class="field is-flex is-align-items-center is-flex-wrap-wrap">
              <div class="field weekdays">
                <div class="control columns" [formGroup]="weeklyForm">
                  <label class="checkbox column is-12div7">
                    <input type="checkbox" formControlName="MON" />
                    月
                  </label>
                  <label class="checkbox column is-12div7">
                    <input type="checkbox" formControlName="TUE" />
                    火
                  </label>
                  <label class="checkbox column is-12div7">
                    <input type="checkbox" formControlName="WED" />
                    水
                  </label>
                  <label class="checkbox column is-12div7">
                    <input type="checkbox" formControlName="THU" />
                    木
                  </label>
                  <label class="checkbox column is-12div7">
                    <input type="checkbox" formControlName="FRI" />
                    金
                  </label>
                  <label class="checkbox column is-12div7">
                    <input type="checkbox" formControlName="SAT" />
                    土
                  </label>
                  <label class="checkbox column is-12div7">
                    <input type="checkbox" formControlName="SUN" />
                    日
                  </label>
                </div>
              </div>
            </div>
            <p>②送信時刻を選択</p>
            <app-cron-time-picker
              [formGroup]="weeklyForm"
              [use24HourTime]="options.use24HourTime"
              [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz"
            >
            </app-cron-time-picker>
          </div>

          <!-- Monthly-->
          <div
            class="tab-pane form"
            *ngIf="!options.hideMonthlyTab && activeTab === 'monthly'"
            [formGroup]="monthlyForm"
          >
            <label
              class="field is-flex is-align-items-center is-flex-wrap-wrap"
            >
              <input
                type="radio"
                name="subTab"
                class="mr-2"
                value="specificDay"
                formControlName="subTab"
              />

              <div class="field has-addons mx-2">
                <div class="control" formGroupName="specificDay">
                  <div class="select">
                    <select formControlName="months">
                      <option
                        *ngFor="let month of selectOptions.months"
                        [value]="month"
                      >
                        {{ month }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="control">
                  <a class="button is-static">ヶ月</a>
                </div>
              </div>

              ごと

              <div class="field has-addons mx-2">
                <ng-container *ngIf="options.cronFlavor === 'quartz'">
                  <div class="control" formGroupName="specificDay">
                    <div class="select">
                      <select formControlName="day">
                        <option
                          *ngFor="
                            let monthDaysWithLast of selectOptions.monthDaysWithLasts
                          "
                          [value]="monthDaysWithLast"
                        >
                          {{ monthDaysWithLast }}
                        </option>
                      </select>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="options.cronFlavor === 'standard'">
                  <div class="control" formGroupName="specificDay">
                    <div class="select">
                      <select formControlName="day">
                        <option
                          *ngFor="
                            let monthDaysWithOutLast of selectOptions.monthDaysWithOutLasts
                          "
                          [value]="monthDaysWithOutLast"
                        >
                          {{ monthDaysWithOutLast }}
                        </option>
                      </select>
                    </div>
                  </div>
                </ng-container>
                <div class="control">
                  <a class="button is-static">日目</a>
                </div>
              </div>

              の

              <app-cron-time-picker
                [formGroup]="monthlyForm.controls.specificDay"
                [use24HourTime]="options.use24HourTime"
                [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz"
              >
              </app-cron-time-picker>

              に送信
            </label>
            <label
              class="field is-flex is-align-items-center is-flex-wrap-wrap"
            >
              <input
                type="radio"
                name="subTab"
                class="mr-2"
                value="specificWeekDay"
                formControlName="subTab"
              />

              <div class="field has-addons mx-2">
                <div class="control" formGroupName="specificWeekDay">
                  <div class="select">
                    <select formControlName="months">
                      <option
                        *ngFor="let month of selectOptions.months"
                        [value]="month"
                      >
                        {{ month }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="control">
                  <a class="button is-static">ヶ月</a>
                </div>
              </div>

              ごと

              <div class="field has-addons mx-2">
                <div class="control" formGroupName="specificWeekDay">
                  <div class="select">
                    <select formControlName="monthWeek">
                      <option
                        *ngFor="let monthWeek of selectOptions.monthWeeks"
                        [value]="monthWeek"
                      >
                        {{ monthWeekDisplay(monthWeek) }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="control" formGroupName="specificWeekDay">
                  <div class="select">
                    <select formControlName="day">
                      <option
                        *ngFor="let day of selectOptions.days"
                        [value]="day"
                      >
                        {{ dayDisplay(day) }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="control">
                  <a class="button is-static">曜日</a>
                </div>
              </div>

              の

              <app-cron-time-picker
                [formGroup]="monthlyForm.controls.specificWeekDay"
                [use24HourTime]="options.use24HourTime"
                [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz"
              >
              </app-cron-time-picker>

              に送信
            </label>
          </div>

          <!-- Yearly-->
          <div
            class="tab-pane form"
            *ngIf="!options.hideYearlyTab && activeTab === 'yearly'"
            [formGroup]="yearlyForm"
          >
            <label
              class="field is-flex is-align-items-center is-flex-wrap-wrap"
            >
              <input
                type="radio"
                name="subTab"
                class="mr-2"
                value="specificMonthDay"
                formControlName="subTab"
              />

              <div class="field has-addons mx-2">
                <div class="control" formGroupName="specificMonthDay">
                  <div class="select">
                    <select formControlName="month">
                      <option
                        *ngFor="let month of selectOptions.months"
                        [value]="month"
                      >
                        {{ month }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="control">
                  <a class="button is-static">月</a>
                </div>
                <div
                  class="control"
                  formGroupName="specificMonthDay"
                  *ngIf="options.cronFlavor === 'quartz'"
                >
                  <div class="select">
                    <select formControlName="day">
                      <option
                        *ngFor="
                          let monthDaysWithLast of selectOptions.monthDaysWithLasts
                        "
                        [value]="monthDaysWithLast"
                      >
                        {{ monthDaysWithLast }}
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  class="control"
                  formGroupName="specificMonthDay"
                  *ngIf="options.cronFlavor === 'standard'"
                >
                  <div class="select">
                    <select formControlName="day">
                      <option
                        *ngFor="
                          let monthDaysWithOutLast of selectOptions.monthDaysWithOutLasts
                        "
                        [value]="monthDaysWithOutLast"
                      >
                        {{ monthDaysWithOutLast }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="control">
                  <a class="button is-static">日</a>
                </div>
              </div>

              の

              <app-cron-time-picker
                [formGroup]="yearlyForm.controls.specificMonthDay"
                [use24HourTime]="options.use24HourTime"
                [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz"
              >
              </app-cron-time-picker>

              に送信
            </label>
            <label
              class="field is-flex is-align-items-center is-flex-wrap-wrap"
            >
              <input
                type="radio"
                name="subTab"
                class="mr-2"
                value="specificMonthWeek"
                formControlName="subTab"
              />

              <div class="field has-addons mx-2">
                <div class="control" formGroupName="specificMonthWeek">
                  <div class="select">
                    <select formControlName="month">
                      <option
                        *ngFor="let month of selectOptions.months"
                        [value]="month"
                      >
                        {{ month }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="control">
                  <a class="button is-static">月</a>
                </div>
                <div class="control" formGroupName="specificMonthWeek">
                  <div class="select">
                    <select formControlName="monthWeek">
                      <option
                        *ngFor="let monthWeek of selectOptions.monthWeeks"
                        [value]="monthWeek"
                      >
                        {{ monthWeekDisplay(monthWeek) }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="control" formGroupName="specificMonthWeek">
                  <div class="select">
                    <select formControlName="day">
                      <option
                        *ngFor="let day of selectOptions.days"
                        [value]="day"
                      >
                        {{ dayDisplay(day) }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="control">
                  <a class="button is-static">曜日</a>
                </div>
              </div>

              の

              <app-cron-time-picker
                [formGroup]="yearlyForm.controls.specificMonthWeek"
                [use24HourTime]="options.use24HourTime"
                [hideSeconds]="options.hideSeconds || !isCronFlavorQuartz"
              >
              </app-cron-time-picker>

              に送信
            </label>
          </div>

          <!-- Advanced-->
          <div
            class="tab-pane form"
            *ngIf="!options.hideAdvancedTab && activeTab === 'advanced'"
            [formGroup]="advancedForm"
          >
            カスタム（Cron表記）
            <div class="field">
              <div class="control">
                <input
                  matInput
                  type="text"
                  class="input"
                  formControlName="expression"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
