
    <div *ngIf="value" [class.message]="value.qualifier != 'NULL'">
      <div
        class="message-header"
        [appAstDraggable]="value"
        (onMoved)="removeFromParentNode.emit()"
      >
        <p>{{ value.qualifier }}</p>
        <div>
          <button
            class="delete"
            aria-label="delete"
            style="transform:rotate(45deg);"
            [withoutValue]="true"
            *ngIf="!value.element || !hideDelete"
            appAstAddElem
            (addElem)="putElem($event)"
          ></button>
          <button class="delete" (click)="removeFromParentNode.emit()"></button>
        </div>
      </div>
      <div
        [appAstDroppable]="!value.element"
        (onDrop)="dropHandler($event)"
        [class.message-body]="value.qualifier != 'NULL'"
      >
        <app-ast-node
          *ngIf="value.element"
          [value]="value.element"
          (removeFromParentNode)="deleteElem()"
        ></app-ast-node>
      </div>
    </div>
  