import { Component, Input, OnInit } from '@angular/core';
import { ASTRoot } from './../../../models/segments.model';
import { ASTNodeComponentBase } from '../ast-node.interface';

@Component({
  selector: 'app-ast-root',
  templateUrl: './ast-root.component.html'
})
export class ASTRootComponent {
  @Input()
  value: ASTRoot;
  public removeTheNode() {
    delete this.value.element;
  }
  public putElem(newElem) {
    this.value.element = newElem;
  }
}
