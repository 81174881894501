import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app works!';
  constructor(
    translate: TranslateService,
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics
  ) {
    angulartics2GoogleAnalytics.startTracking();
    translate.setDefaultLang('en');
    switch (navigator.language.substr(0, 2)) {
      case 'ja': {
        translate.use('ja');
        break;
      }
      default: {
        translate.use('en');
        break;
      }
    }
  }
}
