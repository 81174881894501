<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        通知を選択
      </div>
      <div>
        <ng-container *ngIf="list$ | async as list; else Loading">
          <ng-container *ngIf="list?.length > 0; else NoPush">
            <table class="table is-striped p7-table">
              <thead>
                <th class="thumbnail"></th>
                <th class="pushtitle">タイトル</th>
                <th class="time">送信日時</th>
                <th class="action"></th>
              </thead>
              <tbody>
                <tr *ngFor="let notification of list">
                  <td>
                    <figure class="image is-24x24">
                      <img src="{{ notification.icon }}" alt="icon" />
                    </figure>
                  </td>
                  <td>{{ notification.title }}</td>
                  <td>
                    {{
                      notification.created_date | dateParse | date: 'y/M/d, H:m'
                    }}
                  </td>
                  <td>
                    <a
                      (click)="select(notification.id)"
                      class="button is-success is-outlined p7-button"
                    >
                      <span class="icon">
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </span>
                      <span class="myapp-name">選択</span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="p7-pager has-text-right">
              <a
                (click)="prev()"
                class="button is-primary"
                [class.is-disabled]="page == 1"
              >
                <i class="fa fa-caret-left" aria-hidden="true"></i>
              </a>
              <span class="button view is-primary">{{ page }}/{{ max }}</span>
              <a
                (click)="next()"
                class="button is-primary"
                [class.is-disabled]="page == max"
              >
                <i class="fa fa-caret-right" aria-hidden="true"></i>
              </a>
            </div>
          </ng-container>
          <ng-template #NoPush>
            No Push Notifications
          </ng-template>
        </ng-container>
        <ng-template #Loading>
          <app-loading></app-loading>
        </ng-template>
      </div>
      <div class="content-footer">
        <div class="flex has-text-left">
          <button class="button is-danger p7-button" (click)="close()">
            キャンセル
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
