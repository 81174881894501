<article class="performance-detail">
  <nav class="breadcrumb has-background-white" aria-label="breadcrumbs">
    <ul>
      <li>
        <a [routerLink]="['../../index']">{{
          'ANALYTICS.PERFORMANCE.LABEL' | translate
        }}</a>
      </li>
      <li class="is-active">
        <a>{{ push?.created_date | dateParse | date: 'y/M/d, HH:mm' }}</a>
      </li>
    </ul>
  </nav>

  <div class="sub-nav">
    {{ 'ANALYTICS.PERFORMANCE.GENERAL' | translate }}
  </div>

  <table class="table is-striped is-fullwidth">
    <tbody>
      <tr>
        <td class="detail-param-name">
          {{ 'ANALYTICS.PERFORMANCE.SENT_AT' | translate }}
        </td>
        <td>{{ push?.created_date | dateParse | date: 'y/M/d, HH:mm' }}</td>
      </tr>
      <tr>
        <td class="detail-param-name">
          {{ 'ANALYTICS.PERFORMANCE.SENT_TO' | translate }}
        </td>
        <td>{{ push?.success_send_count || push?.subscribers }}</td>
      </tr>
    </tbody>
  </table>

  <div class="sub-nav">
    {{ 'ANALYTICS.PERFORMANCE.PUSH_INFO' | translate }}
  </div>

  <table class="table is-striped is-fullwidth">
    <tbody>
      <tr>
        <td class="detail-param-name">{{ 'PUSH.COMMON.TITLE' | translate }}</td>
        <td>{{ push?.title }}</td>
      </tr>
      <tr>
        <td class="detail-param-name">{{ 'PUSH.COMMON.BODY' | translate }}</td>
        <td>{{ push?.body }}</td>
      </tr>
      <tr>
        <td class="detail-param-name">{{ 'PUSH.COMMON.ICON' | translate }}</td>
        <td><img src="{{ push?.icon }}" width="78" /></td>
      </tr>
      <tr *ngIf="push?.image">
        <td class="detail-param-name">{{ 'PUSH.COMMON.IMAGE' | translate }}</td>
        <td><img [src]="push?.image" width="312" /></td>
      </tr>
      <tr>
        <td class="detail-param-name">
          {{ 'ANALYTICS.PERFORMANCE.LINK' | translate }}
        </td>
        <td>
          <a target="_blank" [href]="push?.url">{{ push?.url }}</a>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="sub-nav">
    {{ 'ANALYTICS.PERFORMANCE.CLICK_INFO' | translate }}
  </div>

  <table class="table is-striped is-fullwidth">
    <tbody>
      <tr>
        <td colspan="2">
          <canvas width="400" height="200"></canvas>
        </td>
      </tr>
      <tr>
        <td class="detail-param-name">{{ 'PUSH.COMMON.SENT' | translate }}</td>
        <td>{{ push?.success_send_count || push?.subscribers }}</td>
      </tr>
      <tr>
        <td class="detail-param-name">{{ 'PUSH.COMMON.CLICK' | translate }}</td>
        <td>{{ push?.clicked_count }}</td>
      </tr>
      <tr>
        <td class="detail-param-name">
          {{ 'PUSH.COMMON.UNCLICKED' | translate }}
        </td>
        <td>{{ push?.unclicked }}</td>
      </tr>
    </tbody>
  </table>
</article>
