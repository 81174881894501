
    <div class="modal is-active">
      <div class="modal-background" (click)="close()"></div>
      <div class="modal-content">
        <div class="box">
          <div class="content-header is-yellow">
            セグメントを削除
          </div>
          <div class="content">
            <p>削除したセグメントを復旧することができません。</p>
            <p>本当に削除してよろしいですか？</p>
          </div>
          <div class="content-footer">
            <div class="flex has-text-right">
              <button
                class="button is-info p7-button"
                (click)="close()"
                style="margin-right: .8rem;"
              >
                キャンセル
              </button>
              <button class="button is-danger p7-button" (click)="approve()">
                削除
              </button>
            </div>
          </div>
        </div>
      </div>
      <button class="modal-close" (click)="close()"></button>
    </div>
  