import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, forkJoin } from 'rxjs';
import { AppModel } from '../models/app.model';
import { AppService } from '../services/app/app.service';
import * as fromRoot from '../reducers';
import * as app from '../actions/app';
import { map, take, switchMap } from 'rxjs/operators';

@Injectable()
export class AppResolve implements Resolve<AppModel> {
  constructor(
    private appService: AppService,
    private store: Store<fromRoot.State>
  ) {}
  hasAppInStore(appno: string): Observable<AppModel> {
    return this.store.select(fromRoot.getAppsLoaded).pipe(
      map(apps => apps[appno]),
      take(1)
    );
  }
  hasAppInApi(appno: string): Observable<AppModel> {
    // storeにデータがない場合、show APIとセグメント利用可能APIの結果をマージしてstoreに保存する
    return this.appService.show(appno).pipe(
      switchMap(data =>
        forkJoin([of(data), this.appService.can_use_segment(appno)])
      ),
      map(([data, can_use_segment]) => {
        data.permissions.can_use_segment =
          can_use_segment.is_usable_segmentation_push;
        this.store.dispatch(new app.LoadAppCompleteAction({ appno, data }));
        return data;
      })
    );
  }
  hasApp(appno: string): Observable<AppModel> {
    return this.hasAppInStore(appno).pipe(
      switchMap(inStore => {
        if (inStore) {
          return of(inStore);
        }
        return this.hasAppInApi(appno);
      })
    );
  }
  resolve(route: ActivatedRouteSnapshot) {
    return this.hasApp(route.params['appno']);
  }
}
