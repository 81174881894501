
    <div class="columns is-tablet">
      <div class="column is-8 is-offset-2">
        <div class="container main-container">
          <div class="container-body">
            <h3 class="title is-4 has-text-centered">
              支払い情報の入力
            </h3>
            <form class="form" [formGroup]="form" (submit)="submit($event)">
              <app-payment-method-form
                formControlName="method"
              ></app-payment-method-form>
              <div class="has-text-right mt-4">
                <button
                  class="button is-success"
                  type="submit"
                  [class.is-loading]="loading"
                  [disabled]="form.invalid"
                >
                  設定
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  