import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as fromRoot from '../../reducers';

@Injectable()
export class StateService {
  private state: fromRoot.State;
  constructor(private store$: Store<fromRoot.State>) {
    store$.subscribe(state => (this.state = state));
  }
  public get() {
    return this.state;
  }
  public getAppno() {
    return this.state.app.selected;
  }
}
