import { Component, OnDestroy } from '@angular/core';
import { AppService } from '../../../../services/app/app.service';
import * as fromRoot from '../../../../reducers';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as app from '../../../../actions/app';
import { Notification } from '../../../../services/notification/notification.service';

interface RSSError {
  error: string;
  reason: string;
}

@Component({
  selector: 'app-rss',
  templateUrl: './rss.component.html',
  styleUrls: ['./rss.component.scss']
})
export class RssComponent implements OnDestroy {
  public link: string;
  public appno: string;
  public isSet: boolean;
  public error?: RSSError;

  private storeSubscription: Subscription;
  constructor(
    private appService: AppService,
    private store: Store<fromRoot.State>,
    private notification: Notification
  ) {
    this.storeSubscription = this.store
      .select(fromRoot.getAppSelected)
      .subscribe(selectedApp => {
        this.appno = selectedApp.appno;
        this.link = selectedApp.rss;
        this.isSet = !!selectedApp.rss;
      });
  }
  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
  }
  save() {
    this.appService.rss_set(this.link).subscribe(
      success => {
        this.error = null;
        this.notification.open(success.success, 'success');
        this.store.dispatch(new app.LoadAppAction(this.appno));
      },
      response => {
        this.error = response.error;
        this.notification.open(this.error.error, 'warning', 3);
      }
    );
  }
  reset() {
    this.appService.rss_reset().subscribe(
      success => {
        this.notification.open(success.success, 'success');
        this.store.dispatch(new app.LoadAppAction(this.appno));
      },
      response => {
        this.notification.open(response.error.error, 'warning', 3);
      }
    );
  }
}
