import {
  Injectable,
  ViewContainerRef,
  ComponentFactoryResolver,
  ReflectiveInjector,
  ComponentRef
} from '@angular/core';
import { NotificationComponent } from './notification.component';

export type NotificationTypes =
  | 'primary'
  | 'info'
  | 'success'
  | 'warning'
  | 'danger';

export class NotificationContext {
  public type: NotificationTypes;
  constructor(
    private _resolve: Function,
    private _reject: Function,
    public text: string,
    type: string,
    public timeout: number
  ) {
    this.type = <NotificationTypes>type;
  }
  resolve(val?: any) {
    this._resolve(val);
  }
  reject(reason?: any) {
    this._reject(reason);
  }
}

@Injectable()
export class Notification {
  public vcr: ViewContainerRef;
  public count = 0;
  constructor(private cfr: ComponentFactoryResolver) {}
  set(p: ViewContainerRef) {
    this.vcr = p;
  }
  open<T>(text: string, type: NotificationTypes = 'primary', timeout = 5) {
    let cr: ComponentRef<any>;
    return new Promise<T>((resolve, reject) => {
      const cf = this.cfr.resolveComponentFactory(NotificationComponent);
      const _resolve = () => {
        if (cr) {
          cr.destroy();
          resolve();
          this.count--;
        }
      };
      const _reject = () => {
        if (cr) {
          cr.destroy();
          reject();
          this.count--;
        }
      };
      const bindings = ReflectiveInjector.resolve([
        {
          provide: NotificationContext,
          useValue: new NotificationContext(
            _resolve,
            _reject,
            text,
            type,
            timeout
          )
        }
      ]);
      const ctxInjector = this.vcr.parentInjector;
      const injector = ReflectiveInjector.fromResolvedProviders(
        bindings,
        ctxInjector
      );

      cr = this.vcr.createComponent(cf, this.vcr.length, injector);
      this.vcr.element.nativeElement.appendChild(cr.location.nativeElement);
      this.count++;
    });
  }
}
