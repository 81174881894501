import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Services for
 * + Sending Token to the Email given in /provisional page
 * + Register User with Email, Password and Token
 */

@Injectable()
export class ProvisionalService {
  private prefix = '/api/v1';
  constructor(private http: HttpClient) {}
  provisional(email: string): Observable<any> {
    return this.http.post<any>(`${this.prefix}/provisional`, { email });
  }
  signup(payload): Observable<any> {
    return this.http.post<any>(`${this.prefix}/signup`, payload);
  }
  forget(email: string) {
    return this.http.post<any>(`${this.prefix}/pre_forgot`, { email });
  }
  reset(email: string, token: string, new_password: string) {
    return this.http.post<any>(`${this.prefix}/forgot`, {
      email,
      token,
      new_password
    });
  }
}
