<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{'PUSH.STANDARD.DETAIL'|translate}}
      </div>
      <div class="content">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64">
              <img src="{{noti.icon}}" />
            </p>
          </figure>
          <div class="media-content">
            <div class="control">
              <p class="label">{{'PUSH.COMMON.CREATED'|translate}}</p>
              <p>{{noti.created_date | dateParse | date:'y/M/d, H:m'}}</p>
            </div>
            <div class="control">
              <p class="label">{{'PUSH.COMMON.TITLE'|translate}}</p>
              <p>{{noti.title}}</p>
            </div>
            <div class="control">
              <p class="label">{{'PUSH.COMMON.URL'|translate}}</p>
              <p><a href="{{noti.url}}" target="_blank">{{noti.url}}</a></p>
            </div>
            <div class="control">
              <p class="label">{{'PUSH.COMMON.BODY'|translate}}</p>
              <p>{{noti.body}}</p>
            </div>
            <div class="control" *ngIf="noti.image">
              <p class="label">{{'PUSH.COMMON.IMAGE' | translate }}</p>
              <p><img [src]="noti.image" /></p>
            </div>
            <div class="control columns">
              <div class="column is-6">
                <p class="label">
                  {{'PUSH.COMMON.SHOW_UNTIL_CLICKED' | translate}}
                </p>
                <p>
                  {{(noti.disappear_instantly ? 'COMMON.NO' : 'COMMON.YES') |
                  translate}}
                </p>
              </div>
              <div class="column is-6">
                <p class="label">
                  {{'PUSH.COMMON.ENABLE_IOS_SOUND' | translate}}
                </p>
                <p>
                  {{(noti.enable_ios_sound ? 'COMMON.YES' : 'COMMON.NO') |
                  translate}}
                </p>
              </div>
            </div>
            <ng-container [ngSwitch]="queryType">
              <div class="control" *ngSwitchCase="'segment'">
                <p class="label">セグメント配信</p>
                <p>{{noti.query}}</p>
              </div>
              <div class="control columns" *ngSwitchCase="'parameter'">
                <div class="column is-4">
                  <p class="label">{{'PUSH.COMMON.MODE'|translate}}</p>
                  <p>{{noti.query['mode']}}</p>
                </div>
                <div class="column is-8">
                  <p class="label">{{'PUSH.COMMON.PARAMETERS'|translate}}</p>
                  <p>{{noti.query['params'].join(', ')}}</p>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="isGNEX$ | async">
              <div class="control">
                <p class="label">表示数</p>
                <p>{{noti.displayed_count | number}}</p>
              </div>
            </ng-container>
            <div class="control columns">
              <div class="column is-4">
                <p class="label">{{'PUSH.COMMON.SENT'|translate}}</p>
                <p>{{noti.success_send_count || noti.subscribers | number}}</p>
              </div>
              <div class="column is-8">
                <p class="label">{{'PUSH.COMMON.CLICK'|translate}}</p>
                <p>{{noti.clicked_count | number}}</p>
              </div>
            </div>
          </div>
        </article>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button
            class="button is-danger mr-3"
            (click)="hide()"
            [appEnableFor]="'operator'"
            *ngIf="!noti['disable_hide']"
          >
            {{'PUSH.STANDARD.HIDE.BUTTON'|translate}}
          </button>
          <button class="button is-dark" (click)="close()">
            {{'PUSH.COMMON.CLOSE'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="modal-close" (click)="close()"></button>
</div>
