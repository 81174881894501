import { NotificationModel } from './../../../../../models/notification.model';
import {
  Modal,
  ModalContext
} from './../../../../../services/modal/modal.service';
import { Component } from '@angular/core';
import { HidePushConfirmComponent } from './hide-push-confirm/hide-push-confirm';
import { Observable } from 'rxjs';
import { AuthService } from '../../../../../services/auth/auth.service';
import { filter, map } from 'rxjs/operators';

@Component({
  templateUrl: './normal-push-detail.html',
  styleUrls: ['./normal-push-detail.scss']
})
export class NormalPushDetailComponent {
  public noti: NotificationModel;
  public isGNEX$: Observable<boolean>;
  constructor(
    private modalCtx: ModalContext,
    private modal: Modal,
    private authService: AuthService
  ) {
    this.noti = modalCtx.payload;
    // 一時的な措置なのでコンポーネントに書いてしまう
    this.isGNEX$ = this.authService.profile$.pipe(
      map(user => user.email),
      filter(email => !!email),
      map(
        email => email.endsWith('gnex.jp') || email.endsWith('globalnet-ex.com')
      )
    );
  }
  public get queryType(): 'none' | 'segment' | 'parameter' | 'unknown' {
    if (!this.noti.query) {
      return 'none';
    }
    if (typeof this.noti.query === 'object') {
      return 'parameter';
    }
    if (typeof this.noti.query === 'string') {
      return 'segment';
    }
    return 'unknown';
  }
  public close() {
    this.modalCtx.reject();
  }
  public hide() {
    this.modal.open(HidePushConfirmComponent, this.noti.id).then(resolved => {
      this.modalCtx.resolve();
    });
  }
}
