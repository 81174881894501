import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public email: string;
  public password: string;
  public error: string | null;
  constructor(private authService: AuthService, private router: Router) {}
  ngOnInit() {}
  public submit() {
    this.error = null;
    this.authService.login(this.email, this.password).subscribe(
      () => {
        this.router.navigate(['home']);
      },
      (error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.error = 'ログインに失敗しました';
          return;
        }
        this.error = 'エラーが発生しました';
      }
    );
  }
}
