import { Router, ActivatedRoute } from '@angular/router';
import { PlanModel } from './../../../../models/plan.model';
import { FormBuilder } from '@angular/forms';
import { SessionService } from './../../../../services/session/session.service';
import { Component } from '@angular/core';
import { CardType, CardModel } from '../../../../models/card.model';

@Component({
  selector: 'app-plan-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class PlanCardComponent {
  public plan: PlanModel;
  public card: CardModel;
  public cardType: CardType;
  public isValid: boolean;
  constructor(
    private session: SessionService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.plan = session.get('plan');
    if (!this.plan) {
      this.router.navigate(['../list'], { relativeTo: this.route });
    }
  }
  public go() {
    this.session.set('card', this.card);
    this.router.navigate(['../confirm'], { relativeTo: this.route });
  }
}
