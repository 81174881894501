import { Notification } from './../../../services/notification/notification.service';
import { AbtestDetailComponent } from './abtest-detail/abtest-detail.component';
import { NewAbtestModalComponent } from './new-abtest-modal/new-abtest-modal.component';
import { Modal } from './../../../services/modal/modal.service';
import { AppService } from './../../../services/app/app.service';
import {
  ABTestModel,
  NotificationModel
} from './../../../models/notification.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-abtest',
  templateUrl: './abtest.component.html',
  styleUrls: ['./abtest.component.scss']
})
export class AbtestComponent implements OnInit {
  public page = 1;
  public max: number;
  public abtests: ABTestModel[];

  constructor(
    private appService: AppService,
    private modal: Modal,
    private notification: Notification
  ) {}

  update() {
    if (this.page < 1) {
      return;
    } else {
      this.abtests = null;
      this.appService.get_abpushs((this.page - 1) * 10).subscribe(result => {
        this.abtests = result.abpushs;
        this.max = Math.ceil(result.count / 10);
      });
    }
  }
  ngOnInit() {
    this.update();
  }
  create() {
    this.modal
      .open(NewAbtestModalComponent)
      .then(success => {
        this.page = 1;
        this.update();
      })
      .catch(() => {});
  }
  public sumSubscribers(notifications: NotificationModel[]) {
    return notifications.reduce((prev, next) => {
      if (next.success_send_count) {
        return prev + next.success_send_count;
      } else if (next.subscribers) {
        return prev + next.subscribers;
      } else {
        return prev;
      }
    }, 0);
  }
  public sumClicks(notifications: NotificationModel[]) {
    return notifications.reduce((prev, next) => {
      if (next.clicked_count) {
        return prev + next.clicked_count;
      } else {
        return prev;
      }
    }, 0);
  }
  public detail(test) {
    this.modal
      .open(AbtestDetailComponent, { test })
      .then(() => {
        this.update();
      })
      .catch(() => {});
  }

  next() {
    if (this.max != null && this.page + 1 <= this.max) {
      this.page++;
      this.update();
    }
  }
  prev() {
    if (this.page - 1 > 0) {
      this.page--;
      this.update();
    }
  }
}
