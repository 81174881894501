import { environment } from './../../environments/environment';
import * as Raven from 'raven-js';
import { ErrorHandler } from '@angular/core';

export class RavenErrorHandler extends ErrorHandler {
  constructor() {
    super();
  }
  handleError(err: any): void {
    if (environment.production) {
      Raven.captureException(err.originalError || err);
    } else {
      super.handleError(err);
    }
  }
}
