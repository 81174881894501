import {
  Injectable,
  ViewContainerRef,
  Component,
  ViewChild,
  Directive,
  AfterViewInit
} from '@angular/core';
import { Modal } from './modal.service';

@Directive({
  selector: '[appModalInner]'
})
export class ModalInnerDirective {
  constructor(public vcr: ViewContainerRef) {}
}

@Component({
  selector: 'app-modal-entry',
  template: `
    <div>
      <div appModalInner></div>
    </div>
  `,
  providers: [ModalInnerDirective]
})
export class ModalEntryComponent implements AfterViewInit {
  @ViewChild(ModalInnerDirective, { static: true })
  private inner: ModalInnerDirective;
  constructor(public modal: Modal) {}
  ngAfterViewInit() {
    this.modal.set(this.inner.vcr);
  }
}
