<app-navbar></app-navbar>
<div class="main-nav independent">
  <div class="container">
    {{ 'CONTACT.LABEL' | translate }}
  </div>
</div>
<div class="columns is-tablet">
  <div class="column is-6 is-offset-3">
    <div class="container main-container">
      <div class="container-body">
        <app-contact
          (contactChange)="contact = $event"
          (statusChange)="valid = $event"
        ></app-contact>
        <div class="has-text-centered">
          <button
            type="submit"
            [disabled]="!valid"
            [class.is-loading]="sending"
            class="button p7-button is-success has-text-centered"
            (click)="submit()"
          >
            {{ 'CONTACT.SUBMIT' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
