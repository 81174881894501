import { ASTOpened } from './../../../models/segments.model';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ASTNodeComponentBase } from '../ast-node.interface';
import { Modal } from '../../../services/modal/modal.service';
import { PushIdSelectorComponent } from '../components/pushid-selector/pushid-selector.component';

@Component({
  selector: 'app-ast-opened',
  template: `
    <div class="message" *ngIf="value">
      <div
        class="message-header"
        [appAstDraggable]="value"
        (onMoved)="removeFromParentNode.emit()"
      >
        <p>開封済み通知</p>
        <a
          *ngIf="!hideDelete"
          class="delete"
          (click)="removeFromParentNode.emit()"
        ></a>
      </div>
      <div class="message-body">
        <p style="margin-bottom: 0.6rem;">
          pushid: {{ value.pushid || '未選択' }}
        </p>
        <button class="button" (click)="openSelector()">選択</button>
      </div>
    </div>
  `
})
export class ASTOpenedComponent implements ASTNodeComponentBase<ASTOpened> {
  @Input()
  hideDelete: boolean;
  @Input()
  value: ASTOpened;
  @Output()
  removeFromParentNode: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  onMoved: EventEmitter<void> = new EventEmitter<void>();
  constructor(private modal: Modal) {}
  public openSelector() {
    this.modal
      .open(PushIdSelectorComponent)
      .then((pushid: string) => (this.value.pushid = pushid))
      .catch(() => {});
  }
}
