
    <div class="control">
      <label class="label">URLのマッチ方法</label>
      <label class="radio">
        <input
          type="radio"
          [name]="randomId"
          [ngModel]="kind"
          (change)="update('JUST')"
          value="JUST"
        />
        完全一致
      </label>
      <label class="radio">
        <input
          type="radio"
          [name]="randomId"
          [ngModel]="kind"
          (change)="update('PREFIX')"
          value="PREFIX"
        />
        前方一致
      </label>
    </div>
    <div class="control">
      <label class="label mt-3" placeholder="http://example.com/campaign"
        >URL</label
      >
      <input
        class="input"
        type="url"
        placeholder="http://example.com/news"
        [ngModel]="urlQuery.url"
        (ngModelChange)="changeUrl($event)"
      />
    </div>
    <ng-container [ngSwitch]="urlQuery.className">
      <div *ngSwitchCase="'ASTUrlJust'">
        <p>上記URLに完全一致する記録を検索します</p>
      </div>
      <div *ngSwitchCase="'ASTUrlPrefix'">
        <p>上記URLに前方一致する記録を検索します</p>
      </div>
    </ng-container>
  