import { DropEvent } from './../dd-handler/droppable.directive';
import { ASTInnerNode } from './../../../models/segments.model';
import { ASTNodeComponentBase } from '../ast-node.interface';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  OnInit
} from '@angular/core';

function genUniqueClassName() {
  return (
    'ast-node-columns-' +
    new Date().getTime().toString(16) +
    Math.floor(1000 * Math.random()).toString(16)
  );
}

interface XY {
  x: number;
  y: number;
}
interface SingleElement {
  element: Element;
  x: number;
  y: number;
  index: number;
}

@Component({
  selector: 'app-ast-inner-node',
  template: `
    <div
      class="app-ast-inner-node message"
      appAstDroppable
      (onDrop)="dropHandler($event)"
      *ngIf="value"
    >
      <div
        class="message-header"
        [appAstDraggable]="value"
        (onMoved)="removeFromParentNode.emit()"
      >
        <p>Operator:{{ value.operator }}</p>
        <div>
          <button
            *ngIf="!hideDelete"
            class="delete"
            aria-label="delete"
            style="transform:rotate(45deg);"
            appAstAddElem
            [withoutValue]="true"
            (addElem)="pushElem($event)"
          ></button>
          <button
            class="delete"
            aria-label="delete"
            (click)="removeFromParentNode.emit()"
          ></button>
        </div>
      </div>
      <div class="message-body">
        <div class="columns is-multiline {{ uniqueClassName }}">
          <div
            class="column is-narrow ast-elem-wrap"
            *ngFor="let elem of value.children; let i = index"
          >
            <app-ast-node
              [value]="elem"
              (removeFromParentNode)="handleDelete(i)"
            ></app-ast-node>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .column {
        max-width: 100%;
      }
      .ast-elem-wrap {
        max-width: 100%;
      }
    `
  ],
  styleUrls: ['../ast.scss']
})
export class ASTInnerNodeComponent
  implements OnInit, ASTNodeComponentBase<ASTInnerNode> {
  @Input()
  hideDelete: boolean;
  @Input()
  value: ASTInnerNode;
  @Output()
  removeFromParentNode: EventEmitter<void> = new EventEmitter<void>();
  private _el: Element;
  public uniqueClassName: string; // このelementだけを識別するのに使う
  constructor(private el: ElementRef) {
    this.uniqueClassName = genUniqueClassName();
  }
  ngOnInit() {
    this._el = this.el.nativeElement;
  }
  handleDelete(i) {
    this.value.children.splice(i, 1);
  }
  dropHandler(e: DropEvent) {
    // 並び順を考慮してDropする
    const target: XY = {
      x: e.event.clientX,
      y: e.event.clientY
    };

    // 現在存在する各要素
    const elemsList = this._el.querySelectorAll(
      `.columns.${this.uniqueClassName} > .column`
    );
    const elemsArray: Array<Element> = [].slice.call(elemsList);

    const elems: Array<SingleElement> = elemsArray.map((element, i) => {
      const rect = element.getBoundingClientRect();
      return {
        element,
        x: (rect.left + rect.right) / 2,
        y: rect.top,
        index: i
      };
    });

    if (elems.length === 0) {
      // elemsがない場合
      this.pushElem(e.data); // 追加して終わり。順番関係ない
      return;
    }

    let rowY: number = elems[0].y;
    elems.forEach(({ y }) => {
      if (target.y > y) {
        rowY = y;
      }
    });
    const targetRow = elems.filter(({ y }) => y === rowY);

    if (targetRow.length === 0) {
      this.pushElem(e.data);
      return;
    }

    let t: SingleElement = null;
    targetRow.forEach(elem => {
      if (target.x < elem.x) {
        t = elem;
      }
    });
    if (!t) {
      this.pushElem(e.data);
      return;
    }

    setTimeout(() => {
      this.value.children.splice(--t.index, 0, e.data);
    }, 100);
  }
  public pushElem(newElem) {
    this.value.children.push(newElem);
  }
}
