import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  retryWhen,
  concat,
  take,
  mergeMap,
  delay,
  withLatestFrom,
  map,
  flatMap,
  tap
} from 'rxjs/operators';
import { throwError, of } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthHelperService } from '../services/auth-helper/auth-helper.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private authHelper: AuthHelperService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !req.url.includes(environment.API) ||
      req.url.endsWith('/user_auth_token/create')
    ) {
      // API以外の通信
      return next.handle(req);
    }
    let request$ = of(req);
    if (!req.headers.get('Authorization')) {
      // 上書きできるように、Authorizationがセットされていない場合に限ってUserTokenをセットする
      request$ = request$.pipe(
        withLatestFrom(this.authHelper.token$),
        map(([request, token]) =>
          request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`)
          })
        )
      );
    }
    return request$.pipe(
      flatMap(request => next.handle(request)),
      tap(null, error => {
        if (error.status === 403) {
          this.authHelper.tokenExpired();
        }
      }),
      retryWhen(errors => {
        return errors.pipe(
          mergeMap((er: HttpErrorResponse) => {
            if (er.status === 502) {
              return of(er.status).pipe(delay(1000));
            }
            return throwError(er);
          }),
          take(3),
          concat(throwError({ error: 'Error' }))
        );
      })
    );
  }
}
