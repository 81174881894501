<section class="hero pre is-fullheight">
  <div class="hero-body">
    <div class="container has-text-centered">
      <img class="logo" src="/assets/img/logo_black.svg" />
      <div class="inner card">
        <div class="card-content has-text-left">
          <div class="notification is-danger" *ngIf="error">
            {{ error }}
          </div>
          <form (submit)="submit()">
            <div class="field">
              <label class="label">{{ 'COMMON.EMAIL' | translate }}</label>
              <p class="control has-icons-left">
                <input
                  class="input"
                  type="email"
                  required
                  [(ngModel)]="email"
                  name="email"
                  placeholder="mail@example.com"
                />
                <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
              </p>
            </div>
            <div class="field">
              <label class="label">{{ 'COMMON.PASSWORD' | translate }}</label>
              <p class="control has-icons-left">
                <input
                  class="input"
                  type="password"
                  required
                  [(ngModel)]="password"
                  name="password"
                  placeholder="Password"
                />
                <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
              </p>
            </div>
            <div class="field">
              <label class="label">{{ 'RESET.CONFIRM' | translate }}</label>
              <p class="control has-icons-left">
                <input
                  class="input"
                  type="password"
                  required
                  [(ngModel)]="confirm"
                  name="password"
                  placeholder="Password"
                />
                <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
              </p>
            </div>
            <div class="field is-grouped is-grouped-centered">
              <p class="control">
                <button class="button is-primary" type="submit">
                  {{ 'RESET.BUTTON' | translate }}
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
