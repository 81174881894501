import { getAppSelected } from './../../../../../reducers/index';
import { Notification } from './../../../../../services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './../../../../../services/app/app.service';
import { generateUnit } from './../../../../../helpers/analytics';
import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  AfterViewInit,
  Input
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, Observable, combineLatest, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import * as fromRoot from '../../../../../reducers';
import * as app from '../../../../../actions/app';
import { AppModel } from '../../../../../models/app.model';
import { NotificationModel } from '../../../../../models/notification.model';

import { ModifierBehaviorSubject } from './../../../../../components/range-picker/range-picker.component';

import { Chart } from 'chart.js';
import * as Moment from 'moment';

import {
  map,
  take,
  debounceTime,
  switchMap,
  filter,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import * as Flatpicker from 'flatpickr';

@Component({
  selector: 'app-performance-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class AnalyticsPerformanceIndexComponent
  implements OnInit, AfterViewInit {
  private appno: string;
  private _el: HTMLElement;
  private appSubscription: Subscription;

  public chart: any;
  public $list: any;

  public data: any;
  public max: number;
  public page = 1;

  public scope$ = new BehaviorSubject<string>('day');

  public isOpenScopeDropdown = false;

  public notification: Observable<any>;
  public list$: Observable<NotificationModel[] | false>;

  public isLoading = true;

  // For pickers
  public startDate: ModifierBehaviorSubject<Moment.Moment>;
  public endDate: ModifierBehaviorSubject<Moment.Moment>;

  constructor(
    private store$: Store<fromRoot.State>,
    el: ElementRef,
    private api: AppService,
    private translate: TranslateService,
    private notificationService: Notification
  ) {
    this._el = el.nativeElement;

    this.appSubscription = store$
      .select(fromRoot.getAppSelectedId)
      .subscribe(appno => (this.appno = appno));
    this.list$ = store$.select(fromRoot.getTheNotifications);
    this.notification = store$.select(fromRoot.getAppSelectedNotifications);
    this.notification.subscribe(notiState => {
      if (!notiState) {
        return;
      }
      this.page = notiState.current;
      this.max = Math.ceil(notiState.count / 20);
    });

    this.store$
      .select(getAppSelected)
      .pipe(
        take(1),
        map(x => Moment(x.created_date))
      )
      .subscribe(createdAt => {
        const now = Moment();
        const weekAgo = Moment(now)
          .subtract(7, 'day')
          .add(1, 'day');
        this.startDate = new ModifierBehaviorSubject<Moment.Moment>(
          null,
          start => {
            // startがアプリケーション作成日よりも前の場合はアプリケーション作成日を使用する
            return Moment.max(start.startOf('day'), createdAt);
          }
        );
        this.endDate = new ModifierBehaviorSubject<Moment.Moment>(
          null,
          (end: Moment.Moment) => {
            // endは現在よりも後を取らない
            return Moment.min(end.endOf('day'), Moment());
          }
        );
        this.startDate.next(weekAgo);
        this.endDate.next(now);
      });
  }

  ngOnInit() {
    this.update(this.page);
    combineLatest(this.startDate, this.endDate, this.scope$)
      .pipe(
        debounceTime(100),
        tap(() => (this.isLoading = true)),
        switchMap(params => {
          const [start, end, scope] = params;
          const unit = generateUnit(scope);
          let n = 0;
          for (let i = Moment(start); i.isBefore(end); i.add(unit)) {
            n++;
          }
          if (n === 0) {
            n++; // 1件は読む
          }
          if (n >= 10000) {
            this.translate
              .get('ANALYTICS.COMMON.TOO_MANY_TO_LOAD')
              .pipe(take(1))
              .subscribe((m: string) =>
                this.notificationService.open(m, 'warning')
              );
            this.isLoading = false;
            return of(false);
          }
          return this.api.analytics_push_info_with_scope(
            start.format('YYYY-MM-DDTHH'),
            scope,
            n
          );
        }),
        filter(x => !!x),
        withLatestFrom(
          this.translate.get('ANALYTICS.PERFORMANCE.SENT_COUNT'),
          this.translate.get('ANALYTICS.PERFORMANCE.CLICKED_COUNT')
        ),
        map(([res, SENT, CLICKED]) => {
          const labels: string[] = [];
          const subscribers: number[] = [];
          const clicks: number[] = [];
          const format =
            this.scope$.getValue() === 'hour' ? 'MM-DD HH:mm' : 'YYYY-MM-DD';
          res.counts.forEach(count => {
            labels.push(Moment(count.datetime).format(format));
            subscribers.push(count.sent_push_count);
            clicks.push(count.clicked_count);
          });
          return {
            labels,
            datasets: [
              {
                label: SENT,
                backgroundColor: '#0094D1',
                borderWidth: 0,
                data: subscribers
              },
              {
                label: CLICKED,
                backgroundColor: '#D1004F',
                borderWidth: 0,
                data: clicks
              }
            ]
          };
        })
      )
      .subscribe(data => {
        if (!this.chart) {
          return;
        }
        this.chart.data.labels = data.labels;
        this.chart.data.datasets = data.datasets;
        this.chart.update();
        this.isLoading = false;
      });
  }

  ngAfterViewInit() {
    const canvas = this._el.querySelector('canvas');
    const sizes = {
      width: this._el.querySelector('.graph-canvas-wrap').clientWidth,
      height: 370
    };
    canvas.width = sizes.width;
    const context = canvas.getContext('2d');

    this.chart = new Chart(context, {
      type: 'bar',
      options: {
        responsive: false,
        legend: {
          display: false
        }
      }
    });
  }

  changeScope(scope: string) {
    this.scope$.next(scope);
  }
  update(render) {
    if (render < 1 || this.max < render) {
      return;
    } else {
      this.store$.dispatch(
        new app.LoadNotificationsAction({
          page: render
        })
      );
    }
  }
  next() {
    this.update(this.page + 1);
  }
  prev() {
    this.update(this.page - 1);
  }
}
