
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <div class="content-header is-yellow">
            {{ confirmationCtx.title }}
          </div>
          <div class="content">
            <p>{{ confirmationCtx.body }}</p>
          </div>
          <div class="content-footer">
            <div class="flex has-text-right">
              <button
                class="button mr-2"
                [ngClass]="confirmationCtx.cancelClass"
                type="button"
                (click)="cancel()"
              >
                {{ confirmationCtx.cancelLabel }}
              </button>
              <button
                class="button"
                [ngClass]="confirmationCtx.confirmClass"
                (click)="confirm()"
              >
                {{ confirmationCtx.confirmLabel }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  