import { TranslateService } from '@ngx-translate/core';
import { PushComponent } from './../push.component';
import { DeleteReservationConfirmComponent } from './delete-reservation-confirm/delete-reservation-confirm.component';
import { Notification } from './../../../../services/notification/notification.service';
import { AppService } from './../../../../services/app/app.service';
import { EditPushModalComponent } from './../edit-push-modal/edit-push-modal.component';
import {
  Modal,
  ModalContext
} from './../../../../services/modal/modal.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as app from '../../../../actions/app';
import { AppModel } from '../../../../models/app.model';
import { NotificationModel } from '../../../../models/notification.model';
import { PushModalV2Component } from '../push-modal-v2/push-modal-v2.component';

@Component({
  selector: 'app-reserved-push-history',
  templateUrl: './reserved-push-history.component.html',
  styleUrls: ['./reserved-push-history.component.scss']
})
export class ReservedPushHistoryComponent implements OnInit {
  public list$;
  constructor(
    private store: Store<fromRoot.State>,
    private modal: Modal,
    private appService: AppService,
    private notiService: Notification,
    private pushComponent: PushComponent,
    private translate: TranslateService
  ) {
    this.store.dispatch(new app.LoadReservationsAction());
    this.list$ = this.store.select(fromRoot.getAppSelectedReservations);
  }

  ngOnInit() {}
  edit(notification) {
    this.modal
      // .open(EditPushModalComponent, { notification })
      .open(PushModalV2Component, { notification })
      .then(() => {
        // updated so update the view
        this.store.dispatch(new app.LoadReservationsAction());
      })
      .catch(() => {});
  }
  delete(notification) {
    this.modal
      .open(DeleteReservationConfirmComponent)
      .then(() => {
        this.appService.delete_reservation(notification).subscribe(success => {
          this.translate
            .get('PUSH.RESERVED.DELETE_SUCCESS')
            .subscribe(m => this.notiService.open(m, 'success'));
          this.store.dispatch(new app.LoadReservationsAction());
        });
      })
      .catch(() => {});
  }
  compose() {
    this.pushComponent.compose();
  }
}
