import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../../../../services/app/app.service';
import { Notification } from '../../../../services/notification/notification.service';
import { PaymentMethodFormComponent } from '../../../../components/payment-method-form/payment-method-form.component';
import { PlanModel } from '../../../../models/plan.model';
import { SessionService } from '../../../../services/session/session.service';
import { finalize } from 'rxjs/operators';

@Component({
  template: `
    <div class="columns is-tablet">
      <div class="column is-8 is-offset-2">
        <div class="container main-container">
          <div class="container-body">
            <h3 class="title is-4 has-text-centered">
              支払い情報の入力
            </h3>
            <form class="form" [formGroup]="form" (submit)="submit($event)">
              <app-payment-method-form
                formControlName="method"
              ></app-payment-method-form>
              <div class="has-text-right mt-4">
                <button
                  class="button is-success"
                  type="submit"
                  [class.is-loading]="loading"
                  [disabled]="form.invalid"
                >
                  設定
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  `
})
export class PlanPaymentMethodComponent implements OnInit {
  public plan: PlanModel;
  public form: FormGroup;
  public loading = false;
  constructor(
    private session: SessionService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private appService: AppService,
    private notification: Notification
  ) {}
  ngOnInit() {
    this.form = this.fb.group({
      method: this.fb.control(PaymentMethodFormComponent.defaultFormValue())
    });

    // この画面はプランがセッションに保存されていることが前提
    if (!this.session.get('plan')) {
      this.router.navigate(['../list'], { relativeTo: this.route });
    }
  }
  public submit(ev: Event) {
    ev.preventDefault();
    this.loading = true;
    this.appService
      .update_payment_method(this.form.value.method)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(
        () => {
          this.router.navigate(['../options'], { relativeTo: this.route });
        },
        () => {
          this.notification.open('支払い方法の設定に失敗しました', 'danger');
        }
      );
  }
}
