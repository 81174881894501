<ng-container [formGroup]="form">
  <div class="field">
    <label class="label">オートメーションルール名</label>
    <div class="control">
      <input class="input" type="text" formControlName="name" />
    </div>
  </div>
  <div class="field">
    <label class="label">ステータス</label>
    <div class="control">
      <div class="select">
        <select formControlName="status">
          <option value="active">有効</option>
          <option value="suspended">一時停止</option>
        </select>
      </div>
    </div>
  </div>
  <div class="field">
    <label class="label">実行後、このルールの実行をスキップ</label>
    <div class="control">
      <label class="radio">
        <input
          type="radio"
          value="number"
          name="skipDaysType"
          [(ngModel)]="skipDaysType"
          [ngModelOptions]="{ standalone: true }"
        />
        日数で指定
      </label>
      <label class="radio">
        <input
          type="radio"
          value="forever"
          name="skipDaysType"
          [(ngModel)]="skipDaysType"
          [ngModelOptions]="{ standalone: true }"
        />
        一度のみ実行する（無期限にスキップ）
      </label>
    </div>
  </div>
  <div class="field" *ngIf="skipDaysType === 'number'">
    <label class="label">スキップする日数</label>
    <div class="control">
      <input
        class="input"
        min="0"
        max="365"
        type="number"
        formControlName="skip_days"
      />
    </div>
  </div>
  <div class="field">
    <label class="label">実行タイミング</label>
    <div class="control" formGroupName="trigger">
      <div class="cron-box py-2 mb-4">
        <app-cron-editor formControlName="schedule"></app-cron-editor>
      </div>
      <input class="input" readonly [value]="value.trigger.schedule" />
      <div class="help ">Cron表記</div>
    </div>
  </div>
  <hr />
  <label class="label">実行対象</label>
  <app-segmentation-form formControlName="segmentation"></app-segmentation-form>
  <hr />
  <ng-container formGroupName="actions">
    <label class="label">実行時アクション</label>
    <div class="field">
      <label class="checkbox">
        <input
          type="checkbox"
          [(ngModel)]="performPushNotification"
          [ngModelOptions]="{ standalone: true }"
        />
        プッシュ通知を送信する
      </label>
    </div>
    <ng-container
      *ngIf="performPushNotification"
      formGroupName="push_notification"
    >
      <app-notification-body-form
        formControlName="notification"
      ></app-notification-body-form>
      <div class="mt-3">
        <app-delivery-strategy-form
          formControlName="delivery_strategy"
        ></app-delivery-strategy-form>
      </div>
      <hr />
    </ng-container>
    <div class="field">
      <label class="checkbox">
        <input
          type="checkbox"
          [(ngModel)]="performAddParameters"
          [ngModelOptions]="{ standalone: true }"
        />
        パラメータを追加する
      </label>
    </div>
    <ng-container *ngIf="performAddParameters">
      <app-automation-add-parameters-action-form
        formControlName="add_parameters"
      ></app-automation-add-parameters-action-form>
      <hr />
    </ng-container>
    <div class="field">
      <label class="checkbox">
        <input
          type="checkbox"
          [(ngModel)]="performDeleteParameters"
          [ngModelOptions]="{ standalone: true }"
        />
        パラメータを削除する
      </label>
    </div>
    <ng-container *ngIf="performDeleteParameters">
      <app-automation-delete-parameters-action-form
        formControlName="delete_parameters"
      ></app-automation-delete-parameters-action-form>
      <hr />
    </ng-container>
  </ng-container>
</ng-container>
