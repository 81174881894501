
    <div class="messgae is-success">
      <div class="message-header">
        <p>要素を追加してください</p>
      </div>
      <div class="message-body">
        <button class="button is-success is-outlined" (click)="open()">
          新規要素
        </button>
      </div>
    </div>
  