import { SessionService } from './../../services/session/session.service';
import { Modal } from './../../services/modal/modal.service';
import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactModalComponent } from '../contact/contact-modal/contact-modal.component';

import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as app from '../../actions/app';
// import * as fromApp from '../../reducers/app';

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.scss']
})
export class SingleComponent implements OnDestroy {
  actionsSubscription: Subscription;
  constructor(
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
    private modal: Modal,
    private session: SessionService
  ) {
    this.actionsSubscription = route.params
      .pipe(
        map(params => params['appno']),
        map(appno => new app.SelectAppAction(appno))
      )
      .subscribe(store);
  }
  help() {
    if (this.session.get('help-modal-open')) {
      return;
    }
    this.session.set('help-modal-open', true);
    this.modal
      .open(ContactModalComponent)
      .then(() => Promise.reject(false))
      .catch(() => {
        this.session.set('help-modal-open', false);
      });
  }
  ngOnDestroy() {
    this.actionsSubscription.unsubscribe();
    this.store.dispatch(new app.DeselectAppAction());
  }
}
