import {
  Component,
  Input,
  Output,
  OnInit,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  forwardRef
} from '@angular/core';
import { Days, MonthWeeks, Months } from './enums';
import {
  ControlContainer,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

interface CronOptions {
  defaultTime: string;

  hideMinutesTab: boolean;
  hideHourlyTab: boolean;
  hideDailyTab: boolean;
  hideWeeklyTab: boolean;
  hideMonthlyTab: boolean;
  hideYearlyTab: boolean;
  hideAdvancedTab: boolean;

  use24HourTime: boolean;
  hideSeconds: boolean;

  cronFlavor: string;
}

@Component({
  selector: 'app-cron-editor',
  templateUrl: './cron-editor.component.html',
  styleUrls: ['./cron-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CronEditorComponent),
      multi: true
    }
  ]
})
export class CronEditorComponent implements OnInit, ControlValueAccessor {
  @Input() public disabled: boolean;
  @Input() public options: CronOptions = {
    defaultTime: '00:00:00',

    hideMinutesTab: true,
    hideHourlyTab: false,
    hideDailyTab: false,
    hideWeeklyTab: false,
    hideMonthlyTab: true,
    hideYearlyTab: true,
    hideAdvancedTab: false,

    use24HourTime: true,
    hideSeconds: false,

    cronFlavor: 'standard' //standard or quartz
  };

  @Input() get cron(): string {
    return this.localCron;
  }

  set cron(value: string) {
    this.localCron = value;
    this.onChange(value);
  }

  // the name is an Angular convention, @Input variable name + "Change" suffix
  // @Output() cronChange = new EventEmitter();

  public activeTab: string;
  public selectOptions = this.getSelectOptions();
  public state: any;

  private localCron = '0 0 1/1 * *';
  private isDirty: boolean;

  cronForm: FormControl;

  minutesForm: FormGroup;
  hourlyForm: FormGroup;
  dailyForm: FormGroup;
  weeklyForm: FormGroup;
  monthlyForm: FormGroup;
  yearlyForm: FormGroup;
  advancedForm: FormGroup;

  get isCronFlavorQuartz() {
    return this.options.cronFlavor === 'quartz';
  }

  get isCronFlavorStandard() {
    return this.options.cronFlavor === 'standard';
  }

  get yearDefaultChar() {
    return this.options.cronFlavor === 'quartz' ? '*' : '';
  }

  get weekDayDefaultChar() {
    return this.options.cronFlavor === 'quartz' ? '?' : '*';
  }

  get monthDayDefaultChar() {
    return this.options.cronFlavor === 'quartz' ? '?' : '*';
  }

  constructor(private fb: FormBuilder) {}

  /* Update the cron output to that of the selected tab.
   * The cron output value is updated whenever a form is updated. To make it change in response to tab selection, we simply reset
   * the value of the form that goes into focus. */
  public onTabFocus(tab: string) {
    switch (tab) {
      case 'minutes':
        this.minutesForm.setValue(this.minutesForm.value);
        break;
      case 'hourly':
        this.hourlyForm.setValue(this.hourlyForm.value);
        break;
      case 'daily':
        this.dailyForm.setValue(this.dailyForm.value);
        break;
      case 'weekly':
        this.weeklyForm.setValue(this.weeklyForm.value);
        break;
      case 'monthly':
        this.monthlyForm.setValue(this.monthlyForm.value);
        break;
      case 'yearly':
        this.yearlyForm.setValue(this.yearlyForm.value);
        break;
      case 'advanced':
        this.advancedForm.setValue(this.advancedForm.value);
        break;
      default:
        throw new Error('Invalid tab selected');
    }
    this.activeTab = tab;
  }

  public setAllHours(value: boolean) {
    this.hourlyForm.patchValue(
      this.selectOptions.hours.reduce(
        (obj, x) => ({ ...obj, [`h_${x}`]: value }),
        {}
      )
    );
  }

  public setAllDates(value: boolean) {
    this.dailyForm.patchValue(
      this.selectOptions.monthDays.reduce(
        (obj, x) => ({ ...obj, [`d_${x}`]: value }),
        {}
      )
    );
  }

  ngOnInit() {
    this.state = this.getDefaultState();

    this.handleModelChange(this.cron);

    const [
      defaultHours,
      defaultMinutes,
      defaultSeconds
    ] = this.options.defaultTime.split(':').map(Number);

    this.cronForm = new FormControl('0 0 1/1 * *');

    // TODO: customize form controls for limited use

    this.minutesForm = this.fb.group({
      hours: [0],
      minutes: [1],
      seconds: [0]
    });

    this.minutesForm.valueChanges.subscribe(value =>
      this.computeMinutesCron(value)
    );

    const { hours, monthDays } = this.getSelectOptions();
    this.hourlyForm = this.fb.group({
      ...hours.reduce((obj, x) => ({ ...obj, [`h_${x}`]: false }), {}),
      hours: [1],
      minutes: [0],
      seconds: [0]
    });
    this.hourlyForm.valueChanges.subscribe(value =>
      this.computeHourlyCron(value)
    );

    this.dailyForm = this.fb.group({
      ...monthDays.reduce((obj, x) => ({ ...obj, [`d_${x}`]: false }), {}),
      hours: [this.getAmPmHour(1)],
      minutes: [0],
      seconds: [0],
      hourType: [this.getHourType(0)]
    });
    this.dailyForm.valueChanges.subscribe(value =>
      this.computeDailyCron(value)
    );

    this.weeklyForm = this.fb.group({
      MON: [true],
      TUE: [false],
      WED: [false],
      THU: [false],
      FRI: [false],
      SAT: [false],
      SUN: [false],
      hours: [this.getAmPmHour(defaultHours)],
      minutes: [defaultMinutes],
      seconds: [defaultSeconds],
      hourType: [this.getHourType(defaultHours)]
    });
    this.weeklyForm.valueChanges.subscribe(next =>
      this.computeWeeklyCron(next)
    );

    this.monthlyForm = this.fb.group({
      subTab: ['specificDay'],
      specificDay: this.fb.group({
        day: ['1'],
        months: [1],
        hours: [this.getAmPmHour(defaultHours)],
        minutes: [defaultMinutes],
        seconds: [defaultSeconds],
        hourType: [this.getHourType(defaultHours)]
      }),
      specificWeekDay: this.fb.group({
        monthWeek: ['#1'],
        day: ['MON'],
        months: [1],
        hours: [this.getAmPmHour(defaultHours)],
        minutes: [defaultMinutes],
        seconds: [defaultSeconds],
        hourType: [this.getHourType(defaultHours)]
      })
    });
    this.monthlyForm.valueChanges.subscribe(next =>
      this.computeMonthlyCron(next)
    );

    this.yearlyForm = this.fb.group({
      subTab: ['specificMonthDay'],
      specificMonthDay: this.fb.group({
        month: [1],
        day: ['1'],
        hours: [this.getAmPmHour(defaultHours)],
        minutes: [defaultMinutes],
        seconds: [defaultSeconds],
        hourType: [this.getHourType(defaultHours)]
      }),
      specificMonthWeek: this.fb.group({
        monthWeek: ['#1'],
        day: ['MON'],
        month: [1],
        hours: [this.getAmPmHour(defaultHours)],
        minutes: [defaultMinutes],
        seconds: [defaultSeconds],
        hourType: [this.getHourType(defaultHours)]
      })
    });
    this.yearlyForm.valueChanges.subscribe(next =>
      this.computeYearlyCron(next)
    );

    this.advancedForm = this.fb.group({
      expression: [
        this.isCronFlavorQuartz ? '0 15 10 L-2 * ? *' : '15 10 2 * *'
      ]
    });
    this.advancedForm.controls.expression.valueChanges.subscribe(next =>
      this.computeAdvancedExpression(next)
    );
  }

  private computeMinutesCron(state: any) {
    this.cron = `${this.isCronFlavorQuartz ? state.seconds : ''} 0/${
      state.minutes
    } * 1/1 * ${this.weekDayDefaultChar} ${this.yearDefaultChar}`.trim();
    this.cronForm.setValue(this.cron);
  }

  private computeHourlyCron(state: any) {
    // list checked hours
    const checkedHours = this.selectOptions.hours.reduce(
      (acc, hour) => (state[`h_${hour}`] ? acc.concat([hour]) : acc),
      []
    );
    const hours =
      checkedHours.length < 24 ? this.arrayToCronString(checkedHours) : '*';

    this.cron = `${this.isCronFlavorQuartz ? state.seconds : ''} ${
      state.minutes
    } ${hours} 1/1 * ${this.weekDayDefaultChar} ${this.yearDefaultChar}`.trim();
    this.cronForm.setValue(this.cron);
  }

  private computeDailyCron(state: any) {
    const checkedDates = this.selectOptions.monthDays.reduce(
      (acc, date) => (state[`d_${date}`] ? acc.concat([date]) : acc),
      []
    );
    const dates =
      checkedDates.length < 31 ? this.arrayToCronString(checkedDates) : '*';
    this.cron = `${this.isCronFlavorQuartz ? state.seconds : ''} ${
      state.minutes
    } ${this.hourToCron(state.hours, state.hourType)} ${dates} * ${
      this.weekDayDefaultChar
    } ${this.yearDefaultChar}`.trim();
    this.cronForm.setValue(this.cron);
  }

  private computeWeeklyCron(state: any) {
    const days = this.selectOptions.days
      .reduce((acc, day) => (state[day] ? acc.concat([day]) : acc), [])
      .join(',');
    this.cron = `${this.isCronFlavorQuartz ? state.seconds : ''} ${
      state.minutes
    } ${this.hourToCron(state.hours, state.hourType)} ${
      this.monthDayDefaultChar
    } * ${days || '*'} ${this.yearDefaultChar}`.trim();
    this.cronForm.setValue(this.cron);
  }

  private computeMonthlyCron(state: any) {
    switch (state.subTab) {
      case 'specificDay':
        this.cron = `${
          this.isCronFlavorQuartz ? state.specificDay.seconds : ''
        } ${state.specificDay.minutes} ${this.hourToCron(
          state.specificDay.hours,
          state.specificDay.hourType
        )} ${state.specificDay.day} 1/${state.specificDay.months} ${
          this.weekDayDefaultChar
        } ${this.yearDefaultChar}`.trim();
        break;
      case 'specificWeekDay':
        this.cron = `${
          this.isCronFlavorQuartz ? state.specificWeekDay.seconds : ''
        } ${state.specificWeekDay.minutes} ${this.hourToCron(
          state.specificWeekDay.hours,
          state.specificWeekDay.hourType
        )} ${this.monthDayDefaultChar} 1/${state.specificWeekDay.months} ${
          state.specificWeekDay.day
        }${state.specificWeekDay.monthWeek} ${this.yearDefaultChar}`.trim();
        break;
      default:
        throw new Error('Invalid cron montly subtab selection');
    }
    this.cronForm.setValue(this.cron);
  }

  private computeYearlyCron(state: any) {
    switch (state.subTab) {
      case 'specificMonthDay':
        this.cron = `${
          this.isCronFlavorQuartz ? state.specificMonthDay.seconds : ''
        } ${state.specificMonthDay.minutes} ${this.hourToCron(
          state.specificMonthDay.hours,
          state.specificMonthDay.hourType
        )} ${state.specificMonthDay.day} ${state.specificMonthDay.month} ${
          this.weekDayDefaultChar
        } ${this.yearDefaultChar}`.trim();
        break;
      case 'specificMonthWeek':
        this.cron = `${
          this.isCronFlavorQuartz ? state.specificMonthWeek.seconds : ''
        } ${state.specificMonthWeek.minutes} ${this.hourToCron(
          state.specificMonthWeek.hours,
          state.specificMonthWeek.hourType
        )} ${this.monthDayDefaultChar} ${state.specificMonthWeek.month} ${
          state.specificMonthWeek.day
        }${state.specificMonthWeek.monthWeek} ${this.yearDefaultChar}`.trim();
        break;
      default:
        throw new Error('Invalid cron yearly subtab selection');
    }
    this.cronForm.setValue(this.cron);
  }

  private computeAdvancedExpression(expression: any) {
    this.cron = expression;
    this.cronForm.setValue(this.cron);
  }

  public dayDisplay(day: string): string {
    return Days[day];
  }

  public monthWeekDisplay(monthWeekNumber: string): string {
    return MonthWeeks[monthWeekNumber];
  }

  public monthDisplay(month: number): string {
    return Months[month];
  }

  public monthDayDisplay(month: string): string {
    if (month === 'L') {
      return 'Last Day';
    } else if (month === 'LW') {
      return 'Last Weekday';
    } else if (month === '1W') {
      return 'First Weekday';
    } else {
      return `${month}${this.getOrdinalSuffix(month)}`;
    }
  }

  private getAmPmHour(hour: number) {
    return this.options.use24HourTime ? hour : ((hour + 11) % 12) + 1;
  }

  private getHourType(hour: number) {
    return this.options.use24HourTime ? null : hour >= 12 ? 'PM' : 'AM';
  }

  private hourToCron(hour: number, hourType: string) {
    if (this.options.use24HourTime) {
      return hour;
    } else {
      return hourType === 'AM'
        ? hour === 12
          ? 0
          : hour
        : hour === 12
        ? 12
        : hour + 12;
    }
  }

  private handleModelChange(cron: string) {
    if (this.isDirty) {
      this.isDirty = false;
      return;
    } else {
      this.isDirty = false;
    }

    if (!this.cronIsValid(cron)) {
      if (this.isCronFlavorQuartz) {
        throw new Error(
          'Invalid cron expression, there must be 6 or 7 segments'
        );
      }

      if (this.isCronFlavorStandard) {
        throw new Error('Invalid cron expression, there must be 5 segments');
      }
    }

    this.onCronUpdate(cron);
  }

  private onCronUpdate(cron: string) {
    const origCron: string = cron;
    if (cron.split(' ').length === 5 && this.isCronFlavorStandard) {
      cron = `0 ${cron} *`;
    }

    const [seconds, minutes, hours, dayOfMonth, month, dayOfWeek] = cron.split(
      ' '
    );

    if (cron.match(/\d+ 0\/\d+ \* 1\/1 \* [\?\*] \*/)) {
      this.activeTab = 'minutes';

      this.state.minutes.minutes = parseInt(minutes.substring(2), 10);
      this.state.minutes.seconds = parseInt(seconds, 10);
    } else if (
      cron.match(
        /\d+ \d+ (\*|(\d+|(\d+\-\d+))(,(\d+|(\d+\-\d+)))*) 1\/1 \* [\?\*] \*/
      )
    ) {
      this.activeTab = 'hourly';

      // reset all hours
      const selectedHours =
        hours === '*'
          ? this.selectOptions.hours
          : this.cronStringToArray(hours);
      this.selectOptions.hours.forEach(
        hour => (this.state.hourly[`h_${hour}`] = selectedHours.includes(hour))
      );
      this.state.hourly.minutes = parseInt(minutes, 10);
      this.state.hourly.seconds = parseInt(seconds, 10);
    } else if (
      cron.match(
        /\d+ \d+ \d+ (\*|(\d+|(\d+\-\d+))(,(\d+|(\d+\-\d+)))*) \* [\?\*] \*/
      )
    ) {
      this.activeTab = 'daily';

      const selectedDates =
        dayOfMonth === '*'
          ? this.selectOptions.monthDays
          : this.cronStringToArray(dayOfMonth);
      this.selectOptions.monthDays.forEach(
        date => (this.state.daily[`d_${date}`] = selectedDates.includes(date))
      );
      const parsedHours = parseInt(hours, 10);
      this.state.daily.hours = this.getAmPmHour(parsedHours);
      this.state.daily.hourType = this.getHourType(parsedHours);
      this.state.daily.minutes = parseInt(minutes, 10);
      this.state.daily.seconds = parseInt(seconds, 10);
    } else if (
      cron.match(
        /\d+ \d+ \d+ [\?\*] \* (MON|TUE|WED|THU|FRI|SAT|SUN)(,(MON|TUE|WED|THU|FRI|SAT|SUN))* \*/
      )
    ) {
      this.activeTab = 'weekly';
      this.selectOptions.days.forEach(
        weekDay => (this.state.weekly[weekDay] = false)
      );
      dayOfWeek
        .split(',')
        .forEach(weekDay => (this.state.weekly[weekDay] = true));
      const parsedHours = parseInt(hours, 10);
      this.state.weekly.hours = this.getAmPmHour(parsedHours);
      this.state.weekly.hourType = this.getHourType(parsedHours);
      this.state.weekly.minutes = parseInt(minutes, 10);
      this.state.weekly.seconds = parseInt(seconds, 10);
    } else if (cron.match(/\d+ \d+ \d+ (\d+|L|LW|1W) 1\/\d+ [\?\*] \*/)) {
      this.activeTab = 'monthly';
      this.state.monthly.subTab = 'specificDay';
      this.state.monthly.specificDay.day = dayOfMonth;
      this.state.monthly.specificDay.months = parseInt(month.substring(2), 10);
      const parsedHours = parseInt(hours, 10);
      this.state.monthly.specificDay.hours = this.getAmPmHour(parsedHours);
      this.state.monthly.specificDay.hourType = this.getHourType(parsedHours);
      this.state.monthly.specificDay.minutes = parseInt(minutes, 10);
      this.state.monthly.specificDay.seconds = parseInt(seconds, 10);
    } else if (
      cron.match(
        /\d+ \d+ \d+ [\?\*] 1\/\d+ (MON|TUE|WED|THU|FRI|SAT|SUN)((#[1-5])|L) \*/
      )
    ) {
      const day = dayOfWeek.substr(0, 3);
      const monthWeek = dayOfWeek.substr(3);
      this.activeTab = 'monthly';
      this.state.monthly.subTab = 'specificWeekDay';
      this.state.monthly.specificWeekDay.monthWeek = monthWeek;
      this.state.monthly.specificWeekDay.day = day;
      this.state.monthly.specificWeekDay.months = parseInt(
        month.substring(2),
        10
      );
      const parsedHours = parseInt(hours, 10);
      this.state.monthly.specificWeekDay.hours = this.getAmPmHour(parsedHours);
      this.state.monthly.specificWeekDay.hourType = this.getHourType(
        parsedHours
      );
      this.state.monthly.specificWeekDay.minutes = parseInt(minutes, 10);
      this.state.monthly.specificWeekDay.seconds = parseInt(seconds, 10);
    } else if (cron.match(/\d+ \d+ \d+ (\d+|L|LW|1W) \d+ [\?\*] \*/)) {
      this.activeTab = 'yearly';
      this.state.yearly.subTab = 'specificMonthDay';
      this.state.yearly.specificMonthDay.month = parseInt(month, 10);
      this.state.yearly.specificMonthDay.day = dayOfMonth;
      const parsedHours = parseInt(hours, 10);
      this.state.yearly.specificMonthDay.hours = this.getAmPmHour(parsedHours);
      this.state.yearly.specificMonthDay.hourType = this.getHourType(
        parsedHours
      );
      this.state.yearly.specificMonthDay.minutes = parseInt(minutes, 10);
      this.state.yearly.specificMonthDay.seconds = parseInt(seconds, 10);
    } else if (
      cron.match(
        /\d+ \d+ \d+ [\?\*] \d+ (MON|TUE|WED|THU|FRI|SAT|SUN)((#[1-5])|L) \*/
      )
    ) {
      const day = dayOfWeek.substr(0, 3);
      const monthWeek = dayOfWeek.substr(3);
      this.activeTab = 'yearly';
      this.state.yearly.subTab = 'specificMonthWeek';
      this.state.yearly.specificMonthWeek.monthWeek = monthWeek;
      this.state.yearly.specificMonthWeek.day = day;
      this.state.yearly.specificMonthWeek.month = parseInt(month, 10);
      const parsedHours = parseInt(hours, 10);
      this.state.yearly.specificMonthWeek.hours = this.getAmPmHour(parsedHours);
      this.state.yearly.specificMonthWeek.hourType = this.getHourType(
        parsedHours
      );
      this.state.yearly.specificMonthWeek.minutes = parseInt(minutes, 10);
      this.state.yearly.specificMonthWeek.seconds = parseInt(seconds, 10);
    } else {
      this.activeTab = 'advanced';
      this.state.advanced.expression = origCron;
    }
  }

  private patchFormValue() {
    switch (this.activeTab) {
      case 'minutes':
        this.minutesForm.patchValue(this.state.minutes);
        break;
      case 'hourly':
        this.hourlyForm.patchValue(this.state.hourly);
        break;
      case 'daily':
        this.dailyForm.patchValue(this.state.daily);
        break;
      case 'weekly':
        this.weeklyForm.patchValue(this.state.weekly);
        break;
      case 'monthly':
        this.monthlyForm.patchValue(this.state.monthly);
        break;
      case 'yearly':
        this.yearlyForm.patchValue(this.state.yearly);
        break;
      case 'advanced':
      default:
        this.advancedForm.patchValue(this.state.advanced);
        break;
    }
  }

  private cronIsValid(cron: string): boolean {
    if (cron) {
      const cronParts = cron.split(' ');
      return (
        (this.isCronFlavorQuartz &&
          (cronParts.length === 6 || cronParts.length === 7)) ||
        (this.isCronFlavorStandard && cronParts.length === 5)
      );
    }

    return false;
  }

  private cronStringToArray(cron: string) {
    return cron.split(',').reduce((acc, cronRange) => {
      const ranges = cronRange.split('-');
      return acc.concat(
        ranges.length === 1
          ? +cronRange[0]
          : this.getRange(+ranges[0], +ranges[1])
      );
    }, [] as number[]);
  }

  private arrayToCronString(arr: number[]) {
    if (arr.length === 0) return '*';
    return (
      arr
        .slice(1)
        // compile successive hours as [start, end] format
        .reduce(
          (acc, hour) => {
            const last = acc.pop();
            return acc.concat(
              hour - last.slice(-1)[0] === 1
                ? [[last[0], hour]]
                : [last, [hour]]
            );
          },
          [[arr[0]]]
        )
        // join ranged hours
        .map(range => range.join(range[1] - range[0] > 1 ? '-' : ','))
        .join(',')
    );
  }

  private getDefaultState() {
    const [
      defaultHours,
      defaultMinutes,
      defaultSeconds
    ] = this.options.defaultTime.split(':').map(Number);

    const { hours, monthDays } = this.selectOptions;

    return {
      minutes: {
        minutes: 1,
        seconds: 0
      },
      hourly: {
        ...hours.reduce((obj, x) => ({ ...obj, [`h_${x}`]: false }), {}),
        hours: 1,
        minutes: 0,
        seconds: 0
      },
      daily: {
        ...monthDays.reduce((obj, x) => ({ ...obj, [`d_${x}`]: false }), {}),
        days: 1,
        hours: this.getAmPmHour(defaultHours),
        minutes: defaultMinutes,
        seconds: defaultSeconds,
        hourType: this.getHourType(defaultHours)
      },
      weekly: {
        MON: true,
        TUE: false,
        WED: false,
        THU: false,
        FRI: false,
        SAT: false,
        SUN: false,
        hours: this.getAmPmHour(defaultHours),
        minutes: defaultMinutes,
        seconds: defaultSeconds,
        hourType: this.getHourType(defaultHours)
      },
      monthly: {
        subTab: 'specificDay',
        specificDay: {
          day: '1',
          months: 1,
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours)
        },
        specificWeekDay: {
          monthWeek: '#1',
          day: 'MON',
          months: 1,
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours)
        }
      },
      yearly: {
        subTab: 'specificMonthDay',
        specificMonthDay: {
          month: 1,
          day: '1',
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours)
        },
        specificMonthWeek: {
          monthWeek: '#1',
          day: 'MON',
          month: 1,
          hours: this.getAmPmHour(defaultHours),
          minutes: defaultMinutes,
          seconds: defaultSeconds,
          hourType: this.getHourType(defaultHours)
        }
      },
      advanced: {
        expression: this.isCronFlavorQuartz
          ? '0 15 10 L-2 * ? *'
          : '15 10 2 * *'
      }
    };
  }

  private getOrdinalSuffix(value: string) {
    if (value.length > 1) {
      const secondToLastDigit = value.charAt(value.length - 2);
      if (secondToLastDigit === '1') {
        return 'th';
      }
    }

    const lastDigit = value.charAt(value.length - 1);
    switch (lastDigit) {
      case '1':
        return 'st';
      case '2':
        return 'nd';
      case '3':
        return 'rd';
      default:
        return 'th';
    }
  }

  private getSelectOptions() {
    return {
      months: this.getRange(1, 12),
      monthWeeks: ['#1', '#2', '#3', '#4', '#5', 'L'],
      days: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'],
      minutes: this.getRange(0, 59),
      fullMinutes: this.getRange(0, 59),
      seconds: this.getRange(0, 59),
      hours: this.getRange(0, 23),
      monthDays: this.getRange(1, 31),
      monthDaysWithLasts: [
        '1W',
        ...[...this.getRange(1, 31).map(String)],
        'LW',
        'L'
      ],
      monthDaysWithOutLasts: [...[...this.getRange(1, 31).map(String)]],
      hourTypes: ['AM', 'PM']
    };
  }

  private getRange(start: number, end: number): number[] {
    const length = end - start + 1;
    return Array.apply(null, Array(length)).map((_, i) => i + start);
  }

  /*
   * ControlValueAccessor
   */
  onChange = (_: any) => {};
  onTouched = () => {};

  writeValue(obj: string): void {
    this.cron = obj;
    if (this.activeTab !== 'advanced') {
      this.onCronUpdate(obj);
      this.patchFormValue();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
