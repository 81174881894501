<div *ngIf="value.element">
  <app-ast-node
    (removeFromParentNode)="removeTheNode()"
    [value]="value.element"
  ></app-ast-node>
</div>
<div *ngIf="!value.element">
  <!-- 子供をつくるボタン -->
  <app-ast-add-elem (addElem)="putElem($event)"></app-ast-add-elem>
</div>
