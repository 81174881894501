import { Category } from './../../../models/category.model';
import { BehaviorSubject, Subject, fromEvent } from 'rxjs';
import { CategoryService } from './../../../services/category/category.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppService } from './../../../services/app/app.service';
import { Notification } from './../../../services/notification/notification.service';
import * as fromRoot from './../../../reducers';
import { Component, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { debounceTime, map, finalize } from 'rxjs/operators';

import { ModalContext } from '../../../services/modal/modal.service';
import { DefaultService as BackendService } from '../../../services/backend';

@Component({
  selector: 'app-new-app-modal',
  templateUrl: './new-app-modal.component.html',
  styleUrls: ['./new-app-modal.component.scss']
})
export class NewAppModalComponent implements AfterViewInit, OnDestroy {
  public processing = false;

  public name: string;
  public url: string;
  public protocol: string;
  public icon =
    'https://dashboard.push7.jp/uploads/034a359ed2874f788da3c508e973652b.png';
  public domain = '';
  public categoryno: number;

  private isDestroied: Subject<void> = new Subject<void>();

  public categories: BehaviorSubject<Category[]>;

  /**
   * 0 = undefined
   * 1 = available
   * 2 = unavailable or error
   */
  public isAvailable = 0;
  public error: string;
  private _el: HTMLElement;
  private domainInput: Element;
  private projectId: string;
  public projectName: string;
  constructor(
    private modalCtx: ModalContext,
    private notification: Notification,
    private api: AppService,
    private store: Store<fromRoot.State>,
    private translate: TranslateService,
    private el: ElementRef,
    private categoryService: CategoryService,
    private backend: BackendService
  ) {
    this._el = el.nativeElement;
    this.categories = categoryService.categories;
    this.projectName = modalCtx.payload.projectName;
    this.projectId = modalCtx.payload.projectId;
  }
  ngOnDestroy() {
    this.isDestroied.next();
    this.isDestroied.complete();
  }
  ngAfterViewInit() {
    const domainInput = this._el.querySelector('.domain-input');
    const source = fromEvent(domainInput, 'keyup').pipe(
      map((e: any) => e.target.value),
      debounceTime(550)
    );
    source.subscribe(m => this.check());
  }
  select(ev) {
    this.categoryno = ev.target.value;
  }
  checkProtocol(ev) {
    const matched = ev.target.value.match(/^https?\:\/\//);
    if (matched && matched.length) {
      this.protocol = matched[0];
      this.url = ev.target.value.slice(matched[0].length);
    }
  }
  close() {
    this.modalCtx.reject();
  }
  check() {
    // Length
    if (!(this.domain.length >= 4 && this.domain.length <= 32)) {
      // 長さが不正
      this.isAvailable = 2;
      this.translate
        .get('HOME.NEWAPP.INVALID_LENGTH')
        .subscribe(m => (this.error = m));
      return;
    }
    if (!this.domain.match(/^[a-z0-9_-]+$/)) {
      // マッチしない
      this.isAvailable = 2;
      this.translate
        .get('HOME.NEWAPP.INVALID_CHAR')
        .subscribe(m => (this.error = m));
      return;
    }
    this.api.check_domain(this.domain).subscribe(
      () => {
        // success
        this.isAvailable = 1;
      },
      () => {
        this.translate
          .get('HOME.NEWAPP.UNAVAILABLE')
          .subscribe(m => (this.error = m));
        this.isAvailable = 2;
      }
    );
  }
  public isReady() {
    if (
      !this.name ||
      !this.protocol ||
      !this.url ||
      !this.icon ||
      !this.domain ||
      !this.categoryno
    ) {
      return false;
    }
    if (this.isAvailable !== 1) {
      return false;
    }
    return true;
  }
  submit() {
    if (!this.isReady()) {
      return;
    }
    this.processing = true;
    this.backend
      .createApp({
        name: this.name,
        url: this.protocol + this.url,
        icon: this.icon,
        domain: this.domain,
        categoryno: String(this.categoryno),
        project_uuid: this.projectId
      })
      .pipe(finalize(() => (this.processing = false)))
      .subscribe(
        s => {
          this.translate.get('HOME.NEWAPP.CREATED').subscribe((m: string) => {
            this.notification.open(m, 'success');
          });
          this.modalCtx.resolve();
        },
        response => {
          this.notification.open(response.error.error, 'danger');
        }
      );
  }
}
