<div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{ 'COMMON.USER_TYPE' | translate }}
      </div>
      <div class="content">
        <p>{{ 'OTHERS.SET_USER_TYPE' | translate }}</p>
        <div class="field">
          <button
            class="button is-medium"
            (click)="is_corporation = false"
            [class.is-active]="is_corporation == false"
          >
            <span class="icon is-small">
              <i class="fa fa-user"></i>
            </span>
            <span>{{ 'COMMON.INDIVIDUAL' | translate }}</span>
          </button>
          <button
            class="button is-medium"
            (click)="is_corporation = true"
            [class.is-active]="is_corporation == true"
          >
            <span class="icon is-small">
              <i class="fa fa-building-o"></i>
            </span>
            <span>{{ 'COMMON.CORPORATION' | translate }}</span>
          </button>
        </div>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button
            class="button is-success p7-button"
            [disabled]="is_corporation === null"
            (click)="ok()"
          >
            {{ 'COMMON.OK' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
