<div class="main-nav">
  {{ 'MAIN.LABEL' | translate }}
</div>

<div class="bg-black">
  <div class="main-title" [innerHTML]="'MAIN.MESSAGE' | translate"></div>
</div>
<div class="app-status">
  <div class="card is-flex is-align-items-center">
    <img src="{{ (selected$ | async)?.icon }}" />
    <div class="app-detail">
      <div class="inner">
        <div class="app-name">{{ (selected$ | async)?.name }}</div>
        <div class="app-domain">
          <a href="https://{{ (selected$ | async)?.domain }}" target="_blank">{{
            (selected$ | async)?.domain
          }}</a>
        </div>
        <div class="app-subscribers">
          {{ 'MAIN.CURRENT_SUBSCRIBERS' | translate
          }}{{ (selected$ | async)?.subscribers | number }}
        </div>
        <div class="app-counts">
          {{ 'MAIN.SENT_IN_MONTH' | translate
          }}{{ (selected$ | async)?.count.month | number }}/{{
            (selected$ | async)?.count?.limit | number
          }}
        </div>
        <div
          class="app-segment-credits"
          *ngIf="(selected$ | async)?.permissions.can_use_segment"
        >
          {{ 'MAIN.CREDIT_IN_MONTH' | translate
          }}{{
            (selected$ | async)?.segmentation_query_credit.limit -
              (selected$ | async)?.segmentation_query_credit.remaining | number
          }}/{{ (selected$ | async)?.segmentation_query_credit.limit | number }}
        </div>
        <div class="app-plan">
          <span
            >{{ 'MAIN.CURRENT_PLAN' | translate
            }}{{ (selected$ | async)?.plan.current.name }}</span
          >
          <span
            >{{ 'MAIN.NEXT_PLAN' | translate
            }}{{ (selected$ | async)?.plan.next.name }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
<div class="app-select">
  <!-- Enterprise契約誘導カード群 -->
  <ng-container *appDisplayFor="'administrator'">
    <ng-container *ngIf="(project$ | async).is_corporation">
      <!--
        Enterprise契約導線（トライアル含む） を表示するためには
        - Projectのadminである
        - Projectが法人プロジェクトである
        が必須である
      -->
      <!-- Enterpriseトライアル導線 -->
      <ng-container
        *ngIf="
          (selected$ | async).can_enterprise_trial &&
          (selected$ | async).plan.current.is_enterprise == false
        "
      >
        <!--
          Enterpriseトライアル導線の表示導線
          - 現在のプランがEnterpriseではない
          - トライアルを開始していない
        -->
        <div class="column">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                Enterpriseトライアルのご案内
              </p>
            </header>
            <div class="card-content adjust-height">
              <div class="content">
                <div>
                  <p>
                    Enterpriseプランの内容を、1ヶ月無料でお試しいただけます。
                  </p>
                  <ul>
                    <li>ユーザのIDや動きを元にしたセグメント配信</li>
                    <li>月間50万件の通知を送信</li>
                    <li>
                      高品質なサポートとエンジニアによるテクニカルサポート
                    </li>
                  </ul>
                  <p>
                    この他にも多くの特典をEnterpriseプランではご利用いただけます。
                  </p>
                  <p>
                    Enterpiseトライアルプランで更に便利になったPush7の体験を始めましょう。
                  </p>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <a class="card-footer-item" (click)="enterpriseTrialModal()"
                >はじめる</a
              >
            </footer>
          </div>
        </div>
      </ng-container>
      <!-- Enterpriseトライアル導線ここまで -->
      <!-- Enterprise申し込み導線 -->
      <ng-container
        *ngIf="(project$ | async).enterprise_approval.status === 'none'"
      >
        <!--
          Enterprise申し込み導線の表示条件
          - approval_status が none
        -->
        <div class="column">
          <app-application-card></app-application-card>
        </div>
      </ng-container>
      <!-- Enterprise申し込み導線ここまで -->
      <!-- Enterprise申し込み中表示 -->
      <ng-container
        *ngIf="(project$ | async).enterprise_approval.status === 'pending'"
      >
        <!--
          Enterprise申し込み中の表示条件
          - approval_status が pending
        -->
        <div class="column">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                Enterpriseプランへのお申し込みを受け付けました
              </p>
            </header>
            <div class="card-content">
              <div class="content">
                <p>Enterpriseプランへのお申込み誠にありがとうございました。</p>
                <p>
                  お申込結果につきましては3営業日以内にメールにてご連絡いたします。
                </p>
                <p>
                  お申し込みが承認された後、プランのご選択とお支払い情報のご入力をいただきご契約成立となります。
                </p>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- Enterprise申し込み中表示ここまで -->
      <!-- Enterprise再申し込み導線表示 -->
      <ng-container
        *ngIf="(project$ | async).enterprise_approval.status === 'rejected'"
      >
        <!--
          Enterprise再申し込み導線表示
          - approval_status が rejected
        -->
        <div class="column">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                Enterpriseプランへの申し込みが承認されませんでした
              </p>
            </header>
            <div class="card-content">
              <div class="content">
                <div>
                  <p>
                    Enterpriseプランへのお申込みありがとうございました。
                  </p>
                  <p>
                    ご希望に添えず誠に申し訳ございませんが、Enterpriseプランへのお申込みをお受け付けできませんでした。
                  </p>
                  <p>
                    運営事務局からのメッセージ:
                    {{ (project$ | async).enterprise_approval.reason }}
                  </p>
                  <p>
                    お手数をおかけし大変恐縮ですが下記より再度のお申込みをお願いいたします。
                  </p>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <a class="card-footer-item" (click)="enterpriseApplicationModal()"
                >再度Enterpriseプランに申し込み</a
              >
            </footer>
          </div>
        </div>
      </ng-container>
      <!-- Enterprise申し込み中表示ここまで -->
      <!-- Enterprise 申し込み承認後導線表示 -->
      <ng-container
        *ngIf="
          (project$ | async).enterprise_approval.status === 'approved' &&
          (selected$ | async).plan.current.amount === 0
        "
      >
        <!--
          Enterprise 申し込み承認後導線
          - approval_status が approved
          - 現在のプランの価格が0円である（FreeもしくはTrial）
        -->
        <div class="column">
          <div class="card">
            <header class="card-header">
              <p class="card-header-title">
                Enterpriseプランの契約お手続き
              </p>
            </header>
            <div class="card-content">
              <div class="content">
                <div>
                  <p>
                    Push7 for
                    Enterpriseへお申し込みをいただき誠にありがとうございました。
                  </p>
                  <p>
                    下記よりプラン内容をご確認いただき、契約手続きをお願いいたします。
                  </p>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <a [routerLink]="['plan']" class="card-footer-item"
                >契約手続きを開始する</a
              >
            </footer>
          </div>
        </div>
      </ng-container>
      <!-- Enterprise 申し込み承認後導線ここまで -->
    </ng-container>
  </ng-container>
  <!-- Enterprise契約誘導カード群ここまで -->

  <ng-container *appDisplayFor="'administrator'">
    <div class="column" *ngIf="(selected$ | async)?.subscribers <= 10">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">
            {{ 'MAIN.SDK.LABEL' | translate }}
          </p>
        </header>
        <div class="card-content adjust-height">
          <div class="content">
            <div [innerHTML]="'MAIN.SDK.MESSAGE' | translate"></div>
          </div>

          <div class="step adjust-margin">
            <div class="count">
              <p>1</p>
            </div>
            <div class="content download">
              <p
                class="native-download-link"
                [innerHTML]="'MAIN.SDK.STEP1' | translate"
              ></p>
            </div>
          </div>
          <div class="step">
            <div class="count">
              <p>2</p>
            </div>
            <div class="content">
              <p>{{ 'MAIN.SDK.STEP2' | translate }}</p>
            </div>
          </div>

          <div class="columns is-multiline is-mobile adjust-margin">
            <div class="column is-one-third">
              <div class="card">
                <div class="card-image">
                  <figure class="image">
                    <img src="/assets/img/main/button.png" alt="Image" />
                  </figure>
                </div>
                <header class="card-header">
                  <p class="card-header-title">
                    {{ 'MAIN.SDK.TAGS.BUTTON.LABEL' | translate }}
                  </p>
                </header>
                <div class="card-content adjust-height">
                  <div class="content">
                    {{ 'MAIN.SDK.TAGS.BUTTON.MESSAGE' | translate }}
                  </div>
                </div>
                <footer class="card-footer">
                  <a class="card-footer-item" [routerLink]="['tags/button']">{{
                    'MAIN.SDK.TAGS.BUTTON.BUTTON' | translate
                  }}</a>
                </footer>
              </div>
            </div>

            <div class="column is-one-third">
              <div class="card">
                <div class="card-image">
                  <figure class="image">
                    <img src="/assets/img/main/box.png" alt="Image" />
                  </figure>
                </div>
                <header class="card-header">
                  <p class="card-header-title">
                    {{ 'MAIN.SDK.TAGS.BOX.LABEL' | translate }}
                  </p>
                </header>
                <div class="card-content adjust-height">
                  <div class="content">
                    {{ 'MAIN.SDK.TAGS.BOX.MESSAGE' | translate }}
                  </div>
                </div>
                <footer class="card-footer">
                  <a class="card-footer-item" [routerLink]="['tags/box']">{{
                    'MAIN.SDK.TAGS.BOX.BUTTON' | translate
                  }}</a>
                </footer>
              </div>
            </div>

            <div class="column is-one-third">
              <div class="card">
                <div class="card-image">
                  <figure class="image">
                    <img src="/assets/img/main/native.png" alt="Image" />
                  </figure>
                </div>
                <header class="card-header">
                  <p class="card-header-title">
                    {{ 'MAIN.SDK.TAGS.NATIVE.LABEL' | translate }}
                  </p>
                </header>
                <div class="card-content adjust-height">
                  <div class="content">
                    {{ 'MAIN.SDK.TAGS.NATIVE.MESSAGE' | translate }}
                  </div>
                </div>
                <footer class="card-footer">
                  <a class="card-footer-item" [routerLink]="['tags/native']">{{
                    'MAIN.SDK.TAGS.NATIVE.BUTTON' | translate
                  }}</a>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="columns is-multiline is-mobile adjust-margin">
    <div class="column is-one-third" *appDisplayFor="'operator'">
      <div class="card">
        <div class="card-image">
          <figure class="image">
            <img src="/assets/img/main/new-push.png" alt="Image" />
          </figure>
        </div>
        <header class="card-header">
          <p class="card-header-title">
            {{ 'MAIN.NEW_PUSH.LABEL' | translate }}
          </p>
        </header>
        <div class="card-content adjust-height">
          <div class="content">
            {{ 'MAIN.NEW_PUSH.MESSAGE' | translate }}
          </div>
        </div>
        <footer class="card-footer">
          <a class="card-footer-item" [routerLink]="['push']">{{
            'MAIN.COMMON.CREATE' | translate
          }}</a>
        </footer>
      </div>
    </div>

    <div class="column is-one-third" *appDisplayFor="'operator'">
      <div class="card">
        <div class="card-image">
          <figure class="image">
            <img src="/assets/img/main/abtest.png" alt="Image" />
          </figure>
        </div>
        <header class="card-header">
          <p class="card-header-title">
            {{ 'MAIN.NEW_AB.LABEL' | translate }}
          </p>
        </header>
        <div class="card-content adjust-height">
          <div class="content">
            {{ 'MAIN.NEW_AB.MESSAGE' | translate }}
          </div>
        </div>
        <footer class="card-footer">
          <a class="card-footer-item" [routerLink]="['abtest']">{{
            'MAIN.COMMON.CREATE' | translate
          }}</a>
        </footer>
      </div>
    </div>

    <div class="column is-one-third" *appDisplayFor="'administrator'">
      <div class="card">
        <div class="card-image">
          <figure class="image">
            <img src="/assets/img/main/rss.png" alt="Image" />
          </figure>
        </div>
        <header class="card-header">
          <p class="card-header-title">
            {{ 'MAIN.RSS.LABEL' | translate }}
          </p>
        </header>
        <div class="card-content adjust-height">
          <div class="content">
            {{ 'MAIN.RSS.MESSAGE' | translate }}
          </div>
        </div>
        <footer class="card-footer">
          <a class="card-footer-item" [routerLink]="['auto/rss']">{{
            'MAIN.COMMON.CONFIGURE' | translate
          }}</a>
        </footer>
      </div>
    </div>

    <div class="column is-one-third" *appDisplayFor="'administrator'">
      <div class="card">
        <div class="card-image">
          <figure class="image">
            <img src="/assets/img/main/wordpress.png" alt="Image" />
          </figure>
        </div>
        <header class="card-header">
          <p class="card-header-title">
            {{ 'MAIN.WP.LABEL' | translate }}
          </p>
        </header>
        <div class="card-content adjust-height">
          <div class="content">
            {{ 'MAIN.WP.MESSAGE' | translate }}
          </div>
        </div>
        <footer class="card-footer">
          <a class="card-footer-item" [routerLink]="['auto/wp']">{{
            'MAIN.COMMON.CONFIGURE' | translate
          }}</a>
        </footer>
      </div>
    </div>

    <div class="column is-one-third" *appDisplayFor="'administrator'">
      <div class="card">
        <div class="card-image">
          <figure class="image">
            <img src="/assets/img/main/welcome.png" alt="Image" />
          </figure>
        </div>
        <header class="card-header">
          <p class="card-header-title">
            {{ 'MAIN.WELCOME.LABEL' | translate }}
          </p>
        </header>
        <div class="card-content adjust-height">
          <div class="content">
            {{ 'MAIN.WELCOME.MESSAGE' | translate }}
          </div>
        </div>
        <footer class="card-footer">
          <a class="card-footer-item" [routerLink]="['auto/welcome']">{{
            'MAIN.COMMON.CONFIGURE' | translate
          }}</a>
        </footer>
      </div>
    </div>
  </div>
</div>

<!--{{selected$ | async | json}}-->
<!-- <img src="{{(selected$ | async)?.icon}}"> -->
