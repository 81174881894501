<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{ 'NOTIFICATION_EDITOR.QUERY_EDITOR.LABEL' | translate }}
      </div>
      <div class="content">
        <p class="control">
          <span class="selectLabel"
            >{{ 'NOTIFICATION_EDITOR.QUERY_EDITOR.MODE' | translate }}:</span
          >
          <span class="select">
            <select [(ngModel)]="query.mode">
              <option *ngFor="let mode of modes" [ngValue]="mode">{{
                mode
              }}</option>
            </select>
          </span>
        </p>
        <hr />
        <form (submit)="add(param)" class="is-flex">
          <p class="paramsLabel">
            {{ 'NOTIFICATION_EDITOR.QUERY_EDITOR.PARAMETERS' | translate }}:
          </p>
          <div class="field has-addons">
            <div class="control">
              <input
                [(ngModel)]="param"
                name="param"
                class="input"
                type="text"
                placeholder="{{
                  'NOTIFICATION_EDITOR.QUERY_EDITOR.PLACEHOLDER' | translate
                }}"
              />
            </div>
            <div class="control">
              <button type="submit" class="button">
                {{ 'NOTIFICATION_EDITOR.QUERY_EDITOR.ADD' | translate }}
              </button>
            </div>
          </div>
        </form>
        <p class="control paramsList" *ngIf="query.params.length > 0">
          <span class="tag is-light is-medium" *ngFor="let p of query.params"
            >{{ p }}<button (click)="remove(p)" class="delete is-small"></button
          ></span>
        </p>
        <article class="message" *ngIf="query.params.length <= 0">
          <div class="message-body">
            {{ 'NOTIFICATION_EDITOR.QUERY_EDITOR.NO_PARAMS' | translate }}
          </div>
        </article>
        <div class="has-text-right" *ngIf="query.params.length <= 0">
          <div class="button csvupload is-relative">
            <span>{{
              'NOTIFICATION_EDITOR.QUERY_EDITOR.CSV' | translate
            }}</span>
            <input type="file" class="hide" (change)="loadcsv($event)" />
          </div>
        </div>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button class="button is-success p7-button" (click)="ok()">
            {{ 'NOTIFICATION_EDITOR.QUERY_EDITOR.OK' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
