
    <div class="field">
      <label class="label">パラメータ配信条件</label>
      <div class="control">
        <div class="select">
          <select [(ngModel)]="query.mode">
            <option value="AND">AND</option>
            <option value="OR">OR</option>
            <option value="NAND">NAND</option>
            <option value="NOR">NOR</option>
          </select>
        </div>
      </div>
    </div>
    <div class="field has-addons">
      <div class="control">
        <input class="input" type="text" [(ngModel)]="param" />
      </div>
      <div class="control">
        <a class="button" (click)="add(param)">
          パラメータ追加
        </a>
      </div>
    </div>
    <div class="field" *ngIf="query.params?.length > 0">
      <label class="label">パラメータ一覧</label>
      <p class="control" *ngIf="query.params?.length > 0">
        <span class="tag mr-1 is-light is-medium" *ngFor="let p of query.params"
          >{{ p }}<button (click)="remove(p)" class="delete is-small"></button
        ></span>
      </p>
    </div>
    <article class="message is-warning" *ngIf="query.params?.length <= 0">
      <div class="message-body">
        パラメータが設定されていません
      </div>
    </article>
    <div class="field">
      <div class="file">
        <label class="file-label">
          <input
            class="file-input"
            (change)="loadcsv($event)"
            type="file"
            name="csv"
          />
          <span class="file-cta">
            <span class="file-icon">
              <i class="fas fa-upload"></i>
            </span>
            <span class="file-label">
              パラメータをCSVからロード
            </span>
          </span>
        </label>
      </div>
    </div>
  