import {
  Directive,
  ElementRef,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Observable, Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { Project } from '../../services/api';
import { AuthService } from '../../services/auth/auth.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { switchMap, map, takeUntil } from 'rxjs/operators';
import { hasSufficientPermission, PermissionRole } from '../../helpers/roleCmp';

@Directive({
  selector: '[appEnableFor]'
})
export class EnableForDirective implements OnInit, OnDestroy {
  private role$: Observable<Project.RoleEnum>;
  private unsubscribe$: Subject<void> = new Subject();
  private requires$: BehaviorSubject<PermissionRole | null> = new BehaviorSubject(
    null
  );
  constructor(
    private el: ElementRef<HTMLElement>,
    private authService: AuthService,
    private store: Store<fromRoot.State>
  ) {
    this.role$ = this.store.select(fromRoot.getAppSelected).pipe(
      takeUntil(this.unsubscribe$),
      switchMap(app => this.authService.getProjectFromAppno(app.appno)),
      map(project => project.role)
    );
  }
  ngOnInit(): void {
    combineLatest(this.role$, this.requires$)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(([role, requires]) => hasSufficientPermission(role, requires))
      )
      .subscribe(hasPermission => {
        if (hasPermission) {
          this.el.nativeElement.removeAttribute('disabled');
        } else {
          this.el.nativeElement.setAttribute('disabled', 'true');
        }
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  @Input('appEnableFor') set requires(role: PermissionRole) {
    this.requires$.next(role);
  }
}
