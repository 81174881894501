import { Action } from '@ngrx/store';
import { type } from '../helpers/type';
import { AppModel } from '../models/app.model';
import { NotificationModel } from '../models/notification.model';
import { Query } from '../models/segments.model';

export const ActionTypes = {
  LOAD: type('[APP] Load'),
  LOAD_COMPLETE: type('[APP] Load Complete'),
  SELECT: type('[APP] Select'),
  DESELECT: type('[APP] Deselect'),
  LOAD_NOTIFICATIONS: type('[APP] Load Notifications'),
  PUSH_NOTIFICATIONS: type('[APP] Push Notifications'),
  UNSHIFT_NOTIFICATIONS: type('[APP] Unshift Notifications'),
  LOAD_QUERIES: type('[APP] Load Queries'),
  PUSH_QUERIES: type('[APP] Push Queries'),
  UNSHIFT_QUERIES: type('[APP] Unshift Queries'),
  DISAPPEAR_NOTIFICATION: type('[APP] DISAPPEAR Single Notification'),
  DISAPPEAR_NOTIFICATION_COMPLETE: type(
    '[APP] DISAPPEAR Single Notification Completed'
  ),
  LOAD_NOTIFICATIONS_COMPLETE: type('[APP] Load Notifications Complete'),
  LOAD_RESERVATIONS: type('[APP] Load Reservations'),
  LOAD_RESERVATIONS_COMPLETE: type('[APP] Load Reservations Complete')
};

export class LoadAppAction implements Action {
  type = ActionTypes.LOAD;
  constructor(public payload: string) {}
}
export class LoadAppCompleteAction implements Action {
  type = ActionTypes.LOAD_COMPLETE;
  constructor(
    public payload: {
      appno: string;
      data: AppModel;
    }
  ) {}
}
export class SelectAppAction implements Action {
  type = ActionTypes.SELECT;
  constructor(public payload: any) {}
}
export class DeselectAppAction implements Action {
  type = ActionTypes.DESELECT;
  constructor(public payload?: string) {}
}
export class LoadNotificationsAction implements Action {
  type = ActionTypes.LOAD_NOTIFICATIONS;
  constructor(
    public payload: {
      appno?: string;
      page: number;
    }
  ) {}
}
export class PushNotificationsAction implements Action {
  type = ActionTypes.PUSH_NOTIFICATIONS;
  constructor(
    public payload: {
      data: {
        [pushid: string]: NotificationModel;
      };
      order: string[];
      count?: number;
    }
  ) {}
}
export class UnshiftNotificationsAction implements Action {
  type = ActionTypes.UNSHIFT_NOTIFICATIONS;
  constructor(
    public payload: {
      data: {
        [pushid: string]: NotificationModel;
      };
      order: string[];
      count?: number;
    }
  ) {}
}
export class LoadNotificationsCompleteAction implements Action {
  type = ActionTypes.LOAD_NOTIFICATIONS_COMPLETE;
  constructor(
    public payload: {
      page?: number;
      data?: NotificationModel[];
    }
  ) {}
}
export class DisappearNotificationAction implements Action {
  type = ActionTypes.DISAPPEAR_NOTIFICATION;
  constructor(public payload: string) {}
}
export class DisappearNotificationCompleteAction implements Action {
  type = ActionTypes.DISAPPEAR_NOTIFICATION_COMPLETE;
  constructor(public payload: string) {}
}
export class LoadReservationsAction implements Action {
  type = ActionTypes.LOAD_RESERVATIONS;
  constructor(
    public payload?: {
      appno?: string;
      page?: number;
    }
  ) {}
}
export class LoadReservationsCompleteAction implements Action {
  type = ActionTypes.LOAD_RESERVATIONS_COMPLETE;
  constructor(
    public payload: {
      appno?: string;
      data?: NotificationModel[];
    }
  ) {}
}

export type Actions =
  | LoadAppAction
  | LoadAppCompleteAction
  | SelectAppAction
  | DeselectAppAction
  | LoadNotificationsAction
  | LoadNotificationsCompleteAction
  | PushNotificationsAction
  | UnshiftNotificationsAction
  | DisappearNotificationAction
  | DisappearNotificationCompleteAction
  | LoadReservationsAction
  | LoadReservationsCompleteAction;
