import { ASTNode } from './../../models/segments.model';
import {
  AstSelectorComponent,
  AstSelectorOptionType
} from './ast-selector/ast-selector.component';
import { Modal } from './../../services/modal/modal.service';
import { Component, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ast-add-elem',
  template: `
    <div class="messgae is-success">
      <div class="message-header">
        <p>要素を追加してください</p>
      </div>
      <div class="message-body">
        <button class="button is-success is-outlined" (click)="open()">
          新規要素
        </button>
      </div>
    </div>
  `
})
export class AstAddElemComponent {
  @Input()
  withoutValue = false;
  @Output()
  addElem: EventEmitter<ASTNode> = new EventEmitter<ASTNode>();
  constructor(private modal: Modal) {}
  public open() {
    this.modal
      .open(AstSelectorComponent, {
        withoutValue: this.withoutValue
      } as AstSelectorOptionType)
      .then(
        (newElem: ASTNode) => this.addElem.emit(newElem),
        () => {}
      );
  }
}
