import { Component, OnInit, Input, ElementRef } from '@angular/core';

import * as Clipboard from 'clipboard';

@Component({
  selector: 'app-copy-textarea',
  templateUrl: './copy-textarea.component.html',
  styleUrls: ['./copy-textarea.component.scss']
})
export class CopyTextareaComponent implements OnInit {
  private _el: HTMLElement;
  clipboard: any;
  succeeded: boolean;
  failed: boolean;
  @Input()
  copyText: string;
  @Input()
  copyLabel: string;
  constructor(el: ElementRef) {
    this._el = el.nativeElement;
  }

  ngOnInit() {
    this.clipboard = new Clipboard(
      this._el.querySelector('button.copy-button'),
      {
        target: () => {
          return this._el.querySelector('textarea.copy');
        }
      }
    );
    this.clipboard.on('success', e => {
      this.succeeded = true;
      setTimeout(() => (this.succeeded = false), 2000);
    });
    this.clipboard.on('error', e => {
      this.failed = true;
      setTimeout(() => (this.failed = false), 2000);
    });
  }
}
