
    <div class="message" *ngIf="value">
      <div
        class="message-header"
        [appAstDraggable]="value"
        (onMoved)="removeFromParentNode.emit()"
      >
        <p>最終訪問時間</p>
        <a
          *ngIf="!hideDelete"
          class="delete"
          (click)="removeFromParentNode.emit()"
        ></a>
      </div>
      <div class="message-body">
        <app-segment-datequery-editor
          [(dateQuery)]="value.time"
        ></app-segment-datequery-editor>
      </div>
    </div>
  