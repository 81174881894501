<div>
  <div class="field">
    <div class="control has-text-right">
      <div class="select">
        <select [(ngModel)]="device">
          <option value="iPhone">iPhone</option>
          <option value="Android">Android</option>
          <option value="Mac">Mac</option>
          <option value="Windows">Windows</option>
        </select>
      </div>
    </div>
  </div>
  <div *ngIf="device === 'iPhone'">
    <div class="preview-iphone">
      <app-preview-device-iphone
        [notification]="notification"
        [appUrl]="(app$ | async).url"
      >
      </app-preview-device-iphone>
    </div>
  </div>
  <div *ngIf="device === 'Mac'">
    <div class="preview-mac">
      <app-preview-device-mac
        [notification]="notification"
        [appUrl]="(app$ | async).url"
      >
      </app-preview-device-mac>
    </div>
  </div>
  <div *ngIf="device === 'Android'">
    <div class="preview-android">
      <app-preview-device-android
        [notification]="notification"
        [appUrl]="(app$ | async).url"
      >
      </app-preview-device-android>
    </div>
  </div>
  <div *ngIf="device === 'Windows'">
    <div class="preview-windows">
      <app-preview-device-windows
        [notification]="notification"
        [appUrl]="(app$ | async).url"
      >
      </app-preview-device-windows>
    </div>
  </div>
</div>
