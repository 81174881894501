
    <div class="form">
      <div class="select">
        <select
          [ngModel]="keyword"
          (change)="keywordChange.next($event.target.value)"
        >
          <option value="NOW">現在</option>
          <option value="TODAY">今日</option>
        </select>
      </div>
    </div>
  