/**
 * Push7 Project Feature for push7-apiserver
 * Push7 Project Featurs API specfication
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: enginners@gnex.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AppHead } from './appHead';
import { ProjectEnterprise } from './projectEnterprise';
import { ProjectEnterpriseApproval } from './projectEnterpriseApproval';


/**
 * A project.
 */
export interface Project { 
    uuid?: string;
    /**
     * all app belongs this project (order by app.created_at asc)
     */
    apps?: Array<AppHead>;
    /**
     * A name of this project
     */
    name?: string;
    /**
     * createdAt for this project
     */
    created_at?: string;
    /**
     * プロジェクトが法人のものかどうか
     */
    is_corporation?: boolean;
    /**
     * このプロジェクトに対するあなたのロール
     */
    role?: Project.RoleEnum;
    enterprise?: ProjectEnterprise;
    enterprise_approval?: ProjectEnterpriseApproval;
}
export namespace Project {
    export type RoleEnum = 'administrator' | 'operator' | 'viewer' | 'spy_viewer' | 'spy_administrator';
    export const RoleEnum = {
        Administrator: 'administrator' as RoleEnum,
        Operator: 'operator' as RoleEnum,
        Viewer: 'viewer' as RoleEnum,
        SpyViewer: 'spy_viewer' as RoleEnum,
        SpyAdministrator: 'spy_administrator' as RoleEnum
    };
}


