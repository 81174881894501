import {
  Injectable,
  ViewContainerRef,
  Component,
  ViewChild,
  Directive,
  AfterViewInit,
  OnInit,
  ElementRef
} from '@angular/core';
import { Notification, NotificationContext } from './notification.service';

@Directive({
  selector: '[appNotificationInner]'
})
export class NotificationInnerDirective {
  constructor(public vcr: ViewContainerRef) {}
}

@Component({
  selector: 'app-notification-entry',
  template: `
    <div class="notifications">
      <div appNotificationInner></div>
    </div>
  `,
  styles: [
    `
      .notifications {
        display: flex;
        position: fixed;
        top: 0;
        right: 0;
      }
      [appNotificationInner] {
        display: flex;
        flex-direction: column-reverse;
      }
    `
  ],
  providers: [NotificationInnerDirective]
})
export class NotificationEntryComponent implements AfterViewInit {
  @ViewChild(NotificationInnerDirective, { static: true })
  private inner: NotificationInnerDirective;
  constructor(public notification: Notification) {}
  ngAfterViewInit() {
    this.notification.set(this.inner.vcr);
  }
}

@Component({
  selector: 'app-notification',
  template: `
    <div class="p7-notification notification" [ngClass]="elementClass">
      <button class="delete" (click)="close()"></button>
      <div [innerHTML]="text"></div>
    </div>
  `,
  styles: [
    `
      .notification {
        margin: 10px 0;
        position: relative;
        opacity: 0;
        transform: translate(410px);
        transition-property: opacity, transform;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
      }
      .notification.in {
        opacity: 1;
        transform: translate(0px);
        transition-duration: 0.3s;
      }
    `
  ]
})
export class NotificationComponent implements OnInit {
  public text: string;
  public elementClass: string[];
  private closed = false;
  private _el: HTMLElement;
  constructor(
    private notificationCtx: NotificationContext,
    private el: ElementRef
  ) {
    this.text = notificationCtx.text;
    this.elementClass = ['is-' + notificationCtx.type];
    this._el = el.nativeElement;
  }
  close() {
    this.closed = true;
    this.notificationCtx.resolve();
  }
  ngOnInit() {
    setTimeout(() => {
      this._el.querySelector('.notification').classList.add('in');
    }, 10);
    setTimeout(() => {
      this._el.querySelector('.notification').classList.remove('in');
    }, this.notificationCtx.timeout * 1000 - 250);
    setTimeout(() => {
      if (!this.closed) {
        this.notificationCtx.resolve();
      }
    }, this.notificationCtx.timeout * 1000);
  }
}
