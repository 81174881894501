<app-navbar></app-navbar>
<div class="main-nav independent">
  <div class="container">
    {{ 'PASSWORD.LABEL' | translate }}
  </div>
</div>
<div class="columns is-tablet">
  <div class="column is-6 is-offset-3">
    <div class="container main-container">
      <div class="container-body">
        <form (submit)="submit()">
          <p class="msg">{{ 'PASSWORD.MESSAGE' | translate }}</p>
          <div class="field">
            <label class="label adjust-margin">{{
              'PASSWORD.CURRENT' | translate
            }}</label>
            <p class="control">
              <input
                class="input"
                type="password"
                name="current"
                [(ngModel)]="current"
              />
            </p>
          </div>
          <div class="field">
            <label class="label">{{ 'PASSWORD.NEXT' | translate }}</label>
            <p class="control">
              <input
                class="input"
                type="password"
                name="new"
                [(ngModel)]="next"
              />
            </p>
          </div>
          <div class="field">
            <label class="label">{{ 'PASSWORD.CONFIRM' | translate }}</label>
            <p class="control">
              <input
                class="input"
                type="password"
                name="confirm"
                [(ngModel)]="confirm"
              />
            </p>
          </div>
          <div class="has-text-centered">
            <button
              type="submit"
              class="button is-success inline-block has-text-centered"
            >
              {{ 'PASSWORD.SUBMIT' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
