import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from '../../../services/app/app.service';
import { Query } from '../../../models/segments.model';
import { ModalContext } from '../../../services/modal/modal.service';

@Component({
  templateUrl: './segmentation-query-selector-v2.component.html'
})
export class SegmentationQuerySelectorV2Component {
  public queries$: Observable<Query[]>;
  constructor(private app: AppService, private modalCtx: ModalContext) {
    this.queries$ = this.app.get_queries();
  }
  close() {
    this.modalCtx.reject();
  }
  select(name: string, query: string) {
    this.modalCtx.resolve({ name, query });
  }
}
