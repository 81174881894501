/**
 * Push7 Project Feature for push7-apiserver
 * Push7 Project Featurs API specfication
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: enginners@gnex.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProjectEnterpriseApproval { 
    /**
     * 許諾ステータス none ... 申し込みしていない状態。フロントから申し込みができる。 pending ... 申し込みをした状態。フロントから申し込みできない。GNEXがSlackで対応する必要がある。 approved ... エンタープライズプランのプランテーブルを閲覧出来，ダッシュボードからプラン申し込みが出来る。 rejected ...  拒否, フロントから再申し込みできる banned ... 二度と申込みが出来ない。 
     */
    status?: ProjectEnterpriseApproval.StatusEnum;
    reason?: string;
}
export namespace ProjectEnterpriseApproval {
    export type StatusEnum = 'none' | 'pending' | 'approved' | 'rejected' | 'banned';
    export const StatusEnum = {
        None: 'none' as StatusEnum,
        Pending: 'pending' as StatusEnum,
        Approved: 'approved' as StatusEnum,
        Rejected: 'rejected' as StatusEnum,
        Banned: 'banned' as StatusEnum
    };
}


