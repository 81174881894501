import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { uniqueId } from 'lodash';
import {
  UrlQuery,
  ASTUrlJust,
  ASTUrlPrefix
} from '../../../../models/segments.model';

@Component({
  selector: 'app-segment-urlquery-editor',
  styles: [
    `
      :host {
        display: block;
        max-width: 400px;
      }
    `
  ],
  template: `
    <div class="control">
      <label class="label">URLのマッチ方法</label>
      <label class="radio">
        <input
          type="radio"
          [name]="randomId"
          [ngModel]="kind"
          (change)="update('JUST')"
          value="JUST"
        />
        完全一致
      </label>
      <label class="radio">
        <input
          type="radio"
          [name]="randomId"
          [ngModel]="kind"
          (change)="update('PREFIX')"
          value="PREFIX"
        />
        前方一致
      </label>
    </div>
    <div class="control">
      <label class="label mt-3" placeholder="http://example.com/campaign"
        >URL</label
      >
      <input
        class="input"
        type="url"
        placeholder="http://example.com/news"
        [ngModel]="urlQuery.url"
        (ngModelChange)="changeUrl($event)"
      />
    </div>
    <ng-container [ngSwitch]="urlQuery.className">
      <div *ngSwitchCase="'ASTUrlJust'">
        <p>上記URLに完全一致する記録を検索します</p>
      </div>
      <div *ngSwitchCase="'ASTUrlPrefix'">
        <p>上記URLに前方一致する記録を検索します</p>
      </div>
    </ng-container>
  `
})
export class UrlQueryEditorComponent implements OnInit {
  public kind: 'JUST' | 'PREFIX';
  @Input()
  urlQuery: UrlQuery;
  @Output()
  urlQueryChange: EventEmitter<UrlQuery> = new EventEmitter<UrlQuery>();

  public randomId: string;
  constructor() {
    this.randomId = uniqueId();
  }
  ngOnInit(): void {
    switch (this.urlQuery.className) {
      case 'ASTUrlJust':
        this.kind = 'JUST';
        break;
      case 'ASTUrlPrefix':
        this.kind = 'PREFIX';
        break;
    }
  }
  update(type: 'JUST' | 'PREFIX') {
    switch (type) {
      case 'JUST':
        this.urlQueryChange.next(new ASTUrlJust());
        break;
      case 'PREFIX':
        this.urlQueryChange.next(new ASTUrlPrefix());
        break;
    }
  }
  changeUrl(url: string) {
    const q = this.urlQuery;
    q.url = url;
    this.urlQueryChange.emit(q);
  }
}
