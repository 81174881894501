<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" (click)="home()">
      <img src="/assets/img/logo.svg" alt="Push7" width="100" height="26" />
    </a>
  </div>
  <div class="navbar-menu">
    <div class="navbar-end">
      <div
        class="navbar-item has-dropdown is-hoverable"
        *ngIf="(selected$ | async)?.name"
      >
        <a class="navbar-link is-size-6">
          <span class="icon is-medium">
            <img [src]="(selected$ | async)?.icon" />
          </span>
          <span class="myapp-name">{{ (selected$ | async)?.name }}</span>
        </a>
        <div
          class="navbar-dropdown is-right is-boxed"
          *ngIf="(nonSelected$ | async).length > 0"
        >
          <a
            class="navbar-item"
            *ngFor="let app of nonSelected$ | async"
            (click)="openApp(app.appno)"
          >
            <span class="icon is-medium">
              <img src="{{ app.icon }}" alt="" />
            </span>
            <span>{{ app.name }}</span>
          </a>
        </div>
      </div>
      <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
          <span>{{ (profile$ | async)?.email }}</span>
        </a>
        <div class="navbar-dropdown is-right is-boxed">
          <a class="navbar-item" [routerLink]="['/password']">
            {{ 'NAVBAR.CHPASSWD' | translate }}
          </a>
          <a class="navbar-item" [routerLink]="['/contact']">
            {{ 'NAVBAR.CONTACT' | translate }}
          </a>
          <a class="navbar-item" [routerLink]="['/account-delete']">
            {{ 'NAVBAR.DELETE_ACCOUNT' | translate }}
          </a>
          <a class="navbar-item" (click)="logout()">
            {{ 'NAVBAR.LOGOUT' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>

<!--

<header class="nav">
  <div class="container is-fluid">
    <div class="nav-left">
      <a class="nav-item" (click)="home()">
        <img src="/assets/img/logo.svg" alt="Logo">
      </a>
    </div>
    <div class="nav-right nav-menu">
      <span class="nav-item application-menu-wrapper">
        <a *ngIf="(selected$ | async)?.name" (click)="showApps = !showApps;showUserDropdown = false;" class="button is-primary is-inverted">
          <span class="icon">
            <img [src]="(selected$ | async)?.icon">
          </span>
          <span class="myapp-name">{{(selected$ | async)?.name}}</span>
        </a>
        <ul class="p7-dropdown application-menu" *ngIf="(nonSelected$ | async).length > 0" [style.display]="showApps ? 'block' : 'none'">
          <li *ngFor="let app of (nonSelected$ | async)">
            <a (click)="openApp(app.appno)">
              <span class="icon">
                <img src="{{app.icon}}" alt="">
              </span>
              {{app.name}}
            </a>
          </li>
        </ul>
      </span>
      <span class="nav-item application-menu-wrapper">
        <a class="button is-primary is-inverted" (click)="showUserDropdown = !showUserDropdown;showApps = false;">
          <span class="icon">
            <i class="fa fa-angle-down" aria-hidden="true"></i>
          </span>
          <span>{{(user$ | async)?.email}}</span>
        </a>
        <ul class="p7-dropdown application-menu" [style.display]="showUserDropdown ? 'block' : 'none'">
          <li>
            <a [routerLink]="['/password']">
              {{'NAVBAR.CHPASSWD'|translate}}
            </a>
          </li>
          <li>
            <a [routerLink]="['/contact']">
              {{'NAVBAR.CONTACT'|translate}}
            </a>
          </li>
          <li>
            <a [routerLink]="['/account-delete']">
              {{'NAVBAR.DELETE_ACCOUNT'|translate}}
            </a>
          </li>
          <li>
            <a (click)="logout()">
              {{'NAVBAR.LOGOUT'|translate}}
            </a>
          </li>
        </ul>
      </span>
    </div>
  </div>
</header>
-->
