<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{ 'TAGS.SDK.LABEL' | translate }}
      </div>
      <div class="content">
        <div [innerHTML]="'TAGS.SDK.MESSAGE' | translate"></div>
        <app-copy-textarea [copyText]="sdk"></app-copy-textarea>
      </div>
      <div class="content-footer">
        <div class="has-text-right">
          <button class="button is-dark p7-button" (click)="close()">
            {{ 'COMMON.CLOSE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="modal-close" (click)="close()"></button>
</div>
