import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { LoadBalanceStatus } from '../../../../models/notification.model';
import { getAppSelectedId, State } from '../../../../reducers';
import {
  AutomationAction,
  DefaultService as ApiService
} from '../../../../services/api';
import { Modal } from '../../../../services/modal/modal.service';
import { AutomationHistoryDetailModalComponent } from './automation-history-detail-modal/automation-history-detail-modal.component';

const LIMIT = 50;

@Component({
  templateUrl: './automation-history.component.html',
  styleUrls: ['./automation-history.component.scss']
})
export class AutomationHistoryComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject<void>();
  public page$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
  public max: number;
  public actions$: Observable<AutomationAction[]>;

  translateStatus(status: LoadBalanceStatus): string {
    switch (status) {
      case 'canceled':
        return 'キャンセル済み';
      case 'completed':
        return '配信完了';
      case 'failed':
        return '失敗';
      case 'running':
        return '配信中';
      case 'scheduled':
        return '予約済み';
      case 'preparing':
        return '準備中';
    }
  }

  constructor(
    private store: Store<State>,
    private api: ApiService,
    private route: ActivatedRoute,
    private modal: Modal,
    private router: Router
  ) {}
  ngOnInit() {
    this.max = 0;
    this.actions$ = this.page$.asObservable().pipe(
      takeUntil(this.onDestroy$),
      withLatestFrom(this.store.select(getAppSelectedId)),
      withLatestFrom(
        this.route.params.pipe(map(params => params['id'] as string))
      ),
      switchMap(([[page, appno], id]) =>
        this.api.listAutomationActions(appno, id, LIMIT, LIMIT * (page - 1))
      ),
      tap(res => (this.max = Math.ceil(res.count / LIMIT))),
      map(res => res.automation_actions),
      shareReplay(1)
    );
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  openActionDetail(action: AutomationAction) {
    // このモーダルはresolveしかしないのでcatchは必要ない
    this.modal.open(AutomationHistoryDetailModalComponent, { action });
  }
  back() {
    this.router.navigate(['../..'], { relativeTo: this.route });
  }
}
