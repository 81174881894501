<div class="performance-index">
  <div class="performance-graph">
    <div class="graph-menu">
      <div class="graph-menu-types">
        <div class="types-element">
          <div class="types-rect rect-send"></div>
          <span>{{ 'ANALYTICS.PERFORMANCE.SENT_COUNT' | translate }}</span>
        </div>

        <div class="types-element">
          <div class="types-rect rect-open"></div>
          <span>{{ 'ANALYTICS.PERFORMANCE.CLICKED_COUNT' | translate }}</span>
        </div>
      </div>

      <div class="graph-menu-list">
        <ul>
          <li>
            <button
              class="button is-light"
              [class.is-active]="(scope$ | async) === 'hour'"
              (click)="changeScope('hour')"
            >
              {{ 'ANALYTICS.OVERVIEW.SCOPE.HOURLY' | translate }}
            </button>
          </li>
          <li>
            <button
              class="button is-light"
              [class.is-active]="(scope$ | async) === 'day'"
              (click)="changeScope('day')"
            >
              {{ 'ANALYTICS.OVERVIEW.SCOPE.DAILY' | translate }}
            </button>
          </li>
          <li>
            <button
              class="button is-light"
              [class.is-active]="(scope$ | async) === 'week'"
              (click)="changeScope('week')"
            >
              {{ 'ANALYTICS.OVERVIEW.SCOPE.WEEKLY' | translate }}
            </button>
          </li>
          <li>
            <button
              class="button is-light"
              [class.is-active]="(scope$ | async) === 'month'"
              (click)="changeScope('month')"
            >
              {{ 'ANALYTICS.OVERVIEW.SCOPE.MONTHLY' | translate }}
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div class="graph-body">
      <div class="graph-body-topbar">
        <app-range-picker
          [startDate]="startDate"
          [endDate]="endDate"
        ></app-range-picker>
      </div>

      <div class="graph-canvas-wrap">
        <canvas
          height="370"
          [style.display]="isLoading ? 'none' : 'block'"
        ></canvas>
        <app-loading
          [style.display]="isLoading ? 'block' : 'none'"
        ></app-loading>
      </div>
    </div>
  </div>

  <table class="table is-striped p7-table is-fullwidth">
    <thead>
      <tr class="performance-table-header">
        <th>{{ 'PUSH.COMMON.CREATED' | translate }}</th>
        <th>{{ 'PUSH.COMMON.TITLE' | translate }}</th>
        <th>{{ 'PUSH.COMMON.URL' | translate }}</th>
        <th>{{ 'PUSH.COMMON.BODY' | translate }}</th>
        <th>{{ 'PUSH.COMMON.SENT' | translate }}</th>
        <th>{{ 'PUSH.COMMON.CLICK' | translate }}</th>
      </tr>
    </thead>
    <ng-container
      *ngIf="this.list$ | async; then Loaded; else Loading"
    ></ng-container>
    <ng-template #Loaded>
      <tbody>
        <tr
          [routerLink]="['../detail/' + noti.id + '']"
          *ngFor="let noti of list$ | async"
        >
          <td>{{ noti.created_date | dateParse | date: 'y/M/d, HH:mm' }}</td>
          <td>{{ noti.title }}</td>
          <td>{{ noti.url }}</td>
          <td>{{ noti.body }}</td>
          <td>{{ noti.success_send_count || noti.subscribers }}</td>
          <td>{{ noti.clicked_count }}</td>
        </tr>
      </tbody>
    </ng-template>
    <ng-template #Loading>
      <tbody class="table-loading">
        <tr *ngFor="let i of [0, 1, 2, 3, 4, 5]">
          <td>...</td>
          <td>...</td>
          <td>...</td>
          <td>...</td>
          <td>...</td>
          <td>...</td>
        </tr>
      </tbody>
    </ng-template>
  </table>

  <div class="pagination" style="margin-top:20px; margin-bottom:40px">
    <a
      class="pagination-previous has-background-primary"
      (click)="prev()"
      [class.is-disabled]="page == 1 || !(this.list$ | async)"
    >
      <i class="fa fa-caret-left" aria-hidden="true"></i>
    </a>
    <div class="pagination-list">
      <span class="button view is-primary">{{ page }}/{{ max }}</span>
    </div>
    <a
      class="pagination-next has-background-primary"
      (click)="next()"
      [class.is-disabled]="page == max || !(this.list$ | async)"
    >
      <i class="fa fa-caret-right" aria-hidden="true"></i>
    </a>
  </div>
</div>
