<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-header has-text-weight-bold is-size-4">
        {{ 'TAGS.NATIVE.LABEL' | translate }}
      </div>
      <div class="container-body">
        <p class="msg">{{ 'TAGS.NATIVE.MESSAGE' | translate }}</p>
        <div class="step adjust-margin">
          <div class="count">
            <p>1</p>
          </div>
          <div class="content download">
            <p
              class="native-download-link"
              [innerHTML]="'TAGS.NATIVE.DOWNLOAD' | translate"
            ></p>
          </div>
        </div>
        <div class="step">
          <div class="count">
            <p>2</p>
          </div>
          <div class="content">
            <p>{{ 'TAGS.NATIVE.SET' | translate }}</p>
            <ul>
              <li>push7-worker.js</li>
              <li>manifest.json</li>
            </ul>
          </div>
        </div>
        <div class="step">
          <div class="count">
            <p>3</p>
          </div>
          <div class="content">
            <p>{{ 'TAGS.NATIVE.TAG' | translate }}</p>
            <app-copy-textarea [copyText]="sdk"></app-copy-textarea>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
