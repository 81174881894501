<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <ng-container *ngIf="show_enterprise_application | async">
      <div class="container application-card">
        <app-application-card></app-application-card>
      </div>
    </ng-container>

    <div class="container main-container">
      <div class="container-body">
        <ng-container *ngIf="!(isUsingCustomPlan$ | async); else CustomPlan">
          <ng-container *ngIf="plans$ | async; let plans">
            <article class="message is-info" *ngIf="plans.length === 0">
              <div class="message-body">
                現在選択可能なプランがありません
              </div>
            </article>
            <div class="columns is-multiline">
              <div class="column is-4" *ngFor="let plan of plans">
                <div class="card plan">
                  <div class="card-content">
                    <p class="name">{{ plan.name }}</p>
                    <div class="price">
                      <span class="unit">¥</span>
                      <span class="value">{{ plan.amount | number }}</span>
                      <span class="range">/月（税別）</span>
                    </div>
                    <div class="detail">
                      <p
                        [translate]="'PLAN.LIST.LIMIT'"
                        [translateParams]="{
                          sendlimit: plan.sendlimit.toLocaleString()
                        }"
                      ></p>
                    </div>
                    <div class="button-control has-text-centered">
                      <ng-container *ngIf="is_next(plan) | async">
                        <!--
                        選択中は次のプラン。Enterpriseの場合は、契約期間及びオプションを変更することができる。
                       -->
                        <button
                          class="button inline-block"
                          [disabled]="
                            !(isAdministrator$ | async) ||
                            !(nextPlan$ | async).is_enterprise
                          "
                          (click)="change(plan)"
                        >
                          {{ 'PLAN.LIST.SELECTED' | translate }}
                        </button>
                      </ng-container>
                      <ng-container *ngIf="is_current(plan) | async">
                        <!--
                        使用中のプラン。選択中の場合は表示されない（どちらか1つだけ表示するため）
                      -->
                        <button class="button inline-block" disabled>
                          {{ 'PLAN.LIST.USING' | translate }}
                        </button>
                      </ng-container>
                      <ng-container *ngIf="is_not_selected(plan) | async">
                        <button
                          (click)="change(plan)"
                          class="button is-success is-outlined inline-block"
                          [disabled]="
                            !(isAdministrator$ | async) ||
                            !(can_change_to(plan) | async)
                          "
                        >
                          {{ 'PLAN.LIST.SELECT' | translate }}
                        </button>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #CustomPlan>
          <article class="message is-warning">
            <div class="message-body">
              お客様がご利用のプランの変更はダッシュボードから承ることができかねます。お手数ですが、右下のサポートボタンからお問い合わせください。
            </div>
          </article>
        </ng-template>
      </div>
    </div>
  </div>
</div>
