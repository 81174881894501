import { Component } from '@angular/core';
import { ModalContext } from './../../../../../../services/modal/modal.service';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../reducers';
import * as app from '../../../../../../actions/app';

@Component({
  templateUrl: './hide-push-confirm.html',
  selector: 'app-hide-push-confirm'
})
export class HidePushConfirmComponent {
  private pushid: string;
  constructor(
    private modalCtx: ModalContext,
    private store$: Store<fromRoot.State>
  ) {
    this.pushid = this.modalCtx.payload;
  }
  approve() {
    this.modalCtx.resolve();
    this.store$.dispatch(new app.DisappearNotificationAction(this.pushid));
  }
  close() {
    this.modalCtx.reject();
  }
}
