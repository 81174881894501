import {
  EventEmitter,
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../../services/notification/notification.service';

import { AppService } from '../../services/app/app.service';

import Cropper from 'cropperjs/dist/cropper.esm.js';

import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-img-crop',
  templateUrl: './img-crop.component.html',
  styleUrls: ['./img-crop.component.scss']
})
export class ImgCropComponent implements OnInit, AfterViewInit {
  @ViewChild('preview', { static: false })
  private previewRef: ElementRef;
  @ViewChild('editor', { static: false })
  private editorRef: ElementRef;
  @Input()
  public src;
  @Input()
  public aspectRatio: number = null;
  @Output()
  public srcChange: EventEmitter<string> = new EventEmitter<string>();
  public processing = false;
  constructor(
    private translate: TranslateService,
    private notification: Notification,
    private appService: AppService
  ) {}
  ngOnInit() {}
  ngAfterViewInit() {}
  public editing: boolean;
  private cropper;
  private filename: string;
  private filetype: string;
  onChange($event) {
    if ($event.target.files.length <= 0) {
      // if selected
      return;
    }
    const file = $event.target.files[0];
    if (['image/jpeg', 'image/png', 'image/gif'].indexOf(file.type) < 0) {
      this.translate.get('IMGCROP.NONIMAGE').subscribe(message => {
        this.notification.open(message, 'danger');
      });
      return;
    }
    this.filename = file.name;
    this.filetype = file.type;
    // 画像が選択された→Editorを開く
    this.editing = true;
    const previewElem = this.previewRef.nativeElement;
    previewElem.file = file;
    const reader = new FileReader();
    reader.onload = (() => el => {
      this.editorRef.nativeElement.src = el.target.result;
      this.cropper = new Cropper(this.editorRef.nativeElement, {
        aspectRatio: this.aspectRatio,
        modal: true,
        autoCropArea: 1,
        dragMode: 'move',
        zoomOnWheel: false
      });
    })();
    reader.readAsDataURL(file);
  }
  cancel() {
    this.editing = false;
  }
  upload() {
    if (!this.editing) {
      return;
    }
    let type;
    switch (this.filetype) {
      case 'image/jpeg': {
        type = 'image/jpeg';
        break;
      }
      case 'image/png': {
        type = 'image/png';
        break;
      }
      default: {
        type = 'image/png';
        break;
      }
    }
    this.processing = true;
    const data = this.cropper
      .getCroppedCanvas()
      .toDataURL(type)
      .split(',')[1];
    this.appService
      .upload(this.filename, data)
      .pipe<any>(finalize(() => (this.processing = false)))
      .subscribe(
        s => {
          this.src = s.url;
          this.editing = false;
          this.srcChange.emit(this.src);
        },
        (e: HttpErrorResponse) => {
          this.notification.open(`${e.status} ${e.statusText}`, 'danger');
        }
      );
  }
}
