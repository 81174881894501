<section class="hero pre is-fullheight">
  <div class="hero-body">
    <div class="container has-text-centered">
      <img class="logo" src="/assets/img/logo_black.svg" />
      <div class="inner card">
        <div class="card-content">
          <div class="notification has-text-left is-danger" *ngIf="error">
            {{ error }}
          </div>
          <form (submit)="submit()">
            <div class="field">
              <p class="control has-icons-left">
                <input
                  class="input"
                  type="email"
                  required
                  [(ngModel)]="email"
                  name="email"
                  placeholder="{{ 'COMMON.EMAIL' | translate }}"
                />
                <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
              </p>
            </div>
            <div class="field">
              <p class="control has-icons-left">
                <input
                  class="input"
                  type="password"
                  required
                  [(ngModel)]="password"
                  name="password"
                  placeholder="{{ 'COMMON.PASSWORD' | translate }}"
                />
                <span class="icon is-small is-left">
                  <i class="fa fa-lock"></i>
                </span>
              </p>
            </div>
            <div class="field is-grouped is-grouped-centered">
              <p class="control">
                <button class="button is-primary" type="submit">
                  {{ 'LOGIN.BUTTON' | translate }}
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
      <div class="links adjust-margin">
        <p class="adjust-margin">
          <a [routerLink]="['/provisional']">{{
            'PROVISIONAL.LABEL' | translate
          }}</a>
        </p>
        <p class="adjust-margin">
          <a [routerLink]="['/forget']">{{ 'LOGIN.RESET' | translate }}</a>
        </p>
      </div>
    </div>
  </div>
</section>
