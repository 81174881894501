import { Component, OnInit } from '@angular/core';
import {
  LoadBalanceNotificationModel,
  NotificationModel
} from '../../../../../models/notification.model';
import { AutomationAction } from '../../../../../services/api';
import { ModalContext } from '../../../../../services/modal/modal.service';

@Component({
  selector: 'app-automation-history-detail-modal',
  templateUrl: './automation-history-detail-modal.component.html',
  styleUrls: ['./automation-history-detail-modal.component.scss']
})
export class AutomationHistoryDetailModalComponent implements OnInit {
  public action: AutomationAction;
  public get normalPush(): NotificationModel {
    return (this.action.actions.push_notification
      .push as unknown) as NotificationModel;
  }
  public get loadBalancePush(): LoadBalanceNotificationModel {
    return (this.action.actions.push_notification
      .load_balance_push as unknown) as LoadBalanceNotificationModel;
  }
  constructor(private modalCtx: ModalContext) {}

  ngOnInit() {
    if (this.modalCtx.payload && this.modalCtx.payload.action) {
      this.action = this.modalCtx.payload.action;
    } else {
      this.modalCtx.resolve();
    }
  }

  close() {
    this.modalCtx.resolve();
  }
}
