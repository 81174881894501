
    <div class="field">
      <label class="label">ユーザーセグメント</label>
      <ng-container *ngIf="queries$ | async; let queries; else: Loading">
        <table
          class="table is-striped p7-table"
          *ngIf="queries.length > 0; else NoQueries"
        >
          <thead>
            <tr>
              <td>
                セグメント名
              </td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let q of queries">
              <td>{{ q.name }}</td>
              <td>
                <button
                  class="button is-info p7-button is-outlines"
                  (click)="select(q.id)"
                  [disabled]="q.id === queryId"
                >
                  {{ q.id === queryId ? '選択中' : '選択' }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <ng-template #NoQueries>
          <div class="box">
            <div class="content">
              <article class="message is-info">
                <div class="message-header">
                  <p>
                    セグメントがありません
                  </p>
                </div>
                <div class="message-body">
                  <p>
                    サイドバー→セグメント作成と進み、セグメントクエリを作成してください
                  </p>
                </div>
              </article>
            </div>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #Loading>
        <app-loading></app-loading>
      </ng-template>
    </div>
  