import { TranslateService } from '@ngx-translate/core';
import { AppModel } from './../../../../models/app.model';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';

import * as fromRoot from '../../../../reducers';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  public tag: string;
  public type: 't' | 'r' | 'n' = 'n';
  public page: string;
  public buttonText: string;
  public subscribers: number;
  constructor(
    private store: Store<fromRoot.State>,
    private translate: TranslateService
  ) {
    store
      .select(fromRoot.getAppSelected)
      .pipe(take(1))
      .subscribe(app => {
        this.page = 'https://' + (!!app.alias ? app.alias : app.domain);
        this.subscribers = app.subscribers;
      });
    translate
      .get('TAGS.BUTTON.DEFAULT')
      .pipe(take(1))
      .subscribe((m: string) => (this.buttonText = m));
    this.change();
  }

  change() {
    switch (this.type) {
      case 'n': {
        this.tag = `<div class="p7button" data-button-text="${this.buttonText}"></div>`;
        break;
      }
      case 't': {
        this.tag = `<div class="p7button" data-align="top" data-button-text="${this.buttonText}"></div>`;
        break;
      }
      case 'r': {
        this.tag = `<div class="p7button" data-align="right" data-button-text="${this.buttonText}"></div>`;
        break;
      }
    }
  }

  ngOnInit() {}
}
