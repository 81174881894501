import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalContext } from '../../services/modal/modal.service';
import * as Flatpicker from 'flatpickr';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { AppService } from '../../services/app/app.service';
import { Notification } from '../../services/notification/notification.service';
import { LoadAppAction } from '../../actions/app';
import { Observable, combineLatest } from 'rxjs';
import { take, switchMap, map } from 'rxjs/operators';
import { UserService } from '../../services/user/user.service';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  templateUrl: './enterprise-application-modal.component.html',
  styles: ['.field { margin-bottom: 1em; }']
})
export class EnterpriseApplicationComponent {
  public step = 0; // ページ数
  public infoForm: FormGroup;
  public telForm: FormGroup;
  private _el: Element;
  public hours = [11, 12, 13, 14, 15, 16];
  public mins = [0, 10, 20, 30, 40, 50];

  public dates = [2, 3, 4].map(i =>
    moment()
      .add(i, 'days')
      .hour(11)
      .startOf('hour')
  );

  public sending = false;

  private appno$: Observable<string>;
  private email$: Observable<string>;

  constructor(
    private modalCtx: ModalContext,
    private api: AppService,
    private user: UserService,
    el: ElementRef,
    formBuilder: FormBuilder,
    private store: Store<fromRoot.State>,
    private notification: Notification,
    private authService: AuthService
  ) {
    this._el = el.nativeElement;
    this.infoForm = formBuilder.group({
      name: ['', Validators.required],
      department_name: '',
      personnel_name: ['', Validators.required],
      address: ['', Validators.required],
      phone_number: ['', Validators.required],
      is_not_anti_social_org: [false, Validators.requiredTrue]
    });
    // TODO: Trialを経験している場合に情報をロードするようにする
    this.telForm = formBuilder.group({
      tel: ['', Validators.required]
    });
    this.appno$ = this.store.select(fromRoot.getAppSelectedId);
    this.email$ = this.authService.profile$.pipe(map(profile => profile.email));
  }
  public cancel() {
    this.modalCtx.reject();
  }
  public next() {
    this.step++;
  }
  public back() {
    this.step--;
  }
  public submitInfo() {
    // submit value
    this.telForm.controls['tel'].setValue(this.infoForm.value['phone_number']);

    this.sending = true;
    this.api.enterprise_application(this.infoForm.value).subscribe(
      () => {
        // プラン, profileを再読込
        this.authService.loadProfile();
        this.appno$.pipe(take(1)).subscribe(appno => {
          this.store.dispatch(new LoadAppAction(appno));
        });
        this.sending = false;
        this.step = 2;
        // ここでFlatpickerをinitializeする
        setTimeout(() => {
          const inputs = this._el.querySelectorAll('.date_input');
          Array.from(inputs).forEach((input, index) => {
            let instance = new Flatpicker(input, {
              minDate: moment()
                .add(1, 'day')
                .toDate(),
              onChange: ([selectedDate]) => {
                if (!selectedDate) {
                  return;
                }
                this.dates[index]
                  .year(selectedDate.getFullYear())
                  .month(selectedDate.getDate())
                  .date(selectedDate.getDate());
              }
            });
            instance.setDate(this.dates[index].toDate());
          });
        }, 0);
      },
      err => {
        this.notification.open(err.error.error, 'danger');
        this.sending = false;
      }
    );
  }
  public submitTel() {
    this.sending = true;
    combineLatest(this.appno$, this.email$)
      .pipe(
        take(1),
        switchMap(([appno, email]) =>
          this.user.telephone_appointment({
            company: this.infoForm.value['name'],
            tel: this.telForm.value['tel'],
            candidates: this.dates.map(x => x.format('YYYY/MM/DD HH:mm:ss')),
            name: this.infoForm.value['personnel_name'],
            email: email,
            contact_body: `appno "${appno}" のEnterprise本プラン申し込みに伴うテレアポ`
          })
        )
      )
      .subscribe(
        () => {
          this.sending = false;
          this.step = 3;
        },
        err => {
          this.sending = false;
          if (!err.error.result) {
            this.notification.open(
              '送信に失敗しました。電話番号, 日付を確認してださい。',
              'danger'
            );
          }
        }
      );
  }
  public done() {
    this.modalCtx.resolve();
  }
}
