import { Component } from '@angular/core';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { AppModel } from '../../../models/app.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.scss']
})
export class ApiComponent {
  public app$: Observable<AppModel>;
  constructor(private store: Store<fromRoot.State>) {
    this.app$ = this.store.select(fromRoot.getAppSelected);
  }
}
