import { Component } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Modal } from '../../services/modal/modal.service';
import { EnterpriseApplicationComponent } from '../enterprise-application-modal/enterprise-application-modal.component';

@Component({
  selector: 'app-application-card',
  templateUrl: './enterprise-application-card.component.html'
})
export class EnterpriseApplicationCardComponent {
  constructor(private modal: Modal, private authService: AuthService) {}

  public openModal() {
    this.modal
      .open(EnterpriseApplicationComponent)
      .catch(() => {})
      .then(() => {
        this.authService.loadProjects();
      });
  }
}
