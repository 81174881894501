<app-navbar></app-navbar>
<div class="main-nav independent">
  <div class="container">
    {{ 'ACCOUNT_DELETE.LABEL' | translate }}
  </div>
</div>
<div class="columns is-tablet">
  <div class="column is-6 is-offset-3">
    <div class="container main-container">
      <div
        class="container-body content"
        *ngIf="canDeleteAccount$ | async; else DeleteProjectsFirst"
      >
        <p [innerHTML]="'ACCOUNT_DELETE.CANNOT_RECOVER' | translate"></p>
        <ng-container *ngIf="!(profile$ | async)?.is_corporation">
          <div
            [innerHTML]="'ACCOUNT_DELETE.SEE_DOCS' | translate"
            class="content"
          ></div>
        </ng-container>
        <ng-container *ngIf="(profile$ | async)?.is_corporation">
          <div
            [innerHTML]="'ACCOUNT_DELETE.NEED_HELP' | translate"
            class="content"
          ></div>
        </ng-container>
        <p>
          <button
            class="button is-danger is-outlined"
            [disabled]="agreed"
            (click)="agreed = true"
          >
            {{ 'ACCOUNT_DELETE.PROCEED' | translate }}
          </button>
        </p>
        <div *ngIf="agreed">
          <p>{{ 'ACCOUNT_DELETE.ANSWER_SURVEY' | translate }}</p>
          <div class="field">
            <label class="label">{{ 'ACCOUNT_DELETE.Q1' | translate }}</label>
            <div class="control">
              <div class="select">
                <select [(ngModel)]="q1_answer">
                  <option selected="selected" disabled="true"></option>
                  <option
                    *ngFor="let option of q1_options; let i = index"
                    [ngValue]="i"
                    >{{ option }}</option
                  >
                </select>
              </div>
            </div>
            <div class="control" *ngIf="q1_answer == q1_options.length - 1">
              <textarea class="textarea" [(ngModel)]="q1_other"></textarea>
            </div>
          </div>
          <div class="field" *ngIf="q1_answer == 1">
            <label class="label">{{ 'ACCOUNT_DELETE.Q2' | translate }}</label>
            <div class="control">
              <div class="select">
                <select [(ngModel)]="q2_answer">
                  <option selected="selected" disabled="true"></option>
                  <option
                    *ngFor="let option of q2_options; let i = index"
                    [ngValue]="i"
                    >{{ option }}</option
                  >
                </select>
              </div>
            </div>
            <div class="control" *ngIf="q2_answer == q2_options.length - 1">
              <textarea class="textarea" [(ngModel)]="q2_other"></textarea>
            </div>
          </div>
          <div class="field">
            <label class="label">{{ 'ACCOUNT_DELETE.Q3' | translate }}</label>
            <div class="control">
              <textarea class="textarea" [(ngModel)]="opinion"></textarea>
            </div>
          </div>
          <div>
            <button
              class="button is-danger is-outlined"
              [disabled]="!isValid"
              (click)="perform()"
            >
              {{ 'ACCOUNT_DELETE.CONFIRM' | translate }}
            </button>
          </div>
        </div>
      </div>
      <ng-template #DeleteProjectsFirst>
        <div class="container-body content">
          <p>{{ 'ACCOUNT_DELETE.DELETE_PROJECTS' | translate }}</p>
        </div>
      </ng-template>
    </div>
  </div>
</div>
