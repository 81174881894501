/**
 * Push7 Project Feature for push7-apiserver
 * Push7 Project Featurs API specfication
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: enginners@gnex.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AutomationRuleTrigger } from './automationRuleTrigger';
import { AutomationRuleSegmentation } from './automationRuleSegmentation';
import { AutomationRuleActions } from './automationRuleActions';


/**
 * Automation Rule は Push通知の自動配信を実現するルールです 
 */
export interface AutomationRule { 
    /**
     * オートメーションルール名
     */
    name: string;
    /**
     * ステータス. 有効 active, 一時停止 suspended を取る。一時停止中のオートメーションルールは実行されない。
     */
    status?: AutomationRule.StatusEnum;
    /**
     * 同一ルールからの同一購読者あての通知送信を何日間スキップするか？ Union<Int|String> で 0 ~ 365 (日), forever (永遠) が指定可能 
     */
    skip_days: string | number;
    /**
     * Read Only, ISO8601, ルール作成日時 
     */
    created_at?: string;
    /**
     * Read Only, ISO8601, ルール更新日時 
     */
    updated_at?: string;
    trigger: AutomationRuleTrigger;
    segmentation: AutomationRuleSegmentation;
    id?: string;
    actions: AutomationRuleActions;
}
export namespace AutomationRule {
    export type StatusEnum = 'active' | 'suspended';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Suspended: 'suspended' as StatusEnum
    };
}


