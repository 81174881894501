import { NotificationModel } from './../models/notification.model';
import { createSelector } from 'reselect';
import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { environment } from '../../environments/environment';

/**
 * The compose function is one of our most handy tools. In basic terms, you give
 * it any number of functions and it returns a function. This new function
 * takes a value and chains it through every composed function, returning
 * the output.
 *
 * More: https://drboolean.gitbooks.io/mostly-adequate-guide/content/ch5.html
 */
import { compose } from '@ngrx/store';

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
import { storeFreeze } from 'ngrx-store-freeze';

/**
 * combineReducers is another useful metareducer that takes a map of reducer
 * functions and creates a new reducer that stores the gathers the values
 * of each reducer and stores them using the reducer's key. Think of it
 * almost like a database, where every reducer is a table in the db.
 *
 * More: https://egghead.io/lessons/javascript-redux-implementing-combinereducers-from-scratch
 */
import { combineReducers } from '@ngrx/store';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import * as fromApp from './app';
import * as fromAnalytics from './analytics';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  app: fromApp.State;
  analytics: fromAnalytics.State;
  router: fromRouter.RouterReducerState;
}

/**
 * Because metareducers take a reducer function and return a new reducer,
 * we can use our compose helper to chain them together. Here we are
 * using combineReducers to make our top level reducer, and then
 * wrapping that in storeLogger. Remember that compose applies
 * the result from right to left.
 */
export const reducers: ActionReducerMap<State> = {
  app: fromApp.reducer,
  analytics: fromAnalytics.reducer,
  router: fromRouter.routerReducer
};

const developmentReducer = compose(storeFreeze, combineReducers)(reducers);

const productionReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: any, action: any) {
  if (environment.production) {
    return productionReducer(state, action);
  } else {
    return developmentReducer(state, action);
  }
}

/**
 * A selector function is a map function factory. We pass it parameters and it
 * returns a function that maps from the larger state tree into a smaller
 * piece of state. This selector simply selects the `books` state.
 *
 * Selectors are used with the `select` operator.
 *
 * ```ts
 * class MyComponent {
 * 	constructor(state$: Observable<State>) {
 * 	  this.booksState$ = state$.select(getBooksState);
 * 	}
 * }
 * ```
 */
export const getAppState = (state: State) => state.app;
export const getAnalyticsState = (state: State) => state.analytics;

/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them useable, we
 * need to make new selectors that wrap them.
 *
 * Once again our compose function comes in handy. From right to left, we
 * first select the books state then we pass the state to the book
 * reducer's getBooks selector, finally returning an observable
 * of search results.
 *
 * Share memoizes the selector functions and publishes the result. This means
 * every time you call the selector, you will get back the same result
 * observable. Each subscription to the resultant observable
 * is shared across all subscribers.
 */

import { AppModel } from '../models/app.model';

export const getAppsLoaded = createSelector(getAppState, fromApp.getAppsLoaded);
export const getAppSelectedId = createSelector(
  getAppState,
  fromApp.getAppSelectedId
);
export const getAppSelected = createSelector(
  getAppsLoaded,
  getAppSelectedId,
  (apps, appno) => {
    return Object.assign({}, apps[appno], { appno });
  }
);

export const getAppSelectedNotifications = createSelector(
  getAppState,
  getAppSelectedId,
  (appState, appno) => {
    return appState.notifications[appno];
  }
);
export const getTheNotifications = createSelector(
  getAppSelectedNotifications,
  n => {
    if (n.loading) {
      return false; // Objectは空でもtrueなのでfalseの場合はLoading
    }
    // 現在表示するべき通知をリストで返す
    let loaded: NotificationModel[] = [];
    const item = 20; // 1ページに出力する数
    loaded = n.order
      .slice((n.current - 1) * 20, (n.current - 1) * 20 + 20)
      .map(pushid => n.data[pushid]);
    return loaded;
  }
);
export const getAppSelectedReservations = createSelector(
  getAppState,
  getAppSelectedId,
  (appState, appno) => {
    if (!appState.selected || !appState.reservations[appno]) {
      return false;
    }
    if (appState.reservations[appno].loading) {
      return false; // Objectは空でもtrueなのでfalseの場合はLoading
    }
    return appState.reservations[appno].data;
  }
);

export const getAnalyticsSelected = createSelector(
  getAppSelectedId,
  getAnalyticsState,
  (appno, analyticsState) => {
    return analyticsState[appno];
  }
);
export const getAnalyticsInfo = createSelector(
  getAnalyticsSelected,
  analyticsState => {
    if (analyticsState && analyticsState.info) {
      return analyticsState.info;
    }
    return null;
  }
);
export const getAnalyticsOverview = createSelector(
  getAppSelectedId,
  getAnalyticsSelected,
  (appno, analytics) => {
    if (!analytics || !analytics.overview) {
      return;
    }
    return analytics.overview;
  }
);
export const getAnalyticsSubscribers = createSelector(
  getAppSelectedId,
  getAnalyticsSelected,
  (appno, analytics) => {
    if (!analytics || !analytics.subscribers) {
      return;
    }
    return analytics.subscribers;
  }
);
export const getAnalyticsParams = createSelector(
  getAppSelectedId,
  getAnalyticsSelected,
  (appno, analytics) => {
    if (!analytics || !analytics.parameters) {
      return;
    }
    return analytics.parameters;
  }
);
