<div class="crop">
  <div class="preview">
    <figure class="image is-square">
      <img class="img" [class.is-invisible]="!src" [src]="src" alt="" />
    </figure>
    <div
      class="button-wrapper is-overlay is-flex"
      [class.default-hidden]="!!src"
    >
      <label class="button is-shadowless is-dark input-btn">
        {{ 'IMGCROP.CHOOSE' | translate }}
        <input
          type="file"
          class="is-hidden"
          (change)="onChange($event)"
          [(ngModel)]="dummy"
        />
      </label>
      <button
        *ngIf="!!src && canReset"
        class="button is-shadowless is-danger input-btn"
        for="img-reset"
        (click)="reset()"
      >
        {{ 'IMGCROP.RESET' | translate }}
      </button>
    </div>
  </div>
</div>
