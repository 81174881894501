import { Modal } from './../../../services/modal/modal.service';
import { Component, ElementRef, AfterContentInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppModel } from '../../../models/app.model';
import * as fromRoot from '../../../reducers';
import { SdkModalComponent } from './../tags/sdk-modal/sdk-modal.component';
import { EnterpriseTrialComponent } from '../../../components/enterprise-trial-modal/enterprise-trial-modal.component';
import { EnterpriseApplicationComponent } from '../../../components/enterprise-application-modal/enterprise-application-modal.component';
import { ProfileResponse } from '../../../services/backend';
import { AuthService } from '../../../services/auth/auth.service';
import { Project } from '../../../services/api';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements AfterContentInit {
  selected$: Observable<AppModel>;
  profile$: Observable<ProfileResponse>;
  project$: Observable<Project>;
  private elem: Element;
  private sdkLink: Element;
  constructor(
    private store: Store<fromRoot.State>,
    private modal: Modal,
    private _elem: ElementRef,
    private authService: AuthService
  ) {
    this.selected$ = this.store.select(fromRoot.getAppSelected);
    this.profile$ = this.authService.profile$;
    this.project$ = this.selected$.pipe(
      switchMap(app => this.authService.getProjectFromAppno(app.appno!))
    );
  }
  ngAfterContentInit() {
    setTimeout(() => {
      this.elem = this._elem.nativeElement;
      this.sdkLink = this.elem.querySelector('.native-download-link a');
      if (!this.sdkLink) {
        return;
      }
      this.sdkLink.addEventListener('click', () => {
        this.modal.open(SdkModalComponent);
      });
    }, 0);
  }
  sdkModal() {
    this.modal.open(SdkModalComponent).catch(() => {});
  }
  enterpriseTrialModal() {
    this.modal.open(EnterpriseTrialComponent).catch(() => {});
  }
  enterpriseApplicationModal() {
    this.modal
      .open(EnterpriseApplicationComponent)
      .catch(() => {})
      .then(() => {
        this.authService.loadProjects();
      });
  }
}
