<div class="wrap">
  <div class="preview" *ngIf="!editing">
    <img #preview class="img image" src="{{ src }}" />
    <div class="button relative is-primary input-btn p7-button adjust-margin">
      {{ 'IMGCROP.CHOOSE' | translate }}
      <input type="file" class="hide" (change)="onChange($event)" />
    </div>
  </div>
  <div class="editor" *ngIf="editing">
    <div>
      <img id="image" #editor />
    </div>
    <div class="adjust-margin">
      <button class="button is-danger" (click)="cancel()">
        {{ 'IMGCROP.CANCEL' | translate }}
      </button>
      <button
        class="button is-success"
        (click)="upload()"
        [class.is-loading]="processing"
      >
        {{ 'IMGCROP.APPLY' | translate }}
      </button>
    </div>
  </div>
</div>
