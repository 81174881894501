<div class="device-iphone">
  <div class="clock-container">
    <span class="clock-time">
      {{ clock_time | date: 'HH:mm' }}
    </span>
    <span class="clock-date">
      {{ clock_time | date: 'M月d日' }}
    </span>
  </div>
  <a class="notification-container" target="_blank" [href]="notification.url">
    <div class="notification-header">
      <div class="p-icon">
        <img src="/assets/img/logo_icon.svg" />
      </div>
      <div class="p-label">Push7</div>
      <div class="p-badge">
        now
      </div>
    </div>
    <div class="notification-body">
      <div class="text-block">
        <div class="p-title">
          {{
            notification.title ||
              ('NOTIFICATION_EDITOR.VALIDATIONS.TITLE.NOLENGTH' | translate)
          }}
        </div>
        <div class="p-body">
          {{
            notification.body ||
              ('NOTIFICATION_EDITOR.VALIDATIONS.BODY' | translate)
          }}
        </div>
      </div>
      <div class="image-block">
        <img [src]="notification.icon" />
      </div>
    </div>
    <div *ngIf="notification.image" class="notification-footer">
      <img [src]="notification.image" />
    </div>
  </a>
</div>
