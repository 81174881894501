<div class="card">
  <header class="card-header">
    <p class="card-header-title">
      Push7の法人向けプラン「Push7 for Enterprise」のご案内
    </p>
  </header>
  <div class="card-content adjust-height">
    <div class="content">
      <div>
        <p>
          Enterpriseプランは法人向けに専用設計されたWebプッシュ通知ソリューションです。
        </p>
        <ul>
          <li>
            ユーザのIDやサイト内行動、貴社のDMPに蓄積されたデータなどを用いたセグメント配信
          </li>
          <li>
            高品質なサポートとエンジニアによるテクニカルサポート
          </li>
          <li>
            エンジニアによるテクニカルサポートとプッシュ戦略コンサルティングにより、貴社のビジネスドメインに合わせたプッシュ通知の運用をご提案
          </li>
        </ul>
        <p>
          その他にも多数の特典をEnterpriseプランではご利用いただけます。
        </p>
      </div>
    </div>
  </div>
  <footer class="card-footer">
    <a class="card-footer-item" (click)="openModal()">はじめる</a>
  </footer>
</div>
