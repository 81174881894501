import { UserService } from './../../../services/user/user.service';
import { ModalContext } from './../../../services/modal/modal.service';
import { Notification } from '../../../services/notification/notification.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-delete-modal',
  templateUrl: './account-delete-modal.component.html'
})
export class AccountDeleteModalComponent implements OnInit {
  public password = '';
  public inProgress = false;
  constructor(
    private modalCtx: ModalContext,
    private userService: UserService,
    private notification: Notification
  ) {}
  close() {
    this.modalCtx.reject();
  }
  delete() {
    this.inProgress = true;
    this.userService
      .delete_account(this.modalCtx.payload.email, this.password)
      .subscribe(
        () => {
          this.notification.open(
            'アカウントを削除しました。Push7をご利用いただきありがとうございました。',
            'success'
          );
          const redirect = () =>
            setTimeout(() => (location.href = 'https://push7.jp'), 1000);
          this.userService
            .send_survey(this.modalCtx.payload)
            .subscribe(redirect, redirect);
        },
        error => {
          this.notification.open(JSON.stringify(error), 'danger');
        },
        () => {
          this.inProgress = false;
        }
      );
  }
  ngOnInit() {}
}
