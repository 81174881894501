import { Component } from '@angular/core';
import { ModalContext } from '../../../../../services/modal/modal.service';

@Component({
  template: `
    <div class="modal is-active">
      <div class="modal-background" (click)="close()"></div>
      <div class="modal-content">
        <div class="box">
          <div class="content-header is-yellow">
            編集内容を保存
          </div>
          <div class="content">
            <p>一覧に戻る前に編集内容を保存しますか？</p>
          </div>
          <div class="content-footer">
            <div class="flex has-text-right">
              <button
                class="button is-danger p7-button"
                (click)="discard()"
                style="margin-right: .8rem;"
              >
                変更を破棄
              </button>
              <button class="button is-success p7-button" (click)="save()">
                保存
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class QuerySaveBeforeLeaveComponent {
  constructor(private modalCtx: ModalContext) {}
  close() {
    this.modalCtx.reject();
  }
  discard() {
    this.modalCtx.resolve(false);
  }
  save() {
    this.modalCtx.resolve(true);
  }
}
