import { Component, OnInit, Input } from '@angular/core';
import { NotificationModel } from '../../models/notification.model';
import { Observable } from 'rxjs';
import { AppModel } from '../../models/app.model';
import { Store } from '@ngrx/store';

import * as fromRoot from '../../reducers';

@Component({
  selector: 'app-notification-preview',
  templateUrl: './notification-preview.component.html',
  styleUrls: ['./notification-preview.component.scss']
})
export class NotificationPreviewComponent implements OnInit {
  @Input()
  notification: NotificationModel;

  public device: 'iPhone' | 'Android' | 'Mac' | 'Windows' = 'iPhone';

  public app$: Observable<AppModel>;

  constructor(private store: Store<fromRoot.State>) {
    this.app$ = this.store.select(fromRoot.getAppSelected);
  }

  ngOnInit() {}

  public get clock_time() {
    return this.notification.transmission_time || new Date();
  }
}
