<section class="hero pre is-fullheight">
  <div class="hero-body">
    <div class="container has-text-centered">
      <img class="logo" src="/assets/img/logo_black.svg" />
      <div class="inner card">
        <div class="card-content has-text-left">
          <div class="notification is-danger" *ngIf="error">
            {{ error }}
          </div>
          <form (submit)="submit()" [formGroup]="form">
            <div class="field">
              <label class="label">{{ 'COMMON.EMAIL' | translate }}</label>
              <p class="control has-icons-left">
                <input
                  class="input"
                  type="email"
                  [class.is-success]="form.get('email').valid"
                  formControlName="email"
                  required
                  name="email"
                  placeholder="mail@example.com"
                />
                <span class="icon is-small is-left">
                  <i class="fa fa-envelope"></i>
                </span>
              </p>
            </div>
            <div formGroupName="passwords">
              <label class="label">{{ 'COMMON.PASSWORD' | translate }}</label>
              <div class="field">
                <p class="control has-icons-left">
                  <input
                    class="input"
                    type="password"
                    [class.is-success]="
                      form.get('passwords').get('password').valid
                    "
                    [class.is-danger]="
                      form.get('passwords').get('password').touched &&
                      !form.get('passwords').get('password').valid
                    "
                    formControlName="password"
                    required
                    name="password"
                    placeholder="Password"
                  />
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                </p>
                <p
                  class="help is-danger"
                  *ngIf="
                    form.get('passwords').get('password').touched &&
                    !form.get('passwords').get('password').valid
                  "
                >
                  {{ 'SIGNUP.PASSWORD_REQUIREMENT' | translate }}
                </p>
              </div>

              <div class="field">
                <label class="label">{{ 'SIGNUP.CONFIRM' | translate }}</label>
                <p class="control has-icons-left">
                  <input
                    class="input"
                    type="password"
                    [class.is-success]="form.get('passwords').valid"
                    [class.is-danger]="
                      form.get('passwords').get('confirm').touched &&
                      form.get('passwords').hasError('areEqual')
                    "
                    formControlName="confirm"
                    required
                    name="password"
                    placeholder="Password"
                  />
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                </p>
                <p
                  class="help is-danger"
                  *ngIf="
                    form.get('passwords').get('confirm').touched &&
                    form.get('passwords').hasError('areEqual')
                  "
                >
                  {{ 'SIGNUP.ERRORS.PASSWORD_MISMATCH' | translate }}
                </p>
              </div>
            </div>
            <div class="field">
              <label class="label" style="margin-top: 0.5rem;">{{
                'COMMON.USER_TYPE' | translate
              }}</label>
              <p class="control">
                <label class="radio">
                  <input
                    type="radio"
                    name="is_corporation"
                    [value]="false"
                    formControlName="is_corporation"
                  />
                  {{ 'COMMON.INDIVIDUAL' | translate }}
                </label>
                <label class="radio">
                  <input
                    type="radio"
                    name="is_corporation"
                    [value]="true"
                    formControlName="is_corporation"
                  />
                  {{ 'COMMON.CORPORATION' | translate }}
                </label>
              </p>
            </div>
            <p
              class="notification is-warning"
              *ngIf="form.get('is_corporation').value === false"
            >
              機能が異なりますので法人のお客様は必ず「法人」をお選びください。法人アカウントで直ちに料金が発生することはございません。
            </p>
            <div class="field">
              <p class="control">
                <label class="checkbox">
                  <input
                    type="checkbox"
                    name="agreed"
                    formControlName="agreed"
                  />
                  <span [innerHTML]="'SIGNUP.AGREE' | translate"></span>
                </label>
              </p>
            </div>
            <div class="field is-grouped is-grouped-centered">
              <p class="control">
                <button
                  class="button is-primary"
                  [disabled]="form.invalid"
                  type="submit"
                >
                  {{ 'SIGNUP.BUTTON' | translate }}
                </button>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
