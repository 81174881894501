import { AppModel } from './../../../../models/app.model';
import { Observable } from 'rxjs';
import { ModalContext } from '../../../../services/modal/modal.service';

import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-sdk-modal',
  templateUrl: './sdk-modal.component.html',
  styleUrls: ['./sdk-modal.component.scss']
})
export class SdkModalComponent implements OnDestroy {
  public app$: Observable<AppModel>;
  public sdk: string;
  constructor(
    private store: Store<fromRoot.State>,
    private modalCtx: ModalContext
  ) {
    this.app$ = this.store.select(fromRoot.getAppSelected);
    this.app$.pipe(take(1)).subscribe(app => {
      this.sdk = `<script src="https://sdk.push7.jp/v2/p7sdk.js"></script>
<script>
p7.init("${app.appno}");
</script>`;
    });
  }

  ngOnDestroy() {}
  close() {
    this.modalCtx.resolve();
  }
}
