import { Action } from '@ngrx/store';
import { type } from '../helpers/type';

import * as Moment from 'moment';

const prefix = '[Analytics]';
export const ActionTypes = {
  INIT_STATE: `${prefix} Init Analytics State for selected app`,
  INFO_REQUEST: `${prefix} Request Info`,
  INFO_SUCCESS: `${prefix} Received Info`,
  INFO_ERROR: `${prefix} Failed to load info`,
  OVERVIEW_SCOPE: `${prefix} Set the scope of the overview`,
  OVERVIEW_TAB: `${prefix} Change the tab of the overview`,
  OVERVIEW_RANGE: `${prefix} Set range`,
  OVERVIEW_TRANSITION_ADD: `${prefix} Add transition data`,
  OVERVIEW_INCREASE_ADD: `${prefix} Add increase data`,
  OVERVIEW_LOADED: `${prefix} Overview Loaded`,
  SUBSCRIBERS_REQUEST: `${prefix} Request Subscribers`,
  SUBSCRIBERS_SUCCESS: `${prefix} Received Subscribers`,
  PARAMS_REQUEST: `${prefix} Request Parameters`,
  PARAMS_SUCCESS: `${prefix} Received Parameters`
};

export class InitState implements Action {
  type = ActionTypes.INIT_STATE;
  constructor(public appno: string) {}
}
export class InfoRequest implements Action {
  type = ActionTypes.INFO_REQUEST;
  constructor(public payload?: any) {}
}
export class InfoSuccess implements Action {
  type = ActionTypes.INFO_SUCCESS;
  constructor(
    public payload: {
      appno: string;
      info: {
        sent_push_count: number;
        clicked_count: number;
      };
    }
  ) {}
}
export class InfoError implements Action {
  type = ActionTypes.INFO_ERROR;
  constructor(
    public payload: {
      appno: string;
      error: any;
    }
  ) {}
}
export class OverviewScope implements Action {
  type = ActionTypes.OVERVIEW_SCOPE;
  constructor(
    public appno: string,
    public payload: 'hour' | 'day' | 'week' | 'month'
  ) {}
}
export class OverviewTab implements Action {
  type = ActionTypes.OVERVIEW_TAB;
  constructor(public appno: string, public payload: 'total' | 'increase') {}
}
export class OverviewRange implements Action {
  type = ActionTypes.OVERVIEW_RANGE;
  constructor(
    public appno: string,
    public range: {
      beginning?: Moment.Moment;
      ending?: Moment.Moment;
    }
  ) {}
}
export class OverviewTransitionAdd implements Action {
  type = ActionTypes.OVERVIEW_TRANSITION_ADD;
  constructor(
    public appno: string,
    public counts: {
      [date: string]: {
        date: string;
        count: number;
      };
    }
  ) {}
}
export class OverviewIncreaseAdd implements Action {
  type = ActionTypes.OVERVIEW_INCREASE_ADD;
  constructor(
    public appno: string,
    public counts: {
      [date: string]: {
        date: string;
        count: number;
      };
    }
  ) {}
}
export class OverviewLoaded implements Action {
  type = ActionTypes.OVERVIEW_LOADED;
  constructor(public appno: string) {}
}
export class SubscribersRequest implements Action {
  type = ActionTypes.SUBSCRIBERS_REQUEST;
}
export class SubscribersSuccess implements Action {
  type = ActionTypes.SUBSCRIBERS_SUCCESS;
  constructor(public appno: string, public subscribers: any[]) {}
}
export class ParamsRequest implements Action {
  type = ActionTypes.PARAMS_REQUEST;
  constructor() {}
}
export class ParamsSuccess implements Action {
  type = ActionTypes.PARAMS_SUCCESS;
  constructor(public appno: string, public params: any[]) {}
}

// export type Actions
export type Actions =
  | InitState
  | InfoSuccess
  | InfoError
  | SubscribersRequest
  | SubscribersSuccess
  | OverviewScope
  | OverviewTab
  | any;
