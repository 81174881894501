import { Component, OnInit } from '@angular/core';

import { Modal } from '../../../services/modal/modal.service';
import { SdkModalComponent } from './sdk-modal/sdk-modal.component';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  constructor(private modal: Modal) {}

  ngOnInit() {}

  openSDK() {
    this.modal.open(SdkModalComponent);
  }
}
