import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { ModalContext } from '../../../../services/modal/modal.service';
import { Notification } from '../../../../services/notification/notification.service';
import { AppService } from '../../../../services/app/app.service';

import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { AppModel } from '../../../../models/app.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
  selector: 'app-app-delete-modal',
  templateUrl: './app-delete-modal.component.html',
  styleUrls: ['./app-delete-modal.component.scss']
})
export class AppDeleteModalComponent implements OnInit {
  private appcheck: boolean;
  public app$: Observable<AppModel>;
  public id: string;
  public valuecheck: string;
  constructor(
    private modalCtx: ModalContext,
    private appService: AppService,
    private notification: Notification,
    private store: Store<fromRoot.State>,
    private translate: TranslateService,
    private authService: AuthService
  ) {
    this.appcheck = true;
    this.app$ = this.store.select(fromRoot.getAppSelected);
    this.app$.pipe(take(1)).subscribe(app => {
      this.id = app.domain.split('.')[0];
    });
  }

  ngOnInit() {}
  delete() {
    this.appService.delete().subscribe(
      success => {
        this.translate.get('SETTINGS.DELETED').subscribe((m: string) => {
          this.notification.open(m, 'success');
        });
        this.authService.loadProjects();
        this.modalCtx.resolve();
      },
      (response: HttpErrorResponse) => {
        this.notification.open(response.error.error, 'warning');
      }
    );
  }
  close() {
    this.modalCtx.reject();
  }
}
