import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Modal } from './../../../services/modal/modal.service';
import { Notification } from './../../../services/notification/notification.service';
import { AppService } from './../../../services/app/app.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import * as fromRoot from '../../../reducers';

import { DomainConfirmComponent } from './confirm/domain-confirm.component';
import { AppModel } from '../../../../../src/app/models/app.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.scss']
})
export class DomainComponent implements OnDestroy {
  public status: 'none' | 'failed' | 'inProgress' | 'completed' = null;
  public domain: string;
  public error: string;
  public cname: string;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<fromRoot.State>,
    private api: AppService,
    private notification: Notification,
    private modal: Modal,
    private translate: TranslateService
  ) {
    // 現在の状態を確認する
    store
      .select(fromRoot.getAppSelected)
      .pipe(
        takeUntil(this.unsubscribe),
        map(x => x as AppModel)
      )
      .subscribe(app => {
        this.cname = app.domain;
        if (app.alias) {
          // エイリアスがappに設定されている場合はcompletedしている
          this.status = 'completed';
          this.domain = app.alias;
        } else {
          // ない場合は状況をAPIから取得する
          this.api.alias_get().subscribe(
            data => {
              switch (data.status) {
                case '処理中': {
                  this.status = 'inProgress';
                  break;
                }
                case '登録失敗': {
                  this.status = 'failed';
                  break;
                }
                case '登録完了': {
                  this.status = 'completed';
                  break;
                }
              }
            },
            err => {
              // 未申請の場合はエラー
              this.status = 'none';
            }
          );
        }
      });
  }

  // none ページ
  public register() {
    this.api.alias_new(this.domain).subscribe(
      success => {
        this.status = 'inProgress';
        this.translate
          .get('DOMAIN.NONE.REQUESTED', { finish: success.finish })
          .subscribe((m: string) => {
            this.notification.open(m, 'success');
          });
      },
      response => {
        this.notification.open(response.error.error, 'danger');
      }
    );
  }

  // failed
  public retry() {
    this.api.alias_delete().subscribe(
      success => {
        this.translate.get('DOMAIN.FAILED.AGAIN').subscribe((m: string) => {
          this.notification.open(m, 'info');
        });
        this.status = 'none';
      },
      response => {
        this.notification.open(response.error.error, 'danger');
      }
    );
  }

  // complete page param for ngx-translate
  completed_param() {
    return {
      domain: this.domain
    };
  }
  // reset
  public reset() {
    this.modal.open(DomainConfirmComponent).then(() => {
      this.api.alias_delete().subscribe(
        success => {
          this.translate
            .get('DOMAIN.COMPLETED.DELETED')
            .subscribe((m: string) => {
              this.notification.open(m, 'success');
            });
          this.status = 'none';
        },
        response => {
          this.notification.open(response.error.error, 'danger');
        }
      );
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
