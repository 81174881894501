<div class="main-nav" [innerHTML]="'TAGS.LABEL' | translate"></div>

<div class="tabs is-medium p7-tabs">
  <ul class="tabs-gray">
    <li [routerLink]="['button']" [routerLinkActive]="['is-active']">
      <a>{{ 'TAGS.BUTTON.LABEL' | translate }}</a>
    </li>
    <li [routerLink]="['box']" [routerLinkActive]="['is-active']">
      <a>{{ 'TAGS.BOX.LABEL' | translate }}</a>
    </li>
    <li [routerLink]="['native']" [routerLinkActive]="['is-active']">
      <a>{{ 'TAGS.NATIVE.LABEL' | translate }}</a>
    </li>
  </ul>
  <ul class="tabs-gray is-right">
    <li><a (click)="openSDK()" class="button is-primary">Push7 SDK</a></li>
  </ul>
</div>

<router-outlet></router-outlet>
