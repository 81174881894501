import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentMethodModel } from '../../../app/models/plan.model';

const paymentMethodValidator: ValidatorFn = (fg: FormGroup) => {
  const method = fg.get('payment_method').value;
  switch (method) {
    case 'transfer':
      return null;
    case 'card':
      const card = fg.get('card').value;
      if (
        card.card_no &&
        card.holder &&
        card.expire_month &&
        card.expire_year
      ) {
        return null;
      }
      return { card: true };
  }
};

@Component({
  selector: 'app-payment-method-form',
  templateUrl: './payment-method-form.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PaymentMethodFormComponent)
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => PaymentMethodFormComponent)
    }
  ]
})
export class PaymentMethodFormComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  static defaultFormValue(): PaymentMethodModel {
    return {
      payment_method: 'card',
      card: { card_no: '', expire: '', holder: '' }
    };
  }
  public yearsList: number[]; // 現在から20年後まで
  public monthsList: number[]; // 1 ~ 12
  public form: FormGroup;
  // コールバック群
  private onChange: any = () => {};
  private onTouched: any = () => {};
  private onDestroy$: Subject<void> = new Subject<void>();

  public showPaymentOptions = true;
  @Input()
  set canUseTransfer(x: boolean) {
    this.showPaymentOptions = x;
    if (!x) {
      // transferが使えない場合にはcardを強制する
      this.form.get('payment_method').setValue('card');
    }
  }

  get value(): PaymentMethodModel {
    const m = this.form.value;
    if (m.payment_method === 'card') {
      return {
        payment_method: 'card',
        card: {
          card_no: m.card.card_no,
          holder: m.card.holder,
          expire: `${m.card.expire_year}${('0' + m.card.expire_month).slice(
            -2
          )}`
        }
      };
    }
    return { payment_method: 'transfer' };
  }

  // そとから指定されたexpireを年と月に分解する
  @Input('value')
  set value(method: PaymentMethodModel) {
    if (!method) {
      return;
    }
    if (method.payment_method === 'transfer') {
      this.form.setValue(method);
    } else {
      const year = parseInt(method.card.expire.substr(0, 2), 10);
      const month = parseInt(method.card.expire.substr(2, 2), 10);
      this.form.setValue({
        payment_method: 'card',
        card: {
          card_no: method.card.card_no,
          holder: method.card.holder,
          expire_month: month || 1,
          expire_year:
            year || Number(String(new Date().getFullYear()).slice(-2))
        }
      });
    }
    this.onChange(method);
    this.onTouched();
  }
  constructor(private fb: FormBuilder) {
    // カードの期限の範囲を生成
    this.yearsList = [];
    const year = Number(String(new Date().getFullYear()).slice(-2));
    for (let i = year; i < year + 20; i++) {
      this.yearsList.push(i);
    }
    this.monthsList = [];
    for (let i = 1; i <= 12; i++) {
      this.monthsList.push(i);
    }

    this.form = this.fb.group(
      {
        payment_method: this.fb.control('', Validators.required),
        card: this.fb.group({
          card_no: this.fb.control(''),
          expire_month: this.fb.control(null),
          expire_year: this.fb.control(null),
          holder: this.fb.control('')
        })
      },
      { validator: paymentMethodValidator }
    );
  }
  ngOnInit() {
    this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      // 更新時にはthis.valueで外に渡すように変換した値をonChangeする
      this.onChange(this.value);
      this.onTouched();
    });
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  validate(control: AbstractControl): ValidationErrors {
    return this.form.valid ? null : { paymentMethod: 'invalid' };
  }
  writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
    }
    if (obj === null) {
      this.form.reset();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
