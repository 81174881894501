import {
  Component,
  Input,
  EventEmitter,
  Output,
  AfterViewInit,
  OnInit
} from '@angular/core';
import {
  DateQuery,
  ASTDateTime,
  ASTDateTimeRange,
  ASTDateTimeNever
} from '../../../../models/segments.model';

@Component({
  selector: 'app-segment-datequery-editor',
  styles: [
    `
      :host {
        display: block;
        max-width: 400px;
      }
    `
  ],
  templateUrl: './datequery-editor.component.html'
})
export class DateQueryEditorComponent implements OnInit {
  public kind: String;

  @Input()
  dateQuery: DateQuery;
  @Output()
  dateQueryChange: EventEmitter<DateQuery> = new EventEmitter<DateQuery>();

  public randomId: string;
  constructor() {
    this.randomId =
      new Date().getTime().toString(16) + Math.floor(1000 * 12).toString(16);
  }

  ngOnInit() {
    switch (this.dateQuery.className) {
      case 'ASTDateTimeRange':
        this.kind = 'range';
        break;
      case 'ASTDateTimeNever':
        this.kind = 'never';
        break;
      default:
        this.kind = 'specific';
        break;
    }
  }
  update(type: string) {
    switch (type) {
      case 'specific':
        this.dateQueryChange.next(new ASTDateTime());
        break;
      case 'range':
        this.dateQueryChange.next(new ASTDateTimeRange());
        break;
      case 'never':
        this.dateQueryChange.next(new ASTDateTimeNever());
        break;
    }
  }
}
