export const Days = {
  SUN: '日',
  MON: '月',
  TUE: '火',
  WED: '水',
  THU: '木',
  FRI: '金',
  SAT: '土'
};

export const MonthWeeks = {
  '#1': '第一',
  '#2': '第二',
  '#3': '第三',
  '#4': '第四',
  '#5': '第五',
  L: '最終'
};

export enum Months {
  January = 1,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December
}
