import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { take, tap, map, filter, switchMap } from 'rxjs/operators';

import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class AppExistsGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(route: ActivatedRouteSnapshot) {
    const appno = route.params['appno'];
    return this.authService.getProjectFromAppno(appno).pipe(
      map(project => !!project || this.router.createUrlTree(['home'])),
      tap(console.log)
    );
  }
}
