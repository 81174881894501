import {
  EventEmitter,
  Component,
  OnInit,
  Input,
  Output,
  AfterViewInit
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Notification } from '../../services/notification/notification.service';

import { Modal } from '../../services/modal/modal.service';
import { ImgCropModalComponent } from '../img-crop-modal/img-crop-modal.component';

@Component({
  selector: 'app-img-crop-v2',
  templateUrl: './img-crop-v2.component.html',
  styleUrls: ['./img-crop-v2.component.scss']
})
export class ImgCropV2Component implements OnInit, AfterViewInit {
  @Input()
  public src;
  @Input()
  public aspectRatio: number = null;
  @Input()
  public canReset = false;
  @Output()
  public srcChange: EventEmitter<string> = new EventEmitter<string>();
  public dummy;
  constructor(
    private translate: TranslateService,
    private notification: Notification,
    private modal: Modal
  ) {}
  ngOnInit() {}
  ngAfterViewInit() {}
  public editing: boolean;

  reset() {
    this.src = '';
    this.srcChange.emit(this.src);
  }
  onChange($event) {
    if ($event.target.files.length <= 0) {
      // if selected
      return;
    }
    this.dummy = undefined;
    const file = $event.target.files[0];
    if (['image/jpeg', 'image/png', 'image/gif'].indexOf(file.type) < 0) {
      this.translate.get('IMGCROP.NONIMAGE').subscribe(message => {
        this.notification.open(message, 'danger');
      });
      return;
    }
    this.modal
      .open(ImgCropModalComponent, { file, aspectRatio: this.aspectRatio })
      .then((img: string) => {
        this.src = img;
        this.srcChange.emit(this.src);
      });
  }
}
