import { Component, Input } from '@angular/core';

import * as Segments from './../../models/segments.model';

@Component({
  selector: 'app-segment-editor',
  templateUrl: './segment-editor.component.html'
})
export class SegmentEditorComponent {
  @Input()
  ASTRoot: Segments.ASTRoot;
}
