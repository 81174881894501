import { Project } from '../services/api';

/**
 * 権限として存在するロールは3種類
 * spy_xxx は xxx として扱われる
 */
export type PermissionRole = 'administrator' | 'operator' | 'viewer';

/**
 * 権限の比較に用いる順番. 上から偉い順
 */
const permissionOrder: PermissionRole[] = [
  'administrator',
  'operator',
  'viewer'
];

export const hasSufficientPermission = (
  userRole: Project.RoleEnum,
  requiredRole: PermissionRole
): boolean => {
  const permissionRole: PermissionRole =
    userRole === 'spy_administrator'
      ? 'administrator'
      : userRole === 'spy_viewer'
      ? 'viewer'
      : userRole;
  return (
    permissionOrder.indexOf(permissionRole) <=
    permissionOrder.indexOf(requiredRole)
  );
};
