import { AppService } from '../../services/app/app.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss']
})
export class ParametersComponent implements OnInit {
  public page = 1;
  public max: number;
  public list: { parameter: string; subscribers_count: number }[];
  constructor(private appService: AppService) {}
  update() {
    if (this.page < 1) {
      return;
    } else {
      this.list = null;
      this.appService.get_parameters((this.page - 1) * 20).subscribe(result => {
        this.list = result.parameters;
        this.max = Math.ceil(result.count / 20);
      });
    }
  }
  ngOnInit() {
    this.update();
  }
  next() {
    this.page++;
    this.update();
  }
  prev() {
    this.page--;
    this.update();
  }
}
