import { AppModel } from '../../models/app.model';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { QueryEditorV2Component } from './query-editor-v2/query-editor-v2.component';
import { SftpPresetSelectorV2Component } from './sftp-preset-selector-v2/sftp-preset-selector-v2.component';
import { Modal } from '../../services/modal/modal.service';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { NotificationModel, QueryModel } from '../../models/notification.model';
import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import * as fromRoot from '../../reducers';

import * as Flatpicker from 'flatpickr';
import { SegmentationQuerySelectorV2Component } from './segmentation-query-selector-v2/segmentation-query-selector-v2.component';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app/app.service';
import { Notification } from '../../services/notification/notification.service';

@Component({
  selector: 'app-notification-editor-v2',
  templateUrl: './notification-editor-v2.component.html',
  styleUrls: ['./notification-editor-v2.component.scss']
})
export class NotificationEditorV2Component implements OnInit, OnDestroy {
  @Input()
  notification: NotificationModel;
  @Output()
  notificationChange = new EventEmitter<NotificationModel>();

  private _step = 0;
  get step() {
    return this._step;
  }

  @Input()
  set step(val: number) {
    const prevStep = this.step;
    if (this.step >= val) {
      this._step = val;
    } else if (val > 0 && !this.checkContentValidity()) {
      this._step = 0;
    } else if (val > 1 && !this.checkSendTimeValidity()) {
      this._step = 1;
    } else {
      this._step = val;
    }
    if (this.step !== prevStep) {
      if (this.step < prevStep) {
        this.invalidMsgs = {
          title: '',
          url: '',
          body: '',
          ttl: '',
          subscribers: '',
          interval: ''
        };
      }
      if (this.step === 1) {
        this.setReservation(this.sendNow);
      } else {
        this.destroyPickers();
      }
    }

    setTimeout(() => this.stepChange.emit(this.step));
  }
  @Output()
  stepChange = new EventEmitter<number>();

  @Output()
  onComplete = new EventEmitter<{ data: NotificationModel; pushid: string }>();

  @Output()
  onClose = new EventEmitter<void>();

  public validStep = 0;
  public sendNow = false;

  /**
   * 既に作成された通知の場合trueを返します。
   * LBプッシュの切り替えはできません。
   */
  public get isCreated(): boolean {
    return !!this.notification.id;
  }

  // クエリの種類を保存
  private _queryType: 'all' | 'query' | 'sftp' | 'segment';
  public get queryType(): 'all' | 'query' | 'sftp' | 'segment' {
    return this._queryType;
  }
  public set queryType(qt: 'all' | 'query' | 'sftp' | 'segment') {
    this._queryType = qt;
    if (qt !== 'all') {
      // セグメント機能を使う場合、ロードバランス配信は利用できない
      this.notification.delivery_strategy = null;
    }
  }

  public isLoadBalancePush = false; // 負荷分散配信を使うかどうか
  public selectedSegmentName: string; // 選択されたセグメントの名前
  private el: HTMLElement;
  public app$: Observable<AppModel>;
  public appSubscribers: number;

  public invalidMsgs = {
    title: '',
    url: '',
    body: '',
    ttl: '',
    subscribers: '',
    interval: ''
  };
  public sending = false;
  constructor(
    private store: Store<fromRoot.State>,
    private _el: ElementRef,
    private modal: Modal,
    private translate: TranslateService,
    private appService: AppService,
    private notiService: Notification
  ) {
    this.el = _el.nativeElement;
    this.app$ = this.store.select(fromRoot.getAppSelected);
    this.app$.pipe(take(1)).subscribe(app => {
      this.appSubscribers = app.subscribers;
    });
  }
  ngOnInit() {
    if (!this.notification) {
      this.notification = {
        title: '',
        body: '',
        icon: '',
        url: '',
        ttl: 43200,
        disappear_instantly: true,
        enable_ios_sound: true
      };
    }
    if (this.notification.disappear_instantly === undefined) {
      this.notification.disappear_instantly = true;
    }
    if (this.notification.enable_ios_sound === undefined) {
      this.notification.enable_ios_sound = true;
    }
    if (!this.notification.icon) {
      this.store
        .select(fromRoot.getAppSelected)
        .pipe(take(1))
        .subscribe(app => {
          this.notification.icon = app.icon;
        });
    }
    this.store
      .select(fromRoot.getAppSelected)
      .pipe(take(1))
      .subscribe(app => {
        if (!this.notification.icon) {
          this.notification.icon = app.icon;
        }
      });
    // queryがstringであれば新セグメント. それ以外(object)であれば旧パラメータ配信
    if (this.notification.csv_preset_id) {
      this.queryType = 'sftp';
    } else if (typeof this.notification.query === 'string') {
      this.queryType = 'segment';
    } else if (this.notification.query) {
      this.queryType = 'query';
    } else {
      this.queryType = 'all';
    }

    // load-balanceページからモーダルを呼び出したとき
    // strategyがload-balanceだが，他の要素はnullなので初期化
    const strategy = this.notification.delivery_strategy;
    if (
      strategy &&
      !strategy.send_interval_minutes &&
      strategy.type === 'load_balance'
    ) {
      this.isLoadBalancePush = true;
      this._initLoadBalanceStrategy();
    }
  }

  setReservation(stat: boolean) {
    this.sendNow = stat;
    // called when isReservation has changed
    if (this.sendNow) {
      this.notification.transmission_time = null;
      this.destroyPickers();
      this.notificationEmit();
      return;
    }
    // when making a reservation
    setTimeout(() => {
      // use 0 settimeout hack to make sure picker element has created in the DOM
      // original date is a date that originally specified while initialization
      const picker = this.el.querySelector('.flatpickr');
      const flatpicker = new Flatpicker(picker, {
        enableTime: true,
        minDate: new Date(),
        maxDate: new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000), // set up to two weeks
        defaultDate: this.notification.transmission_time || new Date(),
        time_24hr: true,
        onChange: (selectedDates, dateStr, instance) => {
          this.notification.transmission_time = dateStr;
          this.notificationEmit();
        }
      }) as any;

      this.pickers.push(flatpicker);
    }, 0);
  }
  toggleDisappear() {
    this.notification.disappear_instantly = !this.notification
      .disappear_instantly;
  }
  private pickers: any[] = [];
  destroyPickers() {
    this.pickers.forEach(el => {
      el.destroy();
    });
    this.pickers = [];
  }

  toggleStrategy() {
    const strategy = this.notification.delivery_strategy;
    if (strategy && strategy.type === 'load_balance') {
      this.notification.delivery_strategy = null;
    } else {
      this._initLoadBalanceStrategy();
    }
  }

  _initLoadBalanceStrategy() {
    this.notification.delivery_strategy = {
      type: 'load_balance',
      subscribers_per_group: Math.ceil(this.appSubscribers / 6) || 1000,
      send_interval_minutes: 10
    };
  }

  resetQuery() {
    this.notification.csv_preset_id = null;
    this.notification.query = null;
  }

  toggleSendAll() {
    this.resetQuery();
  }

  toggleQuery() {
    this.resetQuery();
    // モーダルを表示してクエリをセット
    this.notification.query = {
      mode: 'AND',
      params: []
    };
    this.editQuery();
  }

  editQuery() {
    this.modal
      .open(QueryEditorV2Component, this.notification.query)
      .then((resolved: QueryModel) => {
        this.notification.query = resolved;
      })
      .catch(() => {});
  }

  toggleSftpPreset() {
    this.resetQuery();
    this.editSftpPreset();
  }
  editSftpPreset() {
    this.modal
      .open(SftpPresetSelectorV2Component, this.notification.csv_preset_id)
      .then((resolved: string) => (this.notification.csv_preset_id = resolved))
      .catch(() => {});
  }
  toggleSegmentationQuery() {
    this.resetQuery();
    this.editSegmentationQuery();
  }
  editSegmentationQuery() {
    this.modal
      .open(SegmentationQuerySelectorV2Component)
      .then(({ query: query, name: name }) => {
        this.notification.query = query;
        this.selectedSegmentName = name;
      })
      .catch(() => {});
  }
  back() {
    if (this.step > 0) {
      this.step -= 1;
    } else if (this.step === 0) {
      this.onClose.emit();
    }
  }

  next() {
    if (this.step < 3) {
      this.step += 1;
    } else if (this.step === 3) {
      this.send();
    }
  }

  checkTitle(title) {
    if (title.length === 0) {
      this.translate
        .get('NOTIFICATION_EDITOR.VALIDATIONS.TITLE.NOLENGTH')
        .subscribe(m => (this.invalidMsgs.title = m));
      return false;
    } else if (title.length > 128) {
      this.translate
        .get('NOTIFICATION_EDITOR.VALIDATIONS.TITLE.OVERLENGTH')
        .subscribe(m => (this.invalidMsgs.title = m));
      return false;
    }
    this.invalidMsgs.title = '';
    return true;
  }

  checkUrl(url) {
    if (url.length === 0) {
      this.translate
        .get('NOTIFICATION_EDITOR.VALIDATIONS.URL.NOLENGTH')
        .subscribe(m => (this.invalidMsgs.url = m));
      return false;
    } else if (!url.match(/^https?:\/\/.+$/)) {
      this.translate
        .get('NOTIFICATION_EDITOR.VALIDATIONS.URL.UNMATCH')
        .subscribe(m => (this.invalidMsgs.url = m));
      return false;
    }
    this.invalidMsgs.url = '';
    return true;
  }

  checkBody(body) {
    if (body.length === 0) {
      this.translate
        .get('NOTIFICATION_EDITOR.VALIDATIONS.BODY')
        .subscribe(m => (this.invalidMsgs.body = m));
      return false;
    }
    this.invalidMsgs.body = '';
    return true;
  }

  checkContentValidity() {
    const { title, url, body } = this.notification;
    let valid = this.checkTitle(title);
    valid = this.checkUrl(url) && valid;
    valid = this.checkBody(body) && valid;
    this.validStep = valid ? Math.max(1, this.validStep) : 0;

    return valid;
  }

  checkTtl(value) {
    if (!(0 <= value && value <= 2419200)) {
      this.translate
        .get('NOTIFICATION_EDITOR.VALIDATIONS.TTL')
        .subscribe(m => (this.invalidMsgs.ttl = m));
      return false;
    }
    this.invalidMsgs.ttl = '';
    return true;
  }

  checkSubscribers(subscribers) {
    if (subscribers <= 0) {
      this.translate
        .get('NOTIFICATION_EDITOR.VALIDATIONS.SUBSCRIBERS')
        .subscribe(m => (this.invalidMsgs.subscribers = m));
      return false;
    }
    this.invalidMsgs.subscribers = '';
    return true;
  }

  checkInterval(interval) {
    if (interval <= 0) {
      this.translate
        .get('NOTIFICATION_EDITOR.VALIDATIONS.INTERVAL')
        .subscribe(m => (this.invalidMsgs.interval = m));
      return false;
    }
    this.invalidMsgs.interval = '';
    return true;
  }

  checkSendTimeValidity() {
    const { ttl, delivery_strategy: strategy } = this.notification;
    let valid = this.checkTtl(ttl);
    if (strategy && strategy.type === 'load_balance') {
      valid = this.checkSubscribers(strategy.subscribers_per_group) && valid;
      valid = this.checkInterval(strategy.send_interval_minutes) && valid;
    }

    this.validStep = valid ? 3 : 1;

    return valid;
  }

  notificationEmit() {
    this.notificationChange.emit(this.notification);
  }

  send() {
    this.sending = true;
    if (this.notification.id) {
      this.appService
        .update_reservation(this.notification)
        .pipe(finalize(() => (this.sending = false)))
        .subscribe(
          success => {
            this.translate
              .get('PUSH.RESERVED.EDITOR_SUCCESS')
              .subscribe((m: string) => {
                this.notiService.open(m, 'success');
              });
            this.onComplete.emit({
              data: this.notification,
              pushid: success.pushid
            });
          },
          response => {
            this.notiService.open(response.error.error, 'danger');
          }
        );
    } else {
      this.appService
        .transmit(this.notification)
        .pipe<any>(finalize(() => (this.sending = false)))
        .subscribe(
          success => {
            this.translate.get('PUSH.COMPLETED').subscribe((m: string) => {
              this.notiService.open(m, 'success');
            });
            this.notification.id = success.pushid;
            this.onComplete.emit({
              data: this.notification,
              pushid: success.pushid
            });
          },
          response => {
            this.notiService.open(response.error.error, 'danger');
          }
        );
    }
  }

  ngOnDestroy() {
    this.destroyPickers();
  }
}
