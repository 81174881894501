import { ModalContext } from '../../../services/modal/modal.service';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from '../../../services/app/app.service';
import { CSVPresetModel } from '../../../models/notification.model';

@Component({
  templateUrl: './sftp-preset-selector.component.html'
})
export class SftpPresetSelectorComponent implements OnInit {
  public presets$: Observable<CSVPresetModel[]>;
  public presetId: string;
  constructor(private modalCtx: ModalContext, private api: AppService) {
    this.presetId = modalCtx.payload as string;
  }
  ngOnInit() {
    this.presets$ = this.api.sftp_presets();
  }
  close() {
    this.modalCtx.reject();
  }
  select(id: string) {
    this.modalCtx.resolve(id);
  }
}
