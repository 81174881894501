<div class="form">
  <ng-container *ngIf="step === 0">
    <div class="field">
      <label class="label">{{ 'NOTIFICATION_EDITOR.TITLE' | translate }}</label>
      <div class="control">
        <input
          class="input"
          [class.is-danger]="invalidMsgs.title"
          type="text"
          name="title"
          [(ngModel)]="notification.title"
          (input)="checkTitle($event.target.value)"
        />
      </div>
      <p class="help is-danger">{{ invalidMsgs.title }}</p>
    </div>
    <div class="field">
      <label class="label">{{ 'NOTIFICATION_EDITOR.URL' | translate }}</label>
      <div class="control">
        <input
          class="input"
          [class.is-danger]="invalidMsgs.url"
          type="url"
          name="url"
          [(ngModel)]="notification.url"
          (input)="checkUrl($event.target.value)"
        />
      </div>
      <p class="help is-danger">{{ invalidMsgs.url }}</p>
    </div>
    <div class="field">
      <label class="label">{{ 'NOTIFICATION_EDITOR.BODY' | translate }}</label>
      <div class="control">
        <textarea
          class="textarea"
          [class.is-danger]="invalidMsgs.body"
          name="body"
          type="text"
          [(ngModel)]="notification.body"
          (input)="checkBody($event.target.value)"
        ></textarea>
      </div>
      <p class="help is-danger">{{ invalidMsgs.body }}</p>
    </div>
    <div class="field columns">
      <div class="column is-half">
        <label class="label">{{
          'NOTIFICATION_EDITOR.ICON' | translate
        }}</label>
        <app-img-crop-v2 [(src)]="notification.icon"></app-img-crop-v2>
      </div>
      <div class="column is-half">
        <label class="label">{{
          'NOTIFICATION_EDITOR.IMAGE' | translate
        }}</label>
        <app-img-crop-v2
          [(src)]="notification.image"
          [canReset]="true"
        ></app-img-crop-v2>
      </div>
    </div>
    <div class="field">
      <div class="control">
        <label class="checkbox label">
          <input
            type="checkbox"
            [(ngModel)]="!notification.disappear_instantly"
            (change)="toggleDisappear()"
          />
          {{ 'NOTIFICATION_EDITOR.DISAPPEAR_INSTANTLY' | translate }}
        </label>
      </div>
    </div>
    <div class="field">
      <div class="control">
        <label class="checkbox label">
          <input type="checkbox" [(ngModel)]="notification.enable_ios_sound" />
          {{ 'NOTIFICATION_EDITOR.ENABLE_IOS_SOUND' | translate }}
        </label>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 1">
    <div>
      <div class="field">
        <label class="label">{{
          'NOTIFICATION_EDITOR.TRANSMISSION_TIME' | translate
        }}</label>
        <div class="control">
          <input class="input flatpickr" type="datetime" [disabled]="sendNow" />
        </div>
        <div class="control has-text-right">
          <label class="checkbox label is-inline">
            <input
              type="checkbox"
              [ngModel]="sendNow"
              (click)="setReservation(!sendNow)"
            />
            {{ 'NOTIFICATION_EDITOR.UNSET_TRANSMISSION_TIME' | translate }}
          </label>
        </div>
      </div>
      <div class="field">
        <label class="label">{{
          'NOTIFICATION_EDITOR.CUSTOM_TTL.LABEL' | translate
        }}</label>
        <p class="help">
          {{ 'NOTIFICATION_EDITOR.CUSTOM_TTL.DESCRIPTION' | translate }}
        </p>
        <app-ttl-editor [(ngModel)]="notification.ttl"></app-ttl-editor>
      </div>
      <article class="message is-info" *ngIf="queryType !== 'all'">
        <div class="message-body">
          セグメント機能をご利用の場合、負荷分散配信を利用することはできません。
        </div>
      </article>
      <div class="field">
        <div class="control label">
          <label class="checkbox label">
            <!-- 既に作成されているプッシュの場合、分散配信の切り替えはできない -->
            <input
              [disabled]="queryType !== 'all' || isCreated"
              type="checkbox"
              [(ngModel)]="isLoadBalancePush"
              (change)="toggleStrategy()"
            />
            {{ 'NOTIFICATION_EDITOR.LOADBALANCE.LABEL' | translate }}
          </label>
        </div>
        <div class="help">
          {{ 'NOTIFICATION_EDITOR.LOADBALANCE.DESCRIPTION' | translate }}
        </div>
      </div>
      <ng-container *ngIf="isLoadBalancePush">
        <div class="field">
          <label class="label">{{
            'NOTIFICATION_EDITOR.LOADBALANCE.SUBSCRIBERS_PER_GROUP' | translate
          }}</label>
          <div class="control">
            <input
              min="1"
              class="input"
              [class.is-danger]="invalidMsgs.subscribers"
              type="number"
              name="number"
              [(ngModel)]="notification.delivery_strategy.subscribers_per_group"
              (input)="checkSubscribers($event.target.value)"
            />
          </div>
          <p class="help is-danger">{{ invalidMsgs.subscribers }}</p>
        </div>
        <div class="field">
          <label class="label">{{
            'NOTIFICATION_EDITOR.LOADBALANCE.SEND_INTERVAL_MINUTES' | translate
          }}</label>
          <div class="control">
            <input
              min="1"
              class="input"
              [class.is-danger]="invalidMsgs.interval"
              type="number"
              name="number"
              [(ngModel)]="notification.delivery_strategy.send_interval_minutes"
              (input)="checkInterval($event.target.value)"
            />
          </div>
          <p class="help is-danger">{{ invalidMsgs.interval }}</p>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 2">
    <div class="field">
      <label class="label">
        送信先
      </label>
      <!-- 負荷分散配信の場合 -->
      <article
        class="message is-info"
        *ngIf="notification?.delivery_strategy?.type === 'load_balance'"
      >
        <div class="message-body">
          負荷分散配信を利用する場合、通知は全ての購読者に送信されます。
        </div>
      </article>
      <!-- 負荷分散配信ではない場合 -> セグメントオプションを表示 -->
      <ng-container
        *ngIf="notification?.delivery_strategy?.type !== 'load_balance'"
      >
        <div class="control radio-w-help">
          <label class="radio label">
            <input
              type="radio"
              value="all"
              [(ngModel)]="queryType"
              (click)="toggleSendAll()"
            />
            全員に送信
          </label>
          <p class="help">
            購読者全員に通知を送信します。
          </p>
        </div>
        <div
          class="control radio-w-help"
          [class.is-disabled]="!(app$ | async).permissions.parameter"
        >
          <label class="radio label">
            <input
              type="radio"
              value="query"
              [(ngModel)]="queryType"
              (click)="toggleQuery()"
              [disabled]="!(app$ | async).permissions.parameter"
            />
            {{ 'NOTIFICATION_EDITOR.PARAMS' | translate }}
          </label>
          <p class="help">
            付与されたパラメータの組み合わせで購読者を絞り込み、該当者のみに通知を送信します。
          </p>
        </div>
        <div
          class="control radio-w-help"
          [class.is-disabled]="!(app$ | async).permissions.can_use_segment"
        >
          <label class="radio label">
            <input
              type="radio"
              value="segment"
              [(ngModel)]="queryType"
              (click)="toggleSegmentationQuery()"
              [disabled]="!(app$ | async).permissions.can_use_segment"
            />
            {{ 'NOTIFICATION_EDITOR.SEGMENTATION_QUERY' | translate }}
          </label>
          <p class="help">
            登録したセグメントクエリを用い、条件に該当する購読者のみに通知を送信します。
          </p>
        </div>
        <div
          class="control radio-w-help"
          [class.is-disabled]="!(app$ | async).permissions.parameter"
        >
          <label class="radio label">
            <input
              type="radio"
              value="sftp"
              [(ngModel)]="queryType"
              (click)="toggleSftpPreset()"
              [disabled]="!(app$ | async).permissions.parameter"
            />
            {{ 'NOTIFICATION_EDITOR.SFTP_INTEGRATION' | translate }}
          </label>
          <p class="help">
            SFTPサーバにアップロードされたCSVファイルに記載されたパラメータを持つ購読者に通知を送信します。
          </p>
        </div>
      </ng-container>
    </div>
    <div class="field" *ngIf="queryType === 'query'">
      <div class="control">
        <span
          >{{ 'NOTIFICATION_EDITOR.MODE' | translate }}:{{
            notification?.query?.mode
          }}</span
        ><br />
        <span
          >{{ notification?.query?.params?.length || 0 }}
          {{ 'NOTIFICATION_EDITOR.PARAMS_COUNT' | translate }}</span
        >
      </div>
      <div class="control">
        <button (click)="editQuery()" class="button is-info p7-button">
          {{ 'NOTIFICATION_EDITOR.PARAMS_CONFIGURE' | translate }}
        </button>
      </div>
    </div>
    <div class="field" *ngIf="queryType === 'sftp'">
      <div class="control">
        <span
          >{{ 'NOTIFICATION_EDITOR.SELECTED_PRESET' | translate }}:
          {{ notification?.csv_preset_id }}</span
        >
      </div>
      <div class="control">
        <button (click)="editSftpPreset()" class="button is-info p7-button">
          {{ 'NOTIFICATION_EDITOR.SELECT_PRESET' | translate }}
        </button>
      </div>
    </div>
    <div class="field" *ngIf="queryType === 'segment'">
      <div class="control">
        <span
          >{{ 'NOTIFICATION_EDITOR.SELECTED_SEGMENTATION_QUERY' | translate }}:
          {{ selectedSegmentName || notification.query }}</span
        >
      </div>
      <div class="control">
        <button
          (click)="editSegmentationQuery()"
          class="button is-info p7-button"
        >
          {{ 'NOTIFICATION_EDITOR.SELECT' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="step === 3">
    <div class="field">
      <div class="help">
        {{ 'NOTIFICATION_EDITOR.PREVIEW.DESCRIPTION' | translate }}
      </div>
    </div>
    <div class="field">
      <label class="label">{{
        'NOTIFICATION_EDITOR.PREVIEW.LINK' | translate
      }}</label>
      <div class="control">
        <input class="input" type="url" readonly [ngModel]="notification.url" />
      </div>
    </div>
    <div class="field">
      <label class="label">{{
        'NOTIFICATION_EDITOR.TRANSMISSION_TIME' | translate
      }}</label>
      <div class="control">
        <input
          class="input"
          readonly
          [ngModel]="
            notification.transmission_time ||
            ('NOTIFICATION_EDITOR.UNSET_TRANSMISSION_TIME' | translate)
          "
        />
      </div>
      <div class="help" *ngIf="notification.delivery_strategy">
        {{
          'NOTIFICATION_EDITOR.PREVIEW.LOADBALANCE_HELP'
            | translate
              : {
                  users: notification.delivery_strategy.subscribers_per_group,
                  minutes: notification.delivery_strategy.send_interval_minutes
                }
        }}
      </div>
    </div>
  </ng-container>
  <div class="buttons is-flex">
    <button
      class="button is-dark is-outlined p7-button"
      [disabled]="sending"
      (click)="back()"
    >
      {{ 'PUSH.COMMON.BACK' | translate }}
    </button>
    <button
      class="button is-dark p7-button"
      (click)="next()"
      [class.is-loading]="sending"
      [class.is-last]="step == 3"
    >
      {{
        'PUSH.COMMON.' +
          (step < 3 ? 'NEXT' : notification.id ? 'UPDATE' : 'SUBMIT')
          | translate
      }}
    </button>
  </div>
</div>
