import { combineLatest, Observable, of, Subject } from 'rxjs';
import { SessionService } from './../../../../services/session/session.service';
import { getAppSelected } from './../../../../reducers/index';
import { AppModel } from '../../../../models/app.model';
import { Store } from '@ngrx/store';
import { PlanModel } from '../../../../models/plan.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy } from '@angular/core';

import * as fromRoot from '../../../../reducers';
import { ProfileResponse } from '../../../../services/backend';
import { AuthService } from '../../../../services/auth/auth.service';
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  DefaultService as ApiService,
  Plan,
  Project
} from '../../../../services/api';
import { AppService } from '../../../../services/app/app.service';
import { HttpErrorResponse } from '@angular/common/http';
import { hasSufficientPermission } from '../../../../helpers/roleCmp';
import { getAppSelectedId } from '../../../../reducers/index';

@Component({
  selector: 'app-plan-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class PlanListComponent implements OnDestroy {
  public plans: PlanModel[];
  public app: AppModel;
  public currentPlan$: Observable<PlanModel>;
  public nextPlan$: Observable<PlanModel>;
  public profile: ProfileResponse;
  public profile$: Observable<ProfileResponse>;
  public project$: Observable<Project>;
  private onDestroy$: Subject<void> = new Subject();
  public plans$: Observable<Plan[]>;
  public isAdministrator$: Observable<boolean>;
  public isUsingCustomPlan$: Observable<boolean>;
  constructor(
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
    private session: SessionService,
    private router: Router,
    private authService: AuthService,
    private appService: AppService,
    private api: ApiService
  ) {
    this.plans$ = this.store.select(getAppSelectedId).pipe(
      takeUntil(this.onDestroy$),
      switchMap(appno => this.authService.getProjectFromAppno(appno)),
      switchMap(project => this.api.listProjectPlans(project.uuid)),
      map(response => response.plan)
    );
    this.store
      .select(getAppSelected)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(app => (this.app = app));
    this.isAdministrator$ = this.store.select(fromRoot.getAppSelectedId).pipe(
      switchMap(appno => this.authService.getProjectFromAppno(appno)),
      map(project => hasSufficientPermission(project.role, 'administrator'))
    );
    // 現在選択されているアプリケーション, プラン一覧から現在のプランを取得
    this.currentPlan$ = this.store.select(getAppSelected).pipe(
      takeUntil(this.onDestroy$),
      map(app => app.plan.current),
      shareReplay(1)
    );
    // 現在選択されているアプリケーション, プラン一覧から次のプランを取得
    this.nextPlan$ = this.store.select(getAppSelected).pipe(
      takeUntil(this.onDestroy$),
      map(app => app.plan.next),
      shareReplay(1)
    );
    this.profile$ = this.authService.profile$;
    this.project$ = this.store.select(fromRoot.getAppSelectedId).pipe(
      takeUntil(this.onDestroy$),
      switchMap(appno => this.authService.getProjectFromAppno(appno))
    );
    this.isUsingCustomPlan$ = this.store.select(fromRoot.getAppSelected).pipe(
      takeUntil(this.onDestroy$),
      map(app => app.plan.current.is_custom || app.plan.next.is_custom)
    );
  }

  get show_enterprise_application(): Observable<boolean> {
    return this.project$.pipe(
      map(
        project =>
          project.is_corporation &&
          project.enterprise_approval.status === 'none'
      )
    );
  }

  /**
   *  nextに設定されているプランかどうか = 選択中かどうか
   **/
  is_next(plan: PlanModel): Observable<boolean> {
    return this.nextPlan$.pipe(map(nextPlan => nextPlan.no === plan.no));
  }

  /**
   * currentに設定されているプランかどうか = 使用中かどうか
   * nextと被らないようにする必要がある
   **/
  is_current(plan: PlanModel): Observable<boolean> {
    return combineLatest([this.currentPlan$, this.nextPlan$]).pipe(
      map(
        ([currentPlan, nextPlan]) =>
          currentPlan.no === plan.no && nextPlan.no !== plan.no
      )
    );
  }

  can_change_to(plan: PlanModel): Observable<boolean> {
    return this.project$.pipe(
      map(project => project.is_corporation),
      switchMap(isCorporation => {
        if (isCorporation) {
          // 法人の場合 -> 現在より大きなrankのプランを選択できる
          return this.nextPlan$.pipe(
            map(nextPlan => nextPlan.rank < plan.rank)
          );
        } else {
          // 個人の場合 -> 現在のプランでも次のプランでもないものを選択できる
          return this.is_not_selected(plan);
        }
      })
    );
  }

  /**
   * 選択中でも使用中でもないものを新しく選ぶことができる
   **/
  is_not_selected(plan: PlanModel): Observable<boolean> {
    return combineLatest([this.is_current(plan), this.is_next(plan)]).pipe(
      map(([a, b]) => !a && !b)
    );
  }

  /**
   * 「ダウングレード」もしくは「フリーでないプランからの変更」はカード情報が不要
   * 「アップグレード」かつ「plan.currentが無料プラン(1)」の場合にカード情報が必要
   */
  change(plan: PlanModel) {
    // 変更したいプランを `plan` に保管しておく。遷移先はプランによって異なる。
    this.session.set('plan', plan);

    if (plan.is_enterprise) {
      // エンタープライズプランへの変更
      this.appService.get_payment_method().subscribe(({ payment_method }) => {
        if (!payment_method) {
          // paymentが登録されていない -> 登録画面へ
          this.router.navigate(['../payment-method'], {
            relativeTo: this.route
          });
        } else {
          // paymentが登録されている場合 -> そのままオプション選択に進む
          this.router.navigate(['../options'], { relativeTo: this.route });
        }
      });
    } else {
      // 個人向けプランへの変更
      let need_card_info = false;
      if (this.app.plan.current.rank < plan.rank) {
        // 現在のプランよりも対象のプランの方が番号が大きい -> アップグレード
        if (this.app.plan.current.no === 1) {
          // かつ現在無料プラン -> カード情報が必要
          need_card_info = true;
        }
      }
      this.session.set('plan', plan);
      if (need_card_info) {
        // 先にカード情報を入力する
        this.router.navigate(['../card'], { relativeTo: this.route });
      } else {
        // カード情報なしでの変更が可能
        this.router.navigate(['../confirm'], { relativeTo: this.route });
      }
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
