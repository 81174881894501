import { SessionService } from './../../../services/session/session.service';
import { ASTNode } from './../../../models/segments.model';
import {
  Directive,
  Output,
  Input,
  EventEmitter,
  ElementRef,
  OnInit
} from '@angular/core';

export interface DropEvent {
  data: ASTNode;
  event: DragEvent;
}

@Directive({
  selector: '[appAstDroppable]'
})
export class AstDroppableDirective implements OnInit {
  @Input()
  appAstDroppable = true;
  @Output()
  onDrop: EventEmitter<DropEvent> = new EventEmitter();
  private _el: Element;
  constructor(private el: ElementRef, private session: SessionService) {}
  ngOnInit() {
    this._el = this.el.nativeElement;
    let beingDrag: Element = null;
    this._el.addEventListener('dragenter', (e: DragEvent) => {
      e.stopPropagation();
      beingDrag = this.session.get('beingDrag') as Element;
    });
    this._el.addEventListener('dragover', (e: DragEvent) => {
      e.stopPropagation();
      const target = document.elementFromPoint(e.clientX, e.clientY);
      if (this.appAstDroppable === false) {
        // オプションでドロップを禁止されている場所
        return;
      }
      if (beingDrag && beingDrag.contains(e.srcElement as Node)) {
        // 自分自身の中にはドロップできない
        return;
      }
      e.preventDefault(); // ドロップ可能にする
    });
    this._el.addEventListener('drop', (e: DragEvent) => {
      e.stopPropagation();
      const key = e.dataTransfer.getData('ast-node');
      const data = this.session.get(key);
      this.onDrop.emit({
        data,
        event: e
      });
    });
  }
}
