import { NewPushModalComponent } from './new-push-modal/new-push-modal.component';
import { PushModalV2Component } from './push-modal-v2/push-modal-v2.component';
import { Modal } from './../../../services/modal/modal.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as app from '../../../actions/app';
import { AppModel } from '../../../models/app.model';
import { NotificationModel } from '../../../models/notification.model';
import { take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-push',
  templateUrl: './push.component.html',
  styleUrls: ['./push.component.scss']
})
export class PushComponent {
  private subscribers: number;
  constructor(
    private modal: Modal,
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.store
      .select(fromRoot.getAppSelected)
      .pipe(take(1))
      .subscribe(selectedApp => {
        this.subscribers = selectedApp.subscribers;
      });
  }
  compose() {
    this.modal
      //.open(NewPushModalComponent)
      .open(PushModalV2Component)
      .then((sent: { data: NotificationModel; pushid: string }) => {
        const notification = sent.data;
        notification.id = sent.pushid;
        if (notification.transmission_time) {
          // 予約投稿
          this.store.dispatch(new app.LoadReservationsAction());
        } else {
          notification.created_date = new Date();
          notification.clicked_count = 0;
          notification.subscribers = this.subscribers;
          this.store.dispatch(
            new app.UnshiftNotificationsAction({
              data: {
                [sent.pushid]: notification
              },
              order: [sent.pushid]
            })
          );
        }

        if (
          notification.delivery_strategy &&
          notification.delivery_strategy.type === 'load_balance'
        ) {
          this.router.navigate(['../load-balance'], { relativeTo: this.route });
        }
      })
      .catch(() => {});
  }
}
