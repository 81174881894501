export enum CardType {
  visa = 'visa',
  master = 'master'
}

export interface CardModel {
  /** カード番号 */
  card_no: string;
  /** 有効期限(YYMM) */
  expire: string;
  /** カード名義 */
  holder: string;
}
