<div class="main-nav">
  {{ 'SIDEBAR.SFTP_INTEGRATION' | translate }}
</div>
<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-header has-text-weight-bold is-size-4">
        {{ 'SIDEBAR.SFTP_INTEGRATION' | translate }}
        <app-help-link
          link="https://push7.jp/docs/development/sftp/"
        ></app-help-link>
      </div>
      <div class="container-body p7-form">
        <div class="content">
          <article
            class="message is-danger"
            *ngIf="!(hasParameterPermission$ | async)"
          >
            <div class="message-header">
              <p>{{ 'SFTP_INTEGRATION.REQUIRE_PERMISSION' | translate }}</p>
            </div>
            <div class="message-body">
              <p>
                {{ 'SFTP_INTEGRATION.REQUIRE_PERMISSION_MESSAGE' | translate }}
              </p>
            </div>
          </article>
          <p [innerHTML]="'SFTP_INTEGRATION.MESSAGE' | translate"></p>
          <ng-container *ngIf="hasParameterPermission$ | async">
            <ng-container *ngIf="(sftp$ | async)?.common; let common">
              <hr />
              <h2 class="title is-5">
                {{ 'SFTP_INTEGRATION.CONNECTION_INFO' | translate }}
              </h2>
              <p>{{ 'SFTP_INTEGRATION.USE_YOUR_PASSWORD' | translate }}</p>
              <app-copy-input
                [copyText]="common.host"
                copyLabel="Host"
              ></app-copy-input>
              <app-copy-input
                [copyText]="common.port"
                copyLabel="Port"
              ></app-copy-input>
              <app-copy-input
                [copyText]="common.username"
                copyLabel="User"
              ></app-copy-input>
            </ng-container>
            <ng-container *appDisplayFor="'administrator'">
              <hr />
              <h2 class="title is-5">
                {{ 'SFTP_INTEGRATION.PASSWORD_CONFIG' | translate }}
              </h2>
              <ng-container *ngIf="(sftp$ | async)?.common; else InitPassword">
                <p>
                  {{ 'SFTP_INTEGRATION.PASSWORD_CONFIG_MESSAGE' | translate }}
                </p>
              </ng-container>
              <ng-template #InitPassword>
                <p>{{ 'SFTP_INTEGRATION.INIT_SFTP' | translate }}</p>
              </ng-template>
              <form [formGroup]="form" class="form" (submit)="submit()">
                <label class="label">{{
                  'SFTP_INTEGRATION.PASSWORD' | translate
                }}</label>
                <p class="control">
                  <input
                    class="input"
                    type="password"
                    [class.is-success]="form.get('password').valid"
                    [class.is-danger]="
                      form.get('password').touched &&
                      form.get('password').invalid
                    "
                    formControlName="password"
                    required
                    name="password"
                  />
                </p>
                <p
                  class="help is-danger"
                  *ngIf="
                    form.get('password').touched && !form.get('password').valid
                  "
                >
                  {{ 'SFTP_INTEGRATION.VALIDATION_ERROR' | translate }}
                </p>
                <label class="label">{{
                  'SFTP_INTEGRATION.CONFIRM' | translate
                }}</label>
                <p class="control">
                  <input
                    class="input"
                    type="password"
                    [class.is-danger]="
                      form.get('confirm').touched && form.hasError('areEqual')
                    "
                    [class.is-success]="
                      form.get('confirm').touched && !form.hasError('areEqual')
                    "
                    formControlName="confirm"
                    required
                    name="confirm"
                  />
                </p>
                <p
                  class="help is-danger"
                  *ngIf="
                    form.get('confirm').touched && form.hasError('areEqual')
                  "
                >
                  {{ 'SIGNUP.ERRORS.PASSWORD_MISMATCH' | translate }}
                </p>
                <div class="has-text-right">
                  <button
                    [disabled]="form.invalid"
                    class="button is-primary"
                    [class.is-loading]="processing"
                    type="submit"
                  >
                    {{ 'SFTP_INTEGRATION.APPLY' | translate }}
                  </button>
                </div>
              </form>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
