import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { map, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Modal } from '../../../../services/modal/modal.service';
import { getAppSelected, getAppSelectedId, State } from '../../../../reducers';
import { AutomationRule } from '../../../../services/api';
import { DefaultService as ApiService } from '../../../../services/api';
import { AutomationRuleEditorModalComponent } from '../automation-rule-editor-modal.component';

@Component({
  templateUrl: './automation-rule-list.component.html'
})
export class AutomationRuleListComponent implements OnDestroy, OnInit {
  private onDestroy$: Subject<void> = new Subject<void>();
  private refresh$: Subject<0> = new Subject();
  public rules$: Observable<AutomationRule[]>;
  private appno$: Observable<string>;
  constructor(
    private api: ApiService,
    private store: Store<State>,
    private modal: Modal
  ) {
    this.appno$ = this.store.select(getAppSelected).pipe(map(app => app.appno));

    this.rules$ = this.refresh$.asObservable().pipe(
      startWith(0),
      switchMap(() =>
        this.store.select(getAppSelected).pipe(map(app => app.appno))
      ),
      switchMap(appno => api.listAutomationRules(appno)),
      map(result => result.automation_rules)
    );
  }

  ngOnInit() {
    this.refresh$.next(0);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public createRule() {
    this.modal
      .open(AutomationRuleEditorModalComponent)
      .then(() => this.refresh$.next())
      .catch(() => {});
  }
  public editRule(rule: AutomationRule) {
    this.modal
      .open(AutomationRuleEditorModalComponent, rule)
      .then(() => this.refresh$.next())
      .catch(() => {});
  }
}
