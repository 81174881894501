import { TranslateService } from '@ngx-translate/core';
import { Notification } from './../../../../services/notification/notification.service';
import { AppService } from './../../../../services/app/app.service';
import { ABTestModel } from './../../../../models/notification.model';
import { ModalContext } from './../../../../services/modal/modal.service';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-new-abtest-modal',
  templateUrl: './new-abtest-modal.component.html',
  styleUrls: ['./new-abtest-modal.component.scss']
})
export class NewAbtestModalComponent implements OnInit {
  public test: ABTestModel;
  public submitting = false;
  constructor(
    private modalCtx: ModalContext,
    private appService: AppService,
    private notificationService: Notification,
    private translate: TranslateService
  ) {
    this.test = {
      name: `Test ${new Date()}`,
      pushs: [
        { title: '', url: '', icon: '', body: '', disappear_instantly: true },
        { title: '', url: '', icon: '', body: '', disappear_instantly: true }
      ]
    };
  }
  ngOnInit() {}
  public get addable() {
    return this.test.pushs.length < 5;
  }
  add() {
    if (this.addable) {
      this.test.pushs.push({ title: '', url: '', icon: '', body: '' });
    }
  }
  public get removable() {
    return this.test.pushs.length > 2;
  }
  remove(i) {
    if (this.removable) {
      this.test.pushs.splice(i, 1);
    }
  }
  submit() {
    if (!this.isValid) {
      this.translate.get('ABTEST.FILL').subscribe(translation => {
        this.notificationService.open(translation, 'warning');
      });
      return;
    }
    this.submitting = true;
    this.appService
      .abtests_set(this.test)
      .pipe(finalize(() => (this.submitting = false)))
      .subscribe(
        success => {
          this.translate.get('ABTEST.SUCCESS').subscribe(translation => {
            this.notificationService.open(translation, 'success');
          });
          this.modalCtx.resolve();
        },
        response => {
          this.notificationService.open(response.error.error, 'danger');
        }
      );
  }
  public get isValid(): boolean {
    if (!this.test.name) {
      return false;
    }
    let valid = this.test.pushs.some(push => {
      return !!(push.title && push.body && push.url && push.icon);
    });
    if (!valid) {
      return false;
    }
    return true;
  }

  close() {
    this.modalCtx.reject();
  }
}
