import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Query } from '../../../../models/segments.model';
import { SegmentService } from '../../../../services/segment/segment.service';
import { Modal } from '../../../../services/modal/modal.service';
import { NewQueryModalComponent } from './new-query-modal/new-query-modal.component';

@Component({
  templateUrl: './query-list.component.html',
  styleUrls: ['./query-list.component.scss']
})
export class QueryListComponent implements OnInit {
  public page: number;
  public max: number;
  public queries$: Observable<Query[]>;
  constructor(private segment: SegmentService, private modal: Modal) {
    this.queries$ = this.segment.list$;
  }
  public newSegment() {
    this.modal
      .open(NewQueryModalComponent)
      .then(() => this.segment.update())
      .catch(() => {});
  }
  public ngOnInit() {
    this.segment.update();
  }
}
