import { getAppSelected } from './../../../../reducers/index';
import { DataSource } from './chart/overview.chart.component';
import { InfoRequest, OverviewScope } from './../../../../actions/analytics';
import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import * as app from '../../../../actions/app';
import * as analytics from '../../../../actions/analytics';
import { StateService } from './../../../../services/state/state.service';
import { AppService } from './../../../../services/app/app.service';
import { AppModel } from '../../../../models/app.model';
import { NotificationModel } from '../../../../models/notification.model';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ModifierBehaviorSubject } from './../../../../components/range-picker/range-picker.component';
import * as Flatpicker from 'flatpickr';
import * as Moment from 'moment';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class AnalyticsOverviewComponent implements OnInit {
  // Observables:
  public app$: Observable<AppModel>;
  public info$: Observable<any>;
  public overview$: Observable<any>;

  // Data to render the chart:
  public data: DataSource;
  // State
  // public scope: 'hour' | 'day' | 'week' | 'month' = 'hour';
  public tab: 'total' | 'increase';

  // For pickers
  public startDate$: ModifierBehaviorSubject<Moment.Moment>;
  public endDate$: ModifierBehaviorSubject<Moment.Moment>;

  public scope$: BehaviorSubject<
    'hour' | 'day' | 'week' | 'month'
  > = new BehaviorSubject<'hour' | 'day' | 'week' | 'month'>('day');
  public type$: BehaviorSubject<
    'ACTIVE_SUBSCRIBERS' | 'CUMULATIVE_SUBSCRIBERS' | 'NEW_SUBSCRIBERS'
  > = new BehaviorSubject<
    'ACTIVE_SUBSCRIBERS' | 'CUMULATIVE_SUBSCRIBERS' | 'NEW_SUBSCRIBERS'
  >('ACTIVE_SUBSCRIBERS');

  public _el: HTMLElement;
  constructor(
    private store$: Store<fromRoot.State>,
    private getter: StateService,
    private api: AppService,
    private el: ElementRef
  ) {
    this._el = el.nativeElement;
    this.app$ = store$.select(fromRoot.getAppSelected);
    store$.dispatch(new InfoRequest());
    this.info$ = store$.select(fromRoot.getAnalyticsInfo);
    this.overview$ = store$.select(fromRoot.getAnalyticsOverview);

    this.store$
      .select(getAppSelected)
      .pipe(
        take(1),
        map(x => Moment(x.created_date))
      )
      .subscribe(createdAt => {
        const now = Moment();
        const weekAgo = Moment(now).subtract(7, 'day');
        this.startDate$ = new ModifierBehaviorSubject<Moment.Moment>(
          null,
          start => {
            // startがアプリケーション作成日よりも前の場合はアプリケーション作成日を使用する
            return Moment.max(start.startOf('day'), createdAt);
          }
        );
        this.endDate$ = new ModifierBehaviorSubject<Moment.Moment>(
          null,
          (end: Moment.Moment) => {
            // endは現在よりも後を取らない
            return Moment.min(end.endOf('day'), Moment());
          }
        );
        this.startDate$.next(weekAgo);
        this.endDate$.next(now);
      });
  }
  ngOnInit() {
    this.changeScope('day');
  }
  public switchChartTypeTabs(tab) {
    this.store$.dispatch(
      new analytics.OverviewTab(this.getter.getAppno(), tab)
    );
  }
  public changeScope(scope) {
    this.scope$.next(scope);
  }
}
