<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-header has-text-weight-bold is-size-4">
        {{ 'AUTO.WP.TITLE' | translate }}
        <app-help-link
          link="https://push7.jp/docs/guide/automation/"
        ></app-help-link>
      </div>
      <div class="container-body">
        <div class="content" [innerHTML]="'AUTO.WP.MESSAGE' | translate"></div>
        <div class="step">
          <div class="count">
            <p>1</p>
          </div>
          <div class="content">
            <p [innerHTML]="'AUTO.WP.STEP1' | translate"></p>
          </div>
        </div>
        <div class="step">
          <div class="count">
            <p>2</p>
          </div>
          <div class="content">
            <p>{{ 'AUTO.WP.STEP2' | translate }}</p>
            <app-copy-input
              copyText="{{ (app$ | async)?.appno }}"
              copyLabel="App Number"
            ></app-copy-input>
            <app-copy-input
              copyText="{{ (app$ | async)?.apikey }}"
              copyLabel="API Key"
            ></app-copy-input>
          </div>
        </div>
        <div class="step">
          <div class="count">
            <p>3</p>
          </div>
          <div class="content">
            <p>{{ 'AUTO.WP.STEP3' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
