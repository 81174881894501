import { Component } from '@angular/core';
import { ModalContext } from './../../../../services/modal/modal.service';

@Component({
  templateUrl: './domain-confirm.component.html'
})
export class DomainConfirmComponent {
  constructor(private modalCtx: ModalContext) {}
  close() {
    this.modalCtx.reject();
  }
  okay() {
    this.modalCtx.resolve();
  }
}
