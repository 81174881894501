import * as moment from 'moment';

/**
 * クエリ自体
 */
export interface Query {
  id: string;
  name: string;
  query: string;
  updated_at: string;
}

/**
 * 指定に使えるオペレータ(多項演算子)
 */
export type Operator =
  | '+'
  | '-'
  | 'AND'
  | 'OR'
  | 'XOR'
  | 'NOR'
  | 'NAND'
  | 'XNOR';

/**
 * 要素の親クラス
 */
export abstract class ASTNode {
  abstract genQuery(): string;
  abstract className: string;
  validate(): { isValid: boolean; error?: string } {
    return { isValid: true };
  }
}

/**
 * InnerNode
 * AND, ORなどのオペレータと複数のConditionsを持つ
 */
export class ASTInnerNode extends ASTNode {
  public className = 'ASTInnerNode';
  constructor(public operator: Operator, public children: Array<ASTNode> = []) {
    super();
  }
  public genQuery() {
    let query = '';
    this.children.forEach((child, i) => {
      if (i === 0) {
        query = child.genQuery();
      } else {
        query = `(${query} ${this.operator} ${child.genQuery()})`;
      }
    });
    return query;
  }
}

/**
 * Root
 * 一番根のElem
 */
export class ASTRoot extends ASTNode {
  public className = 'ASTRoot';
  constructor(public element?: ASTNode) {
    super();
  }
  public genQuery() {
    if (this.element && this.element.genQuery) {
      return this.element.genQuery();
    }
    return null;
  }
}

/**
 * Qualifier(単項演算子)
 */
type Qualifier = 'NULL' | 'NOT';

/**
 * 単項演算子
 * InnerNodeだが1つしかconditionを子に持たない
 */
export class ASTValue extends ASTNode {
  public className = 'ASTValue';
  constructor(
    public qualifier: Qualifier | null,
    public element: ASTNode = null
  ) {
    super();
    if (this.qualifier === null) {
      this.qualifier = 'NULL';
    }
  }
  public genQuery() {
    if (!this.element) {
      return '';
    }
    return this.qualifier === 'NULL'
      ? this.element.genQuery()
      : `(${this.qualifier} ${this.element.genQuery()})`;
  }
}

/**
 * Parameter
 */
export class ASTParameter extends ASTNode {
  public className = 'ASTParameter';
  constructor(public parameter = '') {
    super();
  }
  public genQuery() {
    return `${this.parameter}`;
  }
  public validate() {
    if (!this.parameter) {
      return { isValid: false, error: 'EMPTY-PARAMETER' };
    }
    if (/\W/.test(this.parameter)) {
      return { isValid: false, error: 'CONTAINS-INVALID-CHARACTER' };
    }
    return { isValid: true };
  }
}

/**
 * Parameters - multiple
 */
export class ASTParameters extends ASTNode {
  public className = 'ASTParameters';
  constructor(public parameters: Array<ASTParameter> = []) {
    super();
  }
  public genQuery() {
    return `(ONLY ${this.parameters.map(x => x.genQuery()).join(', ')})`;
  }
  public validate() {
    if (this.parameters.length <= 0) {
      return { isValid: false, error: 'EMPTY-PARAMETERS' };
    }
    return { isValid: true };
  }
}

/**
 * Browsers
 */
export type Browsers = 'Chromium' | 'Firefox' | 'iOS';

export class ASTBrowser extends ASTNode {
  public className = 'ASTBrowser';
  constructor(public browser: Browsers = 'Chromium') {
    super();
  }
  public genQuery() {
    return `(GET_USER_LIST_BROWSER ${this.browser})`;
  }
}

export type OSs = 'PC' | 'Android' | 'iOS';

export class ASTOS extends ASTNode {
  public className = 'ASTOS';
  constructor(public os: OSs = 'PC') {
    super();
  }
  public genQuery() {
    return `(GET_USER_LIST_OS ${this.os})`;
  }
}

/**
 * 開封済み
 */
export class ASTOpened extends ASTNode {
  public className = 'ASTOpened';
  constructor(public pushid = '') {
    super();
  }
  public genQuery() {
    return `(GET_USER_LIST_OPENED ${this.pushid})`;
  }
  public validate() {
    if (this.pushid === '') {
      return { isValid: false, error: 'NO-PUSHID' };
    }
    return { isValid: true };
  }
}

/**
 * 送信済み
 */
export class ASTSent extends ASTNode {
  public className = 'ASTSent';
  constructor(public pushid = '') {
    super();
  }
  public genQuery() {
    return `(GET_USER_LIST_SEND ${this.pushid})`;
  }
  public validate() {
    if (this.pushid === '') {
      return { isValid: false, error: 'NO-PUSHID' };
    }
    return { isValid: true };
  }
}

// TODO: lastvisit, lastopened, subscribedayと関連するDateの処理系

export type DateQuery = ASTDateTime | ASTDateTimeRange | ASTDateTimeNever;
export type DateSpecifier =
  | ASTDateTime
  | ASTRelativeDateTime
  | ASTKeywordDateTime;

/**
 * 特定の日時を表す
 */
export class ASTDateTime extends ASTNode {
  public className = 'ASTDateTime';
  constructor(public date: Date = new Date()) {
    super();
  }
  public genQuery() {
    const m = moment(this.date);
    return `(DATETIME "${m.format()}")`;
  }
}

export type RelativeTimeUnit = 'YEAR' | 'MONTH' | 'DAY' | 'HOUR' | 'MINUTE';

/**
 * (今を基準に)特定の時間を相対的に指定する
 */
export class ASTRelativeDateTime extends ASTNode {
  public className = 'ASTRelativeDateTime';
  constructor(public amount: number, public unit: RelativeTimeUnit) {
    super();
  }
  public genQuery() {
    return `(BEFORE (${this.unit} ${this.amount}))`;
  }
}

export type DateTimeKeyword = 'NOW' | 'TODAY';
export class ASTKeywordDateTime extends ASTNode {
  public className = 'ASTKeywordDateTime';
  constructor(public value: DateTimeKeyword) {
    super();
  }
  public genQuery() {
    return `(${this.value})`;
  }
}

/**
 * Nullを表現
 */
export class ASTDateTimeNever extends ASTNode {
  public className = 'ASTDateTimeNever';
  public genQuery() {
    return `DATETIME_NULL`;
  }
}

/**
 * 時間を範囲で指定
 */
export class ASTDateTimeRange extends ASTNode {
  public className = 'ASTDateTimeRange';
  constructor(
    public from: DateSpecifier = new ASTRelativeDateTime(3, 'DAY'),
    public to: DateSpecifier = new ASTKeywordDateTime('NOW')
  ) {
    super();
  }
  public genQuery() {
    if (!this.from || !this.to) {
      return;
    }
    return `(${this.from.genQuery()} ~ ${this.to.genQuery()})`;
  }
}

/**
 * lastvisit
 */
export class ASTLastVisit extends ASTNode {
  public className = 'ASTLastVisit';
  constructor(public time: DateQuery = new ASTDateTimeNever()) {
    super();
  }
  public genQuery() {
    return `(LASTVISIT ${this.time.genQuery()})`;
  }
}

export type UrlQuery = ASTUrlJust | ASTUrlPrefix;
export class ASTUrlJust extends ASTNode {
  public className = 'ASTUrlJust';
  constructor(public url = '') {
    super();
  }
  public genQuery() {
    return `(JUST "${this.url}")`;
  }
}
export class ASTUrlPrefix extends ASTNode {
  public className = 'ASTUrlPrefix';
  constructor(public url = '') {
    super();
  }
  public genQuery() {
    return `(PREFIX "${this.url}")`;
  }
}

export class UrlDatetime extends ASTNode {
  public className = 'UrlDatetime';
  constructor(public url: UrlQuery, public time: DateQuery) {
    super();
  }
  public genQuery() {
    return `${this.url.genQuery()} ${this.time.genQuery()}`;
  }
}

export class ASTLastVisitUrl extends ASTNode {
  public className = 'ASTLastVisitUrl';
  constructor(
    public urlDatetime: UrlDatetime = new UrlDatetime(
      new ASTUrlJust(''),
      new ASTDateTimeNever()
    )
  ) {
    super();
  }
  public genQuery() {
    return `(LASTVISIT_URL ${this.urlDatetime.genQuery()})`;
  }
}

/**
 * LastOpened
 */
export class ASTLastOpened extends ASTNode {
  public className = 'ASTLastOpened';
  constructor(public time: DateQuery = new ASTDateTimeNever()) {
    super();
  }
  public genQuery() {
    return `(LASTOPENED ${this.time.genQuery()})`;
  }
}

export class ASTSubscribeDay extends ASTNode {
  public className = 'ASTSubscribeDay';
  constructor(public time: DateQuery = new ASTDateTimeNever()) {
    super();
  }
  public genQuery() {
    return `(SUBSCRIBEDAY ${this.time.genQuery()})`;
  }
}

// TODO: lastopen, subscribeday. Lastvisitと同じ

/**
 * ユーザ開封率
 */
export class ASTOpenRate extends ASTNode {
  public className = 'ASTOpenRate';
  constructor(public openrate: number) {
    super();
  }
  public genQuery() {
    return `(OPENRATE ${this.openrate})`;
  }
}

// TODO: geolocation
