import { Observable } from 'rxjs';
import { AppService } from './../services/app/app.service';
import { PlanModel } from './../models/plan.model';
import {
  Resolve,
  ActivatedRoute,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

function separate(num: number | string): string {
  num = String(num);
  const len = num.length;
  if (len > 3) {
    return `${separate(num.substring(0, len - 3))}, ${num.substring(len - 3)}`;
  } else {
    return num;
  }
}

@Injectable()
export class PlansResolve implements Resolve<PlanModel[]> {
  private plans: any[];
  constructor(private appService: AppService) {}
  api() {
    return this.appService.plans().pipe(
      map(d => d.plan),
      map(arr => (this.plans = arr)),
      map(arr =>
        arr.map((plan: PlanModel) => {
          plan.sendlimit = separate(plan.sendlimit);
          return plan;
        })
      )
    );
  }
  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<PlanModel[]> | PlanModel[] {
    if (this.plans && this.plans.length > 0) {
      return this.plans;
    } else {
      return this.api();
    }
  }
}
