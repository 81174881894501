import { ASTOS } from './../../../models/segments.model';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ASTNodeComponentBase } from '../ast-node.interface';

@Component({
  selector: 'app-ast-os',
  template: `
    <div class="message" *ngIf="value">
      <div
        class="message-header"
        [appAstDraggable]="value"
        (onMoved)="removeFromParentNode.emit()"
      >
        <p>Platform</p>
        <a
          *ngIf="!hideDelete"
          class="delete"
          (click)="removeFromParentNode.emit()"
        ></a>
      </div>
      <div class="message-body">
        <div class="select">
          <select
            [(ngModel)]="value.os"
            (change)="ASTBrowser.os = $event.target.value"
          >
            <option value="PC">PC</option>
            <option value="Android">Android</option>
            <option value="iOS">iOS</option>
          </select>
        </div>
      </div>
    </div>
  `
})
export class ASTOSComponent implements ASTNodeComponentBase<ASTOS> {
  @Input()
  hideDelete: boolean;
  @Input()
  value: ASTOS;
  @Output()
  removeFromParentNode: EventEmitter<void> = new EventEmitter<void>();
}
