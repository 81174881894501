/**
 * Push7 Project Feature for push7-apiserver
 * Push7 Project Featurs API specfication
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: enginners@gnex.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AutomationActionActions } from './automationActionActions';


/**
 * 
 */
export interface AutomationAction { 
    /**
     * ルールの評価時刻(分精度、ISO 8601) 
     */
    created_at?: string;
    actions?: AutomationActionActions;
    /**
     * アクションのステータス skipped（対象購読者が0人等の理由で実行がスキップされた) or done(実行が正常終了した)
     */
    status?: AutomationAction.StatusEnum;
    /**
     * 絞り込んだ結果の購読者の数
     */
    target_subscribers?: number;
}
export namespace AutomationAction {
    export type StatusEnum = 'skipped' | 'done';
    export const StatusEnum = {
        Skipped: 'skipped' as StatusEnum,
        Done: 'done' as StatusEnum
    };
}


