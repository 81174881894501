<div class="main-nav" [innerHTML]="'DOMAIN.LABEL' | translate"></div>
<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-header has-text-weight-bold is-size-4">
        {{ 'DOMAIN.LABEL' | translate }}
        <app-help-link
          link="https://push7.jp/docs/guide/opt-in-page-by-custom-domain/"
        ></app-help-link>
      </div>
      <div class="container-body">
        <div class="content">
          <p class="msg">{{ 'DOMAIN.MESSAGE' | translate }}</p>
          <nav class="container menu">
            <div class="wrapper field has-addons">
              <div class="control">
                <button
                  class="button is-primary is-fullwidth"
                  [disabled]="status != 'none'"
                  [class.is-outlined]="status != 'none'"
                >
                  1. {{ 'DOMAIN.NAV.DNS' | translate }}
                </button>
              </div>
              <div class="control">
                <button
                  class="button is-primary is-fullwidth"
                  [disabled]="status != 'inProgress' && status != 'failed'"
                  [class.is-outlined]="
                    status != 'inProgress' && status != 'failed'
                  "
                >
                  2. {{ 'DOMAIN.NAV.CERTIFICATE' | translate }}
                </button>
              </div>
              <div class="control">
                <button
                  class="button is-primary is-fullwidth"
                  [disabled]="status != 'completed'"
                  [class.is-outlined]="status != 'completed'"
                >
                  3. {{ 'DOMAIN.NAV.COMPLETED' | translate }}
                </button>
              </div>
            </div>
          </nav>
          <div class="main adjust-margin" [ngSwitch]="status">
            <div class="none" *ngSwitchCase="'none'">
              <div class="step">
                <div class="count">
                  <p>1</p>
                </div>
                <div class="content">
                  <p>{{ 'DOMAIN.NONE.FIRST' | translate }}</p>
                </div>
              </div>
              <div class="step">
                <div class="count">
                  <p>2</p>
                </div>
                <div class="content p7-form">
                  <p>{{ 'DOMAIN.NONE.SECOND' | translate }}</p>
                  <table class="table is-bordered has-text-centered">
                    <thead>
                      <tr>
                        <td>{{ 'DOMAIN.TABLE.SUBDOMAIN' | translate }}</td>
                        <td>{{ 'DOMAIN.TABLE.VALUE' | translate }}</td>
                        <td>{{ 'DOMAIN.TABLE.TYPE' | translate }}</td>
                        <td>{{ 'DOMAIN.TABLE.TTL' | translate }}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          [innerHTML]="'DOMAIN.TABLE.SUBDOMAIN_EX' | translate"
                        ></td>
                        <td class="cname-white-space">
                          {{ cname
                          }}<b style="font-size:1.2em;color:#CD0202;">.</b>
                        </td>
                        <td>CNAME</td>
                        <td>86400</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="step">
                <div class="count">
                  <p>3</p>
                </div>
                <div class="content">
                  <p>{{ 'DOMAIN.NONE.THIRD' | translate }}</p>
                  <div class="columns">
                    <div class="column is-half">
                      <form (submit)="register()">
                        <div class="field is-grouped">
                          <p class="control is-expanded">
                            <input
                              [(ngModel)]="domain"
                              name="domain"
                              class="input"
                              type="text"
                              required
                              pattern="([A-Za-z0-9][A-Za-z0-9\-]{1,61}[A-Za-z0-9]\.)+[A-Za-z]+"
                              placeholder="push.example.com"
                            />
                          </p>
                          <p class="control">
                            <button type="submit" class="button is-info">
                              {{ 'DOMAIN.NONE.BUTTON' | translate }}
                            </button>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="inProgress" *ngSwitchCase="'inProgress'">
              <article class="message is-info">
                <div class="message-body">
                  <p>{{ 'DOMAIN.PROGRESS.MESSAGE' | translate }}</p>
                </div>
              </article>
            </div>
            <div class="failed" *ngSwitchCase="'failed'">
              <article class="message is-warning">
                <div class="message-body">
                  <p>{{ 'DOMAIN.FAILED.MESSAGE' | translate }}</p>
                </div>
              </article>
              <p class="has-text-centered">
                <button class="button is-danger" (click)="retry()">
                  {{ 'DOMAIN.FAILED.RESET' | translate }}
                </button>
              </p>
            </div>
            <div class="completed" *ngSwitchCase="'completed'">
              <p
                [innerHTML]="
                  'DOMAIN.COMPLETED.MESSAGE' | translate: completed_param()
                "
              ></p>
              <p>
                <button class="button is-danger" (click)="reset()">
                  {{ 'DOMAIN.COMPLETED.DELETE' | translate }}
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
