<section class="hero is-primary is-fullheight">
  <div class="hero-body">
    <div class="container has-text-centered">
      <h1 class="title">
        {{ '404.404' | translate }}
      </h1>
      <h2 class="subtitle">
        {{ '404.SORRY' | translate }}
      </h2>
      <div class="links adjust-margin">
        <p class="adjust-margin">
          <a [routerLink]="['/']">{{ '404.LINK' | translate }}</a>
        </p>
      </div>
    </div>
  </div>
</section>
