<div class="main-nav">
  {{ 'PUSH.LABEL' | translate }}
</div>

<div class="tabs is-medium">
  <ul class="tabs-gray">
    <li [routerLink]="['normal']" [routerLinkActive]="['is-active']">
      <a>{{ 'PUSH.SENT' | translate }}</a>
    </li>
    <li [routerLink]="['reserved']" [routerLinkActive]="['is-active']">
      <a>{{ 'PUSH.RESERVED_LABEL' | translate }}</a>
    </li>
  </ul>
  <ul class="tabs-gray is-right">
    <li *appDisplayFor="'operator'">
      <a (click)="compose()" class="button is-primary">
        {{ 'PUSH.CREATE' | translate }}
      </a>
    </li>
  </ul>
</div>

<router-outlet></router-outlet>
