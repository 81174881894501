<div class="main-nav">
  負荷分散配信
</div>
<div class="tabs is-medium p7-tabs" *appDisplayFor="'operator'">
  <ul class="tabs-gray is-right">
    <li>
      <a class="button is-primary" (click)="create()">
        新規負荷分散配信
      </a>
    </li>
  </ul>
</div>
<div class="columns is-centered">
  <div class="column is-8-widescreen">
    <div class="container main-container">
      <ng-container *ngIf="pushs; then Loaded; else Loading"></ng-container>
      <ng-template #Loaded>
        <ng-container *ngIf="pushs.length != 0; else NoTests">
          <div class="is-paddingless">
            <div class="content is-marginless"></div>
            <table class="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th></th>
                  <th>タイトル</th>
                  <th>ステータス</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let push of pushs">
                  <td class="noti-icon cell-image">
                    <figure class="image is-24x24">
                      <img src="{{ push.icon }}" alt="icon" />
                    </figure>
                  </td>
                  <td>{{ push.title }}</td>
                  <td>{{ translateStatus(push.status) }}</td>
                  <td>
                    <a
                      class="button is-success is-outlined"
                      (click)="detail(push)"
                    >
                      <span>詳細</span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="container-footer">
            <div class="pagination">
              <a
                (click)="prev()"
                class="pagination-previous has-background-primary"
                [class.is-disabled]="page == 1"
              >
                <i class="fa fa-caret-left" aria-hidden="true"></i>
              </a>
              <div class="pagination-list">
                <span class="button is-primary">{{ page }}/{{ max }}</span>
              </div>
              <a
                (click)="next()"
                class="pagination-next has-background-primary"
                [class.is-disabled]="page == max"
              >
                <i class="fa fa-caret-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
  <ng-template #Loading>
    <app-loading></app-loading>
  </ng-template>

  <ng-template #NoTests>
    <div class="container-body">
      <div class="columns is-vcentered is-centered is-mobile">
        <div class="column is-narrow">
          <figure class="image is-185x185">
            <img
              class="box is-paddingless"
              src="/assets/img/hint/abtest.png"
              alt=""
            />
          </figure>
        </div>
        <div class="is-size-4">
          ロードバランス配信がありません
        </div>
      </div>
    </div>
  </ng-template>
</div>
