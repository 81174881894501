import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { getAppSelected, State } from '../../reducers';
import { AutomationRuleSegmentation } from '../../services/api';
import { SimpleQueryFormComponent } from '../simple-query-form/simple-query-form.component';

type Segmentation = AutomationRuleSegmentation;

@Component({
  selector: 'app-segmentation-form',
  templateUrl: './segmentation-form.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SegmentationFormComponent)
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SegmentationFormComponent),
      multi: true
    }
  ]
})
export class SegmentationFormComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  private onDestroy$: Subject<void> = new Subject<void>();

  public form: FormGroup;
  public canUseParameters$: Observable<boolean>;
  public canUseSegmentation$: Observable<boolean>;

  // コールバック群
  private onChange: any = () => {};
  private onTouched: any = () => {};

  get value(): Segmentation {
    return this.form.value;
  }
  @Input('value')
  set value(segmentation: Segmentation) {
    if (!segmentation) {
      return;
    }
    this.form.patchValue(segmentation);
    this.onChange(segmentation);
    this.onTouched();
  }

  constructor(private fb: FormBuilder, private store: Store<State>) {}

  validate(control: AbstractControl): ValidationErrors {
    const error = { segmentation: false };
    if (this.form.invalid) {
      return error;
    }
    const value = this.value;
    switch (value.type) {
      case 'all':
        // 全員対象の場合はパラメータは必要ない
        return null;
      case 'query':
        // シンプルクエリ
        return SimpleQueryFormComponent.validateQuery(value.query as any)
          ? null
          : error;
      case 'segment_preset':
        return value.segment_preset_id ? null : error;
      case 'csv_preset':
        return value.csv_preset_id ? null : error;
    }
  }

  static defaultFormValue() {
    return {
      type: AutomationRuleSegmentation.TypeEnum.All,
      csv_preset_id: null,
      query: null,
      segment_preset_id: null
    };
  }

  ngOnInit() {
    this.form = this.fb.group({
      type: this.fb.control('all', Validators.required),
      csv_preset_id: this.fb.control(''), // CSVプリセット
      query: [],
      segment_preset_id: this.fb.control('')
    });
    this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      this.onChange(value);
      this.onTouched();
    });
    // typeが変わったタイミングでフィールドをリセットする
    this.form
      .get('type')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((type: Segmentation['type']) => {
        // 初回代入時に値をリセットしないように、typeで指定されたものだけはリセットしない
        if (type !== 'query') {
          this.form.get('query').reset();
        }
        if (type !== 'csv_preset') {
          this.form.get('csv_preset_id').reset();
        }
        if (type !== 'segment_preset') {
          this.form.get('segment_preset_id').reset();
        }
      });
    this.canUseParameters$ = this.store
      .select(getAppSelected)
      .pipe(map(app => app.permissions.parameter));
    this.canUseSegmentation$ = this.store
      .select(getAppSelected)
      .pipe(map(app => app.permissions.can_use_segment));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  writeValue(obj: Segmentation): void {
    if (obj) {
      this.value = obj;
    }
    if (obj === null) {
      this.form.reset();
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
