import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { from, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { AppModel } from '../../models/app.model';
import { CardModel } from '../../models/card.model';
import {
  ABTestModel,
  CSVPresetModel,
  LoadBalanceNotificationModel,
  NotificationModel
} from '../../models/notification.model';
import { Query } from '../../models/segments.model';
import * as fromRoot from '../../reducers';
import { Project } from '../api';
import { AuthService } from '../auth/auth.service';
import { BoxModel } from './../../models/box.model';
import {
  EnterprisePlanOptions,
  PaymentMethodModel
} from './../../models/plan.model';

@Injectable()
export class AppService {
  private app: AppModel;
  private notificationLimit = 10;
  private project: Project;
  constructor(
    private http: HttpClient,
    private store: Store<fromRoot.State>,
    private authService: AuthService
  ) {
    store.select(fromRoot.getAppSelected).subscribe(app => {
      this.app = app;
      if (this.app.appno !== null) {
        this.authService
          .getProjectFromAppno(this.app.appno)
          .pipe(take(1))
          .subscribe(project => {
            this.project = project;
          });
      }
    });
  }

  /**
   * ファイルのアップロード
   * @param {string} filename - ファイル名
   * @param {string} data - Base64データ
   */
  upload(filename, data) {
    return this.http.post<any>(`${env.HOST}/upload`, { filename, data });
  }
  /**
   * 単一のアプリケーションの情報を取得する
   * @param {string} appno - appno
   */
  show(appno: string = this.app.appno): Observable<AppModel> {
    return this.http.get<AppModel>(`${env.HOST}/${appno}/show`);
  }
  /**
   * 単一のアプリケーションの情報を更新する
   * @param {string} appno - appno
   */
  update(
    data: { name: string; url: string; icon: string; categoryno: number },
    appno: string = this.app.appno
  ): Observable<any> {
    return this.http.post<any>(`${env.HOST}/${appno}/update`, data);
  }
  /* アプリケーションドメインのチェック
   * @param {string} domain - ドメイン
   */
  check_domain(domain: string) {
    return this.http.get<any>(`${env.HOST}/check_domain?domain=${domain}`);
  }
  /**
   * アプリケーション登録
   * @param {string} name - アプリケーション名
   * @param {string} url - サイトURL
   * @param {string} icon - アイコンURL
   * @param {string} domain - ドメイン
   */
  register(
    name: string,
    url: string,
    icon: string,
    domain: string,
    categoryno: number
  ) {
    return this.http.post<any>(`${env.HOST}/register`, {
      name,
      url,
      icon,
      domain,
      categoryno
    });
  }
  delete(appno: string = this.app.appno) {
    return this.http.delete<any>(`${env.HOST}/${appno}/unregister`);
  }
  /**
   * アプリケーションエイリアスの新作生成依頼
   * @param {string} alias - 登録したいドメインエイリアス
   */
  alias_new(alias: string, appno = this.app.appno) {
    return this.http.post<{
      success: string;
      finish: string;
    }>(`${env.HOST}/${appno}/alias/new`, { alias });
  }
  /**
   * アプリケーションエイリアス作成状況の照会
   */
  alias_get(appno = this.app.appno) {
    return this.http.get<{
      status: '処理中' | '登録失敗' | '登録完了';
    }>(`${env.HOST}/${appno}/alias`);
  }
  /**
   * アプリケーションエイリアスの削除
   */
  alias_delete(appno = this.app.appno) {
    return this.http.delete<any>(`${env.HOST}/${appno}/alias`);
  }

  /**
   * SFTP連携に使用するパスワードの初期化及びアップデート
   * @param password 新しいパスワード
   * @param appno appno
   */
  upsert_sftp_linkage_password(password: string, appno = this.app.appno) {
    return this.http.post<{ success?: string; error?: string; code?: number }>(
      `${env.API}/${appno}/upsert_sftp_linkage_password`,
      {
        sftp_vendor_id: 'common',
        new_password: password
      }
    );
  }
  rss_set(rss: string, appno: string = this.app.appno) {
    return this.http.post<any>(`${env.HOST}/${appno}/rss`, { rss });
  }
  rss_reset(appno: string = this.app.appno) {
    return this.http.delete<any>(`${env.HOST}/${appno}/rss`);
  }
  history(offset = 10, limit = 10, appno: string = this.app.appno) {
    /* tslint:disable */
    return this.http.get<{ pushs: NotificationModel[]; count: number }>(
      `${env.API}/${appno}/pushs`,
      {
        params: {
          offset: String(offset),
          limit: String(limit),
          order: 'desc'
        }
      }
    );
    /* tslint:enable */
    /*

    return this.http.get<{
      push: {
        count: number;
        offset: number;
        limit: number;
        pushs: NotificationModel[];
      };
    }>(`${env.HOST}/${appno}/push?limit=${limit}&offset=${offset}`);*/
  }
  reservations() {
    return this.http.get<{
      pushs: NotificationModel[];
    }>(`${env.API}/${this.app.appno}/reserved_pushs`);
  }
  welcome_get() {
    return this.http.get<NotificationModel>(
      `${env.API}/${this.app.appno}/welcome_push`
    );
  }
  welcome_set(push: NotificationModel | {}) {
    return this.http.post<any>(`${env.API}/${this.app.appno}/welcome_push`, {
      push
    });
  }
  transmit(push: NotificationModel) {
    return this.http.post<any>(`${env.API}/${this.app.appno}/send`, push);
  }
  sftp_presets(): Observable<CSVPresetModel[]> {
    return this.http
      .get<{ csv_presets: CSVPresetModel[] }>(
        `${env.API}/${this.app.appno}/csv_presets`
      )
      .pipe(map(x => x.csv_presets));
  }
  /**
   * 送信済みPushを非表示にするAPI
   * @param {string} pushid - PushのID
   */
  disappear_push(pushid: string) {
    return this.http.post<any>(
      `${env.API}/${this.app.appno}/push/${pushid}/disappear`,
      null
    );
  }
  update_reservation(push: NotificationModel) {
    return this.http.post<any>(
      `${env.API}/${this.app.appno}/reserved_push/update/${push.id}`,
      push
    );
  }
  delete_reservation(push: NotificationModel) {
    return this.http.post<any>(
      `${env.API}/${this.app.appno}/reserved_push/delete/${push.id}`,
      null
    );
  }
  get_dumpcsv(push: NotificationModel) {
    return `${env.HOST}/${this.app.appno}/${push.id}/dumpcsv`;
  }
  abtests_set(test: ABTestModel) {
    return this.http.post<any>(
      `${env.API}/${this.app.appno}/abpush/send`,
      test
    );
  }
  cancel_abtest(id: string) {
    return this.http.post<any>(
      `${env.API}/${this.app.appno}/abpush/cancel/${id}`,
      {}
    );
  }
  /**
   * ABテスト一覧の情報を取得する
   * @param {string} appno - appno
   */
  get_abpushs(
    offset = 0,
    limit = 10,
    appno: string = this.app.appno,
    apikey: string = this.app.apikey
  ) {
    return this.http.get<{
      count: number;
      abpushs: ABTestModel[];
    }>(`${env.API}/${appno}/abpushs`, {
      params: {
        offset: String(offset),
        limit: String(limit),
        order: 'desc'
      }
    });
  }

  get_load_balance_push(
    offset = 0,
    limit = 10,
    appno: string = this.app.appno
  ): Observable<{ pushs: LoadBalanceNotificationModel[]; count: number }> {
    return this.http.get<{
      pushs: LoadBalanceNotificationModel[];
      count: number;
    }>(`${env.API}/${appno}/load_balance_pushs`, {
      params: {
        offset: String(offset),
        limit: String(limit),
        order: 'desc'
      }
    });
  }

  cancel_load_balance_push(
    id: string,
    appno: string = this.app.appno
  ): Observable<{ success: 'deleted' }> {
    return this.http.post<{ success: 'deleted' }>(
      `${env.API}/${appno}/load_balance_pushs/${id}/cancel`,
      {}
    );
  }

  analytics_overview_get(
    scope = 'hour',
    appno = this.app.appno,
    apikey = this.app.apikey
  ) {
    return this.http.get<any>(
      `${env.API}/${appno}/analytics/pushs_overview?apikey=${apikey}&scope=${scope}`
    );
  }
  analytics_info_get(date: string) {
    return this.http
      .get<any>(`${env.API}/${this.app.appno}/analytics/info?date=${date}`)
      .pipe(
        map(res => ({
          appno: this.app.appno,
          info: res
        }))
      );
  }
  /**
   * 購読者数の遷移を取得
   * @param {string} start - 開始時間 - YYYY-MM-DD
   * @param {string} scope - 取得するスコープ hour/day/week/month
   * @param {number} n - 取得する件数
   */
  analytics_subscribers_transition(
    start: string,
    scope: 'hour' | 'day' | 'week' | 'month',
    n: number
  ) {
    return this.http
      .get<any>(
        `${env.API}/${this.app.appno}/analytics/subscribers_changes_amount?start=${start}&scope=${scope}&n=${n}`
      )
      .pipe(
        map(res => ({
          appno: this.app.appno,
          counts: res.counts.map(obj => ({
            datetime: obj.datetime,
            count: obj.total_subscribers
          }))
        }))
      );
  }
  analytics_subscribers_change(
    start: string,
    scope: 'hour' | 'day' | 'week' | 'month',
    n: number,
    appno = this.app.appno
  ): Observable<
    {
      active_subscribers: number;
      breakdown: {
        inactivated: number;
        new_subscriber: number;
        recovered: number;
        unsubscribed_by_user: number;
      };
      datetime: string;
      delta: number;
      total_subscribers: number;
    }[]
  > {
    return this.http
      .get<any>(
        `${env.API}/${this.app.appno}/analytics/subscribers_changes_amount?start=${start}&scope=${scope}&n=${n}`
      )
      .pipe(map(res => res.counts));
  }
  /**
   * 購読者数の増加を取得
   * @param {string} start - 開始時間 - YYYY-MM-DD
   * @param {string} scope - 取得するスコープ hour/day/week/month
   * @param {number} n - 取得する件数
   */
  analytics_subscribers_increase(
    start: string,
    scope: 'hour' | 'day' | 'week' | 'month',
    n: number
  ) {
    return this.http
      .get<any>(
        `${env.API}/${this.app.appno}/analytics/subscribers_changes_amount?start=${start}&scope=${scope}&n=${n}`
      )
      .pipe(
        map(res => ({
          appno: this.app.appno,
          counts: res.counts.map(obj => ({
            datetime: obj.datetime,
            count: obj.delta
          }))
        }))
      );
  }
  /**
   * Performance向けグラフ
   * @param {string} start - 開始時間 YYYY-MM-DD
   * @param {string} scope - 取得するスコープ hour/day/week/month
   * @param {number} n - 取得する件数
   */
  analytics_push_info_with_scope(start: string, scope: string, n: number) {
    return this.http.get<any>(
      `${env.API}/${this.app.appno}/analytics/push_info_with_scope?start=${start}&scope=${scope}&n=${n}`
    );
  }
  analytics_subscribers() {
    return this.http.get<any>(`${env.API}/${this.app.appno}/subscribers`);
  }
  analytics_parameters() {
    return this.http.get<any>(`${env.API}/${this.app.appno}/parameters`);
  }
  analytics_parameter_detail_get(
    target: string,
    appno: string = this.app.appno,
    apikey = this.app.apikey
  ) {
    // Mockup
    return new Observable(observer => {
      observer.next({
        subscribers: [
          {
            subscriberid: '6ff47745b904ee7d73efb51d5faa1f61b05a2622',
            useragent: 'iOS App v.1.0',
            OS: 'iOS 10',
            first_opt_in_time: 'YYYY-MM-DDThh:mm:ss',
            last_clicked_pushid: '8def2e14a88f5fe23416aa01b61e7bb2'
          },
          {
            subscriberid: '21er3fwegrbtryj645ervfdbtgfewrebth4erf23',
            useragent: 'iOS App v.0.1',
            OS: 'iOS  9',
            first_opt_in_time: 'YYYY-MM-DDThh:mm:ss',
            last_clicked_pushid: '32regbgwfefg3e4fw4235t23we3t2fes'
          }
        ]
      });
    });
  }
  plans() {
    return this.http.get<any>(`${env.HOST}/plan`);
  }

  private getStripeToken(card: CardModel): Observable<string> {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', `Bearer ${env.STRIPE_API_KEY}`);
    const params = new HttpParams({
      fromObject: {
        'card[number]': card.card_no,
        'card[exp_month]': card.expire.substring(2, 4),
        'card[exp_year]': card.expire.substring(0, 2),
        'card[name]': card.holder
      }
    });
    return this.http
      .post<{ id: string }>(
        'https://api.stripe.com/v1/tokens',
        params.toString(),
        {
          headers: headers
        }
      )
      .pipe(map(x => x.id));
  }

  /**
   * ロールを上げるAPI
   * @param {string} planId - プランのID
   * @param {string} cardno - カード番号
   * @param {string} expire - カード有効期限 yymm
   * @param {string} holder - カード保持者
   */
  role_upgrade_individual(
    plan,
    card_no?,
    expire?,
    holder?,
    appno = this.app.appno
  ) {
    const apiCall = (token?: string) => {
      return this.http.post(`${env.HOST}/${appno}/role/upgrade`, {
        plan,
        holder,
        token: token
      });
    };
    if (card_no && expire && holder) {
      return this.getStripeToken({ card_no: card_no, holder, expire }).pipe(
        switchMap(apiCall)
      );
    } else {
      return apiCall();
    }
  }

  /**
   * 法人プランのロールを上げるAPI
   */
  role_upgrade_enterprise(
    plan,
    planOptions: EnterprisePlanOptions | {},
    enterpriseDryRun: boolean,
    appno = this.app.appno
  ) {
    return this.http.post(`${env.HOST}/${appno}/role/upgrade`, {
      ...planOptions,
      enterprise_dry_run: enterpriseDryRun,
      plan
    });
  }

  /**
   * ロールを下げるAPI
   * @param {string} planId - プランのID
   */
  role_downgrade(plan, appno = this.app.appno) {
    return this.http.post<any>(`${env.HOST}/${appno}/role/downgrade`, {
      plan
    });
  }

  /**
   * Enterprise trial申し込みAPI
   * @param payload
   * @param {string} appno
   */

  role_trial(
    payload: {
      name: string;
      department_name: string;
      personnel_name: string;
      address: string;
      phone_number: string;
    },
    appno = this.app.appno
  ) {
    return this.http.post<{ success: string } | { error: string }>(
      `${env.HOST}/${appno}/role/trial`,
      payload
    );
  }

  /**
   * Enterprise申し込みAPI
   * @param payload
   * @param {string} appno
   */

  enterprise_application(
    payload: {
      name: string;
      department_name: string;
      personnel_name: string;
      address: string;
      phone_number: string;
      is_not_anti_social_org: boolean;
    },
    project_uuid = this.project.uuid
  ) {
    return this.http.post<{ success: string } | { error: string }>(
      `${env.API}/projects/${project_uuid}/enterprise_application`,
      {
        ...payload,
        project_uuid
      }
    );
  }

  /**
   * 現在指定されている支払いカード情報を取得
   * @param appno appno
   */
  card_info(appno = this.app.appno): Observable<CardModel> {
    return this.http.get<{ card_no: string; expire: string; holder: string }>(
      `${env.HOST}/${appno}/card/get`
    );
  }

  card_register(card: CardModel, appno = this.app.appno) {
    return this.getStripeToken(card).pipe(
      switchMap(token =>
        this.http.post(`${env.HOST}/${appno}/card/update`, {
          token,
          holder: card.holder
        })
      )
    );
  }

  /**
   * 現在登録されている支払い情報を取得
   * @param appno appno
   */
  get_payment_method(appno = this.app.appno) {
    return this.http.get<PaymentMethodModel>(
      `${env.HOST}/${appno}/payment_method/get`
    );
  }

  update_payment_method(method: PaymentMethodModel, appno = this.app.appno) {
    switch (method.payment_method) {
      case 'transfer':
        return this.http.post(
          `${env.HOST}/${appno}/payment_method/update`,
          method
        );
      case 'card':
        return this.getStripeToken(method.card).pipe(
          switchMap(token =>
            this.http.post(`${env.HOST}/${appno}/payment_method/update`, {
              payment_method: 'card',
              card: { token, holder: method.card.holder }
            })
          )
        );
    }
  }

  /**
   * BOXがCreateなのかUpdateなのかを判断するためのストア
   * appnoをキーにサーバーに保存されている値を保存
   */
  private boxs: {
    [appno: string]: BoxModel;
  } = {};
  /**
   * Push7 BOXの設定を取得します
   */
  box_get(appno: string = this.app.appno) {
    return this.http
      .get<BoxModel>(`${env.API}/${appno}/box/show`)
      .pipe(tap((b: BoxModel) => (this.boxs[appno] = b)));
  }
  /**
   * Push7 BOXを作成/保存します
   * @param {BoxModel} box - 保存するBOXのオブジェクト
   */
  box_save(box: BoxModel) {
    const payload: any = box;
    const appno = this.app.appno; // 現在のものを使う
    if (!this.boxs[appno] || Object.keys(this.boxs[appno]).length === 0) {
      // undefinedもしくは{} -> createで作成
      return this.http
        .post<any>(`${env.API}/${appno}/box/create`, payload)
        .pipe(tap(() => (this.boxs[this.app.appno] = payload)));
    } else {
      // 作成済み -> updateで更新
      return this.http.post<any>(`${env.API}/${appno}/box/update`, payload);
    }
  }
  /**
   * カテゴリ一覧の取得
   */
  get_categories() {
    return this.http
      .get(`${env.HOST}/categories`)
      .pipe(map(x => x['categories']));
  }

  /**
   * アプリケーションがセグメント配信を利用できるか返す
   * appを選択する前に取得する必要があるので手動でAPI Keyを渡す必要がある
   */
  can_use_segment(appno: string) {
    return this.http.get<{ is_usable_segmentation_push: boolean }>(
      `${env.API}/${appno}/is_usable_segmentation_push`
    );
  }

  /**
   * 所属セグメントプリセットクエリの一覧を取得する。
   */
  get_queries() {
    return this.http
      .get<{ queries: Query[] }>(
        `${env.API}/${this.app.appno}/queries?order=desc`
      )
      .pipe(map(x => x.queries as Query[]));
  }

  /**
   * Queryを取得する
   * @param {string} id - QueryId
   */
  get_query(id: string | number) {
    return this.http
      .get(`${env.API}/${this.app.appno}/queries/${id}`)
      .pipe(map(x => x['query']));
  }

  /**
   * 所属セグメントプリセットクエリを登録する。
   * @param query クエリ
   * @param name クエリ名称
   */
  create_query(query: string, name: string) {
    return this.http
      .post<{ query: Query }>(`${env.API}/${this.app.appno}/queries`, {
        name,
        query
      })
      .pipe(map(x => x.query));
  }
  /**
   * 所属セグメントプリセットクエリを削除する。
   * @param id QueryModel.id
   * @param query クエリ
   * @param name 名前
   */
  update_query(id: string, query: string, name: string) {
    return this.http
      .put<{ query: Query }>(`${env.API}/${this.app.appno}/queries/${id}`, {
        name,
        query
      })
      .pipe(map(x => x.query));
  }
  /**
   * クエリを削除
   * @param id QueryModel.id
   */
  delete_query(id: string) {
    return this.http.delete<null>(`${env.API}/${this.app.appno}/queries/${id}`);
  }
  generate_ast(query: string) {
    return this.http.post<any>(`${env.SEGMENT_PARSER_API}`, { query: query });
  }
  /**
   * パラメータ一覧の情報を取得する
   * @param {string} appno - appno
   */
  get_parameters(offset = 0, limit = 20, appno: string = this.app.appno) {
    return this.http.get<{
      count: number;
      parameters: { parameter: string; subscribers_count: number }[];
    }>(`${env.API}/${appno}/parameters`, {
      params: {
        offset: String(offset),
        limit: String(limit),
        order: 'desc'
      }
    });
  }
}
