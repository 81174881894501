import { Component } from '@angular/core';

@Component({
  template: `
    <div class="main-nav">
      オートメーション配信
    </div>
    <router-outlet></router-outlet>
  `
})
export class AutomationComponent {
  constructor() {}
}
