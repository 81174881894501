import { Injectable } from '@angular/core';
import { AppService } from '../app/app.service';
import { BehaviorSubject } from 'rxjs';
import { Query } from '../../models/segments.model';

@Injectable()
export class SegmentService {
  public list$: BehaviorSubject<Query[]> = new BehaviorSubject(null);
  constructor(private api: AppService) {
    this.update();
  }
  update() {
    this.api.get_queries().subscribe(qs => this.list$.next(qs));
  }
}
