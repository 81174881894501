import { Component } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import { AppModel } from '../../models/app.model';
import { AuthService } from '../../services/auth/auth.service';
import { ProfileResponse } from '../../services/backend';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  public app$: Observable<AppModel>;
  public profile$: Observable<ProfileResponse>;
  constructor(
    private store: Store<fromRoot.State>,
    private authService: AuthService
  ) {
    this.app$ = this.store.select(fromRoot.getAppSelected);
    this.profile$ = this.authService.profile$;
  }

  /**
   * 支払い情報変更ページを表示するかどうか
   */
  public get showPaymentMethodLink(): Observable<boolean> {
    return this.app$.pipe(
      map(
        app =>
          app.plan.current.no !== 1 &&
          // currentもしくはnextがカスタムプランの場合には支払い情報にアクセスさせない
          app.plan.current.is_custom === false &&
          app.plan.next.is_custom === false
      )
    );
  }
}
