<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{ 'CONTACT.LABEL' | translate }}
      </div>
      <div class="content">
        <app-contact
          [appno]="appno$ | async"
          (contactChange)="contact = $event"
          (statusChange)="valid = $event"
        ></app-contact>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button class="button is-danger p7-button" (click)="close()">
            {{ 'COMMON.CANCEL' | translate }}
          </button>
          <button
            class="button is-success p7-button"
            [class.is-loading]="sending"
            [disabled]="!valid"
            (click)="submit()"
          >
            {{ 'CONTACT.SUBMIT' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
