import { Injectable } from '@angular/core';

/**
 * ルーティングの仲介など一時的にデータを保存する場合に使用できます
 */
@Injectable()
export class SessionService {
  private store: any = {};
  constructor() {}
  set(key: string, value: any) {
    this.store[key] = value;
  }
  get(key) {
    return this.store[key];
  }
}
