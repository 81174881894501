import { Component } from '@angular/core';
import {
  LoadBalanceNotificationModel,
  LoadBalanceStatus
} from '../../../../models/notification.model';
import { ModalContext, Modal } from '../../../../services/modal/modal.service';
import { BehaviorSubject } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';
import { AppService } from '../../../../services/app/app.service';
import { Notification } from '../../../../services/notification/notification.service';

@Component({
  templateUrl: './load-balance-push-detail.html',
  styleUrls: ['./load-balance-push-detail.scss']
})
export class LoadBalancePushDetailComponent {
  public noti: LoadBalanceNotificationModel;
  public isConfirmingCancel: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public isSending = false;
  constructor(
    private modalCtx: ModalContext,
    private appService: AppService,
    private notificationService: Notification
  ) {
    this.noti = modalCtx.payload;
    this.isConfirmingCancel
      .pipe(filter(x => x))
      .subscribe(() =>
        setTimeout(() => this.isConfirmingCancel.next(false), 3000)
      );
  }
  public close() {
    this.modalCtx.reject();
  }
  public get queryType(): 'none' | 'segment' | 'parameter' | 'unknown' {
    if (!this.noti.query) {
      return 'none';
    }
    if (typeof this.noti.query === 'object') {
      return 'parameter';
    }
    if (typeof this.noti.query === 'string') {
      return 'segment';
    }
    return 'unknown';
  }
  public cancel() {
    this.appService
      .cancel_load_balance_push(this.noti.id)
      .pipe(finalize(() => (this.isSending = false)))
      .subscribe(
        () => {
          this.modalCtx.resolve();
        },
        error => {
          this.notificationService.open(error.error, 'danger');
        }
      );
  }
}
