<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        プロジェクト設定
      </div>
      <div class="content">
        <label class="label">プロジェクト名</label>
        <div class="field has-addons">
          <p class="control project-name-control">
            <input
              class="input"
              type="text"
              placeholder="プロジェクト名"
              [disabled]="!canEditProject"
              [(ngModel)]="name"
            />
          </p>
          <p class="control">
            <button
              class="button is-dark"
              [disabled]="!canEditProject"
              (click)="saveName()"
            >
              保存
            </button>
          </p>
        </div>
        <hr class="mt-0" />
        <ng-container *ngIf="canEditProject">
          <label class="label">ユーザーの招待</label>
          <p>
            プロジェクトにユーザーを招待して、複数人で同じアプリケーションを操作できます。詳しくはPush7
            Docsをご覧ください。
          </p>
          <div class="field has-addons" [formGroup]="inviteeForm">
            <div class="control">
              <div class="select">
                <select name="role" formControlName="role">
                  <option value="administrator">管理者</option>
                  <option value="operator">運用担当者</option>
                  <option value="viewer">閲覧者</option>
                  <option *ngIf="isGNEX$ | async" value="spy_administrator"
                    >Spy管理者</option
                  >
                  <option *ngIf="isGNEX$ | async" value="spy_viewer"
                    >Spy閲覧者</option
                  >
                </select>
              </div>
            </div>
            <div class="control invitee-email-control">
              <input
                class="input"
                type="text"
                placeholder="example@push7.jp"
                formControlName="email"
              />
            </div>
            <div class="control">
              <button class="button is-dark" (click)="invite()">
                ユーザーを招待
              </button>
            </div>
          </div>
        </ng-container>
        <label class="label">ユーザーの管理</label>
        <table class="table member-list" *ngIf="members; else loading">
          <thead>
            <td>権限</td>
            <td>ステータス</td>
            <td>メールアドレス</td>
            <td></td>
          </thead>
          <tbody>
            <tr *ngFor="let member of members">
              <td>
                <div class="control">
                  <div class="select">
                    <select
                      name="role"
                      [ngModel]="member.role"
                      (ngModelChange)="updateRole(member, $event)"
                      [disabled]="
                        !canEditMember(member) || member.status === 'declined'
                      "
                    >
                      <option value="administrator">管理者</option>
                      <option value="operator">運用担当者</option>
                      <option value="viewer">閲覧者</option>
                      <option *ngIf="isGNEX$ | async" value="spy_administrator"
                        >Spy管理者</option
                      >
                      <option *ngIf="isGNEX$ | async" value="spy_viewer"
                        >Spy閲覧者</option
                      >
                    </select>
                  </div>
                </div>
              </td>
              <td>
                <ng-container [ngSwitch]="member.status">
                  <p
                    *ngSwitchCase="'inviting'"
                    class="tag is-light is-medium is-info"
                  >
                    招待中
                  </p>
                  <p
                    *ngSwitchCase="'declined'"
                    class="tag is-light is-medium is-danger"
                  >
                    拒否
                  </p>
                  <p
                    *ngSwitchCase="'active'"
                    class="tag is-light is-medium is-success"
                  >
                    参加中
                  </p>
                </ng-container>
              </td>
              <td>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    [value]="member.email"
                    disabled
                  />
                </div>
              </td>
              <td>
                <button
                  class="button is-danger is-outlined"
                  (click)="removeMember(member)"
                  [disabled]="!canEditMember(member)"
                >
                  <span class="icon">
                    <i class="fa fa-trash"></i>
                  </span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <ng-template #loading>
          <app-loading></app-loading>
        </ng-template>
        <ng-container *ngIf="canLeaveProject">
          <hr />
          <label class="label">プロジェクトから離脱</label>
          <p>
            プロジェクトから離脱すると、プロジェクト内のアプリケーションへのアクセスが失われます。
          </p>
          <div class="has-text-centered">
            <button class="button is-danger" (click)="leaveProject()">
              プロジェクトから離脱
            </button>
          </div>
        </ng-container>
        <hr />
        <label class="label">プロジェクトの削除</label>
        <div
          class="has-text-centered"
          *ngIf="canDeleteProject; else HowToDeleteProject"
        >
          <button class="button is-danger" (click)="deleteProject()">
            プロジェクトの削除
          </button>
        </div>
        <ng-template #HowToDeleteProject>
          <p>
            プロジェクトの削除を削除するには、プロジェクトからアプリケーションを全て削除した上で、管理者権限でこのパネルを開いてください。
          </p>
        </ng-template>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button class="button is-dark" (click)="close()">
            閉じる
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
