import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { from, Observable, of } from 'rxjs';
import { finalize, switchMap, take } from 'rxjs/operators';
import { ConfirmationService } from '../../../services/confirmation/confirmation.service';
import { AutomationRuleFormComponent } from '../../../components/automation-rule-form/automation-rule-form.component';
import { getAppSelected, getAppSelectedId, State } from '../../../reducers';
import {
  AutomationRule,
  DefaultService as ApiService
} from '../../../services/api';
import { ModalContext } from '../../../services/modal/modal.service';
import { Notification as NotificationService } from '../../../services/notification/notification.service';
import { deepAssign } from '../../../helpers/deepAssign';
import { assign, cloneDeepWith, defaults, isObject, merge } from 'lodash';

@Component({
  template: `
    <form [formGroup]="form">
      <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content">
          <div class="box">
            <div class="content-header is-yellow">
              オートメーションルールの{{ !!ruleId ? '編集' : '作成' }}
            </div>
            <div class="content">
              <app-automation-rule-form
                formControlName="rule"
              ></app-automation-rule-form>
            </div>

            <div class="content-footer">
              <div class="is-flex is-justify-content-space-between">
                <div>
                  <button
                    *ngIf="!!ruleId"
                    class="button is-danger is-outlined p7-button"
                    [class.is-loading]="loading"
                    (click)="remove()"
                  >
                    削除
                  </button>
                </div>
                <div>
                  <button
                    class="button is-danger is-outlined p7-button mr-1"
                    [disabled]="loading"
                    (click)="close()"
                  >
                    キャンセル
                  </button>
                  <button
                    class="button is-success p7-button"
                    [disabled]="!form.valid"
                    [class.is-loading]="loading"
                    (click)="submit()"
                  >
                    {{ !!ruleId ? '更新' : '作成' }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button class="modal-close" (click)="close()"></button>
      </div>
    </form>
  `
})
export class AutomationRuleEditorModalComponent implements OnInit {
  // nullの場合は新規作成, stringの場合は更新するID
  public ruleId: string | null = null;
  public form: FormGroup;
  public loading = false;
  private appno$: Observable<string>;
  constructor(
    private ctx: ModalContext,
    private fb: FormBuilder,
    private api: ApiService,
    private store: Store<State>,
    private confirmation: ConfirmationService,
    private notificationService: NotificationService
  ) {}
  ngOnInit() {
    if (this.ctx.payload) {
      this.ruleId = this.ctx.payload.id;
    } else {
      this.ruleId = null;
    }
    this.store
      .select(getAppSelected)
      .pipe(take(1))
      .subscribe(app => {
        this.form = this.fb.group({
          rule: AutomationRuleFormComponent.defaultFormValue(app)
        });
        if (this.ctx.payload) {
          const rule: AutomationRule = this.ctx.payload;
          // angularのフォームを使う都合上、全てのキーが存在しnullを持つ必要がある
          const formattedRule = assign(
            // primitiveな値を全てnullにする
            cloneDeepWith(
              AutomationRuleFormComponent.defaultFormValue(app),
              value => (isObject(value) ? undefined : null)
            ),
            // 定義されているものは上書き
            {
              name: rule.name,
              status: rule.status,
              skip_days: rule.skip_days,
              trigger: rule.trigger,
              actions: rule.actions,
              segmentation: rule.segmentation
            }
          );
          // フロントエンド都合でactions.push_notificationはnotification, delivery_strategyが必要
          if (!formattedRule.actions.push_notification) {
            formattedRule.actions.push_notification = {};
            formattedRule.actions.push_notification.notification = null;
            formattedRule.actions.push_notification.delivery_strategy = null;
          }
          this.form.setValue({ rule: formattedRule });
        }
      });
    this.appno$ = this.store.select(getAppSelectedId);
  }
  public close() {
    this.ctx.reject();
  }
  public remove() {
    if (!this.ruleId) {
      return;
    }
    from(
      this.confirmation.open({
        title: 'オートメーションルールの削除',
        body:
          'この操作を取り消すことは出来ません。本当にオートメーションルールを削除してよろしいですか？',
        cancelLabel: 'キャンセル',
        cancelClass: 'is-normal',
        confirmLabel: '削除',
        confirmClass: 'is-danger'
      })
    )
      .pipe(
        switchMap(() => this.appno$),
        take(1),
        switchMap(appno => this.api.deleteAutomationRule(appno, this.ruleId)),
        finalize(() => (this.loading = false))
      )
      .subscribe(() => {
        this.ctx.resolve();
      });
  }
  public submit() {
    this.loading = true;

    /*
    通知を送らない場合、フロントエンドの都合で
    {
      actions: {
        push_notification: {
          notification: null,
          delivery_strategy: null
        },
        [otherAction]: {...}
      }
    }
    というデータ構造を持つが、APIは受け付けられないので
    {
      actions: {
        [otherAction]: {...}
      }
    }
    にする
    */
    const rule = this.form.value.rule;
    if (!rule.actions.push_notification.notification) {
      delete rule.actions.push_notification;
    }

    if (!this.ruleId) {
      // 新規作成
      this.appno$
        .pipe(
          take(1),
          switchMap(appno =>
            this.api.createAutomationRule(appno, this.form.value.rule)
          ),
          finalize(() => (this.loading = false))
        )
        .subscribe(
          () => {
            this.ctx.resolve();
          },
          error => {
            this.notificationService.open(error.error.error, 'danger');
          }
        );
    } else {
      this.appno$
        .pipe(
          take(1),
          switchMap(appno =>
            this.api.updateAutomationRule(
              appno,
              this.ruleId,
              this.form.value.rule
            )
          ),
          finalize(() => (this.loading = false))
        )
        .subscribe(
          () => {
            this.ctx.resolve();
          },
          error => {
            this.notificationService.open(error.error.error, 'danger');
          }
        );
    }
  }
}
