import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UserService } from '../../services/user/user.service';
import * as fromRoot from '../../reducers';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, merge, of } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';
import { AppModel } from '../../models/app.model';
import { ContactModel } from '../../models/contact.model';
import { AppService } from '../../services/app/app.service';
import { AuthService } from '../../services/auth/auth.service';

interface ContactCategory {
  id: number;
  name: string;
  require_enterprise: boolean;
  require_subject: boolean;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  /**
   * デフォルトで指定するappno
   */
  @Input()
  public set appno(appno: string) {
    if (!this.form || !this.form.get('appno')) {
      this._appno = appno;
      return;
    }
    this.form.get('appno').setValue(appno);
  }
  private _appno: string;
  /**
   * 送信
   */
  @Input()
  public submit?: Observable<void>;
  /**
   * 問い合わせの状態(valid/invalid)を親コンポーネントに伝える
   */
  @Output()
  public statusChange = new EventEmitter<boolean>(false);
  /**
   * 問い合わせ本体
   */
  @Output()
  public contactChange = new EventEmitter<ContactModel>();
  /**
   * フォームの状態
   */
  public form: FormGroup;
  /**
   * カテゴリ一覧
   */
  public categories: ContactCategory[] = [
    {
      id: 10,
      name: '契約に関する事項（メールアドレス変更）',
      require_enterprise: false,
      require_subject: false
    },
    {
      id: 11,
      name: '契約に関する事項（有償プラン、解約）',
      require_enterprise: false,
      require_subject: false
    },
    {
      id: 12,
      name: '契約に関する事項（パラメータ・セグメント配信）',
      require_enterprise: false,
      require_subject: false
    },
    {
      id: 19,
      name: '契約に関する事項（その他）',
      require_enterprise: false,
      require_subject: true
    },
    {
      id: 20,
      name: 'Push7 ダッシュボード利用方法（送信数表示）',
      require_enterprise: false,
      require_subject: false
    },
    {
      id: 29,
      name: 'Push7 ダッシュボード利用方法（その他）',
      require_enterprise: false,
      require_subject: true
    },
    {
      id: 30,
      name: 'テクニカルサポート（パラメータ・セグメント配信）',
      require_enterprise: true,
      require_subject: false
    },
    {
      id: 31,
      name:
        'テクニカルサポート（購読ページ・Push7 SDK・WordPress・Push7 API・iOS）',
      require_enterprise: true,
      require_subject: false
    },
    {
      id: 39,
      name: 'テクニカルサポート（その他）',
      require_enterprise: true,
      require_subject: true
    },
    {
      id: 40,
      name: 'システム障害・バグ',
      require_enterprise: false,
      require_subject: false
    },
    {
      id: 90,
      name: 'その他',
      require_enterprise: false,
      require_subject: true
    }
  ];
  /**
   * アプリケーション一覧
   */
  public apps$: Observable<AppModel[]> = this.authService.apps$;

  /**
   * プルダウンで選択されたアプリケーション
   */
  public selectedApp: AppModel | null = null;

  constructor(
    private user: UserService,
    private api: AppService,
    private store: Store<fromRoot.State>,
    private translate: TranslateService,
    private fb: FormBuilder,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      appno: [this._appno],
      isAppNonSpecific: [false],
      categoryId: [null, Validators.required],
      subject: [null],
      body: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(5000)])
      ]
    });
    this.form
      .get('isAppNonSpecific')
      .valueChanges.subscribe((isAppNonSpecific: boolean) => {
        if (isAppNonSpecific) {
          // 特定のアプリケーションに限定しない問い合わせの場合, appnoの選択が必要ない
          this.form.get('appno').setValue(null);
          this.form.get('appno').disable();
        } else {
          this.form.get('appno').enable();
        }
      });
    // appnoが選択されるたび, showから選択されたアプリの情報を取得する
    merge(this.form.get('appno').valueChanges, of(this.form.get('appno').value))
      .pipe(
        filter(appno => !!appno),
        switchMap((appno: string) => this.api.show(appno))
      )
      .subscribe(app => (this.selectedApp = app));
    merge(
      this.form.get('appno').valueChanges,
      this.form.get('isAppNonSpecific').valueChanges
    ).subscribe(() => {
      // selectedAppをリセット
      this.selectedApp = null;
      // appno, isAppNonSpecificが変更された際にはカテゴリをリセット
      this.form.get('categoryId').setValue(null);
      // 件名が必要無くなる可能性があるため件名もリセット
      this.form.get('subject').setValue(null);
    });
    this.form.get('categoryId').valueChanges.subscribe(() => {
      if (this.requireSubject) {
        this.form.get('subject').setValidators(Validators.required);
      } else {
        this.form.get('subject').clearValidators();
      }
    });
    this.form.statusChanges.subscribe(() =>
      this.statusChange.emit(this.form.status === 'VALID')
    );
    this.form.valueChanges.subscribe(() => {
      this.contactChange.emit({
        appno: this.form.get('isAppNonSpecific').value
          ? null
          : this.form.get('appno').value,
        categoryId: parseInt(this.form.get('categoryId').value, 10),
        subject: this.form.get('subject').value,
        body: this.form.get('body').value
      });
    });
  }

  /**
   * アプリケーションの情報がロードされ、カテゴリ選択が可能な状態になっているか返す
   */
  public get isAppLoaded(): boolean {
    if (this.form.get('isAppNonSpecific').value) {
      // app-nonspecificな場合はロードが必要ないのでtrue
      return true;
    }
    return !!this.selectedApp;
  }

  /**
   * 選択されたアプリがEnterpriseか返す
   */
  public get isEnterpriseApp(): boolean {
    if (!this.selectedApp) {
      return false;
    }
    return this.selectedApp.plan.current.no >= 5;
  }

  /**
   * 選択されているカテゴリを返す
   */
  public get selectedCategory(): ContactCategory | null {
    const categoryControl = this.form.get('categoryId');
    if (!categoryControl.value) {
      return null;
    }
    return this.categories.find(
      x => x.id === parseInt(categoryControl.value, 10)
    );
  }

  /**
   * 選択されたアプリケーションで選択されているカテゴリが利用可能か返す
   */
  public get isSelectedCategoryAvailable(): boolean {
    if (!this.selectedCategory) {
      return false;
    }
    if (this.selectedCategory.require_enterprise && !this.isEnterpriseApp) {
      // enterpriseが要求されているがアプリがenterpriseではない
      return false;
    }
    return true;
  }

  /**
   * 指定されたCategoryIdに対してsubjectが必要かどうかを返す
   */
  public get requireSubject(): boolean {
    const categoryId = this.form.get('categoryId').value;
    const category = this.categories.find(
      x => x.id === parseInt(categoryId, 10)
    );
    return categoryId && category && category.require_subject;
  }

  /**
   * submitが可能な状態か返す
   */
  public get isValid(): boolean {
    if (this.form.invalid) {
      return false;
    }
    if (!this.isSelectedCategoryAvailable) {
      return false;
    }
    if (this.requireSubject && !this.form.get('subject')) {
      return false;
    }
    return true;
  }
}
