import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Query } from '../../../models/segments.model';
import { ModalContext } from '../../../services/modal/modal.service';
import { SegmentService } from '../../../services/segment/segment.service';

@Component({
  templateUrl: './segmentation-query-selector.component.html'
})
export class SegmentationQuerySelectorComponent {
  public queries$: Observable<Query[]>;
  constructor(private segment: SegmentService, private modalCtx: ModalContext) {
    this.queries$ = this.segment.list$;
  }
  close() {
    this.modalCtx.reject();
  }
  select(name: string, query: string) {
    this.modalCtx.resolve({ name, query });
  }
}
