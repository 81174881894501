import { cloneDeep } from 'lodash';

import { AppModel } from '../models/app.model';
import { NotificationModel } from '../models/notification.model';

import * as app from '../actions/app';
import { Query } from '../models/segments.model';

export interface State {
  selected: string | null;
  loaded: { [appno: string]: AppModel };
  notifications: {
    [appno: string]: {
      current: number;
      count: number;
      loading: boolean;
      data: {
        [pushid: string]: NotificationModel;
      };
      order: string[];
    };
  };
  reservations: {
    [appno: string]: {
      loading: boolean;
      data: NotificationModel[];
    };
  };
}

const initialState: State = {
  selected: null,
  loaded: {},
  notifications: {},
  reservations: {}
};

export function reducer(state: State = initialState, action: app.Actions) {
  switch (action.type) {
    case app.ActionTypes.LOAD_COMPLETE: {
      const loaded = Object.assign({}, state.loaded);
      loaded[action.payload.appno] = action.payload.data;
      return Object.assign({}, state, { loaded });
    }
    case app.ActionTypes.SELECT: {
      return Object.assign({}, state, { selected: action.payload });
    }
    case app.ActionTypes.DESELECT: {
      return Object.assign({}, state, { selected: null });
    }
    case app.ActionTypes.LOAD_NOTIFICATIONS: {
      const n = cloneDeep(state);
      if (!n.notifications[n.selected]) {
        // 初期値の代入
        n.notifications[n.selected] = {
          current: 1,
          count: 0,
          data: {},
          loading: false,
          order: []
        };
      }
      n.notifications[n.selected].loading = true;
      return n;
    }
    case app.ActionTypes.PUSH_NOTIFICATIONS: {
      const n = cloneDeep(state);
      Object.assign(n.notifications[n.selected].data, action.payload.data);
      n.notifications[n.selected].order = [].concat(
        n.notifications[n.selected].order,
        action.payload.order
      );
      if (action.payload.count) {
        n.notifications[n.selected].count = action.payload.count;
      }
      return n;
    }
    case app.ActionTypes.UNSHIFT_NOTIFICATIONS: {
      const n = cloneDeep(state);
      Object.assign(n.notifications[n.selected].data, action.payload.data);
      n.notifications[n.selected].order = [].concat(
        action.payload.order,
        n.notifications[n.selected].order
      );
      if (action.payload.count) {
        n.notifications[n.selected].count = action.payload.count;
      }
      return n;
    }
    case app.ActionTypes.DISAPPEAR_NOTIFICATION: {
      return Object.assign({}, state);
    }
    case app.ActionTypes.DISAPPEAR_NOTIFICATION_COMPLETE: {
      const n: State = cloneDeep(state);
      const i = n.notifications[n.selected].order.indexOf(action.payload); // disappearされたpushid
      n.notifications[n.selected].order.splice(i, 1);
      return n;
    }
    case app.ActionTypes.LOAD_NOTIFICATIONS_COMPLETE: {
      const n = cloneDeep(state);
      n.notifications[n.selected].current = action.payload.page;
      n.notifications[n.selected].loading = false;
      return n;
    }
    case app.ActionTypes.LOAD_RESERVATIONS: {
      const n = cloneDeep(state);
      if (!n.reservations[n.selected]) {
        n.reservations[n.selected] = {
          data: [],
          loading: false
        };
      }
      n.reservations[n.selected].loading = true;
      return n;
    }
    case app.ActionTypes.LOAD_RESERVATIONS_COMPLETE: {
      const n = cloneDeep(state);
      n.reservations[n.selected].data = action.payload.data;
      n.reservations[n.selected].loading = false;
      return n;
    }
    default: {
      return state;
    }
  }
}

export const getAppsLoaded = (state: State) => state.loaded;
export const getAppsNotifications = (state: State) => state.notifications;
export const getAppSelectedId = (state: State) => state.selected;
