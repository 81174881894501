<div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{ 'ACCOUNT_DELETE.LABEL' | translate }}
      </div>
      <div class="content">
        <p>{{ 'ACCOUNT_DELETE.ENTER_PASSWORD' | translate }}</p>
        <div class="form">
          <div class="field">
            <div class="control">
              <input
                class="input"
                type="password"
                placeholder="{{ 'ACCOUNT_DELETE.PASSWORD' | translate }}"
                [(ngModel)]="password"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button class="button p7-button" (click)="close()">
            {{ 'ACCOUNT_DELETE.CANCEL' | translate }}
          </button>
          <button
            class="button is-danger p7-button"
            (click)="delete()"
            [disabled]="password == ''"
            [class.is-loading]="inProgress"
          >
            {{ 'ACCOUNT_DELETE.DELETE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
