import * as Moment from 'moment';

export const normalizeBeginning = function(
  date: Moment.Moment,
  scope: string
): Moment.Moment {
  // dateを前方に含むnormalize
  date
    .millisecond(0)
    .second(0)
    .minute(0);
  if (scope === 'hour') {
    return date;
  }
  date.hour(0);
  if (scope === 'day') {
    return date;
  }
  date.isoWeekday(0);
  if (scope === 'week') {
    return date;
  }
  // month
  date.date(1);
  return date;
};
export const normalizeEnding = function(
  date: Moment.Moment,
  scope: string
): Moment.Moment {
  date
    .millisecond(0)
    .second(0)
    .minute(0);
  if (scope === 'hour') {
    return date;
  }
  date.hour(0).add(1, 'day');
  if (scope === 'day') {
    return date;
  }
  date.isoWeekday(7);
  if (scope === 'week') {
    return date;
  }
  // month
  date.date(1).add(1, 'month'); // 次の1日
  return date;
};

export const generateUnit = function(scope: string) {
  // hour
  switch (scope) {
    case 'hour': {
      return {
        hour: 1
      };
    }
    case 'day': {
      return {
        day: 1
      };
    }
    case 'week': {
      return {
        day: 7
      };
    }
    case 'month': {
      return {
        month: 1
      };
    }
  }
};
export const generateList = (
  begin: Moment.Moment,
  end: Moment.Moment,
  scope
): string[] => {
  const unit = generateUnit(scope);
  let list: string[] = [];
  for (let i = Moment(begin); i.isBefore(end); i.add(unit)) {
    list.push(i.format('YYYY-MM-DDTHH'));
  }
  return list;
};
