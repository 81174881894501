<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-header has-text-weight-bold is-size-4">
        {{ 'AUTO.WELCOME.TITLE' | translate }}
        <app-help-link
          link="https://push7.jp/docs/guide/welcome-push/"
        ></app-help-link>
      </div>
      <div class="container-body p7-form">
        <div class="content">{{ 'AUTO.WELCOME.MESSAGE' | translate }}</div>
        <p class="control">
          <label class="checkbox">
            <input
              type="checkbox"
              [(ngModel)]="show"
              [appEnableFor]="'administrator'"
            />
            {{ 'AUTO.WELCOME.TOGGLE' | translate }}
          </label>
        </p>
        <div class="" *ngIf="show">
          <app-notification-editor
            [enableImage]="false"
            [permanent]="false"
            [reservation]="false"
            [(notification)]="notification"
            [role]="'administrator'"
          ></app-notification-editor>
        </div>
      </div>
      <div class="container-footer has-text-right">
        <button
          (click)="save()"
          class="button is-primary p7-button"
          [class.is-loading]="processing"
          [appEnableFor]="'administrator'"
        >
          {{ 'AUTO.WELCOME.SAVE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
