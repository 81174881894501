import * as Raven from 'raven-js';
import { environment } from './../environments/environment';
import { RavenErrorHandler } from './helpers/ravenErrorHandler';

import { ApiModule, Configuration as ApiConfiguration } from './services/api';
import {
  ApiModule as BackendApiModule,
  Configuration as BackendConfiguration
} from './services/backend';

import { AuthService } from './services/auth/auth.service';

import { HidePushConfirmComponent } from './pages/single/push/normal-push-history/normal-push-detail/hide-push-confirm/hide-push-confirm';
import { NormalPushDetailComponent } from './pages/single/push/normal-push-history/normal-push-detail/normal-push-detail';
import { PlanListComponent } from './pages/single/plan/list/list.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, forwardRef, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers } from './reducers';

import { RouterEffects } from './effects/router';

/* Tracking */
import { setGA } from './helpers/trackingCode';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';

/* Session */
import { SessionService } from './services/session/session.service';
import { StateService } from './services/state/state.service';

/* Modal */
import {
  ModalEntryComponent,
  ModalInnerDirective
} from './services/modal/modal.component';
import { Modal } from './services/modal/modal.service';

/* Notification */
import {
  NotificationEntryComponent,
  NotificationComponent,
  NotificationInnerDirective
} from './services/notification/notification.component';
import { Notification } from './services/notification/notification.service';

/* Confirmation */
import {
  ConfirmationEntryComponent,
  ConfirmationComponent,
  ConfirmationInnerDirective
} from './services/confirmation/confirmation.component';
import { ConfirmationService } from './services/confirmation/confirmation.service';

/* Category */
import { CategoryService } from './services/category/category.service';

/* User */
import { UserService } from './services/user/user.service';
import { LoginGuard } from './guards/login.guard';

/* App */
import { AppService } from './services/app/app.service';
import { AppEffects } from './effects/app';
import { AppExistsGuard } from './guards/app.guard';
import { AppResolve } from './resolves/app.resolve';

/* Segment */
import { SegmentService } from './services/segment/segment.service';
import {
  components as SegmentEditorComponents,
  entryComponents as SegmentEditorEntryComponents
} from './components/segment-editor/index';

/* Interceptor */
import { ApiInterceptor } from './interceptors/api.interceptor';

/* Analytics */
import { AnalyticsEffects } from './effects/analytics';

/* Plans */
import { PlansResolve } from './resolves/plans.resolve';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CustomLoader } from './helpers/translationLoader';

import { routes } from './app.routes';

import { AppComponent } from './app.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ProvisionalComponent } from './pages/prelogin/provisional/provisional.component';
import { SignupComponent } from './pages/prelogin/signup/signup.component';
import { ForgetComponent } from './pages/prelogin/forget/forget.component';
import { ResetComponent } from './pages/prelogin/reset/reset.component';
import { LoginComponent } from './pages/prelogin/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SingleComponent } from './pages/single/single.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MainComponent } from './pages/single/main/main.component';
import { SettingsComponent } from './pages/single/settings/settings.component';
import { AutoComponent } from './pages/single/auto/auto.component';
import { PushComponent } from './pages/single/push/push.component';
import { AbtestComponent } from './pages/single/abtest/abtest.component';
import { LoadBalanceComponent } from './pages/single/load-balance/load-balance.component';
import { NewLoadBalancePushModalComponent } from './pages/single/load-balance/new-load-balance-push-modal/new-load-balance-push-modal';

/* Analytics */
import { AnalyticsComponent } from './pages/single/analytics/analytics.component';
import { AnalyticsOverviewComponent } from './pages/single/analytics/overview/overview.component';
import { OverviewChartComponent } from './pages/single/analytics/overview/chart/overview.chart.component';

import { AnalyticsPerformanceComponent } from './pages/single/analytics/performance/performance.component';
import { AnalyticsPerformanceIndexComponent } from './pages/single/analytics/performance/index/index.component';
import { AnalyticsPerformanceDetailComponent } from './pages/single/analytics/performance/detail/detail.component';

import { AnalyticsListComponent } from './pages/single/analytics/list/list.component';
import { AnalyticsListUserComponent } from './pages/single/analytics/list/user/user.component';
import { AnalyticsListParameterComponent } from './pages/single/analytics/list/parameter/parameter.component';
import { AnalyticsListParameterIndexComponent } from './pages/single/analytics/list/parameter/index/index.component';
import { AnalyticsListParameterDetailComponent } from './pages/single/analytics/list/parameter/detail/detail.component';

import { AutomationComponent } from './pages/single/automation/automation.component';

/* Segment Delivery */
import { QueryComponent } from './pages/single/query/query.component';
import { QueryListComponent } from './pages/single/query/query-list/query-list.component';
import { NewQueryModalComponent } from './pages/single/query/query-list/new-query-modal/new-query-modal.component';
import { QueryDetailComponent } from './pages/single/query/query-detail/query-detail.component';

import { PlanComponent } from './pages/single/plan/plan.component';
import { TagsComponent } from './pages/single/tags/tags.component';
import { DomainComponent } from './pages/single/domain/domain.component';
import { DomainConfirmComponent } from './pages/single/domain/confirm/domain-confirm.component';
import { SftpComponent } from './pages/single/sftp/sftp.component';
import { ApiComponent } from './pages/single/api/api.component';
import { RssComponent } from './pages/single/auto/rss/rss.component';
import { WpComponent } from './pages/single/auto/wp/wp.component';
import { WelcomeComponent } from './pages/single/auto/welcome/welcome.component';
import { ButtonComponent } from './pages/single/tags/button/button.component';
import { BoxComponent } from './pages/single/tags/box/box.component';
import { CopyInputComponent } from './components/copy-input/copy-input.component';
import { CopyTextareaComponent } from './components/copy-textarea/copy-textarea.component';
import { HelpLinkComponent } from './components/help-link/help-link.component';
import { NewAppModalComponent } from './pages/home/new-app-modal/new-app-modal.component';
import { NewProjectModalComponent } from './pages/home/new-project-modal/new-project-modal.component';
import { EditProjectModalComponent } from './pages/home/edit-project-modal/edit-project-modal.component';
import { ImgCropComponent } from './components/img-crop/img-crop.component';
import { LoadingComponent } from './components/loader/loader.component';
import { AppDeleteModalComponent } from './pages/single/settings/app-delete-modal/app-delete-modal.component';
import { NormalPushHistoryComponent } from './pages/single/push/normal-push-history/normal-push-history.component';

import { ParametersComponent } from './pages/parameters/parameters.component';

import { ReservedPushHistoryComponent } from './pages/single/push/reserved-push-history/reserved-push-history.component';
import { NotificationEditorComponent } from './components/notification-editor/notification-editor.component';
import { QueryEditorComponent } from './components/notification-editor/query-editor/query-editor.component';
import { SftpPresetSelectorComponent } from './components/notification-editor/sftp-preset-selector/sftp-preset-selector.component';
import { SegmentationQuerySelectorComponent } from './components/notification-editor/segmentation-query-selector/segmentation-query-selector.component';
import { QueryEditorV2Component } from './components/notification-editor-v2/query-editor-v2/query-editor-v2.component';
import { SftpPresetSelectorV2Component } from './components/notification-editor-v2/sftp-preset-selector-v2/sftp-preset-selector-v2.component';
import { SegmentationQuerySelectorV2Component } from './components/notification-editor-v2/segmentation-query-selector-v2/segmentation-query-selector-v2.component';
import { NewPushModalComponent } from './pages/single/push/new-push-modal/new-push-modal.component';
import { EditPushModalComponent } from './pages/single/push/edit-push-modal/edit-push-modal.component';
import { DeleteReservationConfirmComponent } from './pages/single/push/reserved-push-history/delete-reservation-confirm/delete-reservation-confirm.component';
import { NativeComponent } from './pages/single/tags/native/native.component';
import { SdkModalComponent } from './pages/single/tags/sdk-modal/sdk-modal.component';
import { AbtestDetailComponent } from './pages/single/abtest/abtest-detail/abtest-detail.component';
import { NewAbtestModalComponent } from './pages/single/abtest/new-abtest-modal/new-abtest-modal.component';
import { DateParsePipe } from './pipes/date-parse/date-parse.pipe';
import { ImgCropModalComponent } from './components/img-crop-modal/img-crop-modal.component';
import { PlanCardComponent } from './pages/single/plan/card/card.component';
import { UpdateCardComponent } from './pages/single/update-card/update-card.component';
import { CardEditorComponent } from './components/card-editor/card-editor.component';
import { PlanConfirmComponent } from './pages/single/plan/confirm/confirm.component';
import { ContactPageComponent } from './pages/contact/contact-page.component';
import { ContactModalComponent } from './pages/contact/contact-modal/contact-modal.component';
import { AccountDeleteComponent } from './pages/account-delete/account-delete.component';
import { AccountDeleteModalComponent } from './pages/account-delete/modal/account-delete-modal.component';
import { PasswordComponent } from './pages/password/password.component';
import { SdkMessageComponent } from './pages/single/tags/sdk-message.component';
import { RangePickerComponent } from './components/range-picker/range-picker.component';
import { SetCorporateComponent } from './components/set-corporate-modal/set-corporate-modal.component';
import { SetCategoryComponent } from './components/set-category-modal/set-category-modal.component';
import { QueryDeleteWarningComponent } from './pages/single/query/delete-warning/query-delete-warning.component';
import { QuerySaveBeforeLeaveComponent } from './pages/single/query/query-detail/query-save-before-leave/query-save-before-leave.component';
import { EnterpriseTrialComponent } from './components/enterprise-trial-modal/enterprise-trial-modal.component';
import { EnterpriseApplicationComponent } from './components/enterprise-application-modal/enterprise-application-modal.component';
import { AbtestDetailCancelConfirmComponent } from './pages/single/abtest/abtest-detail/abtest-detail-cancel-confirm/abtest-detail-cancel-confirm.component';
import { ContactComponent } from './components/contact/contact.component';
import { LoadBalancePushDetailComponent } from './pages/single/load-balance/load-balance-push-detail/load-balance-push-detail';
import { EnterpriseApplicationCardComponent } from './components/enterprise-application-card/enterprise-application-card.component';
import { PushModalV2Component } from './pages/single/push/push-modal-v2/push-modal-v2.component';
import { NotificationEditorV2Component } from './components/notification-editor-v2/notification-editor-v2.component';
import { NotificationProgressStepsComponent } from './components/notification-progress-steps/notification-progress-steps.component';
import { ImgCropV2Component } from './components/img-crop-v2/img-crop-v2.component';
import { AuthHelperService } from './services/auth-helper/auth-helper.service';
import { EnableForDirective } from './directives/enable-for/enable-for.directive';
import { DisplayForDirective } from './directives/display-for/display-for.directive';
import { NotificationPreviewComponent } from './components/notification-preview/notification-preview.component';
import { PreviewDeviceIphoneComponent } from './components/notification-preview/device-iphone/device-iphone.component';
import { PreviewDeviceMacComponent } from './components/notification-preview/device-mac/device-mac.component';
import { PreviewDeviceAndroidComponent } from './components/notification-preview/device-android/device-android.component';
import { PreviewDeviceWindowsComponent } from './components/notification-preview/device-windows/device-windows.component';
import { AutomationRuleListComponent } from './pages/single/automation/automation-rule-list/automation-rule-list.component';
import { AutomationRuleFormComponent } from './components/automation-rule-form/automation-rule-form.component';
import { AutomationRuleEditorModalComponent } from './pages/single/automation/automation-rule-editor-modal.component';
import { NotificationBodyFormComponent } from './components/notification-body-form/notification-body-form.component';
import { SegmentationFormComponent } from './components/segmentation-form/segmentation-form.component';
import { SimpleQueryFormComponent } from './components/simple-query-form/simple-query-form.component';
import { SftpQueryFormComponent } from './components/sftp-query-form/sftp-query-form.component';
import { AdvancedQueryFormComponent } from './components/advanced-query-form/advanced-query-form.component';
import { DeliveryStrategyFormComponent } from './components/delivery-strategy-form/delivery-strategy-form.component';
import { AutomationHistoryComponent } from './pages/single/automation/automation-history/automation-history.component';
import { CronEditorComponent } from './components/cron-editor/cron-editor.component';
import { CronTimePickerComponent } from './components/cron-editor/cron-time-picker/cron-time-picker.component';
import { TtlEditorComponent } from './components/ttl-editor/ttl-editor.component';
import { PaymentMethodComponent } from './pages/single/payment-method/payment-method.component';
import { PaymentMethodFormComponent } from './components/payment-method-form/payment-method-form.component';
import { PlanOptionsComponent } from './pages/single/plan/options/plan-options.component';
import { PlanPaymentMethodComponent } from './pages/single/plan/plan-payment-method/plan-payment-method.component';
import { AutomationAddParametersActionFormComponent } from './components/automation-add-parameters-action-form/automation-add-parameters-action-form';
import { AutomationDeleteParametersActionFormComponent } from './components/automation-delete-parameters-action-form/automation-delete-parameters-action-form';
import { AutomationHistoryDetailModalComponent } from './pages/single/automation/automation-history/automation-history-detail-modal/automation-history-detail-modal.component';

// production時のみSentryを有効化
if (environment.production) {
  Raven.config(
    'https://be27a176c11d42e484c0e794f3bbbf60@sentry.io/138094'
  ).install();
}
setGA(environment.production);

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    ProvisionalComponent,
    SignupComponent,
    ForgetComponent,
    ResetComponent,
    LoginComponent,
    HomeComponent,
    NavbarComponent,
    SingleComponent,
    SidebarComponent,
    MainComponent,
    SettingsComponent,
    AutoComponent,
    PushComponent,
    AbtestComponent,
    LoadBalanceComponent,
    NewLoadBalancePushModalComponent,
    LoadBalancePushDetailComponent,

    AnalyticsComponent,
    AnalyticsOverviewComponent,
    OverviewChartComponent,

    AnalyticsPerformanceComponent,
    AnalyticsPerformanceIndexComponent,
    AnalyticsPerformanceDetailComponent,

    AnalyticsListComponent,
    AnalyticsListUserComponent,
    AnalyticsListParameterComponent,
    AnalyticsListParameterIndexComponent,
    AnalyticsListParameterDetailComponent,

    PlanComponent,
    TagsComponent,
    DomainComponent,
    DomainConfirmComponent,
    SftpComponent,
    ApiComponent,
    RssComponent,
    WpComponent,
    WelcomeComponent,
    ButtonComponent,
    BoxComponent,
    CopyInputComponent,
    CopyTextareaComponent,
    ModalEntryComponent,
    ModalInnerDirective,
    NewAppModalComponent,
    NewProjectModalComponent,
    EditProjectModalComponent,
    NotificationEntryComponent,
    ConfirmationEntryComponent,
    NotificationComponent,
    ConfirmationComponent,
    NotificationInnerDirective,
    ConfirmationInnerDirective,
    ImgCropComponent,
    LoadingComponent,
    AppDeleteModalComponent,
    NormalPushHistoryComponent,
    ReservedPushHistoryComponent,

    ParametersComponent,

    NotificationEditorComponent,
    QueryEditorComponent,
    SftpPresetSelectorComponent,
    SegmentationQuerySelectorComponent,
    NotificationEditorV2Component,
    QueryEditorV2Component,
    SftpPresetSelectorV2Component,
    SegmentationQuerySelectorV2Component,
    NewPushModalComponent,
    EditPushModalComponent,
    DeleteReservationConfirmComponent,
    NativeComponent,
    SdkModalComponent,
    AbtestDetailComponent,
    AbtestDetailCancelConfirmComponent,
    NewAbtestModalComponent,
    DateParsePipe,
    ImgCropModalComponent,
    PlanListComponent,
    PlanCardComponent,
    UpdateCardComponent,
    CardEditorComponent,
    PlanConfirmComponent,
    ContactPageComponent,
    AccountDeleteComponent,
    AccountDeleteModalComponent,
    PasswordComponent,
    NormalPushDetailComponent,
    ContactModalComponent,
    HidePushConfirmComponent,
    SdkMessageComponent,
    HelpLinkComponent,
    SetCorporateComponent,
    SetCategoryComponent,
    RangePickerComponent,
    AutomationComponent,
    AutomationRuleListComponent,
    AutomationRuleFormComponent,
    AutomationRuleEditorModalComponent,
    QueryComponent,
    QueryListComponent,
    NewQueryModalComponent,
    QueryDetailComponent,
    QueryDeleteWarningComponent,
    QuerySaveBeforeLeaveComponent,
    ...SegmentEditorComponents,
    EnterpriseTrialComponent,
    EnterpriseApplicationComponent,
    ContactComponent,
    EnterpriseApplicationCardComponent,
    ContactComponent,
    PushModalV2Component,
    NotificationEditorV2Component,
    NotificationProgressStepsComponent,
    ImgCropV2Component,
    EnableForDirective,
    DisplayForDirective,
    NotificationPreviewComponent,
    PreviewDeviceIphoneComponent,
    PreviewDeviceMacComponent,
    PreviewDeviceAndroidComponent,
    PreviewDeviceWindowsComponent,
    NotificationBodyFormComponent,
    SegmentationFormComponent,
    SimpleQueryFormComponent,
    SftpQueryFormComponent,
    AdvancedQueryFormComponent,
    DeliveryStrategyFormComponent,
    AutomationHistoryComponent,
    TtlEditorComponent,
    PaymentMethodComponent,
    PaymentMethodFormComponent,
    PlanOptionsComponent,
    PlanPaymentMethodComponent,
    CronEditorComponent,
    CronTimePickerComponent,
    TtlEditorComponent,
    AutomationAddParametersActionFormComponent,
    AutomationDeleteParametersActionFormComponent,
    AutomationHistoryDetailModalComponent
  ],
  entryComponents: [
    CopyInputComponent,
    CopyTextareaComponent,
    NewAppModalComponent,
    NewProjectModalComponent,
    EditProjectModalComponent,
    SdkModalComponent,
    NotificationComponent,
    ConfirmationComponent,
    AppDeleteModalComponent,
    NewPushModalComponent,
    EditPushModalComponent,
    SftpPresetSelectorComponent,
    SegmentationQuerySelectorComponent,
    QueryEditorComponent,
    SftpPresetSelectorV2Component,
    SegmentationQuerySelectorV2Component,
    QueryEditorV2Component,
    DeleteReservationConfirmComponent,
    AbtestDetailComponent,
    NewAbtestModalComponent,
    NormalPushDetailComponent,
    DomainConfirmComponent,
    ContactModalComponent,
    HidePushConfirmComponent,
    LoadingComponent,
    SetCorporateComponent,
    SetCategoryComponent,
    AccountDeleteModalComponent,
    NewQueryModalComponent,
    QueryDeleteWarningComponent,
    QuerySaveBeforeLeaveComponent,
    ...SegmentEditorEntryComponents,
    EnterpriseTrialComponent,
    EnterpriseApplicationComponent,
    AbtestDetailCancelConfirmComponent,
    NewLoadBalancePushModalComponent,
    LoadBalancePushDetailComponent,
    PushModalV2Component,
    AutomationRuleEditorModalComponent,
    ImgCropModalComponent,
    AutomationHistoryDetailModalComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    Angulartics2Module.forRoot(),
    RouterModule.forRoot(routes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader
      }
    }),
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot(),
    !environment.production
      ? StoreDevtoolsModule.instrument({ maxAge: 50 })
      : [],
    EffectsModule.forRoot([AnalyticsEffects, AppEffects, RouterEffects]),
    ApiModule,
    BackendApiModule
  ],
  exports: [TranslateModule],
  providers: [
    SessionService,
    StateService,
    LoginGuard,
    AppExistsGuard,
    AppService,
    AppResolve,
    PlansResolve,
    Modal,
    Notification,
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    CategoryService,
    AuthHelperService,
    AuthService,
    UserService,
    ConfirmationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    SegmentService,
    {
      provide: ApiConfiguration,
      useFactory: () => new ApiConfiguration({ basePath: environment.API })
    },
    {
      provide: BackendConfiguration,
      useFactory: () => new BackendConfiguration({ basePath: environment.HOST })
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
