import { Component, OnInit } from '@angular/core';
import { AppService } from '../../../services/app/app.service';
import { Store } from '@ngrx/store';
import { State, getAppSelected, getAppSelectedId } from '../../../reducers';
import { AppModel } from '../../../models/app.model';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Notification } from '../../../services/notification/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadAppAction } from '../../../actions/app';
import { of } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Component({
  templateUrl: './sftp.component.html'
})
export class SftpComponent implements OnInit {
  public sftp$: Observable<AppModel['sftp_linkage'] | null>;
  public hasParameterPermission$: Observable<Boolean>;
  public form: FormGroup;
  public processing = false;
  constructor(
    private api: AppService,
    private notification: Notification,
    private translate: TranslateService,
    private store: Store<State>,
    private fb: FormBuilder
  ) {
    this.sftp$ = store
      .select(getAppSelected)
      .pipe(map(app => app.sftp_linkage));
    this.hasParameterPermission$ = store
      .select(getAppSelected)
      .pipe(map(app => app.permissions.parameter));
  }

  private areEqual(group: FormGroup): { [key: string]: boolean } {
    const password = group.controls['password'].value;
    const confirm = group.controls['confirm'].value;
    if (password === confirm) {
      return null;
    }
    return {
      areEqual: true
    };
  }

  ngOnInit() {
    this.form = this.fb.group(
      {
        password: ['', [Validators.required, Validators.minLength(8)]],
        confirm: ['', [Validators.required, Validators.minLength(8)]]
      },
      {
        validator: this.areEqual
      }
    );
  }

  submit() {
    if (this.form.invalid) {
      return;
    }
    this.processing = true;
    this.api
      .upsert_sftp_linkage_password(this.form.get('password').value as string)
      .subscribe(
        () => {
          this.processing = false;
          // リセット
          this.form.get('password').setValue('');
          this.form.get('confirm').setValue('');
          this.form.markAsUntouched();
          this.translate
            .get('SFTP_INTEGRATION.SUCCESS')
            .subscribe((msg: string) => this.notification.open(msg, 'success'));
          this.store.select(getAppSelectedId).subscribe(appno => {
            this.store.dispatch(new LoadAppAction(appno));
          });
        },
        (errorResponse: HttpErrorResponse) => {
          this.processing = false;
          const error = errorResponse.error;
          let msg$: Observable<string>;
          switch (error.code) {
            case 4:
              msg$ = this.translate.get('SFTP_INTEGRATION.VALIDATION_ERROR');
              break;
            case 5:
              msg$ = this.translate.get('SFTP_INTEGRATION.INVALID_VENDOR_ID');
              break;
            case 6:
              msg$ = this.translate.get('SFTP_INTEGRATION.PERMISSION_ERROR');
              break;
            default:
              msg$ = of(error.error);
              break;
          }
          msg$.subscribe((msg: string) =>
            this.notification.open(msg, 'danger')
          );
        }
      );
  }
}
