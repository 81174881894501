<div class="modal is-active">
  <div class="modal-background"></div>
  <div class="modal-content">
    <form class="box" (submit)="submit()">
      <div class="content-header is-yellow">
        新規プロジェクト
      </div>
      <div class="content">
        <div class="field">
          <label class="label">プロジェクト名</label>
          <p class="control">
            <input
              class="input"
              required
              name="name"
              type="text"
              placeholder="プロジェクト名"
              [(ngModel)]="name"
            />
          </p>
        </div>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button class="button is-danger mr-2" type="button" (click)="close()">
            キャンセル
          </button>
          <button class="button is-success" type="submit" [disabled]="!name">
            {{ 'HOME.NEWAPP.SUBMIT' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
