<div class="main-nav">
  {{ 'SIDEBAR.ABTEST' | translate }}
</div>
<div class="tabs is-medium p7-tabs" *appDisplayFor="'operator'">
  <ul class="tabs-gray is-right">
    <li>
      <a (click)="create()" class="button is-primary">
        {{ 'ABTEST.CREATE' | translate }}
      </a>
    </li>
  </ul>
</div>
<div class="columns is-centered">
  <div class="column is-8-widescreen">
    <div class="container main-container">
      <ng-container *ngIf="abtests; then Loaded; else Loading"></ng-container>
      <ng-template #Loaded>
        <ng-container *ngIf="abtests.length != 0; else NoTests">
          <div class="is-paddingless">
            <div class="content is-marginless"></div>
            <table class="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th class="name">{{ 'ABTEST.AB_TITLE' | translate }}</th>
                  <th class="sent">{{ 'ABTEST.TOTAL_SENT' | translate }}</th>
                  <th class="clicked">
                    {{ 'ABTEST.TOTAL_CLICKED' | translate }}
                  </th>
                  <th class="action">{{ 'ABTEST.ACTION' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let test of abtests">
                  <td>{{ test.name }}</td>
                  <td>{{ sumSubscribers(test.pushs) }}</td>
                  <td>{{ sumClicks(test.pushs) }}</td>
                  <td>
                    <a
                      class="button is-success is-outlined"
                      (click)="detail(test)"
                    >
                      <span>{{ 'ABTEST.DETAIL' | translate }}</span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="container-footer">
              <div class="pagination">
                <a
                  (click)="prev()"
                  class="pagination-previous has-background-primary"
                  [class.is-disabled]="page == 1"
                >
                  <i class="fa fa-caret-left" aria-hidden="true"></i>
                </a>
                <div class="pagination-list">
                  <span class="button is-primary">{{ page }}/{{ max }}</span>
                </div>
                <a
                  (click)="next()"
                  class="pagination-next has-background-primary"
                  [class.is-disabled]="page == max"
                >
                  <i class="fa fa-caret-right" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </div>
  <ng-template #Loading>
    <app-loading></app-loading>
  </ng-template>

  <ng-template #NoTests>
    <div class="container-body">
      <div class="columns is-vcentered is-centered is-mobile">
        <div class="column is-narrow">
          <figure class="image is-185x185">
            <img
              class="box is-paddingless"
              src="/assets/img/hint/abtest.png"
              alt=""
            />
          </figure>
        </div>
        <div class="column is-half">
          <p class="is-size-4">
            {{ 'ABTEST.NO_TEST' | translate }}
          </p>
          <a class="button" (click)="create()" *appDisplayFor="'operator'">
            {{ 'ABTEST.CREATE' | translate }}
          </a>
        </div>
      </div>
    </div>
  </ng-template>
</div>
