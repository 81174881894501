import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, from } from 'rxjs';
import {
  tap,
  map,
  filter,
  distinctUntilChanged,
  mergeMap,
  catchError,
  switchMap
} from 'rxjs/operators';

import { DefaultService as ApiService, Project } from '../../services/api';
import {
  DefaultService as BackendService,
  ProfileResponse
} from '../../services/backend';
import { AuthHelperService } from '../auth-helper/auth-helper.service';
import { UserService } from '../user/user.service';
import { AppModel } from '../../models/app.model';
import { ConfirmationService } from '../confirmation/confirmation.service';
import { Router } from '@angular/router';

const LOCALSTORAGE_JWT_KEY = 'PUSH7_UT';

type LoginStatus =
  | {
      status: 'LOADING';
    }
  | {
      status: 'LOGGED_IN';
      token: string;
    }
  | {
      status: 'LOGGED_OUT';
    };

@Injectable()
export class AuthService {
  /**
   * ログイン状況
   */
  private _loginStatus$: BehaviorSubject<LoginStatus> = new BehaviorSubject<
    LoginStatus
  >({
    status: 'LOADING'
  });

  /**
   * ログイン状態を示すObservable
   */
  public isLoggedIn$: Observable<boolean> = this._loginStatus$.pipe(
    filter(({ status }) => status !== 'LOADING'),
    map(({ status }) => status === 'LOGGED_IN')
  );

  public token$: Observable<string> = this._loginStatus$.pipe(
    filter(({ status }) => status === 'LOGGED_IN'),
    map(status => {
      if (status.status === 'LOGGED_IN') {
        return status.token;
      }
    }),
    distinctUntilChanged()
  );

  private _profile$: BehaviorSubject<ProfileResponse | null> = new BehaviorSubject<ProfileResponse | null>(
    null
  );

  /**
   * ユーザーのプロフィールを返却します
   * ログインするまで待ちます
   */
  public profile$ = this._profile$
    .asObservable()
    .pipe(filter(profile => !!profile));

  private _projects$: BehaviorSubject<Project[] | null> = new BehaviorSubject(
    null
  );
  public projects$: Observable<Project[]> = this._projects$
    .asObservable()
    .pipe(filter(projects => !!projects));

  public apps$: Observable<AppModel[]> = this.projects$.pipe(
    map(projects =>
      projects.reduce((accm, curr) => [...accm, ...curr.apps], [])
    )
  );

  constructor(
    private authHelper: AuthHelperService,
    private backend: BackendService,
    private api: ApiService,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
    this.token$.subscribe(t => this.authHelper.setToken(t));
    const token = localStorage.getItem(LOCALSTORAGE_JWT_KEY) || null;
    if (token) {
      this._loginStatus$.next({ status: 'LOGGED_IN', token });
      this.postLogin();
    } else {
      this._loginStatus$.next({ status: 'LOGGED_OUT' });
    }
    // Token が expire していた場合、ログアウト後にログイン画面に遷移させる
    this.authHelper.tokenExpired$
      .pipe(switchMap(() => this.logout()))
      .subscribe(() => {
        this.router.navigate(['login']);
      });
  }

  /**
   * メールアドレスをパスワードを使い, ログインします
   * @param email メールアドレス
   * @param password パスワード
   */
  public login(email: string, password: string): Observable<void> {
    // APIとつなぎこむ
    return this.userService.login(email, password).pipe(
      // トークンを保存
      tap(({ token }) => {
        this._loginStatus$.next({ status: 'LOGGED_IN', token });
        localStorage.setItem(LOCALSTORAGE_JWT_KEY, token);
        this.postLogin();
      }),
      map(() => void 0)
    );
  }

  /**
   * トークンを削除し、ログアウトする
   */
  public logout(): Observable<void> {
    localStorage.removeItem(LOCALSTORAGE_JWT_KEY);
    this._loginStatus$.next({ status: 'LOGGED_OUT' });
    this._profile$.next(null);
    this._projects$.next(null);
    return of(void 0);
  }

  /**
   * ログイン後に行う処理
   */
  private postLogin() {
    this.loadProfile();
    this.loadProjects();
    this.checkInvitations();
  }

  /**
   * プロフィールを更新
   */
  public loadProfile() {
    this.backend.showProfile().subscribe(x => this._profile$.next(x));
  }

  /**
   * プロジェクト一覧を更新
   */
  public loadProjects() {
    this.api.listProjects().subscribe(({ projects }) => {
      this._projects$.next(projects);
    });
  }

  /**
   * 自分宛てに届いているプロジェクトへの招待を確認する
   */
  private checkInvitations() {
    this.api
      .listProjectInvitations()
      .pipe(
        mergeMap(invitations => from(invitations.project_invitations)),
        mergeMap(invitation =>
          this.confirmationService
            .open({
              title: `プロジェクト「${invitation.project.name}」に参加しますか？`,
              body: `${invitation.inviter_email} さんが、プロジェクト「${invitation.project.name}」にあなたを招待しています。プロジェクトに参加することで、プロジェクト内のアプリケーションの操作が行えるようになります。プロジェクトに参加しますか？`,
              cancelLabel: '拒否',
              confirmLabel: 'プロジェクトに参加'
            })
            .then(
              () =>
                ({
                  invitationId: invitation.uuid,
                  response: 'accept'
                } as const)
            )
            .catch(
              () =>
                ({
                  invitationId: invitation.uuid,
                  response: 'decline'
                } as const)
            )
        ),
        mergeMap(({ invitationId, response }) => {
          return (
            this.api
              .respondProjectInvitations(invitationId, { respond: response })
              // エラーがその後の処理に影響しないようにする
              .pipe(catchError(() => void 0))
          );
        })
      )
      .subscribe(() => {
        // プロジェクトを再読み込み
        this.loadProjects();
      });
  }

  public getProjectFromAppno(appno: string): Observable<Project | undefined> {
    return this.projects$.pipe(
      map(projects => projects.find(p => p.apps.some(a => a.appno === appno)))
    );
  }
}
