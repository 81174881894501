import { ModalContext } from './../../../../../services/modal/modal.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-reservation-confirm',
  templateUrl: './delete-reservation-confirm.component.html',
  styleUrls: ['./delete-reservation-confirm.component.scss']
})
export class DeleteReservationConfirmComponent implements OnInit {
  constructor(private modalCtx: ModalContext) {}

  ngOnInit() {}
  close() {
    this.modalCtx.reject();
  }
  delete() {
    this.modalCtx.resolve();
  }
}
