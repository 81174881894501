import { Component } from '@angular/core';

import { ModalContext } from '../../../../../services/modal/modal.service';

@Component({
  templateUrl: './abtest-detail-cancel-confirm.component.html'
})
export class AbtestDetailCancelConfirmComponent {
  constructor(private modalCtx: ModalContext) {}
  approve() {
    this.modalCtx.resolve();
  }
  close() {
    this.modalCtx.reject();
  }
}
