import * as Segment from '../models/segments.model';

function parse(obj: any): Segment.ASTNode {
  switch (obj.type) {
    case 'ASTNode':
      switch (obj.op) {
        case 'ADD':
          obj.op = '+';
          break;
        case 'SUB':
          obj.op = '-';
          break;
      }
      return new Segment.ASTInnerNode(obj.op, obj.conditions.map(parse));
    case 'ASTValue':
      switch (obj.op) {
        case 'NULL':
          return parse(obj.value);
        case 'GET_USER_LIST_OPENED':
          return new Segment.ASTOpened(obj.value.value);
        case 'GET_USER_LIST_SEND':
          return new Segment.ASTSent(obj.value.value);
        case 'LASTOPENED':
          return new Segment.ASTLastOpened(parse(obj.value));
        case 'SUBSCRIBEDAY':
          return new Segment.ASTSubscribeDay(parse(obj.value));
        case 'LASTVISIT':
          return new Segment.ASTLastVisit(parse(obj.value));
        case 'LASTVISIT_URL':
          return new Segment.ASTLastVisitUrl(
            parse(obj.value) as Segment.UrlDatetime
          );
        case 'ONLY':
          return new Segment.ASTParameters(obj.value.parameters.map(parse));
        case 'GET_USER_LIST_BROWSER':
          return new Segment.ASTBrowser(obj.value.browser);
        default:
          return new Segment.ASTValue(obj.op, parse(obj.value));
      }
    case 'ASTParameter':
      return new Segment.ASTParameter(obj.parameter);
    case 'DATETIME_ITEM':
      return parseDate(obj.value);
    case 'DATETIME_NULL':
      return new Segment.ASTDateTimeNever();
    case 'DATETIME_RANGE':
      return new Segment.ASTDateTimeRange(
        parseDate(obj.from),
        parseDate(obj.to)
      );
    case 'DATETIME_DATETIME':
      return new Segment.ASTDateTime(new Date(obj.value));
    case 'URL_DATETIME_OBJECT':
      return new Segment.UrlDatetime(
        (({ type, value }) => {
          switch (type) {
            case 'JUST':
              return new Segment.ASTUrlJust(value);
            case 'PREFIX':
              return new Segment.ASTUrlPrefix(value);
          }
        })(obj.url),
        parse(obj.datetime)
      );
  }
  throw new Error(`Unknown query ${JSON.stringify(obj)}`);
}

function parseDate(obj: any): Segment.DateSpecifier {
  switch (obj.type) {
    case 'DATETIME_BEFORE':
      return new Segment.ASTRelativeDateTime(
        obj.value[0].value,
        obj.value[0].type
      );
    case 'DATETIME_KEYWORD':
      return new Segment.ASTKeywordDateTime(obj.value);
  }
}

/**
 * /query/api からのJSONをフロントエンドのデータ構造に変換します
 * @param jsonStr /api/v1/query/astで生成したJSON
 */
export default function segmentJsonParser(obj: any): Segment.ASTRoot {
  const root = parse(obj);
  return new Segment.ASTRoot(
    root.className !== 'ASTInnerNode'
      ? new Segment.ASTInnerNode('AND', [root])
      : root
  );
}
