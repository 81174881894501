<div class="form" [class.horizonal]="horizonal">
  <div>
    <label class="label">{{ 'NOTIFICATION_EDITOR.TITLE' | translate }}</label>
    <p class="control has-icon has-icon-right">
      <input
        class="input"
        type="text"
        name="title"
        [(ngModel)]="notification.title"
        [appEnableFor]="role"
      />
    </p>
    <label class="label">{{ 'NOTIFICATION_EDITOR.URL' | translate }}</label>
    <p class="control has-icon has-icon-right">
      <input
        class="input"
        type="url"
        name="url"
        [(ngModel)]="notification.url"
        [appEnableFor]="role"
      />
    </p>
  </div>
  <div [class.adjust-margin]="!horizonal">
    <label class="label">{{ 'NOTIFICATION_EDITOR.BODY' | translate }}</label>
    <p class="control has-icon has-icon-right">
      <textarea
        class="textarea"
        name="body"
        type="text"
        [(ngModel)]="notification.body"
        [appEnableFor]="role"
      ></textarea>
    </p>
    <ng-container *ngIf="isLoadBalancePush">
      <label class="label">一度に配信する通数</label>
      <p class="control has-icon has-icon-right">
        <input
          min="1"
          class="input"
          type="number"
          name="number"
          [(ngModel)]="notification.delivery_strategy.subscribers_per_group"
          [appEnableFor]="role"
        />
      </p>
      <label class="label">配信間隔(分)</label>
      <p class="control has-icon has-icon-right">
        <input
          min="1"
          class="input"
          type="number"
          name="number"
          [(ngModel)]="notification.delivery_strategy.send_interval_minutes"
          [appEnableFor]="role"
        />
      </p>
    </ng-container>
    <p class="control" *ngIf="permanent">
      <label class="checkbox">
        <input
          type="checkbox"
          [(ngModel)]="until_clicked"
          (change)="toggleDisappear()"
          [appEnableFor]="role"
        />
        {{ 'NOTIFICATION_EDITOR.DISAPPEAR_INSTANTLY' | translate }}
      </label>
    </p>
    <p class="control" *ngIf="permanent">
      <label class="checkbox">
        <input
          type="checkbox"
          [(ngModel)]="notification.enable_ios_sound"
          [appEnableFor]="role"
        />
        {{ 'NOTIFICATION_EDITOR.ENABLE_IOS_SOUND' | translate }}
      </label>
    </p>
  </div>
  <div>
    <p class="control">
      <label class="checkbox">
        <input
          type="checkbox"
          [(ngModel)]="customIcon"
          (change)="toggleIcon()"
          [appEnableFor]="role"
        />
        {{ 'NOTIFICATION_EDITOR.USE_CUSTOM_ICON' | translate }}
      </label>
    </p>
    <ng-container *ngIf="customIcon">
      <label class="label">{{ 'NOTIFICATION_EDITOR.ICON' | translate }}</label>
      <ng-container *appDisplayFor="role; fallback: IconFallback">
        <app-img-crop [(src)]="notification.icon"></app-img-crop>
      </ng-container>
      <ng-template #IconFallback>
        <img [src]="notification.icon" width="120" />
      </ng-template>
    </ng-container>
    <ng-container *ngIf="enableImage">
      <p class="control" [class.adjust-margin]="!horizonal">
        <label class="checkbox">
          <input
            type="checkbox"
            [(ngModel)]="useImage"
            (change)="toggleImage()"
            [appEnableFor]="role"
          />
          {{ 'NOTIFICATION_EDITOR.USE_IMAGE' | translate }}
        </label>
      </p>
      <ng-container *ngIf="useImage">
        <label class="label">{{
          'NOTIFICATION_EDITOR.IMAGE' | translate
        }}</label>
        <ng-container *appDisplayFor="role; fallback: ImageFallback">
          <app-img-crop [(src)]="notification.image"></app-img-crop>
        </ng-container>
        <ng-template #ImageFallback>
          <img [src]="notification.image" width="120" />
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
  <div>
    <ng-container *ngIf="reservation">
      <p class="control" [class.adjust-margin]="!horizonal">
        <label class="checkbox">
          <input
            type="checkbox"
            [(ngModel)]="isReservation"
            (change)="toggleReservation()"
            [appEnableFor]="role"
          />
          {{ 'NOTIFICATION_EDITOR.SET_TRANSMISSION_TIME' | translate }}
        </label>
      </p>
      <ng-container *ngIf="isReservation">
        <p class="control">
          <label class="label">{{
            'NOTIFICATION_EDITOR.TRANSMISSION_TIME' | translate
          }}</label>
          <input
            class="input flatpickr"
            type="datetime"
            [appEnableFor]="role"
          />
          <!-- Write binding in logic, not using angular's binding' -->
        </p>
      </ng-container>
    </ng-container>
    <div *ngIf="permanent">
      <p class="control" [class.adjust-margin]="!horizonal">
        <label class="checkbox">
          <input
            type="checkbox"
            [(ngModel)]="useCustomTtl"
            (change)="toggleTtl()"
            [appEnableFor]="role"
          />
          {{ 'NOTIFICATION_EDITOR.USE_CUSTOM_TTL' | translate }}
        </label>
      </p>
      <ng-container *ngIf="useCustomTtl">
        <p class="control">
          <label class="label">{{
            'NOTIFICATION_EDITOR.CUSTOM_TTL.LABEL' | translate
          }}</label>
          <input
            class="input"
            type="number"
            [(ngModel)]="notification.ttl"
            [appEnableFor]="role"
          />
        </p>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="query && (app$ | async).permissions.parameter">
    <p class="control" [class.adjust-margin]="!horizonal">
      <label class="checkbox">
        <input
          type="checkbox"
          [(ngModel)]="isQuery"
          (change)="toggleQuery()"
          [appEnableFor]="role"
        />
        {{ 'NOTIFICATION_EDITOR.PARAMS' | translate }}
      </label>
    </p>
    <ng-container *ngIf="isQuery">
      <p class="control">
        <span
          >{{ 'NOTIFICATION_EDITOR.MODE' | translate }}:{{
            notification?.query?.mode
          }}</span
        ><br />
        <span
          >{{ notification?.query?.params?.length }}
          {{ 'NOTIFICATION_EDITOR.PARAMS_COUNT' | translate }}</span
        >
      </p>
      <p class="control">
        <button
          (click)="editQuery()"
          class="button is-info p7-button"
          [appEnableFor]="role"
        >
          {{ 'NOTIFICATION_EDITOR.PARAMS_CONFIGURE' | translate }}
        </button>
      </p>
    </ng-container>
    <p class="control" [class.adjust-margin]="!horizonal">
      <label class="checkbox">
        <input
          type="checkbox"
          [(ngModel)]="isSftpPreset"
          (change)="toggleSftpPreset()"
          [appEnableFor]="role"
        />
        {{ 'NOTIFICATION_EDITOR.SFTP_INTEGRATION' | translate }}
      </label>
    </p>
    <ng-container *ngIf="isSftpPreset">
      <p class="control">
        <span
          >{{ 'NOTIFICATION_EDITOR.SELECTED_PRESET' | translate }}:
          {{ notification?.csv_preset_id }}</span
        >
      </p>
      <p class="control">
        <button
          (click)="editSftpPreset()"
          class="button is-info p7-button"
          [appEnableFor]="role"
        >
          {{ 'NOTIFICATION_EDITOR.SELECT_PRESET' | translate }}
        </button>
      </p>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="query && (app$ | async).permissions.can_use_segment">
    <p class="control" [class.adjust-margin]="!horizonal">
      <label class="checkbox">
        <input
          type="checkbox"
          [(ngModel)]="isSegmentationQuery"
          (change)="toggleSegmentationQuery()"
          [appEnableFor]="role"
        />
        {{ 'NOTIFICATION_EDITOR.SEGMENTATION_QUERY' | translate }}
      </label>
    </p>
    <ng-container *ngIf="isSegmentationQuery">
      <p class="control">
        <span
          >{{ 'NOTIFICATION_EDITOR.SELECTED_SEGMENTATION_QUERY' | translate }}:
          {{ selectedSementName || notification.query }}</span
        >
      </p>
      <p class="control">
        <button
          [appEnableFor]="role"
          (click)="editSegmentationQuery()"
          class="button is-info p7-button"
        >
          {{ 'NOTIFICATION_EDITOR.SELECT' | translate }}
        </button>
      </p>
    </ng-container>
  </ng-container>
</div>
