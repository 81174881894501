/**
 * Push7 Project Feature for push7-apiserver
 * Push7 Project Featurs API specfication
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: enginners@gnex.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * プロジェクトに参加している・招待されている単一メンバーを表現します。
 */
export interface Member { 
    uuid?: string;
    /**
     * メンバーを識別するメールアドレス
     */
    email?: string;
    /**
     * このメンバーを招待した人。NULLならば、プロジェクト作成者
     */
    inviter_email?: string | null;
    /**
     * メンバーの作成日。招待日時またはプロジェクト作成日時
     */
    created_at?: string;
    /**
     * メンバーの最終更新日.
     */
    updated_at?: string;
    /**
     * メンバーステータス inviting ... 招待中 declined ... 招待を拒否した, updated_at は 招待拒否日時 active ... プロジェクト参加中 
     */
    status?: Member.StatusEnum;
    /**
     * A role of this member administrator ... プロジェクト管理者 operator ... プロジェクト運用担当者 viewer ... プロジェクト閲覧者 spy_viewer ... スパイプロジェクト閲覧者 spy_administrator ... スパイプロジェクト管理者 スパイは、GNEXユーザーのみから認知される 
     */
    role?: Member.RoleEnum;
}
export namespace Member {
    export type StatusEnum = 'inviting' | 'declined' | 'active';
    export const StatusEnum = {
        Inviting: 'inviting' as StatusEnum,
        Declined: 'declined' as StatusEnum,
        Active: 'active' as StatusEnum
    };
    export type RoleEnum = 'administrator' | 'operator' | 'viewer' | 'spy_viewer' | 'spy_administrator';
    export const RoleEnum = {
        Administrator: 'administrator' as RoleEnum,
        Operator: 'operator' as RoleEnum,
        Viewer: 'viewer' as RoleEnum,
        SpyViewer: 'spy_viewer' as RoleEnum,
        SpyAdministrator: 'spy_administrator' as RoleEnum
    };
}


