import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
  Validator,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-ttl-editor',
  templateUrl: './ttl-editor.component.html',
  styleUrls: ['./ttl-editor.component.scss']
})
export class TtlEditorComponent
  implements OnInit, ControlValueAccessor, Validator {
  public day = 0;
  public hour = 0;
  public minute = 0;
  public second = 0;

  public set ttl(t: number) {
    const tPositive = Math.max(t, 0);
    this.day = Math.floor(tPositive / (24 * 60 * 60));
    this.hour = Math.floor((tPositive / (60 * 60)) % 24);
    this.minute = Math.floor((tPositive / 60) % 60);
    this.second = tPositive % 60;
  }
  public get ttl(): number {
    return 60 * (60 * (24 * this.day + this.hour) + this.minute) + this.second;
  }

  private onChange: any = () => {};
  private onTouched: any = () => {};

  get value(): number {
    return this.ttl;
  }
  @Input('value')
  set value(t: number) {
    this.ttl = t;
    this.onChange(t);
    this.onTouched();
  }

  public emitTtl() {
    this.value = this.ttl;
  }

  constructor(@Optional() @Self() public controlDir: NgControl) {
    controlDir.valueAccessor = this;
    if (controlDir.control) {
      controlDir.control.setValidators(this.validate);
      controlDir.control.updateValueAndValidity();
    }
  }

  ngOnInit() {}

  writeValue(obj: string): void {
    if (!isNaN(+obj)) {
      this.value = +obj;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(ctrl: AbstractControl) {
    return Validators.compose([Validators.min(0), Validators.max(2419200)])(
      ctrl
    );
  }
}
