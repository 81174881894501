import { SetCategoryComponent } from './../components/set-category-modal/set-category-modal.component';
import { Modal } from './../services/modal/modal.service';
import { ActionTypes } from './../actions/analytics';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable, concat } from 'rxjs';
import {
  withLatestFrom,
  switchMap,
  mergeMap,
  filter,
  map
} from 'rxjs/operators';
import { of } from 'rxjs';

import * as app from '../actions/app';
import * as analytics from '../actions/analytics';
import * as fromRoot from '../reducers';
import * as fromApp from '../reducers/app';

import { AppService } from '../services/app/app.service';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private appService: AppService,
    private store: Store<fromRoot.State>,
    private modal: Modal
  ) {}
  @Effect()
  update$: Observable<Action> = this.actions$.pipe(
    ofType(app.ActionTypes.LOAD),
    map((action: app.LoadAppAction) => action.payload),
    switchMap(appno =>
      this.appService
        .show(appno)
        .pipe(map(data => new app.LoadAppCompleteAction({ appno, data })))
    )
  );
  // ロード時にカテゴリが未設定ならモーダルで設定させる
  afterLoad$ = this.actions$
    .pipe(
      ofType(app.ActionTypes.LOAD_COMPLETE),
      map((action: app.LoadAppCompleteAction) => action.payload.data),
      filter(a => !a.categoryno)
    )
    .subscribe(() => this.modal.open(SetCategoryComponent));
  @Effect()
  select$: Observable<Action> = this.actions$.pipe(
    ofType(app.ActionTypes.SELECT),
    map((action: app.LoadAppAction) => action.payload),
    mergeMap((appno: string) => concat(of(new analytics.InitState(appno))))
  );
  @Effect()
  loadNotifications$: Observable<Action> = this.actions$.pipe(
    ofType(app.ActionTypes.LOAD_NOTIFICATIONS),
    map((action: app.LoadNotificationsAction) => action.payload),
    withLatestFrom(this.store.select(fromRoot.getAppSelectedNotifications)),
    switchMap(([payload, notiState]) => {
      return new Promise(resolve => {
        const item = 20; // 1ページに表示する数
        // payload.pageを表示するために十分なorderがロードされているか確認
        let loaded = notiState.order.length; // 現在ロードされている数
        const required: number = payload.page * item; // 1ページ目なら10個, 2ページ目なら20個ロードされている必要がある
        const enough: boolean = required < loaded; // 十分かどうか
        // 足りている場合
        if (enough) {
          return resolve(payload.page); // resolveしてそのまま読み込ませる
        }
        // 足りていない場合
        const loadPromises = [];
        while (required > loaded) {
          loadPromises.push(
            new Promise((_resolve, _reject) => {
              this.appService.history(loaded).subscribe(d => {
                const order = d.pushs.map(push => push.id);
                const data = {};
                d.pushs.forEach(push => {
                  data[push.id] = push;
                });
                _resolve({
                  data,
                  order,
                  count: d.count
                }); // PushNotificationsActionのpayloadの形でresolve
              });
            })
          );
          loaded = loaded + 10;
        }
        Promise.all(loadPromises).then(results => {
          results.forEach(result => {
            this.store.dispatch(new app.PushNotificationsAction(result));
          });
          resolve(payload.page);
        });
      });
    }),
    map((page: number) => {
      return new app.LoadNotificationsCompleteAction({ page });
    })
  );
  /**
   * PushをDisappearする
   */
  @Effect()
  disappearPush$: Observable<Action> = this.actions$.pipe(
    ofType(app.ActionTypes.DISAPPEAR_NOTIFICATION),
    map((action: app.DisappearNotificationAction) => action.payload),
    mergeMap((pushid: string) =>
      this.appService
        .disappear_push(pushid)
        .pipe(map(() => new app.DisappearNotificationCompleteAction(pushid)))
    )
  );
  /**
   * Disappearした際にページを再読込する
   */
  @Effect()
  disappearPushComplete$: Observable<Action> = this.actions$.pipe(
    ofType(app.ActionTypes.DISAPPEAR_NOTIFICATION),
    map(x => (x as app.DisappearNotificationAction).payload),
    withLatestFrom(this.store.select(fromRoot.getAppSelectedNotifications)),
    map(
      ([payload, notifications]) =>
        new app.LoadNotificationsAction({
          page: notifications.current
        })
    )
  );
  @Effect()
  loadReservations$: Observable<Action> = this.actions$.pipe(
    ofType(app.ActionTypes.LOAD_RESERVATIONS),
    map((action: app.LoadReservationsAction) => action.payload),
    withLatestFrom(this.store.select(fromRoot.getAppSelectedReservations)),
    switchMap(([payload, resState]) => this.appService.reservations()),
    map(d => {
      return new app.LoadReservationsCompleteAction({ data: d.pushs });
    })
  );
}
