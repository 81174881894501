import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * トークンを保持するだけのサービス
 * Intercepter -> AuthService -> HttpClient -> Intercepter
 * のCircular Dependencyが発生しないようにするためだけに存在している
 */
@Injectable()
export class AuthHelperService {
  private tokenSubject: BehaviorSubject<string | null>;
  private tokenExpiredSubject = new Subject<void>();
  public token$: Observable<string>;
  public tokenExpired$: Observable<void>;
  constructor() {
    this.tokenSubject = new BehaviorSubject(null);
    this.token$ = this.tokenSubject
      .asObservable()
      .pipe(filter(token => !!token));
    this.tokenExpired$ = this.tokenExpiredSubject.asObservable();
  }
  public setToken(token: string) {
    this.tokenSubject.next(token);
  }
  public tokenExpired() {
    this.tokenExpiredSubject.next();
  }
}
