import { Component, OnInit, Input } from '@angular/core';
import { NotificationModel } from '../../../models/notification.model';

@Component({
  selector: 'app-preview-device-windows',
  templateUrl: './device-windows.component.html',
  styleUrls: ['./device-windows.component.scss']
})
export class PreviewDeviceWindowsComponent implements OnInit {
  @Input()
  notification: NotificationModel;
  @Input()
  appUrl: string;

  constructor() {}

  ngOnInit() {}

  public get url_without_http() {
    return this.appUrl.replace(/^https?:\/\//, '');
  }
}
