import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.scss']
})
export class AnalyticsPerformanceComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
