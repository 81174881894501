import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../reducers';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-native',
  templateUrl: './native.component.html',
  styleUrls: ['./native.component.scss']
})
export class NativeComponent implements OnInit {
  sdk: string;
  example: string;
  constructor(private store: Store<fromRoot.State>) {
    this.store
      .select(fromRoot.getAppSelected)
      .pipe(take(1))
      .subscribe(app => {
        this.sdk = `<script src="https://sdk.push7.jp/v2/p7sdk.js"></script>
<script>
p7.init("${app.appno}",{
  mode:"native",
  subscribe:"auto"
});
</script>`;
      });
  }

  ngOnInit() {}
}
