<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        ABテストのキャンセル
      </div>
      <div class="content">
        <p>ABテストをキャンセルした場合、操作は取り消せません。</p>
        <p>
          既に一部のテストケースが送信されている場合、残りのテストケースに割り振られた購読者には通知が送信されません。
        </p>
        <p>ABテストをキャンセルしてよろしいですか？</p>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button class="button is-info p7-button" (click)="close()">
            取り消し
          </button>
          <button class="button is-danger p7-button" (click)="approve()">
            テストをキャンセルする
          </button>
        </div>
      </div>
    </div>
  </div>
  <button class="modal-close" (click)="close()"></button>
</div>
