<ng-container>
  <div
    class="field has-addons"
    [class.is-danger]="controlDir && !controlDir.control.valid"
  >
    <div class="control">
      <input
        class="input"
        type="number"
        [(ngModel)]="day"
        (input)="emitTtl()"
        min="0"
      />
    </div>
    <div class="control">
      <a class="button is-static">
        日
      </a>
    </div>
    <div class="control">
      <input
        class="input"
        type="number"
        [(ngModel)]="hour"
        (change)="emitTtl()"
        min="0"
      />
    </div>
    <div class="control">
      <a class="button is-static">
        時
      </a>
    </div>
    <div class="control">
      <input
        class="input"
        type="number"
        [(ngModel)]="minute"
        (change)="emitTtl()"
        min="0"
      />
    </div>
    <div class="control">
      <a class="button is-static">
        分
      </a>
    </div>
    <div class="control">
      <input
        class="input"
        type="number"
        [(ngModel)]="second"
        (change)="emitTtl()"
        min="0"
      />
    </div>
    <div class="control">
      <a class="button is-static">
        秒
      </a>
    </div>
  </div>

  <p *ngIf="controlDir && !controlDir.control.valid" class="help is-danger">
    {{ 'NOTIFICATION_EDITOR.VALIDATIONS.TTL' | translate }}
  </p>
</ng-container>
