<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{ 'NOTIFICATION_EDITOR.CSV_PRESET.LABEL' | translate }}
      </div>
      <div>
        <ng-container *ngIf="presets$ | async; let presets; else: Loading">
          <table
            class="table is-striped p7-table"
            *ngIf="presets.length > 0; else NoPresets"
          >
            <thead>
              <tr>
                <td>
                  {{ 'NOTIFICATION_EDITOR.CSV_PRESET.FILENAME' | translate }}
                </td>
                <td>
                  {{ 'NOTIFICATION_EDITOR.CSV_PRESET.HEADER' | translate }}
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let preset of presets">
                <td>{{ preset.file_name }}</td>
                <td>{{ preset.header_name }}</td>
                <td>
                  <a
                    class="button is-info p7-button is-outlines"
                    (click)="select(preset.id)"
                    >{{
                      'NOTIFICATION_EDITOR.CSV_PRESET.SELECT' | translate
                    }}</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <ng-template #NoPresets>
            <div class="box">
              <div class="content">
                <article class="message is-info">
                  <div class="message-header">
                    <p>
                      {{
                        'NOTIFICATION_EDITOR.CSV_PRESET.NO_PRESETS' | translate
                      }}
                    </p>
                  </div>
                  <div class="message-body">
                    <p>
                      {{
                        'NOTIFICATION_EDITOR.CSV_PRESET.NO_PRESETS_MESSAGE'
                          | translate
                      }}
                    </p>
                  </div>
                </article>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #Loading>
          <app-loading></app-loading>
        </ng-template>
      </div>
    </div>
  </div>
</div>
