<div class="main-nav">
  支払い方法の変更
</div>
<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-header has-text-weight-bold is-size-4">
        支払い情報の変更
      </div>
      <div class="container-body">
        <h3 class="title is-5">
          現在の支払い方法
        </h3>
        <ng-container *ngIf="paymentMethod$ | async; let paymentMethod">
          <fieldset disabled>
            <div class="field">
              <label class="label">支払い方法</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  [ngModel]="displayPaymentMethod(paymentMethod.payment_method)"
                />
              </div>
            </div>
            <ng-container
              *ngIf="
                paymentMethod.payment_method === 'card' &&
                paymentMethod['card'] as card
              "
            >
              <div class="field">
                <label class="label">カード番号</label>
                <div class="control has-icons-left">
                  <input class="input" type="text" [ngModel]="card.card_no" />
                  <span class="icon is-small is-left">
                    <i class="fa fa-cc" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div class="field">
                <label class="label">有効期限</label>
                <div class="control has-icons-left">
                  <input class="input" type="text" [ngModel]="card.expire" />
                  <span class="icon is-small is-left">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div class="field">
                <label class="label">カード名義</label>
                <div class="control has-icons-left">
                  <input class="input" type="text" [ngModel]="card.holder" />
                  <span class="icon is-small is-left">
                    <i class="fa fa-font" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </ng-container>
          </fieldset>
        </ng-container>
        <hr />
        <h3 class="title is-5 mt-4">
          支払い方法の変更
        </h3>
        <form [formGroup]="form" (submit)="submit()">
          <app-payment-method-form
            formControlName="paymentMethod"
            [canUseTransfer]="isEnterpriseProject$ | async"
          ></app-payment-method-form>
          <div class="has-text-right mt-4">
            <button
              class="button is-success"
              type="submit"
              [class.is-loading]="loading"
              [disabled]="form.invalid"
            >
              更新
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
