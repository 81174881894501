/**
 * Push7 Project Feature for push7-apiserver
 * Push7 Project Featurs API specfication
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: enginners@gnex.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 送信したPush通知
 */
export interface AutomationActionActionsPushNotification { 
    /**
     * その Push 通知が push か load_balance_push か
     */
    type?: AutomationActionActionsPushNotification.TypeEnum;
    push?: object | null;
    load_balance_push?: object | null;
}
export namespace AutomationActionActionsPushNotification {
    export type TypeEnum = 'load_balance_push' | 'push';
    export const TypeEnum = {
        LoadBalancePush: 'load_balance_push' as TypeEnum,
        Push: 'push' as TypeEnum
    };
}


