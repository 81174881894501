import {
  Injectable,
  ViewContainerRef,
  Component,
  ViewChild,
  Directive,
  AfterViewInit,
  OnInit,
  ElementRef,
  forwardRef,
  Inject
} from '@angular/core';
import {
  ConfirmationService,
  ConfirmationContext
} from './confirmation.service';

@Directive({
  selector: '[appConfirmationInner]'
})
export class ConfirmationInnerDirective {
  constructor(public vcr: ViewContainerRef) {}
}

@Component({
  selector: 'app-confirmation-entry',
  template: `
    <div class="confirmations">
      <div appConfirmationInner></div>
    </div>
  `,
  styles: [``],
  providers: [ConfirmationInnerDirective]
})
export class ConfirmationEntryComponent implements AfterViewInit {
  private confirmation: ConfirmationService;
  @ViewChild(ConfirmationInnerDirective, { static: true })
  private inner: ConfirmationInnerDirective;
  constructor(@Inject(forwardRef(() => ConfirmationService)) confirmation) {
    this.confirmation = confirmation;
  }
  ngAfterViewInit() {
    this.confirmation.set(this.inner.vcr);
  }
}

@Component({
  selector: 'app-notification',
  template: `
    <div class="modal is-active">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <div class="content-header is-yellow">
            {{ confirmationCtx.title }}
          </div>
          <div class="content">
            <p>{{ confirmationCtx.body }}</p>
          </div>
          <div class="content-footer">
            <div class="flex has-text-right">
              <button
                class="button mr-2"
                [ngClass]="confirmationCtx.cancelClass"
                type="button"
                (click)="cancel()"
              >
                {{ confirmationCtx.cancelLabel }}
              </button>
              <button
                class="button"
                [ngClass]="confirmationCtx.confirmClass"
                (click)="confirm()"
              >
                {{ confirmationCtx.confirmLabel }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
})
export class ConfirmationComponent implements OnInit {
  public text: string;
  public elementClass: string[];
  public confirmationCtx: ConfirmationContext;
  private _el: HTMLElement;
  constructor(
    @Inject(forwardRef(() => ConfirmationContext)) confirmationCtx,
    private el: ElementRef
  ) {
    this.confirmationCtx = confirmationCtx;
    this._el = el.nativeElement;
  }
  cancel() {
    this.confirmationCtx.reject();
  }
  confirm() {
    this.confirmationCtx.resolve();
  }
  ngOnInit() {}
}
