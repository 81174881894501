<div class="columns is-tablet">
  <div class="column is-8 is-offset-2">
    <div class="container main-container">
      <div class="container-body">
        <h3 class="title is-4 has-text-centered">
          オプション選択
        </h3>
        <form [formGroup]="form" (submit)="submit($event)">
          <div class="columns">
            <div class="column is-6">
              <label class="label">セグメントオプションの有効化</label>
              <div class="field">
                <fieldset [disabled]="currentSegmentationStatus$ | async">
                  <div class="control">
                    <label class="radio">
                      <input
                        type="radio"
                        formControlName="enterprise_segment_option"
                        name="enterprise_segment_option"
                        [value]="true"
                      />
                      する
                    </label>
                    <label class="radio">
                      <input
                        type="radio"
                        formControlName="enterprise_segment_option"
                        name="enterprise_segment_option"
                        [value]="false"
                      />
                      しない
                    </label>
                  </div>
                </fieldset>
              </div>
              <article
                class="message"
                *ngIf="currentSegmentationStatus$ | async; else SegmentDetails"
              >
                <div class="message-body">
                  既にセグメントオプションが有効化されているため、契約変更後もセグメント機能をご利用いただけます。
                </div>
              </article>
              <ng-template #SegmentDetails>
                <article class="message">
                  <div class="message-body">
                    セグメントオプションを有効化いただくことで、パラメータや最終訪問時刻などを利用して特定の購読者のみにプッシュ通知を送信することが可能になります。初期費用100,000円(税別)を頂戴いたします。
                  </div>
                </article>
              </ng-template>
            </div>
            <div class="column is-6">
              <label class="label">お支払い頻度</label>
              <div class="field">
                <div class="control">
                  <label class="radio">
                    <input
                      type="radio"
                      formControlName="enterprise_payment_frequency"
                      name="enterprise_payment_frequency"
                      [value]="1"
                    />
                    1ヶ月
                  </label>
                  <label class="radio">
                    <input
                      type="radio"
                      formControlName="enterprise_payment_frequency"
                      name="enterprise_payment_frequency"
                      [value]="6"
                    />
                    6ヶ月
                  </label>
                  <label class="radio">
                    <input
                      type="radio"
                      formControlName="enterprise_payment_frequency"
                      name="enterprise_payment_frequency"
                      [value]="12"
                    />
                    12ヶ月
                  </label>
                </div>
              </div>
              <article class="message">
                <div class="message-body">
                  支払い頻度ごとに、ご指定の支払い方法（クレジットカードもしくは銀行振り込み）にて料金をお支払いいただきます。
                </div>
              </article>
            </div>
          </div>
          <ng-container *ngIf="form.valid; else MissingOptions">
            <article
              class="message is-danger"
              *ngIf="previewErrorMessage; else NoError"
            >
              <div class="message-body">
                {{ previewErrorMessage }}
              </div>
            </article>
            <ng-template #NoError>
              <ng-container *ngIf="preview$ | async as preview; else Loading">
                <!-- 契約期間のみの変更など、初回支払いが発生しない場合には上半分を非表示に -->
                <ng-container *ngIf="preview.initial.length > 0">
                  <div class="has-text-centered">
                    <p class="title is-4">{{ plan.name }}プラン</p>
                  </div>
                  <p class="title is-6 mt-6">
                    契約時にお支払いいただく費用:
                    {{
                      preview.initial_total + preview.initial_total_tax
                        | number
                    }}円 （内消費税 {{ preview.initial_total_tax | number }}円）
                  </p>
                  <table class="table is-striped breakdown is-fullwidth">
                    <thead>
                      <tr>
                        <td>項目</td>
                        <td>金額(税抜)</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let entry of preview.initial">
                        <td>{{ entry.description }}</td>
                        <td>¥{{ entry.amount | number }}</td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>
                <p class="title is-6 mt-4">
                  支払い頻度ごとにお支払いいただく費用:
                  {{
                    preview.periodical_total + preview.periodical_total_tax
                      | number
                  }}円 （内消費税
                  {{ preview.periodical_total_tax | number }}円）
                </p>
                <table class="table is-striped breakdown is-fullwidth">
                  <thead>
                    <tr>
                      <td>項目</td>
                      <td>金額(税抜)</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let entry of preview.periodical">
                      <td>{{ entry.description }}</td>
                      <td>¥{{ entry.amount | number }}</td>
                    </tr>
                  </tbody>
                </table>
              </ng-container>
              <ng-template #Loading>
                <app-loading></app-loading>
              </ng-template>
            </ng-template>
          </ng-container>
          <ng-template #MissingOptions>
            <article class="message is-warning">
              <div class="message-body">
                上部よりオプション・支払い頻度を選択してください
              </div>
            </article>
          </ng-template>
          <div class="has-text-right">
            <button
              class="button is-success"
              type="submit"
              [class.is-loading]="processing"
              [disabled]="previewErrorMessage || form.invalid"
            >
              契約
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
