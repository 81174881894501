import { AppModel } from './../../models/app.model';
import { Observable } from 'rxjs';
import { QueryEditorComponent } from './query-editor/query-editor.component';
import { SftpPresetSelectorComponent } from './sftp-preset-selector/sftp-preset-selector.component';
import { Modal } from './../../services/modal/modal.service';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import {
  NotificationModel,
  QueryModel
} from './../../models/notification.model';
import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ElementRef,
  OnDestroy
} from '@angular/core';
import * as fromRoot from './../../reducers';

import * as Flatpicker from 'flatpickr';
import { SegmentationQuerySelectorComponent } from './segmentation-query-selector/segmentation-query-selector.component';
import { PermissionRole } from '../../helpers/roleCmp';

const defaultTTL = 43200;

@Component({
  selector: 'app-notification-editor',
  templateUrl: './notification-editor.component.html',
  styleUrls: ['./notification-editor.component.scss']
})
export class NotificationEditorComponent implements OnInit, OnDestroy {
  @Input()
  role: PermissionRole = 'viewer';
  @Input()
  notification: NotificationModel;
  @Input()
  reservation = true;
  @Input()
  horizonal = false;
  @Input()
  enableImage = true; // Large Imageを使えるかどうか
  @Input()
  query = false; // パラメータ配信の設定の表示
  @Input()
  permanent = true;
  @Input()
  isLoadBalancePush = false;
  public isReservation = false;
  public isQuery = false; // パラメータ配信を用いるかどうか
  public isSftpPreset = false; // SFTPパラメータを用いるかどうか
  public isSegmentationQuery = false; // セグメント配信を用いるかどうか
  public selectedSementName: string; // 選択されたセグメントの名前
  private originalImage: string;
  public customIcon = false;
  public useImage: boolean;
  public useCustomTtl: boolean;
  private el: HTMLElement;
  public app$: Observable<AppModel>;
  public until_clicked = false;
  constructor(
    private store: Store<fromRoot.State>,
    private _el: ElementRef,
    private modal: Modal
  ) {
    this.el = _el.nativeElement;
    this.app$ = this.store.select(fromRoot.getAppSelected);
  }
  ngOnInit() {
    if (!this.notification) {
      this.notification = {
        title: '',
        body: '',
        icon: '',
        url: '',
        disappear_instantly: true,
        enable_ios_sound: true
      };
    }
    if (this.notification.disappear_instantly === undefined) {
      this.notification.disappear_instantly = true;
      this.until_clicked = false;
    } else {
      this.until_clicked = !this.notification.disappear_instantly;
    }
    if (this.notification.enable_ios_sound === undefined) {
      this.notification.enable_ios_sound = true;
    }
    if (!this.notification.icon) {
      this.store
        .select(fromRoot.getAppSelected)
        .pipe(take(1))
        .subscribe(app => {
          this.notification.icon = app.icon;
        });
    }
    this.store
      .select(fromRoot.getAppSelected)
      .pipe(take(1))
      .subscribe(app => {
        this.originalImage = app.icon;
        if (!this.notification.icon) {
          this.notification.icon = app.icon;
        }
        if (this.notification.icon === this.originalImage) {
          this.customIcon = false;
        } else {
          this.customIcon = true;
        }
      });
    if (this.enableImage && this.notification.image) {
      this.useImage = true;
    }
    if (this.notification.transmission_time) {
      this.isReservation = true;
      // call toggleReservation to init date picker
      this.toggleReservation();
    }
    if (this.notification.ttl && this.notification.ttl !== defaultTTL) {
      this.useCustomTtl = true;
    }
    if (this.notification.csv_preset_id) {
      this.isSftpPreset = true;
    }
    // queryがstringであれば新セグメント. それ以外(object)であれば旧パラメータ配信
    if (typeof this.notification.query === 'string') {
      this.isSegmentationQuery = true;
    } else if (this.notification.query) {
      this.isQuery = true;
    }
  }
  toggleIcon() {
    if (!this.customIcon) {
      this.store
        .select(fromRoot.getAppSelected)
        .pipe(take(1))
        .subscribe(app => {
          this.notification.icon = app.icon;
        });
      return;
    }
  }
  toggleImage() {
    if (!this.useImage) {
      delete this.notification.image;
    }
  }
  toggleDisappear() {
    this.notification.disappear_instantly = !this.until_clicked;
  }
  private pickers: any[] = [];
  destroyPickers() {
    this.pickers.forEach(el => {
      el.destroy();
    });
    this.pickers = [];
  }
  toggleReservation() {
    // called when isReservation has changed
    if (!this.isReservation) {
      this.notification.transmission_time = null;
      this.destroyPickers();
      return;
    }
    // when making a reservation
    setTimeout(() => {
      // use 0 settimeout hack to make sure picker element has created in the DOM
      // original date is a date that originally specified while initialization
      const picker = this.el.querySelector('.flatpickr');
      const flatpicker = new Flatpicker(picker, {
        enableTime: true,
        minDate: new Date(),
        maxDate: new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000), // set up to two weeks
        defaultDate: this.notification.transmission_time || new Date(),
        time_24hr: true,
        onChange: (selectedDates, dateStr, instance) => {
          this.notification.transmission_time = dateStr;
        }
      }) as any;
      // format date to string due to initialize
      this.notification.transmission_time = flatpicker.formatDate(
        flatpicker.selectedDates[0],
        'Y-m-d H:i'
      );
    }, 0);
  }
  toggleQuery() {
    if (!this.isQuery) {
      this.notification.query = null;
    } else {
      // モーダルを表示してクエリをセット
      this.isSftpPreset = false;
      this.isSegmentationQuery = false;
      this.notification.query = {
        mode: 'AND',
        params: []
      };
      this.editQuery();
    }
  }
  editQuery() {
    this.modal
      .open(QueryEditorComponent, this.notification.query)
      .then((resolved: QueryModel) => {
        this.notification.query = resolved;
      })
      .catch(() => {});
  }
  toggleSftpPreset() {
    if (!this.isSftpPreset) {
      this.notification.csv_preset_id = null;
    } else {
      this.isQuery = false;
      this.isSegmentationQuery = false;
      this.editSftpPreset();
    }
  }
  editSftpPreset() {
    this.modal
      .open(SftpPresetSelectorComponent, this.notification.csv_preset_id)
      .then((resolved: string) => (this.notification.csv_preset_id = resolved))
      .catch(() => {});
  }
  toggleSegmentationQuery() {
    if (!this.isSegmentationQuery) {
      this.notification.query = null;
    } else {
      this.isSftpPreset = false;
      this.isQuery = false;
      this.editSegmentationQuery();
    }
  }
  editSegmentationQuery() {
    this.modal
      .open(SegmentationQuerySelectorComponent)
      .then(({ query: query, name: name }) => {
        this.notification.query = query;
        this.selectedSementName = name;
      })
      .catch(() => {});
  }
  toggleTtl() {
    if (this.useCustomTtl) {
      this.notification.ttl = defaultTTL;
    } else {
      delete this.notification.ttl;
    }
  }
  ngOnDestroy() {
    this.destroyPickers();
  }
}
