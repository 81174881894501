import { ASTParameters, ASTParameter } from './../../../models/segments.model';
import { ASTNodeComponentBase } from '../ast-node.interface';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ast-parameters',
  styles: [
    `
      .tag {
        margin: 0.6rem;
      }
      .warning {
        color: red;
        margin-bottom: 0.6rem;
      }
    `
  ],
  template: `
    <div class="message" [class.is-danger]="error">
      <div
        class="message-header"
        [appAstDraggable]="value"
        (onMoved)="removeFromParentNode.emit()"
      >
        <p>指定パラメータのみ</p>
        <a
          *ngIf="!hideDelete"
          class="delete"
          (click)="removeFromParentNode.emit()"
        ></a>
      </div>
      <div class="message-body">
        <p *ngIf="error" class="warning">{{ error }}</p>
        <form (submit)="add()">
          <div class="field has-addons">
            <div class="control">
              <input
                [(ngModel)]="param"
                name="param"
                class="input"
                type="text"
                placeholder="{{
                  'NOTIFICATION_EDITOR.QUERY_EDITOR.PLACEHOLDER' | translate
                }}"
              />
            </div>
            <div class="control">
              <button type="submit" [disabled]="!param" class="button">
                {{ 'NOTIFICATION_EDITOR.QUERY_EDITOR.ADD' | translate }}
              </button>
            </div>
          </div>
        </form>
        <div>
          <p class="control paramsList" *ngIf="value.parameters.length > 0">
            <span
              class="tag is-dark is-medium"
              *ngFor="let p of value.parameters; index as i"
              >{{ p.parameter
              }}<button (click)="remove(i)" class="delete is-small"></button
            ></span>
          </p>
        </div>
      </div>
    </div>
  `
})
export class ASTParametersComponent
  implements ASTNodeComponentBase<ASTParameters> {
  public param: string;
  @Input()
  hideDelete: boolean;
  @Input()
  value: ASTParameters;
  @Output()
  removeFromParentNode: EventEmitter<void> = new EventEmitter<void>();

  public error: string;
  add() {
    this.error = '';
    if (/\W/.test(this.param)) {
      this.error = 'パラメータに使用できない文字が含まれています';
      return;
    }
    this.value.parameters.push(new ASTParameter(this.param));
    this.param = '';
    this.validate();
  }
  remove(i) {
    this.value.parameters.splice(i, 1);
    this.validate();
  }
  validate() {
    this.error = null;
    const { isValid, error } = this.value.validate();
    if (isValid) {
      return;
    }
    switch (error) {
      case 'EMPTY-PARAMETERS':
        this.error = 'パラメータを1つ以上指定してください';
        break;
    }
  }
}
