import { DropEvent } from './../dd-handler/droppable.directive';
import { SessionService } from './../../../services/session/session.service';
import { ASTValue } from './../../../models/segments.model';
import { ASTNodeComponentBase } from '../ast-node.interface';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  AfterViewInit,
  ElementRef
} from '@angular/core';

@Component({
  selector: 'app-ast-value',
  template: `
    <div *ngIf="value" [class.message]="value.qualifier != 'NULL'">
      <div
        class="message-header"
        [appAstDraggable]="value"
        (onMoved)="removeFromParentNode.emit()"
      >
        <p>{{ value.qualifier }}</p>
        <div>
          <button
            class="delete"
            aria-label="delete"
            style="transform:rotate(45deg);"
            [withoutValue]="true"
            *ngIf="!value.element || !hideDelete"
            appAstAddElem
            (addElem)="putElem($event)"
          ></button>
          <button class="delete" (click)="removeFromParentNode.emit()"></button>
        </div>
      </div>
      <div
        [appAstDroppable]="!value.element"
        (onDrop)="dropHandler($event)"
        [class.message-body]="value.qualifier != 'NULL'"
      >
        <app-ast-node
          *ngIf="value.element"
          [value]="value.element"
          (removeFromParentNode)="deleteElem()"
        ></app-ast-node>
      </div>
    </div>
  `,
  styleUrls: ['../ast.scss']
})
export class ASTValueComponent
  implements OnChanges, AfterViewInit, ASTNodeComponentBase<ASTValue> {
  @Input()
  hideDelete: boolean;
  @Input()
  value: ASTValue;
  @Output()
  removeFromParentNode: EventEmitter<void> = new EventEmitter<void>();

  isValid() {
    return true;
  }

  private _el: Element;
  private headerElem: Element;
  constructor(private el: ElementRef, private session: SessionService) {}
  ngAfterViewInit() {}
  ngOnChanges() {}

  deleteElem() {
    this.value.element = null;
    if (this.value.qualifier === 'NULL') {
      // null単体では成り立たない
      this.removeFromParentNode.emit();
    }
  }
  putElem(newElem) {
    this.value.element = newElem;
  }
  dropHandler(e: DropEvent) {
    this.putElem(e.data);
  }
}
