
    <div class="message" [class.is-danger]="!isValid()" *ngIf="value">
      <div
        class="message-header"
        [appAstDraggable]="value"
        (onMoved)="removeFromParentNode.emit()"
      >
        <p>パラメータ</p>
        <a
          *ngIf="!hideDelete"
          class="delete"
          (click)="removeFromParentNode.emit()"
        ></a>
      </div>
      <div class="message-body">
        <input
          class="input"
          (focusout)="focused = true"
          [(ngModel)]="value.parameter"
          placeholder="parameter"
        />
        <p class="warning">{{ error }}</p>
      </div>
    </div>
  