import { Component } from '@angular/core';
import { ModalContext } from '../../../services/modal/modal.service';
import { DefaultService } from '../../../services/api';

@Component({
  templateUrl: './new-project-modal.component.html'
})
export class NewProjectModalComponent {
  public name = '';
  constructor(private modalCtx: ModalContext, private api: DefaultService) {}
  public close() {
    this.modalCtx.reject();
  }
  public submit() {
    this.api
      .createProject({ name: this.name })
      .subscribe(() => this.modalCtx.resolve());
  }
}
