import { ASTSubscribeDay } from '../../../models/segments.model';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ASTNodeComponentBase } from '../ast-node.interface';

@Component({
  selector: 'app-ast-subscribeday',
  template: `
    <div class="message" [class.is-danger]="!value" *ngIf="value">
      <div
        class="message-header"
        [appAstDraggable]="value"
        (onMoved)="removeFromParentNode.emit()"
      >
        <p>購読日</p>
        <a
          *ngIf="!hideDelete"
          class="delete"
          (click)="removeFromParentNode.emit()"
        ></a>
      </div>
      <div class="message-body">
        <app-segment-datequery-editor
          [(dateQuery)]="value.time"
        ></app-segment-datequery-editor>
      </div>
    </div>
  `
})
export class ASTSubscribeDayComponent
  implements ASTNodeComponentBase<ASTSubscribeDay> {
  @Input()
  hideDelete: boolean;
  @Input()
  value: ASTSubscribeDay;
  @Output()
  removeFromParentNode: EventEmitter<void> = new EventEmitter<void>();
}
