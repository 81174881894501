import { TranslateService } from '@ngx-translate/core';
import { Notification } from './../services/notification/notification.service';
import { StateService } from './../services/state/state.service';
import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { switchMap, map, tap, filter, take } from 'rxjs/operators';

import * as Moment from 'moment';

import * as analytics from '../actions/analytics';
import * as fromRoot from '../reducers';
import * as fromAnalytics from '../reducers/analytics';

import { AppService } from './../services/app/app.service';

const AT = analytics.ActionTypes;

import {
  normalizeBeginning,
  normalizeEnding,
  generateList,
  generateUnit
} from '../helpers/analytics';

@Injectable()
export class AnalyticsEffects {
  constructor(
    private actions$: Actions,
    private appService: AppService,
    private store: Store<fromRoot.State>,
    private state: StateService,
    private notification: Notification,
    private translate: TranslateService
  ) {}
  @Effect()
  info$: Observable<Action> = this.actions$.pipe(
    ofType(AT.INFO_REQUEST),
    switchMap(() =>
      this.appService.analytics_info_get(Moment().format('YYYY-MM'))
    ),
    map(res => new analytics.InfoSuccess(res))
  );
  @Effect()
  subscribers_transition$: Observable<any> = this.actions$.pipe(
    ofType(AT.OVERVIEW_RANGE, AT.OVERVIEW_SCOPE, AT.OVERVIEW_TAB),
    map(() => this.state.get()),
    filter(
      state => state.analytics[state.app.selected].overview.tab === 'total'
    ),
    map(state => state.analytics[state.app.selected]),
    map(state => {
      // 必要なリスト
      const required = generateList(
        state.overview.beginning,
        state.overview.ending,
        state.overview.scope
      );
      const loaded = Object.keys(state.transitions[state.overview.scope]);
      const needToLoad = required.filter(req => loaded.indexOf(req) === -1);
      const oldest = needToLoad[0];
      const latest = needToLoad[needToLoad.length - 1];
      const unit = generateUnit(state.overview.scope);
      let n = 1;
      for (let i = Moment(oldest); i.isBefore(latest); i.add(unit)) {
        n++;
      }
      if (n >= 10000) {
        this.translate
          .get('ANALYTICS.COMMON.TOO_MANY_TO_LOAD')
          .pipe(take(1))
          .subscribe((m: string) => this.notification.open(m, 'warning'));
        return null;
      }
      return {
        n,
        start: oldest,
        scope: state.overview.scope,
        unit
      };
    }),
    filter(x => !!x),
    tap(params => {
      if (!params.start) {
        this.store.dispatch(
          new analytics.OverviewLoaded(this.state.getAppno())
        );
      }
    }),
    filter(params => !!params.start), // startがundefined => ロードの必要がない
    switchMap(params =>
      this.appService.analytics_subscribers_transition(
        params.start,
        params.scope,
        params.n
      )
    ),
    map(
      res =>
        new analytics.OverviewTransitionAdd(this.state.getAppno(), res.counts)
    )
  );
  @Effect()
  subscribers_increase$: Observable<Action> = this.actions$.pipe(
    ofType(AT.OVERVIEW_RANGE, AT.OVERVIEW_SCOPE, AT.OVERVIEW_TAB),
    map(() => this.state.get()),
    filter(
      state => state.analytics[state.app.selected].overview.tab === 'increase'
    ),
    map(state => state.analytics[state.app.selected]),
    map(state => {
      const required = generateList(
        state.overview.beginning,
        state.overview.ending,
        state.overview.scope
      );
      const loaded = Object.keys(state.increases[state.overview.scope]);
      const needToLoad = required.filter(req => loaded.indexOf(req) === -1);
      const oldest = needToLoad[0];
      const latest = needToLoad[needToLoad.length - 1];
      const unit = generateUnit(state.overview.scope);
      let n = 1;
      for (let i = Moment(oldest); i.isBefore(latest); i.add(unit)) {
        n++;
      }
      return {
        n,
        start: oldest,
        scope: state.overview.scope,
        unit
      };
    }),
    tap(params => {
      if (!params.start) {
        this.store.dispatch(
          new analytics.OverviewLoaded(this.state.getAppno())
        );
      }
    }),
    filter(params => !!params.start), // startがundefined => ロードの必要がない
    switchMap(params =>
      this.appService.analytics_subscribers_increase(
        params.start,
        params.scope,
        params.n
      )
    ),
    map(
      res =>
        new analytics.OverviewIncreaseAdd(this.state.getAppno(), res.counts)
    )
  );
  @Effect()
  add$: Observable<Action> = this.actions$.pipe(
    ofType(analytics.ActionTypes.OVERVIEW_TRANSITION_ADD),
    map(() => new analytics.OverviewLoaded(this.state.getAppno()))
  );
  @Effect()
  subscribers$: Observable<Action> = this.actions$.pipe(
    ofType(analytics.ActionTypes.SUBSCRIBERS_REQUEST),
    switchMap(() => this.appService.analytics_subscribers()),
    map(
      res =>
        new analytics.SubscribersSuccess(this.state.getAppno(), res.subscribers)
    )
  );
  @Effect()
  params$: Observable<Action> = this.actions$.pipe(
    ofType(analytics.ActionTypes.PARAMS_REQUEST),
    switchMap(() => this.appService.analytics_parameters()),
    map(
      res => new analytics.ParamsSuccess(this.state.getAppno(), res.parameters)
    )
  );
}
