<div class="columns is-tablet">
  <div class="column is-10 is-offset-1">
    <div class="container main-container">
      <div class="container-body">
        <ng-container
          *appDisplayFor="'operator'; fallback: InsufficientPermission"
        ></ng-container>
        <ng-template #InsufficientPermission>
          <div class="notification is-info is-light">
            「閲覧者」として設定されているため、セグメントの編集は行うことができません
          </div>
        </ng-template>
        <ng-container *ngIf="!!ASTRoot; else Loading">
          <label class="label">セグメント名</label>
          <div class="control">
            <input
              class="input"
              [(ngModel)]="name"
              type="text"
              [appEnableFor]="'administrator'"
            />
          </div>
          <hr />
          <app-segment-editor [ASTRoot]="ASTRoot"></app-segment-editor>
          <hr />
          <div class="is-flex" style="justify-content: space-between;">
            <a class="p7-button button" (click)="back()">
              セグメント一覧に戻る
            </a>
            <div class="has-text-right">
              <button
                class="p7-button button is-danger"
                (click)="remove()"
                style="margin-right: .8rem;"
                [appEnableFor]="'operator'"
              >
                削除
              </button>
              <button
                class="p7-button button is-success"
                (click)="save()"
                [appEnableFor]="'operator'"
              >
                保存
              </button>
            </div>
          </div>
        </ng-container>
        <ng-template #Loading>
          <app-loading></app-loading>
        </ng-template>
      </div>
    </div>
  </div>
</div>
