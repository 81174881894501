<div class="analytics-wrapper">
  <div class="analytics-columns columns is-multiline">
    <dl class="card analytics-panel param-panel column is-5">
      <dt class="param-panel-name">
        累計購読者数
      </dt>
      <dd class="param-panel-status">
        {{ (app$ | async)?.total_subscribers | number }}
      </dd>
    </dl>

    <dl class="card analytics-panel param-panel column is-5">
      <dt class="param-panel-name">
        現在有効な購読者数
      </dt>
      <dd class="param-panel-status">
        {{ (app$ | async)?.active_subscribers | number }}
      </dd>
    </dl>

    <dl class="card analytics-panel param-panel column is-5">
      <dt class="param-panel-name">
        {{ 'ANALYTICS.OVERVIEW.MONTH_SENT' | translate }}
      </dt>
      <dd class="param-panel-status">
        <ng-container *ngIf="info$ | async as info; else loadingSent">
          {{ info.sent_push_count | number }}
        </ng-container>
        <ng-template #loadingSent
          ><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i
        ></ng-template>
      </dd>
    </dl>

    <dl class="card analytics-panel param-panel column is-5">
      <dt class="param-panel-name">
        {{ 'ANALYTICS.OVERVIEW.MONTH_CLICKED' | translate }}
      </dt>
      <dd class="param-panel-status">
        <ng-container *ngIf="info$ | async as info; else loadingClicked">
          {{ info.clicked_count | number }}
        </ng-container>
        <ng-template #loadingClicked
          ><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i
        ></ng-template>
      </dd>
    </dl>
  </div>

  <div class="analytics-columns columns">
    <section class="analytics-graph card">
      <header class="graph-header">
        <p class="title is-5">
          {{ 'ANALYTICS.OVERVIEW.NUM_OF_SUBSCRIBERS' | translate }}
        </p>
        <app-range-picker
          [startDate]="startDate$"
          [endDate]="endDate$"
        ></app-range-picker>
      </header>

      <div class="graph-content">
        <div class="graph-menu">
          <ul>
            <li>
              <button
                class="button is-light"
                [class.is-active]="(scope$ | async) == 'hour'"
                (click)="changeScope('hour')"
              >
                {{ 'ANALYTICS.OVERVIEW.SCOPE.HOURLY' | translate }}
              </button>
            </li>
            <li>
              <button
                class="button is-light"
                [class.is-active]="(scope$ | async) == 'day'"
                (click)="changeScope('day')"
              >
                {{ 'ANALYTICS.OVERVIEW.SCOPE.DAILY' | translate }}
              </button>
            </li>
            <li>
              <button
                class="button is-light"
                [class.is-active]="(scope$ | async) == 'week'"
                (click)="changeScope('week')"
              >
                {{ 'ANALYTICS.OVERVIEW.SCOPE.WEEKLY' | translate }}
              </button>
            </li>
            <li>
              <button
                class="button is-light"
                [class.is-active]="(scope$ | async) == 'month'"
                (click)="changeScope('month')"
              >
                {{ 'ANALYTICS.OVERVIEW.SCOPE.MONTHLY' | translate }}
              </button>
            </li>
          </ul>
        </div>
        <div class="graph-wrapper">
          <app-ovewview-chart
            [chartType]="type$ | async"
            [scope]="scope$ | async"
            [startDate]="startDate$ | async"
            [endDate]="endDate$ | async"
          ></app-ovewview-chart>
        </div>
      </div>

      <footer class="graph-footer">
        <div class="tabs is-medium p7-tabs">
          <ul>
            <li [class.is-active]="(type$ | async) == 'ACTIVE_SUBSCRIBERS'">
              <a
                class="has-text-black-bis"
                (click)="type$.next('ACTIVE_SUBSCRIBERS')"
                >有効購読者数</a
              >
            </li>

            <li [class.is-active]="(type$ | async) == 'CUMULATIVE_SUBSCRIBERS'">
              <a
                class="has-text-black-bis"
                (click)="type$.next('CUMULATIVE_SUBSCRIBERS')"
                >累計購読者数</a
              >
            </li>

            <li [class.is-active]="(type$ | async) == 'NEW_SUBSCRIBERS'">
              <a
                class="has-text-black-bis"
                (click)="type$.next('NEW_SUBSCRIBERS')"
                >購読者数変化</a
              >
            </li>
          </ul>
        </div>
      </footer>
    </section>
  </div>
</div>
