import { ASTParameter } from './../../../models/segments.model';
import { ASTNodeComponentBase } from '../ast-node.interface';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ast-parameter',
  styles: [
    `
      .warning {
        color: red;
      }
    `
  ],
  template: `
    <div class="message" [class.is-danger]="!isValid()" *ngIf="value">
      <div
        class="message-header"
        [appAstDraggable]="value"
        (onMoved)="removeFromParentNode.emit()"
      >
        <p>パラメータ</p>
        <a
          *ngIf="!hideDelete"
          class="delete"
          (click)="removeFromParentNode.emit()"
        ></a>
      </div>
      <div class="message-body">
        <input
          class="input"
          (focusout)="focused = true"
          [(ngModel)]="value.parameter"
          placeholder="parameter"
        />
        <p class="warning">{{ error }}</p>
      </div>
    </div>
  `
})
export class ASTParameterComponent
  implements ASTNodeComponentBase<ASTParameter> {
  @Input()
  hideDelete: boolean;
  @Input()
  value: ASTParameter;
  @Output()
  removeFromParentNode: EventEmitter<void> = new EventEmitter<void>();

  public error: string;
  private focused = false;
  isValid() {
    this.error = null;
    if (!this.focused) {
      return true;
    }
    const result = this.value.validate();
    if (result.isValid) {
      return true;
    }
    switch (result.error) {
      case 'EMPTY-PARAMETER':
        this.error = 'パラメータを入力してください';
        break;
      case 'CONTAINS-INVALID-CHARACTER':
        this.error = 'パラメータに使用できない文字が含まれています';
        break;
    }
    return false;
  }
}
