import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type Parameters = string[];

@Component({
  selector: 'app-automation-delete-parameters-action-form',
  template: `
    <div class="field">
      <article class="message is-info">
        <div class="message-header">
          オートメーションルールによるパラメータの削除
        </div>
        <div class="message-body">
          <p>
            オートメーションルールが実行された際、「実行対象」であり「スキップ条件」を満たさない購読者からパラメータを削除することができます。
          </p>
          <p>
            パラメータは、改行区切りで入力してください。パラメータに利用できるのは、半角英数字・ハイフン・アンダーバーです。
          </p>
          <p>
            ワイルドカードとして、<code>%</code>を利用できます。<code>%</code>は、0文字以上の任意の文字列とマッチし、マッチしたパラメータ全てが削除されます。
          </p>
        </div>
      </article>
      <div class="control">
        <textarea
          class="textarea"
          placeholder="parameter1
parameter2"
          [(ngModel)]="strParameters"
        ></textarea>
      </div>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(
        () => AutomationDeleteParametersActionFormComponent
      )
    }
  ]
})
export class AutomationDeleteParametersActionFormComponent
  implements ControlValueAccessor {
  private onChange: any = () => {};
  private onTouched: any = () => {};

  private _strParameters = '';
  get strParameters(): string {
    return this._strParameters;
  }
  set strParameters(str: string) {
    this._strParameters = str;
    this.onChange(this.value);
  }
  get value(): Parameters {
    return this.strParameters.split('\n').filter(x => x.length > 0);
  }
  @Input('value')
  set value(params: Parameters) {
    if (params) {
      this.strParameters = params.join('\n');
      this.onChange(this.value);
    } else {
      this.onChange(params);
    }
    this.onTouched();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
