import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ConnectableObservable, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  publishReplay,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { ConfirmationService } from '../../../../services/confirmation/confirmation.service';
import { PlanModel } from '../../../../models/plan.model';
import { getAppSelected, getAppSelectedId, State } from '../../../../reducers';
import { AuthService } from '../../../../services/auth/auth.service';
import { SessionService } from '../../../../services/session/session.service';
import { AppService } from '../../../../services/app/app.service';
import { Notification } from '../../../../services/notification/notification.service';
import { LoadAppAction } from '../../../../actions/app';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-plan-options',
  templateUrl: './plan-options.component.html',
  styles: [
    `
      .table.breakdown tbody tr td {
        white-space: normal;
      }
      .table.breakdown tr td:nth-child(2) {
        width: 160px;
      }
    `
  ]
})
export class PlanOptionsComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject();
  public form: FormGroup;
  public plan: PlanModel;
  public is_corporation$: Observable<boolean>;
  public preview$: Observable<any>;
  public currentSegmentationStatus$: Observable<boolean>;
  public processing = false;
  public previewErrorMessage = '';
  constructor(
    private store: Store<State>,
    private authService: AuthService,
    private session: SessionService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private appService: AppService,
    private notificationService: Notification
  ) {
    this.is_corporation$ = this.store.select(getAppSelectedId).pipe(
      takeUntil(this.onDestroy$),
      switchMap(appno => this.authService.getProjectFromAppno(appno)),
      map(project => project.is_corporation),
      shareReplay(1)
    );
    this.currentSegmentationStatus$ = this.store.select(getAppSelected).pipe(
      takeUntil(this.onDestroy$),
      map(app => app.permissions.parameter),
      shareReplay(1)
    );
  }
  ngOnInit() {
    // このページの利用にはplanがセッションにあることが前提
    this.plan = this.session.get('plan');
    if (!this.plan) {
      this.router.navigate(['..'], { relativeTo: this.route });
      return;
    }

    // フォームを作る
    this.form = this.fb.group({
      enterprise_payment_frequency: this.fb.control(null, [
        Validators.required
      ]),
      enterprise_segment_option: this.fb.control(null, [Validators.required])
    });
    this.currentSegmentationStatus$.subscribe(alreadyPurchasedSegmentation => {
      if (alreadyPurchasedSegmentation) {
        this.form.get('enterprise_segment_option').setValue(true);
      }
    });

    // Dry-runの実行
    this.preview$ = this.form.valueChanges.pipe(
      tap(() => (this.previewErrorMessage = '')),
      filter(() => this.form.valid),
      switchMap(values =>
        this.appService
          .role_upgrade_enterprise(this.plan.no, values, true)
          .pipe(
            catchError((error: HttpErrorResponse) => {
              this.previewErrorMessage = error.error.error;
              return of(void 0);
            })
          )
      ),
      takeUntil(this.onDestroy$),
      publishReplay(1)
    );
    // template側がngIfの条件内で購読しているため、ここで動かしておく
    (this.preview$ as ConnectableObservable<any>).connect();
  }
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  submit(ev: Event) {
    ev.preventDefault();
    this.confirmationService
      .open({
        title: '契約確認',
        body:
          'ご指定のプラン・オプション及び支払い頻度で契約をします。よろしいですか？',
        confirmLabel: '契約',
        cancelLabel: 'キャンセル'
      })
      .then(() => {
        this.processing = true;
        this.appService
          .role_upgrade_enterprise(this.plan.no, this.form.value, false)
          .pipe(finalize(() => (this.processing = false)))
          .subscribe(
            () => {
              this.notificationService.open('プランを更新しました', 'success');
              this.router.navigate(['../list'], { relativeTo: this.route });
              this.store
                .select(getAppSelectedId)
                .pipe(take(1))
                .subscribe(appno => {
                  this.store.dispatch(new LoadAppAction(appno));
                });
            },
            (response: HttpErrorResponse) => {
              this.notificationService.open(
                `プランの更新に失敗しました。${response.error.error}`,
                'danger'
              );
            }
          );
      })
      .catch(() => (this.processing = false));
  }
}
