<div class="main-nav" [innerHTML]="'AUTO.LABEL' | translate"></div>

<div class="tabs is-medium p7-tabs">
  <ul class="tabs-gray">
    <li [routerLink]="['rss']" [routerLinkActive]="['is-active']">
      <a>RSS</a>
    </li>
    <li
      [routerLink]="['wp']"
      [routerLinkActive]="['is-active']"
      *appDisplayFor="'administrator'"
    >
      <a>WordPress</a>
    </li>
    <li [routerLink]="['welcome']" [routerLinkActive]="['is-active']">
      <a>Welcome</a>
    </li>
  </ul>
</div>

<router-outlet></router-outlet>
