<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <div class="box">
      <div class="content-header is-yellow">
        {{ 'NOTIFICATION_EDITOR.SEGMENT.LABEL' | translate }}
      </div>
      <div>
        <ng-container *ngIf="queries$ | async; let queries; else: Loading">
          <ng-container *ngIf="queries.length > 0; else NoQueries">
            <table class="table is-striped is-fullwidth">
              <thead>
                <tr>
                  <th>{{ 'SEGMENTATION.NAME' | translate }}</th>
                  <th>{{ 'SEGMENTATION.QUERY' | translate }}</th>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let query of queries">
                  <td>{{ query.name }}</td>
                  <td>{{ query.query }}</td>
                  <td>
                    <a
                      class="button is-info p7-button is-outlines"
                      (click)="select(query.name, query.query)"
                      >{{ 'NOTIFICATION_EDITOR.SEGMENT.SELECT' | translate }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <ng-template #NoQueries>
            <div class="box">
              <div class="content">
                <article class="message is-info">
                  <div class="message-header">
                    <p>{{ 'SEGMENTATION.NOQUERY' | translate }}</p>
                  </div>
                  <div class="message-body">
                    <p>{{ 'SEGMENTATION.NOQUERY_MESSAGE' | translate }}</p>
                  </div>
                </article>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #Loading>
          <app-loading></app-loading>
        </ng-template>
      </div>
    </div>
  </div>
</div>
