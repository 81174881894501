import {
  Injectable,
  ViewContainerRef,
  ComponentFactoryResolver,
  ReflectiveInjector,
  ComponentRef
} from '@angular/core';
import { ConfirmationComponent } from './confirmation.component';

export class ConfirmationContext {
  constructor(
    private _resolve: Function,
    private _reject: Function,
    public title: string,
    public body: string,
    public confirmLabel = 'OK',
    public confirmClass = 'is-success',
    public cancelLabel = 'キャンセル',
    public cancelClass = 'is-danger'
  ) {}
  resolve(val?: any) {
    this._resolve(val);
  }
  reject(reason?: any) {
    this._reject(reason);
  }
}

@Injectable()
export class ConfirmationService {
  public vcr: ViewContainerRef;
  public count = 0;
  constructor(private cfr: ComponentFactoryResolver) {}
  set(p: ViewContainerRef) {
    this.vcr = p;
  }
  open(options: {
    title: string;
    body: string;
    confirmLabel?: string;
    confirmClass?: string;
    cancelLabel?: string;
    cancelClass?: string;
  }): Promise<void> {
    let cr: ComponentRef<any>;
    return new Promise((resolve, reject) => {
      const cf = this.cfr.resolveComponentFactory(ConfirmationComponent);
      const _resolve = val => {
        if (cr) {
          cr.destroy();
          resolve(val);
          this.count--;
        }
      };
      const _reject = (reason?: any) => {
        if (cr) {
          cr.destroy();
          reject(reason);
          this.count--;
        }
      };
      const bindings = ReflectiveInjector.resolve([
        {
          provide: ConfirmationContext,
          useValue: new ConfirmationContext(
            _resolve,
            _reject,
            options.title,
            options.body,
            options.confirmLabel,
            options.confirmClass,
            options.cancelLabel,
            options.cancelClass
          )
        }
      ]);
      const ctxInjector = this.vcr.parentInjector;
      const injector = ReflectiveInjector.fromResolvedProviders(
        bindings,
        ctxInjector
      );

      cr = this.vcr.createComponent(cf, this.vcr.length, injector);
      this.vcr.element.nativeElement.appendChild(cr.location.nativeElement);
      this.count++;
    });
  }
}
