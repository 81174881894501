import { ASTNode } from './../../models/segments.model';
import { ASTNodeComponentBase } from './ast-node.interface';
import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ast-node',
  template: `
    <div class="ast-leaf" [ngSwitch]="value.className">
      <app-ast-inner-node
        *ngSwitchCase="'ASTInnerNode'"
        [value]="value"
        [hideDelete]="false"
        (removeFromParentNode)="handleDelete()"
      ></app-ast-inner-node>
      <app-ast-value
        *ngSwitchCase="'ASTValue'"
        (removeFromParentNode)="handleDelete()"
        [value]="value"
        [hideDelete]="hideDelete"
      ></app-ast-value>
      <app-ast-parameter
        *ngSwitchCase="'ASTParameter'"
        (removeFromParentNode)="handleDelete()"
        [value]="value"
        [hideDelete]="hideDelete"
      ></app-ast-parameter>
      <app-ast-parameters
        *ngSwitchCase="'ASTParameters'"
        (removeFromParentNode)="handleDelete()"
        [value]="value"
        [hideDelete]="hideDelete"
      ></app-ast-parameters>
      <app-ast-browser
        *ngSwitchCase="'ASTBrowser'"
        (removeFromParentNode)="handleDelete()"
        [value]="value"
        [hideDelete]="hideDelete"
      ></app-ast-browser>
      <app-ast-os
        *ngSwitchCase="'ASTOS'"
        (removeFromParentNode)="handleDelete()"
        [value]="value"
        [hideDelete]="hideDelete"
      ></app-ast-os>
      <app-ast-opened
        *ngSwitchCase="'ASTOpened'"
        (removeFromParentNode)="handleDelete()"
        [value]="value"
        [hideDelete]="hideDelete"
      ></app-ast-opened>
      <app-ast-sent
        *ngSwitchCase="'ASTSent'"
        (removeFromParentNode)="handleDelete()"
        [value]="value"
        [hideDelete]="hideDelete"
      ></app-ast-sent>
      <app-ast-lastopened
        *ngSwitchCase="'ASTLastOpened'"
        (removeFromParentNode)="handleDelete()"
        [value]="value"
        [hideDelete]="hideDelete"
      ></app-ast-lastopened>
      <app-ast-subscribeday
        *ngSwitchCase="'ASTSubscribeDay'"
        (removeFromParentNode)="handleDelete()"
        [value]="value"
        [hideDelete]="hideDelete"
      ></app-ast-subscribeday>
      <app-ast-lastvisit
        *ngSwitchCase="'ASTLastVisit'"
        (removeFromParentNode)="handleDelete()"
        [value]="value"
        [hideDelete]="hideDelete"
      ></app-ast-lastvisit>
      <app-ast-lastvisit-url
        *ngSwitchCase="'ASTLastVisitUrl'"
        (removeFromParentNode)="handleDelete()"
        [value]="value"
        [hideDelete]="hideDelete"
      ></app-ast-lastvisit-url>
      <ng-container *ngSwitchDefault>
        <p>未定義のASTElem: {{ value.className }}</p>
      </ng-container>
    </div>
  `
})
export class AstNodeComponent implements OnInit, ASTNodeComponentBase<ASTNode> {
  @Input()
  hideDelete = false;
  @Input()
  value: ASTNode;
  @Output()
  removeFromParentNode: EventEmitter<void> = new EventEmitter<void>();
  ngOnInit() {}
  handleDelete() {
    this.removeFromParentNode.emit();
  }
}
