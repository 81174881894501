import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { ProvisionalService } from '../../../services/provisional/provisional.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';

import { Notification } from './../../../services/notification/notification.service';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [ProvisionalService]
})
export class SignupComponent implements OnInit {
  private token: string;
  public error: string;
  public form: FormGroup;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private provisionalService: ProvisionalService,
    private translate: TranslateService,
    private store$: Store<fromRoot.State>,
    private notification: Notification,
    private _fb: FormBuilder,
    private authService: AuthService
  ) {
    this.authService.isLoggedIn$.subscribe(u => {
      if (u) {
        this.router.navigate(['/home']);
      }
    });
  }

  private areEqual(group: FormGroup): { [key: string]: boolean } {
    const password = group.controls['password'].value;
    const confirm = group.controls['confirm'].value;
    if (password === confirm) {
      return null;
    }
    return {
      areEqual: true
    };
  }

  ngOnInit() {
    this.route.params.subscribe(res => (this.token = res['token']));
    this.form = this._fb.group({
      email: ['', [Validators.required, Validators.email]],
      passwords: this._fb.group(
        {
          password: [
            '',
            [
              Validators.required,
              Validators.minLength(6),
              Validators.maxLength(72)
            ]
          ],
          confirm: [
            '',
            [
              Validators.required,
              Validators.minLength(6),
              Validators.maxLength(72)
            ]
          ]
        },
        { validator: this.areEqual }
      ),
      is_corporation: [null, [Validators.required]],
      agreed: [false, [Validators.requiredTrue]]
    });
  }

  submit() {
    this.error = null;
    let payload = {
      email: null,
      password: null,
      token: null,
      is_corporation: null
    };
    payload.email = this.form.get('email').value;
    payload.password = this.form.get('passwords').get('password').value;
    payload.token = this.token;
    payload.is_corporation = this.form.get('is_corporation').value;
    this.provisionalService.signup(payload).subscribe(
      res => {
        this.translate.get('SIGNUP.COMPLETED').subscribe((m: string) => {
          this.authService
            .login(payload.email, payload.password)
            .subscribe(() => this.router.navigate(['home']));
          this.notification.open(m, 'success');
        });
      },
      (response: HttpErrorResponse) => {
        this.error = response.error.error;
      }
    );
  }
}
