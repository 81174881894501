import { TranslateService } from '@ngx-translate/core';
import { Notification } from './../../services/notification/notification.service';
import { UserService } from './../../services/user/user.service';
import { Component, OnInit } from '@angular/core';
import * as fromRoot from '../../reducers';
import { Go } from './../../actions/router';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  public current: string;
  public next: string;
  public confirm: string;

  constructor(
    private user: UserService,
    private notification: Notification,
    private store: Store<fromRoot.State>,
    private translate: TranslateService
  ) {}
  submit() {
    if (this.next !== this.confirm) {
      this.translate.get('PASSWORD.NOT_MATCH').subscribe((m: string) => {
        this.notification.open(m, 'danger');
      });
      return;
    }
    this.user.password(this.current, this.next).subscribe(
      success => {
        this.translate.get('PASSWORD.SUCCESS').subscribe((m: string) => {
          this.notification.open(m, 'success');
        });
        this.store.dispatch(new Go({ path: ['home'] }));
      },
      error => {
        this.translate.get('PASSWORD.ERROR').subscribe((m: string) => {
          this.notification.open(m, 'danger');
        });
      }
    );
  }
  ngOnInit() {}
}
