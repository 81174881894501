import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromRoot from '../../reducers';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { Modal } from './../../services/modal/modal.service';
import { AccountDeleteModalComponent } from './modal/account-delete-modal.component';
import { ProfileResponse } from '../../services/backend';
import { AuthService } from '../../services/auth/auth.service';
import { AppModel } from '../../models/app.model';

@Component({
  selector: 'app-account-delete',
  templateUrl: './account-delete.component.html',
  styleUrls: ['./account-delete.component.scss']
})
export class AccountDeleteComponent implements OnDestroy {
  public profile$: Observable<ProfileResponse>;
  public apps$: Observable<AppModel[]>;
  public subscriptions: Subscription[] = [];
  public canDeleteAccount$: Observable<boolean>;
  public email: string;
  public agreed = false;

  public q1_answer;
  public q1_options: string[] = [];
  public q1_other = '';

  public q2_answer;
  public q2_options: string[] = [];
  public q2_other = '';

  public opinion = '';

  constructor(
    private store$: Store<fromRoot.State>,
    private modal: Modal,
    private translate: TranslateService,
    private authService: AuthService
  ) {
    this.profile$ = authService.profile$.pipe(debounceTime(1));
    this.apps$ = authService.apps$;
    this.subscriptions.push(
      this.profile$.subscribe(user => (this.email = user.email)),
      this.translate
        .get('ACCOUNT_DELETE.Q1_OPTIONS')
        .subscribe(jsonoptions => (this.q1_options = JSON.parse(jsonoptions))),
      this.translate
        .get('ACCOUNT_DELETE.Q2_OPTIONS')
        .subscribe(jsonoptions => (this.q2_options = JSON.parse(jsonoptions)))
    );
    // 所属しているProjectのうち、権限がAdministratorのものがあれば抜けられない
    this.canDeleteAccount$ = this.authService.projects$.pipe(
      map(
        projects => !projects.some(project => project.role === 'administrator')
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  get isValid() {
    if (this.q1_answer === undefined) {
      return false;
    }
    if (this.q1_answer === this.q1_options.length - 1 && this.q1_other === '') {
      return false;
    }
    if (this.q1_answer === 1 && this.q2_answer === undefined) {
      return false;
    }
    if (
      this.q1_answer === 1 &&
      this.q2_answer === this.q2_options.length - 1 &&
      this.q2_other === ''
    ) {
      return false;
    }

    return true;
  }

  public perform() {
    let q1,
      q2 = '';
    if (this.q1_answer === this.q1_options.length - 1) {
      q1 = this.q1_other;
    } else {
      q1 = this.q1_options[this.q1_answer];
    }
    if (this.q1_answer === 1) {
      if (this.q2_answer === this.q2_options.length - 1) {
        q2 = this.q2_other;
      } else {
        q2 = this.q2_options[this.q2_answer];
      }
    }
    this.modal
      .open(AccountDeleteModalComponent, {
        email: this.email,
        reason: q1,
        reason_detail: q2,
        comments: this.opinion
      })
      .then(
        () => {},
        () => {}
      );
  }
}
