<div class="modal is-active">
  <div class="modal-background" (click)="close()"></div>
  <div class="modal-content">
    <form class="box" (submit)="submit()">
      <div class="content-header is-yellow">
        新規セグメントクエリ
      </div>
      <div class="content">
        <form>
          <label class="label">セグメント名</label>
          <p class="control">
            <input class="input" name="name" type="text" [formControl]="form" />
          </p>
        </form>
      </div>
      <div class="content-footer">
        <div class="flex has-text-right">
          <button class="button" (click)="close()">
            キャンセル
          </button>
          <button
            class="button is-success"
            type="submit"
            [disabled]="form.invalid"
            [class.is-loading]="processing"
          >
            {{ 'HOME.NEWAPP.SUBMIT' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
